<script lang="ts" setup>
import {useAuthStore} from "../../stores/authStore";
import {defineEmits, defineProps, onMounted, provide, reactive, ref, toRefs,} from "vue";
import {required} from "@vuelidate/validators";
import {useVuelidate} from "@vuelidate/core";
import {useHelperStore} from "../../stores/helperStore";
import {useUiStore} from "../../stores/uiStore";
import {useRoute} from "vue-router";
import {useFreskdeskStore} from "../../stores/freshdeskStore";
import {useEmployeesStore} from "../../stores/employeesStore";
import {useTicketsStore} from "../../stores/ticketsStore";
import moment from "moment";
import Button from "../../base-components/Button";
import {ClassicEditor} from "../../base-components/Ckeditor";
import Notification, {NotificationElement} from "../../base-components/Notification";
import Lucide from "../../base-components/Lucide";
import router from "../../router";
import EmployeeInterface from "../interfaces/employeesInterface";

const toastData = reactive({
    message: "",
    subject: "",
    severity: "",
});
const TicketsStore = useTicketsStore();

const props = defineProps({
    ticketTypeId: Number,
});

const emit = defineEmits(["output", "ticketType"]);

const AuthStore = useAuthStore();

const HelperStore = useHelperStore();

const UiStore = useUiStore();

const EmployeesStore = useEmployeesStore();
const FreshdeskStore = useFreskdeskStore();

const route = useRoute();
const id = Array.isArray(route.query.id) ? route.query.id[0] : route.query.id;
const employee = reactive({
    data: null as EmployeeInterface | null,
});
const ticketForm = reactive({
    subject: "Request for Certificate of Employment",
    reason: "",
    includeSalary: "",
    includePositon: "",
    includeAddress: "",
});

const editorConfig = {
    toolbar: {
        items: ["bold", "italic", "link"],
    },
};

const rules = {
    reason: {required},
};

const attachments = reactive({
    file: [],
});

onMounted(async () => {
    //@ts-ignore
    //@ts-ignore
    console.log("populateEmployeeDetails", window.Laravel.user.id);

    await EmployeesStore.getUserEmployeeData(
        //@ts-ignore
        parseInt(window.Laravel.user.id)
    ).then((response) => {
        employee.data = EmployeesStore.userEmployee;
        console.log("AU", employee.data);
    });
});

const formReset = () => {
    ticketForm.reason = "";
    ticketForm.includeSalary = "";
    ticketForm.includePositon = "";
    ticketForm.includeAddress = "";
};

const validate = useVuelidate(rules, toRefs(ticketForm));
const submitTicket = async () => {
    const currentDate = moment().format("MMMM Do YYYY, h:mm:ss a");
    const includeSalary = ticketForm.includeSalary ? "yes" : "no";
    const includePositon = ticketForm.includePositon ? "yes" : "no";
    const includeAddress = ticketForm.includeAddress ? "yes" : "no";

    validate.value.$touch();

    if (validate.value.$invalid) {
        toastData.severity = "danger";
        toastData.message = "All fields required!";
        toastData.subject = "Please provide all the required information";
        console.log("show error notification");
    } else {
        const ticketType = HelperStore.getTicketType(Number(id ?? 0));

        const desc_data = new FormData();
        desc_data.append("Date Filed", currentDate);
        //desc_data.append('Position', employee.data.position);
        desc_data.append("Should include Salary", includeSalary);
        desc_data.append("Should include Position", includePositon);
        desc_data.append("Should include Address", includeAddress);
        desc_data.append("Reason for Request", ticketForm.reason);

        var form_body = {};
        //@ts-ignore
        desc_data.forEach((value, key) => (form_body[key] = value));

        const form = new FormData();
        form.append("id", String(id));
        //@ts-ignore
        const userName = window.Laravel.user.name;
        form.append(
            "subject",
            ticketForm.subject + ": " + userName
        );
        form.append("priority", ticketType?.f_priority_id?.toString() ?? '');
        form.append("status", ticketType?.f_status_id?.toString() ?? '');
        form.append("type", ticketType?.f_type?.toString() ?? '');
        form.append("form_body", JSON.stringify(form_body));
        form.append("ticket_type_id", ticketType?.id?.toString() ?? '');

        TicketsStore.save(form).then((response) => {
            if (response.status === "success") {
                validate.value.$reset();
                emit("output", {render: true, type: "success"});
                if (id !== null) {
                    emit("ticketType", HelperStore.getTicketType(parseInt(id)));
                }
                showSuccessNotification();
                formReset();
                router.push({
                    name: "ticketDetails",
                    params: {id: response.data.id},
                });
            } else {
                emit("output", {render: true, type: "danger"});
            }
        });
    }
};

const successNotification = ref<NotificationElement>();
const successNotificationToggle = () => {
    // Show notification
    successNotification.value?.showToast();
};

provide("bind[successNotification]", (el: NotificationElement) => {
    successNotification.value = el;
});

const showSuccessNotification = () => {
    successNotificationToggle();
};
</script>
<template>
    <div class="intro-y box p-5">
        <div class="mt-3">
            <label
            >Reason for Request
                <span style="color: rgb(255, 0, 0)">*</span></label
            >
            <div class="mt-2">
                <ClassicEditor
                    id="reason"
                    v-model="ticketForm.reason"
                    :config="editorConfig"
                    name="reason"
                />
                <template v-if="validate.reason.$error">
                    <div
                        v-for="(error, index) in validate.reason.$errors"
                        id="error_reason"
                        :key="index"
                        class="text-danger mt-2"
                    >
                        {{ error.$message }}
                    </div>
                </template>
            </div>
        </div>

        <div class="mt-3">
            <input
                id="includeSalary"
                v-model="ticketForm.includeSalary"
                class="form-check-input"
                name="includeSalary"
                type="checkbox"
            />
            <label class="form-label" for="crud-form-1"
            >&nbsp; Should include Salary?</label
            >
        </div>

        <div class="mt-3">
            <input
                id="includePositon"
                v-model="ticketForm.includePositon"
                class="form-check-input"
                name="includePositon"
                type="checkbox"
            />
            <label class="form-label" for="crud-form-1"
            >&nbsp; Should include Position?</label
            >
        </div>

        <div class="mt-3">
            <input
                id="includeAddress"
                v-model="ticketForm.includeAddress"
                class="form-check-input"
                name="includeAddress"
                type="checkbox"
            />
            <label class="form-label" for="crud-form-1"
            >&nbsp; Should include Address?</label
            >
        </div>

        <div class="text-right mt-5">
            <router-link
                class="btn text-white bg-warning py-2 rounded-md w-28 text-center"
                to="/submit-a-ticket"
                type="button"
            >
                Cancel
            </router-link>
            <Button
                class="ml-2"
                type="button"
                variant="customPrimary"
                @click="submitTicket"
            >Submit Ticket
            </Button>
        </div>
    </div>
    <!-- Success Notification -->
    <Notification
        :options="{
            duration: 3000,
        }"
        class="flex"
        refKey="successNotification"
    >
        <Lucide class="text-success" icon="CheckCircle"/>
        <div class="ml-4 mr-4">
            <div class="font-medium">System Notification</div>
            <div class="mt-1 text-slate-500">
                Ticket was created successfully.
            </div>
        </div>
    </Notification>
    <!-- Success Notification -->
</template>
