<script lang="ts" setup>
import { useRoute } from "vue-router";
import Divider from "@/pages/layout/Divider.vue";
import Menu from "@/pages/layout/Menu.vue";
import TopBar from "@/pages/layout/TopBar/TopBar.vue";
import MobileMenu from "@/pages/layout/MobileMenu/MobileMenu.vue";
import { useSideMenuStore } from "@/stores/side-menu";
import { enter, FormattedMenu, leave, nestedMenu } from "./side-menu";
import { computed, onMounted, reactive, watch, ref, provide } from "vue";
import { useHelperStore } from "@/stores/helperStore";
import { useAuthStore } from "@/stores/authStore";
import AlertForgeBuild from "@/pages/components/AlertForgeBuild.vue";
import { Dialog } from "@/base-components/Headless";
import Button from "@/base-components/Button";

const AuthStore = useAuthStore();
const route = useRoute();
let formattedMenu = reactive<Array<FormattedMenu | "divider">>([]);
const setFormattedMenu = (
    computedFormattedMenu: Array<FormattedMenu | "divider">
) => {
    Object.assign(formattedMenu, computedFormattedMenu);
};
const sideMenuStore = useSideMenuStore();
sideMenuStore.processAvailableMenu();
const sideMenu = computed(() => nestedMenu(sideMenuStore.menu, route));

watch(sideMenu, () => {
    setFormattedMenu(sideMenu.value);
});

const helperStore = useHelperStore();
const allowedRoles = ["admin", "hr"];
onMounted(async () => {
    setFormattedMenu(sideMenu.value);
    await AuthStore.getLoggedInUserDetails();
    if (allowedRoles.includes(AuthStore.role[0])) {
        await Promise.all([
            helperStore.fillAttachmentTypes(),
            helperStore.fillStates(),
            helperStore.fillCountries(),
            helperStore.fillTimezones(),
            helperStore.fillTeamRoles(),
            helperStore.fillShiftBreakTypes(),
            helperStore.fillInvoiceSubscriptions(),
        ]);
    }
    await helperStore.fillTicketTypes();
});

const closeGlobalModal = (data: any) => {
    AuthStore.resetGlobalModal();
};

const attendanceUpdated = ref<boolean>(false);
const setAttendanceUpdated = (updated:boolean) => attendanceUpdated.value = updated;
provide('attendance', {
    isUpdated: attendanceUpdated,
    setIsUpdated: setAttendanceUpdated,
});

const logout = () => {
    AuthStore.logout();
    AuthStore.$reset();
    setTimeout(() => {
        window.location.href = import.meta.env.VITE_APP_URL + "/user-login";
    }, 1000);
};
</script>

<template>
    <div class="py-5 md:py-0">
        <!--    <DarkModeSwitcher />-->
        <!--    <MainColorSwitcher />-->
        <MobileMenu />
        <TopBar layout="side-menu" />
        <div class="flex overflow-hidden">
            <!-- BEGIN: Side Menu -->
            <nav
                class="w-[105px] xl:w-[260px] px-5 pb-16 overflow-x-hidden z-50 pt-32 -mt-4 hidden md:block"
            >
                <ul>
                    <!-- BEGIN: First Child -->
                    <template v-for="(menu, menuKey) in formattedMenu">
                        <Divider
                            v-if="menu == 'divider'"
                            :key="'divider-' + menuKey"
                            :class="[
                                'my-6',

                                // Animation
                                `opacity-0 animate-[0.4s_ease-in-out_0.1s_intro-divider] animate-fill-mode-forwards animate-delay-${
                                    (menuKey + 1) * 10
                                }`,
                            ]"
                            type="li"
                        ></Divider>
                        <li
                            v-else
                            :id="`sidemenu_${menu.pageName}`"
                            :key="menuKey"
                        >
                            <Menu
                                :class="{
                                    // Animation
                                    [`opacity-0 translate-x-[50px] animate-[0.4s_ease-in-out_0.1s_intro-menu] animate-fill-mode-forwards animate-delay-${
                                        (menuKey + 1) * 10
                                    }`]: !menu.active,
                                }"
                                :formattedMenuState="[
                                    formattedMenu,
                                    setFormattedMenu,
                                ]"
                                :menu="menu"
                                level="first"
                            ></Menu>
                            <!-- BEGIN: Second Child -->
                            <Transition @enter="enter" @leave="leave">
                                <ul
                                    v-if="menu.subMenu && menu.activeDropdown"
                                    :class="[
                                        'bg-white/[0.04] rounded-xl relative dark:bg-transparent',
                                        'before:content-[\'\'] before:block before:inset-0 before:bg-white/30 before:rounded-xl before:absolute before:z-[-1] before:dark:bg-darkmode-900/30',
                                        { block: menu.activeDropdown },
                                        { hidden: !menu.activeDropdown },
                                    ]"
                                >
                                    <li
                                        v-for="(
                                            subMenu, subMenuKey
                                        ) in menu.subMenu"
                                        :key="subMenuKey"
                                    >
                                        <Menu
                                            :class="{
                                                // Animation
                                                [`opacity-0 translate-x-[50px] animate-[0.4s_ease-in-out_0.1s_intro-menu] animate-fill-mode-forwards animate-delay-${
                                                    (subMenuKey + 1) * 10
                                                }`]: !subMenu.active,
                                            }"
                                            :formattedMenuState="[
                                                formattedMenu,
                                                setFormattedMenu,
                                            ]"
                                            :menu="subMenu"
                                            level="second"
                                        ></Menu>
                                        <!-- BEGIN: Third Child -->
                                        <Transition
                                            v-if="subMenu.subMenu"
                                            @enter="enter"
                                            @leave="leave"
                                        >
                                            <ul
                                                v-if="
                                                    subMenu.subMenu &&
                                                    subMenu.activeDropdown
                                                "
                                                :class="[
                                                    'bg-white/[0.04] rounded-xl relative dark:bg-transparent',
                                                    'before:content-[\'\'] before:block before:inset-0 before:bg-white/30 before:rounded-xl before:absolute before:z-[-1] before:dark:bg-darkmode-900/30',
                                                    {
                                                        block: subMenu.activeDropdown,
                                                    },
                                                    {
                                                        hidden: !subMenu.activeDropdown,
                                                    },
                                                ]"
                                            >
                                                <li
                                                    v-for="(
                                                        lastSubMenu,
                                                        lastSubMenuKey
                                                    ) in subMenu.subMenu"
                                                    :key="lastSubMenuKey"
                                                >
                                                    // @ts-ignore
                                                    <Menu
                                                        :class="{
                                                            // Animation
                                                            [`opacity-0 translate-x-[50px] animate-[0.4s_ease-in-out_0.1s_intro-menu] animate-fill-mode-forwards animate-delay-${
                                                                (lastSubMenuKey +
                                                                    1) *
                                                                10
                                                            }`]:
                                                                !lastSubMenu.active,
                                                        }"
                                                        :formattedMenuState="[
                                                            formattedMenu,
                                                            setFormattedMenu,
                                                        ]"
                                                        :menu="lastSubMenu"
                                                        level="third"
                                                    ></Menu>
                                                </li>
                                            </ul>
                                        </Transition>
                                        <!-- END: Third Child -->
                                    </li>
                                </ul>
                            </Transition>
                            <!-- END: Second Child -->
                        </li>
                    </template>
                    <!-- END: First Child -->
                </ul>
            </nav>
            <!-- END: Side Menu -->
            <!-- BEGIN: Content -->
            <div
                :class="[
                    'max-w-full md:max-w-none rounded-[30px] md:rounded-none px-4 md:px-[22px] min-w-0 min-h-screen bg-slate-100 flex-1 md:pt-20 pb-10 mt-5 md:mt-1 relative dark:bg-darkmode-700',
                    'before:content-[\'\'] before:w-full before:h-px before:block',
                ]"
            >
                <AlertForgeBuild :location="'private'" />
                <RouterView />
                <Dialog
                    :open="AuthStore.showGlobalModal"
                    staticBackdrop
                    @close="
                        () => {
                            closeGlobalModal(false);
                        }
                    "
                >
                    <Dialog.Panel class="px-5 py-10">
                        <div class="text-center">
                            <div class="mb-5 text-danger-500">
                                <span class="text-red-500">{{
                                    AuthStore.globalModalMessage
                                }}</span>
                            </div>
                            <div class="mb-5">
                                Please contact your system administrator.
                            </div>
                            <Button
                                class="w-24"
                                type="button"
                                variant="primary"
                                @click="logout"
                            >
                                Logout
                            </Button>
                        </div>
                    </Dialog.Panel>
                </Dialog>
            </div>
            <!-- END: Content -->
        </div>
    </div>
</template>
