<script lang="ts" setup>
import Button from "@/base-components/Button";
import Lucide, { Icon } from "@/base-components/Lucide/Lucide.vue";
import { Dialog } from "@/base-components/Headless";
import { useClockInsStore } from "@/stores/clockInsStore";
import { useAuthStore } from "@/stores/authStore";
import { useEmployeeAttendanceStore } from "@/stores/employeeAttendanceStore";
import { computed, provide, ref, defineEmits, inject, Ref } from "vue";
import { FormLabel } from "@/base-components/Form";
import TomSelect from "@/base-components/TomSelect";
import ConfirmModal from "@/pages/components/ConfirmModal.vue";
import AlertModal from "@/pages/components/AlertModal.vue";
import FormTextarea from "@/base-components/Form/FormTextarea.vue";
import Notification, {
    NotificationElement,
} from "@/base-components/Notification";
import { FormSwitch } from "@/base-components/Form";
import { ConsoleMessage } from "puppeteer";
import { useTicketsStore } from "@/stores/ticketsStore";
import { useHelperStore } from "@/stores/helperStore";
import moment from "moment";

const loading = ref(false);

const ClockInStore = useClockInsStore();
const AuthStore = useAuthStore();
const AttendanceStore = useEmployeeAttendanceStore();
const TicketsStore = useTicketsStore();
const HelperStore = useHelperStore();
const emit = defineEmits(["output", "ticketType"]);

const form = ref({
    shift_break_id:
        AuthStore.myShift &&
            AuthStore.myShift.shift_breaks &&
            AuthStore.myShift.shift_breaks.length > 0
            ? String(AuthStore.myShift.shift_breaks[0].id)
            : "",
});

type ErrorMessages = {
    [key: string]: string[];
};

const errorNotification = ref<NotificationElement>();
const successNotification = ref<NotificationElement>();
const successMessage = ref("");
const errors = ref<any>({});
const dialog = ref(false);
const alert = ref(false);
const showModal = ref(false);
const modalIcon = ref<Icon>("CheckCircle");
const modalMessage = ref("");
const attendance = inject<{isUpdated: Ref<boolean>; setIsUpdated: (arg:boolean) => void}>('attendance');

const isStartTimeEmpty = () => {
    return (
        !ClockInStore.myAttendance ||
        ClockInStore.myAttendance.start_time === null ||
        ClockInStore.myAttendance.start_time.toString() === ""
    );
};

const isStartTimeNotEmpty = () => {
    return !!ClockInStore.myAttendance.start_time;
};

const showStartShiftButton = () => {
    const { myAttendance } = ClockInStore;
    return (
        !myAttendance ||
        !myAttendance.start_time ||
        myAttendance.start_time === null ||
        myAttendance.start_time.toString() === ""
    );
    //myAttendance.start_time === ""
};

const showEndShiftButton = () => {
    const { myAttendance } = ClockInStore;
    return (
        myAttendance &&
        myAttendance.start_time &&
        myAttendance.start_time.toString() !== "" &&
        (!myAttendance.end_time || myAttendance.end_time.toString() === "")
    );
};

const showBreakTab = () => {
    const { myAttendance } = ClockInStore;
    return (
        myAttendance &&
        myAttendance.start_time &&
        myAttendance.start_time.toString() !== "" &&
        (!myAttendance.end_time || myAttendance.end_time.toString() === "")
    );
};

const onBreak = computed(() => {
    const { myAttendance } = ClockInStore;
    return (
        myAttendance &&
        myAttendance.breaks?.length > 0 &&
        myAttendance.breaks?.filter((a) => a.end_time === null).length > 0
    );
});

const shiftEnded = computed(() => {
    const { myAttendance } = ClockInStore;
    return myAttendance && myAttendance.end_time !== null;
});

const shiftStarted = computed(() => {
    const { myAttendance } = ClockInStore;
    return myAttendance && myAttendance.start_time !== null;
});

const showEndButton = computed(() => {
    return !onBreak.value && shiftStarted.value && !shiftEnded.value;
});

const showStartBreakButton = () => {
    const { breaks } = ClockInStore.myAttendance;
    return (
        breaks?.length === 0 ||
        breaks?.every(
            (breakObj) =>
                breakObj.start_time !== null && breakObj.end_time !== null
        )
    );
};

const showEndBreakButton = () => {
    const { breaks } = ClockInStore.myAttendance;
    return (
        breaks?.length > 0 &&
        breaks?.some(
            (breakObj: { end_time: string | null }) =>
                breakObj.end_time === null || breakObj.end_time === ""
        )
    );
};

const startShift = async () => {
    errors.value = {};
    loading.value = true;
    successMessage.value = "";

    try {
        await ClockInStore.startShift();
        successMessage.value = "Successfully clocked in.";
        showSuccessNotification();
        attendance?.setIsUpdated(true);
    } catch (error: any) {
        errors.value = error?.response?.data?.errors;
        modalIcon.value = "XCircle";
        modalMessage.value = errors.value?.attendance?.[0];
        showModal.value = true;
    } finally {
        loading.value = false;
    }
};

const endShift = async () => {
    errors.value = {};
    loading.value = true;
    try {
        await ClockInStore.endShift();
        modalIcon.value = "CheckCircle";
        modalMessage.value = "Successfully ended shift";
        attendance?.setIsUpdated(true);
    } catch (error: any) {
        const errorMsgs = error?.response?.data?.errors;
        errors.value = errorMsgs;
        modalIcon.value = "XCircle";
        modalMessage.value = errorMsgs && !isEmptyObject(errorMsgs) ? Object.values(errorMsgs as ErrorMessages)[0][0] : "Something went wrong.";
    } finally {
        showModal.value = true;
        loading.value = false;
    }
};

const startBreak = async () => {
    errors.value = {};
    loading.value = true;
    successMessage.value = "";

    try {
        await ClockInStore.startBreak(+form.value.shift_break_id);
        successMessage.value = "Successfully started break.";
        showSuccessNotification();
        attendance?.setIsUpdated(true);
    } catch (error: any) {
        const errorMsgs = error?.response?.data?.errors;
        errors.value = errorMsgs;
        modalIcon.value = "XCircle";
        modalMessage.value = errorMsgs && !isEmptyObject(errorMsgs) ? Object.values(errorMsgs as ErrorMessages)[0][0] : "Something went wrong.";
        showModal.value = true;
    } finally {
        loading.value = false;
    }
};

const endBreak = async () => {
    errors.value = {};
    loading.value = true;
    try {
        await ClockInStore.endBreak();
        modalIcon.value = "CheckCircle";
        modalMessage.value = "Successfully ended break";
        attendance?.setIsUpdated(true);
    } catch (error: any) {
        const errorMsgs = error?.response?.data?.errors;
        errors.value = errorMsgs;
        modalIcon.value = "XCircle";
        modalMessage.value = errorMsgs && !isEmptyObject(errorMsgs) ? Object.values(errorMsgs as ErrorMessages)[0][0] : "Something went wrong.";
    } finally {
        showModal.value = true;
        loading.value = false;
    }
};

const late = ref({
    subject: "Employee Late/Absent Notification",
    reason: "",
    remarks: "",
    active: false,
    errors: {} as any,
});

const isIAmLate = ref<boolean>(true);
const isIAmAbsent = ref<boolean>(false);

const selectOption = (event: Event) => {
    const target = event.target as HTMLInputElement;
    const value = target.value;

    if (value === 'option1') {
        late.value.reason = 'I will be late'
        isIAmLate.value = !isIAmLate.value;
        isIAmAbsent.value = !isIAmLate.value;
    } else if (value === 'option2') {
        late.value.reason = 'I will be absent'
        isIAmAbsent.value = !isIAmAbsent.value;
        isIAmLate.value = !isIAmAbsent.value;
    }
}

const onSubmitLate = async () => {
    loading.value = true;
    late.value.errors = {};
    successMessage.value = "";
    await AttendanceStore.late({
        remarks: late.value.remarks,
    }).then(async () => {
        const currentDate = moment().format("MMMM Do YYYY, h:mm:ss a");
        const ticketType = HelperStore.getTicketType(Number(29));

        const desc_data = new FormData();

        if (late.value.reason == '') {
            late.value.reason = 'I will be late'
        }

        //@ts-ignore
        desc_data.append("Employee Name", window.Laravel.user.name);
        desc_data.append("Date Filed", currentDate);
        desc_data.append("Remarks", late.value.remarks);
        desc_data.append("Reason for Request", late.value.reason);

        var form_body = {};
        //@ts-ignore
        desc_data.forEach((value, key) => (form_body[key] = value));

        const form = new FormData();
        form.append("id", String(29));
        //@ts-ignore
        const userName = window.Laravel.user.name;
        form.append(
            "subject",
            late.value.subject + ": " + userName
        );
        form.append("priority", ticketType?.f_priority_id?.toString() ?? '');
        form.append("status", ticketType?.f_status_id?.toString() ?? '');
        form.append("type", ticketType?.f_type?.toString() ?? '');
        form.append("form_body", JSON.stringify(form_body));
        form.append("ticket_type_id", '29');
        
        await TicketsStore.save(form)

        late.value = {
            subject: "Employee Late/Absent Notification",
            reason: "",
            remarks: "",
            active: false,
            errors: {},
        };

        await AuthStore.getLoggedInUserDetails();
        successMessage.value = "Late notification has been successfully sent.";
        showSuccessNotification();
    }).catch(error => {
        console.error(error?.response?.data?.errors);
        late.value.errors = error?.response?.data?.errors
    }).finally(() => {
        loading.value = false;
        isIAmLate.value = true;
        isIAmAbsent.value = false;
    });
};

const isEmptyObject = (obj: {}) => {
    return Object.keys(obj).length === 0;
};

const onClickConfirm = async (event: any) => {
    if (event === true) {
        if (onBreak.value) {
            await endBreak();
        } else {
            await endShift();
        }
    }
};

const errorNotificationToggle = () => {
    // Show notification
    errorNotification.value?.showToast();
};
provide("bind[errorNotification]", (el: NotificationElement) => {
    errorNotification.value = el;
});
const showErrorsNotification = () => {
    errorNotificationToggle();
};

const successNotificationToggle = () => {
    successNotification.value?.showToast();
};
provide("bind[successNotification]", (el: NotificationElement) => {
    successNotification.value = el;
});
const showSuccessNotification = () => {
    successNotificationToggle();
};
</script>
<template>
    <div class="mt-4 border border-gray-200 p-4 rounded shadow-lg">
        <div v-if="!ClockInStore.enableClockIn">
            <div v-if="!late.active" class="flex flex-col gap gap-y-4">
                <Button id="lateNotification" v-if="
                    !ClockInStore.myAttendance.start_time &&
                    !ClockInStore.myAttendance.end_time
                " :disabled="loading" class="w-full text-xs lg:text-lg" elevated size="lg" variant="warning"
                    @click="late.active = !late.active">
                    <Lucide class="w-4 h-4 mr-2" icon="AlertTriangle" />
                    I will be late
                </Button>
            </div>
            <div v-if="late.active">
                <div class="p-5 text-left">
                    <FormLabel class="form-label text-left" for="remarks">Remarks
                        <span style="color: rgb(255, 0, 0)">*</span>
                    </FormLabel>
                    <FormTextarea id="remarks-input" v-model="late.remarks" name="remarks" rows="3"
                        placeholder="Please briefly describe why you will be late or absent. If late kindly provide when you expect to arrive."
                        type="text" />

                    <div>
                        <span class="text-gray-500 text-xs text-left">
                            Do not put any sensitive information on this form
                        </span>
                    </div>

                    <template v-if="late.errors.remarks">
                        <div class="text-danger">
                            {{ late.errors.remarks[0] }}
                        </div>
                    </template>

                    <div class="mt-4 flex flex-row align-center items-center">
                        <FormSwitch>
                            <FormSwitch.Input :checked="isIAmLate" type="checkbox" value="option1"
                                @change="selectOption" />
                        </FormSwitch>

                        <label class="ml-2">
                            I will be late
                        </label>

                        <FormSwitch class="ml-7">
                            <FormSwitch.Input type="checkbox" :checked="isIAmAbsent" value="option2"
                                @change="selectOption" />
                        </FormSwitch>

                        <label class="ml-2">
                            I will be absent
                        </label>
                    </div>

                </div>
                <div class="px-5 pb-8 text-left mt-3">
                    <Button id="remarks-submit-button" :disabled="loading" class="w-24 mr-1" type="button"
                        variant="primary" @click="onSubmitLate()">
                        Submit
                    </Button>

                    <Button id="remarks-cancel-button" :disabled="loading" class="w-24 mr-3" type="button"
                        variant="warning" @click="late.active = !late.active">
                        Cancel
                    </Button>
                </div>
            </div>
        </div>

        <div v-else class="flex flex-col gap gap-y-4">
            <div v-if="!late.active" class="flex flex-col gap gap-y-4">
                <Button id="startShift" :class="[
                    'w-full text-xs lg:text-lg',
                    {
                        'opacity-25 brightness-50':
                            !showStartShiftButton() || loading,
                    },
                ]" :disabled="!showStartShiftButton() || loading" elevated size="lg" variant="customPrimary"
                    @click="startShift">
                    <Lucide class="w-4 h-4 mr-2" icon="LogIn" />
                    Start Shift
                </Button>

                <Button id="endShift" :class="[
                    'w-full text-xs lg:text-lg',
                    { 'opacity-25 brightness-50': !showEndButton || loading },
                ]" :disabled="!showEndButton || loading" elevated size="lg" variant="danger" @click="dialog = !dialog">
                    <Lucide class="w-4 h-4 mr-2" icon="LogOut" />
                    End Shift
                </Button>

                <Button id="lateNotification" v-if="
                    !ClockInStore.myAttendance.start_time &&
                    !ClockInStore.myAttendance.end_time
                " :disabled="loading" class="w-full text-xs lg:text-lg" elevated size="lg" variant="warning"
                    @click="late.active = !late.active">
                    <Lucide class="w-4 h-4 mr-2" icon="AlertTriangle" />
                    I will be late
                </Button>
            </div>

            <div v-if="late.active">
                <div class="p-5 text-left">
                    <FormLabel class="form-label text-left" for="remarks">Remarks
                        <span style="color: rgb(255, 0, 0)">*</span>
                    </FormLabel>
                    <FormTextarea id="remarks-input" v-model="late.remarks" name="remarks" rows="3"
                        placeholder="Please briefly describe why you will be late or absent. If late kindly provide when you expect to arrive."
                        type="text" />

                    <div>
                        <span class="text-gray-500 text-xs text-left">
                            Do not put any sensitive information on this form
                        </span>
                    </div>

                    <template v-if="late.errors.remarks">
                        <div class="text-danger">
                            {{ late.errors.remarks[0] }}
                        </div>
                    </template>

                    <div class="mt-4 flex flex-row align-center items-center">
                        <FormSwitch>
                            <FormSwitch.Input :checked="isIAmLate" type="checkbox" value="option1"
                                @change="selectOption" />
                        </FormSwitch>

                        <label class="ml-2">
                            I will be late
                        </label>

                        <FormSwitch class="ml-7">
                            <FormSwitch.Input type="checkbox" :checked="isIAmAbsent" value="option2"
                                @change="selectOption" />
                        </FormSwitch>

                        <label class="ml-2">
                            I will be absent
                        </label>
                    </div>

                </div>
                <div class="px-5 pb-8 text-left mt-3">
                    <Button id="remarks-submit-button" :disabled="loading" class="w-24 mr-1" type="button"
                        variant="primary" @click="onSubmitLate()">
                        Submit
                    </Button>

                    <Button id="remarks-cancel-button" :disabled="loading" class="w-24 mr-3" type="button"
                        variant="warning" @click="late.active = !late.active">
                        Cancel
                    </Button>
                </div>
            </div>

            <div v-if="shiftStarted && !shiftEnded" class="flex flex-col gap gap-y-4">
                <hr />

                <div v-if="showEndButton">
                    <TomSelect id="shift_break_id" v-model="form.shift_break_id" :disabled="!showEndButton"
                        :value="form.shift_break_id" class="w-full p-0" name="shift_break_id">
                        <option v-for="shiftBreak in AuthStore.myShift.shift_breaks" :value="shiftBreak.id">
                            {{ shiftBreak.friendly_name }}
                        </option>
                    </TomSelect>
                </div>

                <Button id="start-break-button" v-if="showStartBreakButton()" :disabled="loading"
                    class="w-full text-xs lg:text-lg" elevated size="lg" variant="customPrimary" @click="startBreak">
                    <Lucide class="w-4 h-4 mr-2" icon="Smile" />
                    Start Break
                </Button>

                <Button id="end-break-button" v-if="showEndBreakButton()" :disabled="loading"
                    class="w-full text-xs lg:text-lg" elevated size="lg" variant="warning" @click="dialog = !dialog">
                    <Lucide class="w-4 h-4 mr-2" icon="Frown" />
                    End Break
                </Button>

                <div v-if="errors && !isEmptyObject(errors)" class="p-2 rounded font-semibold bg-red-200 text-red-700 text-left">
                    <ul v-for="(error, index) in errors" :key="index">
                        <li>
                            {{ error[0] }}
                        </li>
                    </ul>
                </div>
            </div>
        </div>

        <ConfirmModal :description="`You are about to end your ${onBreak ? 'break' : 'shift'}.`"
            :displayConfirmModal="dialog" :showSuccessDialog="false" :type="'Confirm'"
            @closeConfirmModal="dialog = false" @proceedProcess="onClickConfirm($event)" />
        
        <Dialog :open="showModal" size="md">
            <Dialog.Panel>
                <div class="p-5 text-center">
                    <Lucide
                        class="w-16 h-16 mx-auto mt-3 text-success"
                        :icon="modalIcon"
                    />
                    <div class="mt-2 text-slate-500">
                        {{ modalMessage }}
                    </div>
                </div>

                <div class="px-5 pb-8 text-center">
                    <Button
                        class="w-24"
                        type="button"
                        variant="customPrimary"
                        @click="showModal = false"
                    >
                        Ok
                    </Button>
                </div>
            </Dialog.Panel>
        </Dialog>

        <AlertModal
            :description="`You are clocking in outside your supposed schedule (${AuthStore.myShift.start_time} - ${AuthStore.myShift.end_time}).`"
            :dialog="alert" title="Invalid Clock-in Time" @close="alert = false" />
    </div>

    <Notification :options="{
        duration: 3000,
    }" class="flex" refKey="successNotification">
        <Lucide class="text-success" icon="CheckCircle" />
        <div class="ml-4 mr-4">
            <div class="font-medium">System Notification</div>
            <div class="mt-1 text-slate-500">
                {{ successMessage }}
            </div>
        </div>
    </Notification>

    <Notification :options="{
        duration: 3000,
    }" class="flex" refKey="errorNotification">
        <Lucide class="text-danger" icon="X" />
        <div class="ml-4 mr-4">
            <div class="font-medium">System Notification</div>
            <div class="mt-1 text-slate-500">
                <ul class="list-disc" v-if="errors && !isEmptyObject(errors)">
                    <template v-for="key in Object.keys(errors)">
                        <li class="text-red-500">
                            {{ errors[key][0] }}
                        </li>
                    </template>
                </ul>
            </div>
        </div>
    </Notification>
</template>
