<script setup lang="ts">
import { useAuthStore } from "../../../stores/authStore";
import { useRoute } from "vue-router";
import { onMounted, reactive, ref } from "vue";
import { useEmployeeScheduleStore } from "../../../stores/employeeScheduleStore";
import { FilterMatchMode, FilterOperator } from "primevue/api";
import LazyParamsInterface from "../../interfaces/lazyParamsInterface";
import DataTable from "primevue/datatable";
import Column from "primevue/column";
import InputText from "primevue/inputtext";
import ShiftFrom from "../../forms/shifts/FormShift.vue";
import FormSlider from "../../FormSlider.vue";
import { ShiftInterface } from "../../interfaces/shiftInterface";
import { EmployeeScheduleInterface } from "../../interfaces/employeeScheduleInterface";
import FormEmployeeSchedule from "../../forms/employees/FormEmployeeSchedule.vue";
import moment from "moment-timezone";
import { debounce } from "lodash";
import { FormInput } from "../../../base-components/Form";
import Button from "../../../base-components/Button";
import DataTableSkeleton from "../DataTableSkeleton.vue";

const AuthStore = useAuthStore();
const EmployeeScheduleStore = useEmployeeScheduleStore();
const route = useRoute();

const slider = ref(false);
const dt = ref();
const lazyParams = ref<LazyParamsInterface>({
    sortField: "start_time",
    sortOrder: -1,
});
const filters = ref({
    global: { value: null, matchMode: FilterMatchMode.CONTAINS },
    "employee.name": {
        operator: FilterOperator.AND,
        constraints: [{ value: null, matchMode: FilterMatchMode.CONTAINS }],
    },
    "shift.name": {
        operator: FilterOperator.AND,
        constraints: [{ value: null, matchMode: FilterMatchMode.CONTAINS }],
    },
});
const searchableColumns = ref([
    "employee.name",
    "employee.third_party_employee_id",
    "employee.client.legal_business_name",
    "employee.shift.name",
]);
const loading = ref(false);
const mounting = ref(false);
const showClearFilter = ref(false);
const schedule = ref<EmployeeScheduleInterface>(
    {} as EmployeeScheduleInterface
);
const range = reactive({
    from: moment().startOf("month").tz("Asia/Manila").format("YYYY-MM-DD"),
    to: moment().endOf("month").tz("Asia/Manila").format("YYYY-MM-DD"),
});

const onFilter = debounce(async (event: any) => {
    showClearFilter.value = filters.value.global.value !== "";

    lazyParams.value.filters = filters.value;

    await onDataFetch();
}, 500);

const onPage = async (event: any) => {
    showClearFilter.value = true;

    lazyParams.value = event;

    await onDataFetch();
};

const onSort = async (event: any) => {
    showClearFilter.value = true;

    lazyParams.value = event;

    await onDataFetch();
};

const onDataFetch = async () => {
    loading.value = true;

    const params = {
        dt_params: JSON.stringify(lazyParams.value),
        searchable_columns: JSON.stringify(searchableColumns.value),
        employee_id: route?.params?.id,
        range,
    };

    await EmployeeScheduleStore.getSchedules(params);

    loading.value = false;
};

const onClickButton = (data: any) => {
    slider.value = !slider.value;

    if (
        data &&
        Object.keys(data).length > 0 &&
        !(data instanceof PointerEvent)
    ) {
        schedule.value = data;
    } else {
        schedule.value = {} as EmployeeScheduleInterface;
    }
};

onMounted(async () => {
    mounting.value = true;
    await onDataFetch();
    mounting.value = false;
});
</script>

<template>
    <DataTableSkeleton v-if="mounting" />

    <template v-else>
        <div
            class="flex flex-col md:flex-row lg:flex-row w-full sm:w-auto mt-4 sm:mt-0 sm:ml-auto md:ml-0 justify-end"
        >
            <Button variant="customPrimary" class="mr-3" @click="onClickButton"
                >Add Schedule</Button
            >

            <div class="w-full md:mt-2 lg:mt-0 lg:w-56 relative text-slate-500">
                <FormInput
                    v-model="range.from"
                    :value="range.from"
                    class="w-full lg:w-56 !box"
                    type="date"
                    @change="onFilter($event)"
                />
            </div>

            <div
                class="mt-2 ml-0 md:ml-2 lg:ml-2 lg:mt-0 w-full lg:w-56 relative text-slate-500"
            >
                <FormInput
                    v-model="range.to"
                    :value="range.to"
                    class="w-full lg:w-56 !box"
                    type="date"
                    @change="onFilter($event)"
                />
            </div>
        </div>

        <DataTable
            ref="dt"
            v-model:filters="filters"
            :globalFilterFields="searchableColumns"
            :lazy="true"
            :loading="loading"
            :paginator="true"
            :rows="15"
            :totalRecords="EmployeeScheduleStore.schedules.total"
            :value="EmployeeScheduleStore.schedules.data"
            class="mt-4 p-datatable-sm rounded-lg text-xs"
            filterDisplay="menu"
            responsiveLayout="scroll"
            showGridlines
            stripedRows
            sort-field="start_time"
            :sort-order="-1"
            @filter="onFilter($event)"
            @page="onPage($event)"
            @sort="onSort($event)"
        >
            <template #empty> No available data.</template>
            <template #loading> Loading data. Please wait.</template>

            <Column field="employee.name" header="Employee" sortable></Column>
            <Column field="shift.name" header="Shift" sortable>
                <template #filter="{ filterModel }">
                    <InputText
                        v-model="filterModel.value"
                        class="p-column-filter"
                        placeholder="Search by shift name"
                        type="text"
                    />
                </template>
            </Column>
            <Column field="start_time" header="Start Time" sortable></Column>
            <Column field="end_time" header="End Time" sortable></Column>
            <Column
                field="is_manual_override"
                header="Overridden?"
                sortable
                class="!w-[5%]"
            >
                <template #body="{ data }">
                    <div v-if="data.is_manual_override">YES</div>
                    <div v-else>NO</div>
                </template>
            </Column>
            <Column>
                <template #body="{ data }">
                    <div>
                        <svg
                            :id="'update_' + data.id"
                            class="icon icon-tabler icon-tabler-edit cursor-pointer"
                            fill="none"
                            height="18"
                            stroke="currentColor"
                            stroke-linecap="round"
                            stroke-linejoin="round"
                            stroke-width="2"
                            viewBox="0 0 24 24"
                            width="18"
                            xmlns="http://www.w3.org/2000/svg"
                            @click="onClickButton(data)"
                        >
                            <path
                                d="M0 0h24v24H0z"
                                fill="none"
                                stroke="none"
                            ></path>
                            <path
                                d="M7 7h-1a2 2 0 0 0 -2 2v9a2 2 0 0 0 2 2h9a2 2 0 0 0 2 -2v-1"
                            ></path>
                            <path
                                d="M20.385 6.585a2.1 2.1 0 0 0 -2.97 -2.97l-8.415 8.385v3h3l8.385 -8.415z"
                            ></path>
                            <path d="M16 5l3 3"></path>
                        </svg>
                    </div>
                </template>
            </Column>
        </DataTable>
    </template>

    <FormSlider
        :is-open="slider"
        :header="
            Object.keys(schedule).length > 0
                ? `Update Schedule Form`
                : `Add Schedule Form`
        "
        size="lg"
        @close="onClickButton"
    >
        <FormEmployeeSchedule :schedule="schedule" @close="onClickButton" />
    </FormSlider>
</template>
