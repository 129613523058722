<script setup lang="ts">
import {
    defineEmits,
    defineProps,
    onMounted,
    PropType,
    provide,
    reactive,
    ref,
    toRefs,
} from "vue";
import Button from "../../../base-components/Button";
import {
    InvoiceInterface,
    InvoiceLineItemInterface,
} from "../../interfaces/invoiceInterface";
import {
    FormInput,
    FormLabel,
    FormSwitch,
} from "../../../base-components/Form";
import FormTextarea from "../../../base-components/Form/FormTextarea.vue";
import Lucide from "../../../base-components/Lucide";
import TomSelect from "../../../base-components/TomSelect";
import { useEmployeesStore } from "../../../stores/employeesStore";
import { required, requiredIf } from "@vuelidate/validators";
import { useVuelidate } from "@vuelidate/core";
import Notification, {
    NotificationElement,
} from "../../../base-components/Notification";
import { useInvoiceStore } from "../../../stores/invoiceStore";
import { InputGroup } from "../../../base-components/Form";
import { vMaska } from "maska";
import { debounce } from "lodash";
import { useActivityLogsStore } from "../../../stores/activityLogsStore";

const EmployeeStore = useEmployeesStore();
const InvoiceStore = useInvoiceStore();
const ActivityLogStore = useActivityLogsStore();

const props = defineProps({
    errors: {
        type: Object,
        default: () => ({}),
    },
    items: {
        type: Array as () => InvoiceLineItemInterface[],
        default: () => [],
    },
    validate: {
        type: Object,
        default: () => ({}),
    },
    invoice: {
        type: Object as PropType<InvoiceInterface>,
        default: () => ({}),
    },
    single: {
        type: Boolean,
        default: false,
    },
    item: {
        type: Object as PropType<InvoiceLineItemInterface>,
        default: () => ({}),
    },
});

const emit = defineEmits(["close"]);

const editing = ref(false);
const loading = ref(false);
const form = reactive({
    invoice_line_item_type_id: "",
    description: "",
    quantity: 1,
    unit_price: 0,
    line_item_total: 0,
    employee_id: "",
});
const rules = {
    invoice_line_item_type_id: props.single ? { required } : {},
    description: props.single ? { required } : {},
    quantity: props.single ? { required } : {},
    unit_price: props.single ? { required } : {},
    line_item_total: props.single ? { required } : {},
    employee_id: {
        required: requiredIf(() => form.invoice_line_item_type_id !== "5"),
    },
};
const backendValidationErrors = ref({});
const $externalResults = ref({});
const validate = useVuelidate(rules, toRefs(form), { $externalResults });
const errorNotification = ref<NotificationElement>();
const successNotification = ref<NotificationElement>();
const errorNotificationToggle = () => {
    errorNotification.value?.showToast();
};
provide("bind[errorNotification]", (el: NotificationElement) => {
    errorNotification.value = el;
});
const showErrorsNotification = () => {
    errorNotificationToggle();
};
const successNotificationToggle = () => {
    successNotification.value?.showToast();
};
provide("bind[successNotification]", (el: NotificationElement) => {
    successNotification.value = el;
});
const showSuccessNotification = () => {
    successNotificationToggle();
};

const close = async () => {
    emit("close");

    setTimeout(() => {
        form.invoice_line_item_type_id = "";
        form.description = "";
        form.quantity = 1;
        form.unit_price = 0;
        form.line_item_total = 0;
        form.employee_id = "";
    }, 250);
};

const update = async () => {
    editing.value = true;
    const payload = {
        ...form,
        id: props.item.id,
        invoice_id: props.invoice.id,
    };

    await InvoiceStore.updateLineItem(payload);
};

const save = async () => {
    editing.value = false;
    const payload = {
        ...form,
        invoice_id: props.invoice.id,
    };

    await InvoiceStore.addLineItem(payload);
};

const onSubmit = async () => {
    loading.value = true;
    validate.value.$reset();
    validate.value.$clearExternalResults();
    validate.value.$touch();

    if (validate.value.$invalid) {
        backendValidationErrors.value = {
            message: ["Incomplete or Missing required data"],
        };

        showErrorsNotification();
        loading.value = false;
        return;
    }

    try {
        props?.item?.id ? await update() : await save();

        showSuccessNotification();
        await ActivityLogStore.fill("Invoice", Number(props.invoice.id));
        await close();
    } catch (error: any) {
        $externalResults.value = error.response.data.errors;

        backendValidationErrors.value = {
            message: ["Something went wrong."],
        };

        showErrorsNotification();
    } finally {
        loading.value = false;
    }
};

const add = () => {
    props.items.push({
        invoice_line_item_type_id: "",
        description: "",
        quantity: 1,
        unit_price: 0,
        line_item_total: 0,
        employee_id: "",
    });
};

const onClickTrash = (item: any) => {
    const index = props.items.indexOf(item);

    if (props.items.length > -1) {
        props.items.splice(index, 1);
    }
};

const calculateLineItemTotal = debounce((index: number | undefined | Event) => {
    if (index === undefined || index instanceof Event) {
        form.line_item_total =
            form.quantity *
            +String(form.unit_price).replace(" ", "").replace(",", "");
        return;
    }

    props.items[index].line_item_total =
        props.items[index].quantity * +props.items[index].unit_price;
}, 250);

onMounted(async () => {
    loading.value = true;

    await EmployeeStore.getClientEmployees({
        client_id: props.invoice.client_id,
    });

    if (Object.keys(props.item).length > 0) {
        editing.value = true;

        form.invoice_line_item_type_id = props.item.invoice_line_item_type_id
            ? props.item.invoice_line_item_type_id.toString()
            : "";
        form.description = props.item.description;
        form.quantity = props.item.quantity;
        form.unit_price = props.item.unit_price;
        form.line_item_total = props.item.line_item_total;
        form.employee_id = props.item.employee_id
            ? props.item.employee_id.toString()
            : "";
    }

    await InvoiceStore.getInvoiceLineItemTypes();
    loading.value = false;
});
</script>

<template>
    <div>
        <form @submit.prevent="onSubmit">
            <div class="grid grid-cols-12 gap-6 mt-5 items-left text-left">
                <div class="intro-y col-span-12">
                    <div class="intro-y pb-5 pl-5 pr-2">
                        <div
                            v-if="EmployeeStore.clientEmployees.length === 0"
                            class="p-4 bg-red-100 text-red-900/100 rounded-sm"
                        >
                            <div class="flex items-center">
                                <Lucide icon="Info" />

                                <div class="ml-2">
                                    There's no employee assigned to
                                    <strong>{{
                                        props.invoice?.client
                                            ?.legal_business_name ?? "Client"
                                    }}</strong
                                    >.
                                </div>
                            </div>
                        </div>

                        <div class="mt-4">
                            <FormLabel
                                class="form-label"
                                for="invoice_line_item_type_id"
                                >Type</FormLabel
                            >
                            <div>
                                <TomSelect
                                    id="invoice_line_item_type_id"
                                    v-model="form.invoice_line_item_type_id"
                                    :value="form.invoice_line_item_type_id"
                                    class="w-full p-0"
                                    name="invoice_line_item_type_id"
                                >
                                    <option selected>Select Type</option>
                                    <option
                                        v-for="type in InvoiceStore
                                            .invoiceLineItemTypes.data"
                                        :value="type.id"
                                        :disabled="
                                            type.id !== 5 &&
                                            EmployeeStore.clientEmployees
                                                .length === 0
                                        "
                                    >
                                        {{ type.name }}
                                    </option>
                                </TomSelect>
                            </div>
                            <template
                                v-if="
                                    validate.invoice_line_item_type_id.$invalid
                                "
                            >
                                <div
                                    v-for="(error, index) in validate
                                        .invoice_line_item_type_id.$errors"
                                    :key="index"
                                    class="text-danger mt-2"
                                >
                                    {{ error.$message }}
                                </div>
                            </template>
                        </div>

                        <div class="mt-4">
                            <FormLabel class="form-label" for="description"
                                >Description
                                <span class="text-red-600">*</span>
                            </FormLabel>
                            <FormTextarea
                                id="description"
                                v-model="form.description"
                                :value="form.description"
                                class="form-control w-full"
                                name="description"
                                placeholder="Add your description here..."
                            />
                            <template v-if="validate.description.$invalid">
                                <div
                                    v-for="(error, index) in validate
                                        .description.$errors"
                                    :key="index"
                                    class="text-danger mt-2"
                                >
                                    {{ error.$message }}
                                </div>
                            </template>
                        </div>

                        <div class="grid grid-cols-12 gap-6">
                            <div class="mt-4 col-span-12 lg:col-span-4">
                                <FormLabel class="form-label" for="quantity"
                                    >Quantity
                                    <span class="text-red-600">*</span>
                                </FormLabel>
                                <FormInput
                                    id="quantity"
                                    v-model.number="form.quantity"
                                    :value="form.quantity"
                                    class="form-control w-full"
                                    name="quantity"
                                    type="number"
                                    min="1"
                                    @input="calculateLineItemTotal"
                                />
                                <template v-if="validate.quantity.$invalid">
                                    <div
                                        v-for="(error, index) in validate
                                            .quantity.$errors"
                                        :key="index"
                                        class="text-danger mt-2"
                                    >
                                        {{ error.$message }}
                                    </div>
                                </template>
                            </div>

                            <div class="mt-4 col-span-12 lg:col-span-4">
                                <FormLabel class="form-label" for="unit_price"
                                    >Unit Price
                                    <span class="text-red-600">*</span>
                                </FormLabel>

                                <InputGroup>
                                    <FormInput
                                        id="unit_price"
                                        v-model="form.unit_price"
                                        :value="form.unit_price"
                                        type="text"
                                        class="form-control w-full"
                                        @input="calculateLineItemTotal"
                                    />
                                    <InputGroup.Text v-if="invoice.currency">
                                        {{ invoice.currency?.symbol }}
                                    </InputGroup.Text>
                                </InputGroup>

                                <template v-if="validate.unit_price.$invalid">
                                    <div
                                        v-for="(error, index) in validate
                                            .unit_price.$errors"
                                        :key="index"
                                        class="text-danger mt-2"
                                    >
                                        {{ error.$message }}
                                    </div>
                                </template>
                            </div>

                            <div class="mt-4 col-span-12 lg:col-span-4">
                                <FormLabel
                                    class="form-label"
                                    for="line_item_total"
                                    >Total
                                </FormLabel>
                                <FormInput
                                    id="line_item_total"
                                    v-model="form.line_item_total"
                                    :value="form.line_item_total"
                                    class="form-control w-full"
                                    name="line_item_total"
                                    type="number"
                                    disabled
                                />
                                <template
                                    v-if="validate.line_item_total.$invalid"
                                >
                                    <div
                                        v-for="(error, index) in validate
                                            .line_item_total.$errors"
                                        :key="index"
                                        class="text-danger mt-2"
                                    >
                                        {{ error.$message }}
                                    </div>
                                </template>
                            </div>
                        </div>

                        <div class="mt-4">
                            <FormLabel class="form-label" for="employee_id"
                                >Employee</FormLabel
                            >
                            <div>
                                <TomSelect
                                    id="employee_id"
                                    v-model="form.employee_id"
                                    :value="form.employee_id"
                                    class="w-full p-0"
                                    name="employee_id"
                                >
                                    <option selected>Select Employee</option>
                                    <option
                                        v-for="employee in EmployeeStore.clientEmployees"
                                        :value="employee.id"
                                    >
                                        {{ employee.name }}
                                    </option>
                                </TomSelect>
                            </div>
                            <template v-if="validate.employee_id.$invalid">
                                <div
                                    v-for="(error, index) in validate
                                        .employee_id.$errors"
                                    :key="index"
                                    class="text-danger mt-2"
                                >
                                    {{ error.$message }}
                                </div>
                            </template>
                        </div>
                    </div>
                </div>
            </div>

            <div class="">
                <div class="text-right p-2">
                    <Button
                        id="btn_cancel"
                        class="w-32 mb-2 mr-1"
                        type="button"
                        variant="warning"
                        :disabled="loading"
                        @click="close()"
                    >
                        Cancel
                    </Button>
                    <Button
                        type="submit"
                        id="btn_process"
                        class="w-36 mb-2 mr-1"
                        variant="customPrimary"
                        :disabled="loading"
                    >
                        Save
                    </Button>
                </div>
            </div>
        </form>

        <Notification
            :options="{
                duration: 3000,
            }"
            class="flex"
            refKey="successNotification"
        >
            <Lucide class="text-success" icon="CheckCircle" />
            <div class="ml-4 mr-4">
                <div class="font-medium">System Notification</div>
                <div v-if="editing" class="mt-1 text-slate-500">
                    Item has been successfully updated.
                </div>
                <div v-else class="mt-1 text-slate-500">
                    Item has been successfully added.
                </div>
            </div>
        </Notification>

        <Notification
            :options="{
                duration: 3000,
            }"
            class="flex"
            refKey="errorNotification"
        >
            <Lucide class="text-danger" icon="X" />
            <div class="ml-4 mr-4">
                <div class="font-medium">System Notification</div>
                <div class="mt-1 text-slate-500">
                    <ul class="list-disc">
                        <template v-for="errorBag in backendValidationErrors">
                            <li class="text-red-500">
                                {{ errorBag[0] }}
                            </li>
                        </template>
                    </ul>
                </div>
            </div>
        </Notification>
    </div>
</template>

<style scoped></style>
