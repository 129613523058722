<script lang="ts" setup>
import { defineEmits, onMounted, provide, reactive, ref, toRefs } from "vue";
import {
    FormInput,
    FormLabel,
    FormSwitch,
} from "../../../base-components/Form";
import { helpers, required } from "@vuelidate/validators";
import { useVuelidate } from "@vuelidate/core";
import { useHelperStore } from "../../../stores/helperStore";
import { useShiftStore } from "../../../stores/shiftStore";
import { computed } from "@vue/reactivity";
import Button from "../../../base-components/Button";
import Litepicker from "../../../base-components/Litepicker";
import TomSelect from "../../../base-components/TomSelect";
import FormShiftBreaks from "./FormShiftBreaks.vue";
import FormShiftOvertimes from "./FormShiftOvertimes.vue";
import useTimeCalculator from "../../../composables/time-calculator";
import moment from "moment";
import _ from "lodash";
import { ShiftInterface } from "../../interfaces/shiftInterface";
import Notification, {
    NotificationElement,
} from "../../../base-components/Notification";
import Lucide from "../../../base-components/Lucide";

const props = defineProps({
    shift: {
        type: Object,
        default: {},
    },
    workDays: {
        type: Array,
        default: () => [
            "Sunday",
            "Monday",
            "Tuesday",
            "Wednesday",
            "Thursday",
            "Friday",
            "Saturday",
        ],
    },
});
const backendValidationErrors = ref({});
const errorNotification = ref<NotificationElement>();
const successNotification = ref<NotificationElement>();
const workDays = ref<string[]>(props.workDays as string[]);

const toggleWorkDay = (day: string) => {
    console.log("toggleWorkDay");
    console.log("day", day);
    if (form.work_days.includes(day)) {
        form.work_days = form.work_days.filter((d) => d !== day);
    } else {
        form.work_days.push(day);
    }
};

const form = reactive<ShiftInterface>({
    id: 0,
    name: "",
    timezone_id: "",
    shift_active_start_date: "",
    start_time: "",
    end_time: "",
    work_days: [],
    total_hours: 0,
    shift_breaks: [
        {
            friendly_name: "",
            shift_break_type_id: "",
            start_time: "",
            end_time: "",
            total_hours: 0,
            is_paid: false,
        },
    ],
    shift_overtimes: [],
    used_on_holidays: false,
    used_on_restdays: false,
});

const rules = {
    name: { required },
    timezone_id: { required },
    shift_active_start_date: { required },
    work_days: { required },
    start_time: "",
    end_time: "",
    total_hours: 0,
    shift_breaks:
        form.total_hours > 4
            ? {
                  $each: helpers.forEach({
                      friendly_name: { required },
                      shift_break_type_id: { required },
                      start_time: "",
                      end_time: "",
                      total_hours: 0,
                  }),
              }
            : {},
    used_on_holidays: { required },
    used_on_restdays: { required },
};

const emit = defineEmits(["close"]);
const $externalResults = ref({});

const validate = useVuelidate(rules, toRefs(form), { $externalResults });
const HelperStore = useHelperStore();
const ShiftStore = useShiftStore();
const TimeCalculator = useTimeCalculator();

const onSubmit = async () => {
    validate.value.$reset();
    validate.value.$clearExternalResults();
    validate.value.$touch();

    if (validate.value.$invalid) {
        backendValidationErrors.value = {
            message: ["Incomplete or Missing required data"],
        };

        showErrorsNotification();
        return;
    }

    if (props.shift.id) {
        await update();
    } else {
        await save();
    }
};

const save = async () => {
    if (!validate.value.$invalid) {
        try {
            calculateTotalHours();

            await ShiftStore.addShift(form);

            validate.value.$reset();
            resetForm();
            closeModal();
            showSuccessNotification();
        } catch (error: any) {
            $externalResults.value = error?.response?.data?.errors;
        }
    }
};

const update = async () => {
    if (!validate.value.$invalid) {
        try {
            calculateTotalHours();

            await ShiftStore.updateShift(form);

            validate.value.$reset();
            resetForm();
            closeModal();
            showSuccessNotification();
        } catch (error: any) {
            $externalResults.value = error?.response?.data?.errors;
        }
    }
};

const resetForm = () => {
    form.id = 0;
    form.name = "";
    form.timezone_id = "";
    form.shift_active_start_date = "";
    form.start_time = "";
    form.end_time = "";
    form.total_hours = 0;
    form.shift_breaks = [
        {
            friendly_name: "",
            shift_break_type_id: "",
            start_time: "",
            end_time: "",
            total_hours: 0,
            is_paid: false,
        },
    ];
    form.shift_overtimes = [
        {
            start_time: "",
            end_time: "",
        },
    ];
    form.used_on_holidays = false;
    form.used_on_restdays = false;
};

const closeModal = () => {
    emit("close");
};

const calculateTotalHours = () => {
    TimeCalculator.calculate(form.start_time, form.end_time);

    form.total_hours = TimeCalculator.total.value;
};

const totalBreakHours = computed(() => {
    let total = 0;

    form.shift_breaks.forEach((breaks) => {
        total += +breaks.total_hours;
    });

    return total;
});

const totalOvertimeHours = computed(() => {
    let total = 0;

    form.shift_overtimes.forEach((overtime) => {
        if (overtime.start_time && overtime.end_time) {
            TimeCalculator.calculate(overtime.start_time, overtime.end_time);

            total += TimeCalculator.total.value;
        }
    });

    return total;
});

const isNewShift = computed(() => {
    return !props.shift.id;
});

const onChangeTimezone = () => {
    if (form.timezone_id === "Select Timezone") {
        form.timezone_id = "";
    }
};

const errorNotificationToggle = () => {
    // Show notification
    errorNotification.value?.showToast();
};
provide("bind[errorNotification]", (el: NotificationElement) => {
    errorNotification.value = el;
});
const showErrorsNotification = () => {
    errorNotificationToggle();
};

const successNotificationToggle = () => {
    successNotification.value?.showToast();
};
provide("bind[successNotification]", (el: NotificationElement) => {
    successNotification.value = el;
});
const showSuccessNotification = () => {
    successNotificationToggle();
};

onMounted(() => {
    const { shift } = toRefs(props);

    if (shift.value.id) {
        form.id = shift.value.id;
        form.name = shift.value.name;
        form.timezone_id = shift.value.timezone_id?.toString() ?? "";
        form.shift_active_start_date = moment(
            shift.value.shift_active_start_date
        )
            .format("YYYY-MM-DD")
            .toString();
        form.start_time = shift.value.start_time;
        form.end_time = shift.value.end_time;
        form.work_days = shift.value.work_days;
        form.total_hours = shift.value.total_hours;
        form.shift_breaks = _.each(shift.value.shift_breaks, (breaks) => {
            breaks.friendly_name = breaks.friendly_name;
            breaks.shift_break_type_id = breaks.shift_break_type_id.toString();
            breaks.start_time = breaks.start_time;
            breaks.end_time = breaks.end_time;
            breaks.total_hours = breaks.total_hours.toString();
        });
        form.used_on_holidays = shift.value.used_on_holidays;
        form.used_on_restdays = shift.value.used_on_restdays;

        if (shift.value.shift_overtimes.length > 0) {
            form.shift_overtimes = shift.value.shift_overtimes;
        }
    }
});
</script>

<template>
    <form @submit.prevent="onSubmit">
        <div class="grid grid-cols-12 gap-6 mt-5 items-left text-left">
            <div class="intro-y col-span-12">
                <!-- BEGIN: Form Layout -->
                <div class="intro-y pb-5 pl-5 pr-2">
                    <div class="mt-4">
                        <FormLabel class="form-label" for="name"
                            >Name
                            <span class="text-red-600">*</span>
                        </FormLabel>
                        <FormInput
                            id="name"
                            v-model="form.name"
                            :value="form.name"
                            class="form-control w-full"
                            maxlength="50"
                            name="name"
                            placeholder=""
                            type="text"
                        />
                        <template v-if="validate.name.$error">
                            <div
                                v-for="(error, index) in validate.name.$errors"
                                :key="index"
                                class="text-danger mt-2"
                            >
                                {{ error.$message }}
                            </div>
                        </template>
                    </div>

                    <div class="mt-4">
                        <FormLabel class="form-label"
                            >Timezone
                            <span class="text-red-600">*</span></FormLabel
                        >
                        <div>
                            <TomSelect
                                id="timezone_id"
                                v-model="form.timezone_id"
                                :value="form.timezone_id"
                                class="w-full p-0"
                                name="timezone_id"
                                @update:modelValue="onChangeTimezone()"
                            >
                                <option selected>Select Timezone</option>
                                <option
                                    v-for="timezone of HelperStore.timezones"
                                    :value="timezone.id"
                                >
                                    {{ timezone.name }}
                                </option>
                            </TomSelect>
                        </div>
                        <template v-if="validate.timezone_id.$error">
                            <div
                                v-for="(error, index) in validate.timezone_id
                                    .$errors"
                                :key="index"
                                class="text-danger mt-2"
                            >
                                {{ error.$message }}
                            </div>
                        </template>
                    </div>

                    <div class="mt-4">
                        <FormLabel class="form-label"
                            >Start Date
                            <span class="text-red-600">*</span>
                        </FormLabel>
                        <Litepicker
                            id="shift_active_start_date"
                            v-model="form.shift_active_start_date"
                            :options="{
                                autoApply: true,
                                format: 'YYYY-MM-DD',
                                showWeekNumbers: true,
                                autoRefresh: true,
                                resetButton: true,
                                dropdowns: {
                                    minYear: 1990,
                                    maxYear: null,
                                    months: true,
                                    years: true,
                                },
                            }"
                            class="form-control w-full block mx-auto"
                            name="shift_active_start_date"
                        />
                        <template
                            v-if="validate.shift_active_start_date.$error"
                        >
                            <div
                                v-for="(error, index) in validate
                                    .shift_active_start_date.$errors"
                                :key="index"
                                class="text-danger mt-2"
                            >
                                {{ error.$message }}
                            </div>
                        </template>
                    </div>

                    <div class="grid grid-cols-12 gap-6">
                        <div class="mt-4 col-span-12 lg:col-span-6">
                            <FormLabel class="form-label" for="name"
                                >Start Time
                            </FormLabel>
                            <FormInput
                                id="start_time"
                                v-model="form.start_time"
                                :value="form.start_time"
                                class="form-control w-full"
                                maxlength="50"
                                name="start_time"
                                placeholder=""
                                type="time"
                                @change="calculateTotalHours"
                            />
                            <template v-if="validate.start_time.$error">
                                <div
                                    v-for="(error, index) in validate.start_time
                                        .$errors"
                                    :key="index"
                                    class="text-danger mt-2"
                                >
                                    {{ error.$message }}
                                </div>
                            </template>
                        </div>

                        <div class="mt-4 col-span-12 lg:col-span-6">
                            <FormLabel class="form-label" for="name"
                                >End Time
                            </FormLabel>
                            <FormInput
                                id="end_time"
                                v-model="form.end_time"
                                :value="form.end_time"
                                class="form-control w-full"
                                maxlength="50"
                                name="end_time"
                                placeholder=""
                                type="time"
                                @change="calculateTotalHours"
                            />
                            <template v-if="validate.end_time.$error">
                                <div
                                    v-for="(error, index) in validate.end_time
                                        .$errors"
                                    :key="index"
                                    class="text-danger mt-2"
                                >
                                    {{ error.$message }}
                                </div>
                            </template>
                        </div>
                    </div>

                    <div class="mt-4">
                        <FormLabel class="form-label" for="name"
                            >Total Hours
                        </FormLabel>
                        <FormInput
                            id="total_hours"
                            :value="validate.total_hours.$model"
                            class="form-control w-full"
                            disabled
                            maxlength="50"
                            name="total_hours"
                            placeholder=""
                            type="text"
                            @update:modelValue="
                                validate.total_hours.$model.value = +$event
                            "
                        />
                        <template v-if="validate.total_hours.$error">
                            <div
                                v-for="(error, index) in validate.total_hours
                                    .$errors"
                                :key="index"
                                class="text-danger mt-2"
                            >
                                {{ error.$message }}
                            </div>
                        </template>
                    </div>

                    <div class="mt-4">
                        <FormLabel class="form-label" for="work_days"
                            >Work Days
                        </FormLabel>

                        <div class="flex gap-x-4">
                            <div
                                v-for="day in workDays"
                                :key="day"
                                class="mt-6 flex flex-row align-center items-center"
                            >
                                <FormSwitch>
                                    <FormSwitch.Input
                                        :id="day.toLowerCase()"
                                        :checked="form.work_days.includes(day)"
                                        type="checkbox"
                                        @change="toggleWorkDay(day)"
                                    />
                                </FormSwitch>

                                <label :for="day.toLowerCase()" class="ml-2">
                                    {{ day }}
                                </label>
                            </div>
                        </div>

                        <template v-if="validate.work_days.$error">
                            <div
                                v-for="(error, index) in validate.work_days
                                    .$errors"
                                :key="index"
                                class="text-danger mt-4"
                            >
                                {{ error.$message }}
                            </div>
                        </template>
                    </div>

                    <hr class="my-6" />

                    <FormShiftBreaks
                        :breaks="form.shift_breaks"
                        :errors="$externalResults"
                        :validate="validate"
                        :editing="!!props.shift.id"
                    />

                    <hr class="my-6" />

                    <FormShiftOvertimes
                        :errors="$externalResults"
                        :overtimes="form.shift_overtimes"
                        :validate="validate"
                    />

                    <hr class="my-6" />

                    <div class="grid grid-cols-12 gap-6">
                        <div class="mt-4 col-span-12 lg:col-span-4">
                            <div class="mt-4">
                                <FormLabel
                                    class="form-label"
                                    for="total_expected_work_hours"
                                    >Total Expected Work Hours
                                </FormLabel>
                                <FormInput
                                    id="total_expected_work_hours"
                                    :value="form.total_hours"
                                    class="form-control w-full"
                                    disabled
                                    maxlength="50"
                                    name="total_expected_work_hours"
                                    placeholder=""
                                    type="text"
                                />
                            </div>
                        </div>

                        <div class="mt-4 col-span-12 lg:col-span-4">
                            <div class="mt-4">
                                <FormLabel
                                    class="form-label"
                                    for="total_break_hours"
                                    >Total Break Hours
                                </FormLabel>
                                <FormInput
                                    id="total_break_hours"
                                    :value="totalBreakHours"
                                    class="form-control w-full"
                                    disabled
                                    maxlength="50"
                                    name="total_break_hours"
                                    placeholder=""
                                    type="text"
                                />
                            </div>
                        </div>

                        <div class="mt-4 col-span-12 lg:col-span-4">
                            <div class="mt-4">
                                <FormLabel
                                    class="form-label"
                                    for="total_overtime_hours"
                                    >Total Scheduled Overtime Hours
                                </FormLabel>
                                <FormInput
                                    id="total_overtime_hours"
                                    :value="totalOvertimeHours"
                                    class="form-control w-full"
                                    disabled
                                    maxlength="50"
                                    name="total_overtime_hours"
                                    placeholder=""
                                    type="text"
                                />
                            </div>
                        </div>
                    </div>

                    <div class="grid grid-cols-12 gap-6">
                        <div class="mt-4 col-span-12 lg:col-span-6">
                            <div
                                class="mt-4 flex flex-row align-center items-center"
                            >
                                <FormSwitch>
                                    <FormSwitch.Input
                                        v-model="form.used_on_holidays"
                                        type="checkbox"
                                        v-bind:checked="form.used_on_holidays"
                                    />
                                </FormSwitch>

                                <label class="ml-2">Schedule on Holidays</label>
                            </div>

                            <div
                                class="mt-4 flex flex-row align-center items-center"
                            >
                                <FormSwitch>
                                    <FormSwitch.Input
                                        v-model="form.used_on_restdays"
                                        type="checkbox"
                                        v-bind:checked="form.used_on_restdays"
                                    />
                                </FormSwitch>

                                <label class="ml-2"
                                    >Schedule on Rest Days</label
                                >
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>

        <div class="">
            <div class="text-right p-2">
                <Button
                    id="btn_cancel"
                    class="w-32 mb-2 mr-1"
                    type="button"
                    variant="warning"
                    @click="closeModal"
                >
                    Cancel
                </Button>
                <Button
                    id="btn_process"
                    class="w-36 mb-2 mr-1"
                    type="submit"
                    variant="customPrimary"
                >
                    {{ isNewShift ? "Add Shift" : "Update Shift" }}
                </Button>
            </div>
        </div>
    </form>

    <Notification
        :options="{
            duration: 3000,
        }"
        class="flex"
        refKey="successNotification"
    >
        <Lucide class="text-success" icon="CheckCircle" />
        <div class="ml-4 mr-4">
            <div class="font-medium">System Notification</div>
            <div class="mt-1 text-slate-500">
                Shift has been successfully added.
            </div>
        </div>
    </Notification>

    <Notification
        :options="{
            duration: 3000,
        }"
        class="flex"
        refKey="errorNotification"
    >
        <Lucide class="text-danger" icon="X" />
        <div class="ml-4 mr-4">
            <div class="font-medium">System Notification</div>
            <div class="mt-1 text-slate-500">
                <ul class="list-disc">
                    <template v-for="errorBag in backendValidationErrors">
                        <li class="text-red-500">
                            {{ errorBag[0] }}
                        </li>
                    </template>
                </ul>
            </div>
        </div>
    </Notification>
</template>
