<script lang="ts" setup>
import { onMounted, reactive, ref } from "vue";
import { required } from "@vuelidate/validators";
import moment from "moment/moment";
import "primevue/resources/themes/saga-blue/theme.css";
import "primevue/resources/primevue.min.css";
import "primeicons/primeicons.css";
import { FilterMatchMode, FilterOperator } from "primevue/api";
import DataTable from "primevue/datatable";
import Column from "primevue/column";
import InputText from "primevue/inputtext";
import Button from "../base-components/Button";
import { FormInput } from "../base-components/Form";
import Lucide from "../base-components/Lucide";
import { useClientsStore } from "../stores/clientsStore";
import { useTicketsStore } from "../stores/ticketsStore";
import { useHelperStore } from "../stores/helperStore";
import PageHeaderStandard from "./components/PageHeaderStandard.vue";
import { useAuthStore } from "../stores/authStore";
import LazyParamsInterface from "./interfaces/lazyParamsInterface";
import { useTeamStore } from "../stores/teamStore";
import Dropdown from "primevue/dropdown";

const AuthStore = useAuthStore();

const ClientStore = useClientsStore();
const ticketStore = useTicketsStore();
const HelperStore = useHelperStore();
const TeamStore = useTeamStore();

let statesOptionsDetails = {};
let timezoneOptionsDetails = {};

const alertModelSettings = reactive({
    isDisplayed: false,
    mode: "",
    subject: "",
    message: "",
});

const teamFilter = ref("");
const filters = ref({
    global: { value: null, matchMode: FilterMatchMode.CONTAINS },
    "ticket_type.name": {
        operator: FilterOperator.AND,
        constraints: [{ value: null, matchMode: FilterMatchMode.STARTS_WITH }],
    },
    "user.name": {
        operator: FilterOperator.AND,
        constraints: [{ value: null, matchMode: FilterMatchMode.STARTS_WITH }],
    },
    "user.employee.employee_teams": {
        operator: FilterOperator.OR,
        constraints: [{ value: null, matchMode: FilterMatchMode.EQUALS }],
    },
    form_body: {
        operator: FilterOperator.AND,
        constraints: [{ value: null, matchMode: FilterMatchMode.STARTS_WITH }],
    },
});

const tickets = ref();

const selectedtickets = ref();

const selectAll = ref(false);

const loading = ref(true);

const totalRecords = ref(0);

const showClearFilter = ref(false);

const lazyParams = ref<LazyParamsInterface>({});

const dt = ref();

let timezone = ref();

const searchableCols = ref([
    "types.name",
    "status.name",
    "ticket_type.name",
    "user.name",
    "form_body",
]);

let showSlider = ref(false);

let buttonModalPreview = ref(false);

const formData = reactive({
    user_id: "",
    name: "",
    position: "",
    personal_phone: "",
    personal_email: "",
    work_email: "",
    work_phone: "",
    client_email: null,
    primary_work_location: null,
    location: "",
    shift_schedule: null,
    notes: null,
    emergency_contact_name: null,
    emergency_contact_relationship: null,
    internal_staff_flag: null,
    sss: null,
    pagibig: null,
    tin: null,
    phic: null,
    basic_salary: null,
    regularization_date: null,
    timezone_id: null,
});

const rules = {
    legal_business_name: { required },
    user_id: { required },
    name: { required },
    position: { required },
    personal_phone: { required },
    personal_email: { required },
    work_email: { required },
    work_phone: { required },
    client_email: { required },
    timezone_id: { required },
    primary_work_location: { required },
    location: { required },
    shift_schedule: { required },
    notes: { required },
    emergency_contact_name: { required },
    emergency_contact_relationship: { required },
    internal_staff_flag: { required },
    sss: { required },
    pagibig: { required },
    tin: { required },
    phic: { required },
    basic_salary: { required },
    regularization_date: { required },
};

const formticketSettings = reactive({
    id: "",
});

const onClickTeamFilterClear = () => {
    teamFilter.value = "";

    loadLazyData();
};

onMounted(async () => {
    await AuthStore.getLoggedInUserDetails();
    await TeamStore.getAllTeams();
    lazyParams.value = {
        first: 0,
        rows: dt.value.rows,
        sortField: null,
        sortOrder: null,
        filters: filters.value,
    };
    await loadLazyData();
});

const createNew = () => {
    //router.push({ name: 'tickets' });
};

const updateticket = async (data: { id: any }) => {
    console.log("updateticket", data);
    showSlider.value = true;
    formticketSettings.id = data.id;
};
const deleteticket = async (data: { id: any }) => {
    console.log("deleteticket", data);
    await ticketStore.delete(data.id);
    loadLazyData();
};

const formticketStatus = (status: any) => {
    console.log("status", status);
    if (status) {
        loadLazyData();
    }
};

const loadLazyData = async () => {
    console.log("loadLazyData");
    loading.value = true;
    const params = {
        dt_params: JSON.stringify(lazyParams.value),
        searchable_columns: JSON.stringify(searchableCols.value),
        historical: true,
        team_filter: teamFilter.value,
    };

    await ticketStore.get(params);
    //await ticketStore.fill(params)
    tickets.value = ticketStore.tickets.data;
    totalRecords.value = ticketStore.tickets.total;
    loading.value = false;
    console.log(tickets.value);
};

const onPage = (event: {}) => {
    console.log("onPage");

    showClearFilter.value = true;
    lazyParams.value = event;
    console.log("New onPage");
    console.log(lazyParams);
    loadLazyData();
};

const onSort = (event: {}) => {
    console.log("onSort");

    showClearFilter.value = true;
    lazyParams.value = event;
    loadLazyData();
};

const onFilter = () => {
    console.log("onFilter");

    showClearFilter.value = filters.value.global.value !== "";
    lazyParams.value.filters = filters.value;
    loadLazyData();
};

const resetGlobalFilter = () => {
    showClearFilter.value = false;

    filters.value = {
        global: { value: null, matchMode: FilterMatchMode.CONTAINS },
        "ticket_type.name": {
            operator: FilterOperator.AND,
            constraints: [
                { value: null, matchMode: FilterMatchMode.STARTS_WITH },
            ],
        },
        "user.name": {
            operator: FilterOperator.AND,
            constraints: [
                { value: null, matchMode: FilterMatchMode.STARTS_WITH },
            ],
        },
        "user.employee.employee_teams": {
            operator: FilterOperator.OR,
            constraints: [{ value: null, matchMode: FilterMatchMode.EQUALS }],
        },
        form_body: {
            operator: FilterOperator.AND,
            constraints: [
                { value: null, matchMode: FilterMatchMode.STARTS_WITH },
            ],
        },
    };

    lazyParams.value = {
        first: 0,
        rows: dt.value.rows,
        sortField: null,
        sortOrder: null,
        filters: filters.value,
    };

    teamFilter.value = "";
    loadLazyData();
};

const onSelectAllChange = (event: { checked: any }) => {
    console.log("onSelectAllChange");
    const selectAll = event.checked;

    // if (selectAll) {
    //     customerService.value.getCustomers().then((data: { customers: any; }) => {
    //         selectAll.value = true;
    //         selectedtickets.value = data.customers;
    //     });
    // } else {
    //     selectAll.value = false;
    //     selectedtickets.value = [];
    // }
};

const onRowSelect = () => {
    console.log("onRowSelect");
    selectAll.value = selectedtickets.value.length === totalRecords.value;
};

const onRowUnselect = () => {
    console.log("onRowUnselect");
    selectAll.value = false;
};
</script>
<template>
    <div class="w-full">
        <PageHeaderStandard :title="'Tickets'" />
        <div
            class="intro-y col-span-12 flex flex-wrap sm:flex-nowrap items-center mt-2"
        >
            <Button
                class="mr-2 shadow-md"
                variant="customPrimary"
                @click="createNew"
            >
                Add New Ticket
            </Button>
            <div class="hidden md:block mx-auto text-slate-500"></div>
            <div class="flex w-full sm:w-auto mt-3 sm:mt-0 sm:ml-auto md:ml-0">
                <template v-if="showClearFilter">
                    <button
                        class="btn bg-custom-color-1000 text-white w-full mx-3 rounded-md px-4"
                        @click="resetGlobalFilter"
                    >
                        Clear
                        <span
                            class="h-3 w-4 pi pi-filter-slash p-component"
                        ></span>
                    </button>
                </template>

                <div class="w-56 relative text-slate-500">
                    <FormInput
                        v-model="filters['global'].value"
                        :value="filters['global'].value"
                        class="w-56 pr-10 !box"
                        placeholder="Search..."
                        type="text"
                        @keyup.enter="onFilter()"
                    />
                    <Lucide
                        class="absolute inset-y-0 right-0 w-4 h-4 my-auto mr-3"
                        icon="Search"
                    />
                </div>
            </div>
        </div>
        <DataTable
            ref="dt"
            v-model:filters="filters"
            v-model:selection="selectedtickets"
            :globalFilterFields="searchableCols"
            :lazy="true"
            :loading="loading"
            :paginator="true"
            :rows="10"
            :selectAll="selectAll"
            :totalRecords="totalRecords"
            :value="tickets"
            class="mt-4 p-datatable-sm rounded-lg shadow-lg text-xs"
            dataKey="id"
            filterDisplay="menu"
            responsiveLayout="scroll"
            showGridlines
            stripedRows
            @filter="onFilter()"
            @page="onPage($event)"
            @sort="onSort($event)"
            @select-all-change="onSelectAllChange"
            @row-select="onRowSelect"
            @row-unselect="onRowUnselect"
        >
            <template #empty> No tickets found.</template>
            <template #loading> Loading ticket data. Please wait.</template>
            <Column
                headerStyle="min-width: 36px"
                selectionMode="multiple"
            ></Column>
            <Column
                :sortable="true"
                field="ticket_type.name"
                header="Ticket Type"
            >
                <template #body="{ data }">
                    {{ data.ticket_type.name }}
                </template>
                <template #filter="{ filterModel }">
                    <InputText
                        v-model="filterModel.value"
                        class="p-column-filter"
                        placeholder="Search by Ticket Type"
                        type="text"
                    />
                </template>
            </Column>
            <Column :sortable="true" field="user.name" header="Submitted By">
                <template #body="{ data }">
                    {{ data.user.name }}
                </template>
                <template #filter="{ filterModel }">
                    <InputText
                        v-model="filterModel.value"
                        class="p-column-filter"
                        placeholder="Search by Employee Name"
                        type="text"
                    />
                </template>
            </Column>
            <Column :sortable="true" field="status.name" header="Status">
                <template #body="{ data }">
                    <div v-if="data.num_approvals_required > 0">
                        <div
                            v-if="
                                data.status?.name === 'Pending Approval' ||
                                data.status?.name === 'Open' ||
                                data.status?.name === 'Waiting on Third Party'
                            "
                            class="bg-blue-50 text-blue-900 rounded-full py-1 px-4 text-center"
                        >
                            Pending Approval ({{ data.approved_count ?? 0 }}/{{
                                data.num_approvals_required ?? 0
                            }})
                        </div>

                        <div
                            v-if="data.status?.name === 'Approved'"
                            class="bg-green-50 text-green-900 rounded-full py-1 px-4 text-center"
                        >
                            {{ data.status?.name }}
                        </div>

                        <div
                            v-if="data.status?.name === 'Declined'"
                            class="bg-red-50 text-red-900 rounded-full py-1 px-4 text-center"
                        >
                            {{ data.status?.name }}
                        </div>
                    </div>

                    <div v-else>
                        <div
                            v-if="
                                data.status?.name === 'Open' ||
                                data.status?.name === 'Waiting on Third Party'
                            "
                            class="bg-blue-50 text-blue-900 rounded-full py-1 px-4 text-center"
                        >
                            {{ data.status?.name }}
                        </div>
                    </div>
                </template>
            </Column>
            <Column
                :show-add-button="false"
                :show-filter-match-modes="false"
                :show-filter-operator="false"
                field="user.employee.employee_teams"
                header="Team Name"
            >
                <template #body="{ data }">
                    <ul>
                        <li v-for="teams in data.user.employee.employee_teams">
                            <span v-for="team in teams.team">
                                {{ team.name }}
                            </span>
                        </li>
                    </ul>
                </template>
                <template #filter>
                    <Dropdown
                        v-model="teamFilter"
                        :options="TeamStore.teams"
                        option-label="name"
                        option-value="name"
                        placeholder="Select Team"
                        :showClear="true"
                    >
                    </Dropdown>
                </template>
                <template #filterclear>
                    <Button
                        class="border border-[#2196f3] text-[#2196f3] py-1.5 px-2"
                        @click="onClickTeamFilterClear"
                        >Clear
                    </Button>
                </template>
            </Column>
            <Column :sortable="true" field="created_at" header="Created At">
                <template #body="{ data }">
                    {{ moment(data.created_at).format("YYYY-MM-D h:mm") }}
                </template>
            </Column>
            <Column :exportable="false" style="width: 8rem; max-width: 8rem">
                <template #body="slotProps">
                    <div class="content-center flex justify-center">
                        <p>
                            <router-link
                                :to="{
                                    name: 'ticketDetails',
                                    params: { id: slotProps.data.id },
                                }"
                            >
                                <svg
                                    class="icon icon-tabler icon-tabler-eye"
                                    fill="none"
                                    height="18"
                                    stroke="currentColor"
                                    stroke-linecap="round"
                                    stroke-linejoin="round"
                                    stroke-width="2"
                                    viewBox="0 0 24 24"
                                    width="18"
                                    xmlns="http://www.w3.org/2000/svg"
                                >
                                    <path
                                        d="M0 0h24v24H0z"
                                        fill="none"
                                        stroke="none"
                                    ></path>
                                    <path
                                        d="M10 12a2 2 0 1 0 4 0a2 2 0 0 0 -4 0"
                                    ></path>
                                    <path
                                        d="M21 12c-2.4 4 -5.4 6 -9 6c-3.6 0 -6.6 -2 -9 -6c2.4 -4 5.4 -6 9 -6c3.6 0 6.6 2 9 6"
                                    ></path>
                                </svg>
                            </router-link>
                        </p>
                    </div>
                </template>
                <template #header="slotProps">
                    <div class="content-center flex justify-center"></div>
                </template>
            </Column>
            <template #paginatorstart>
                <!--                <Button type="button" icon="pi pi-refresh" class="p-button-text"/>-->
            </template>
            <template #paginatorend>
                <!--                <Button type="button" icon="pi pi-cloud" class="p-button-text"/>-->
            </template>
        </DataTable>
    </div>
</template>
