<script lang="ts" setup>
import { computed, onMounted, reactive, ref, toRefs } from "vue";
import {
    EmployeeAttendanceBreakInterface,
    EmployeeAttendanceInterface,
} from "../../interfaces/employeeAttendanceInterface";
import { helpers, required } from "@vuelidate/validators";
import useVuelidate from "@vuelidate/core";
import { useEmployeeAttendanceStore } from "../../../stores/employeeAttendanceStore";
import { FormInput, FormLabel } from "../../../base-components/Form";
import { useEmployeesStore } from "../../../stores/employeesStore";
import TomSelect from "../../../base-components/TomSelect";
import { useShiftStore } from "../../../stores/shiftStore";
import FormAttendanceBreak from "./FormAttendanceBreak.vue";
import moment from "moment-timezone";
import Button from "../../../base-components/Button";
import { ShiftBreakInterface } from "../../interfaces/shiftInterface";
import FormTextarea from "../../../base-components/Form/FormTextarea.vue";
import { useAuthStore } from "../../../stores/authStore";

const props = defineProps({
    attendance: {
        type: Object as () => EmployeeAttendanceInterface,
        default: () => ({}),
    },
});
const emit = defineEmits(["close"]);

const AuthStore = useAuthStore();
const AttendanceStore = useEmployeeAttendanceStore();
const EmployeeStore = useEmployeesStore();
const ShiftStore = useShiftStore();

const form = reactive<EmployeeAttendanceInterface>({
    id: null,
    employee_id: "",
    shift_id: "",
    employee_schedule_id: "",
    start_time: moment().tz("Asia/Manila").format("YYYY-MM-DDTHH:mm"),
    end_time: moment().tz("Asia/Manila").format("YYYY-MM-DDTHH:mm"),
    breaks: [] as EmployeeAttendanceBreakInterface[],
    notes: "",
});

const shiftBreaks = ref<ShiftBreakInterface[]>([]);

const rules = {
    employee_id: {
        required,
    },
    shift_id: {
        required,
    },
    employee_schedule_id: {
        required,
    },
    start_time: {
        required,
    },
    end_time: {
        required,
    },
    breaks: {
        $each: helpers.forEach({
            shift_break_id: { required },
            start_time: { required },
            end_time: { required },
        }),
    },
    notes: {},
};
const $externalResults = ref({});
const validate = useVuelidate(rules, toRefs(form), { $externalResults });

const onSelectShift = () => {
    const shift = EmployeeStore.employees.find(
        (employee) => employee.id === +form.employee_id
    )?.shift?.shift;

    console.log(shift);

    if (shift) {
        form.shift_id = String(shift.id);

        const start_time = moment.utc(
            moment().tz("Asia/Manila").format("YYYY-MM-DD") +
                " " +
                shift.start_time
        );
        const end_time = moment.utc(
            moment().tz("Asia/Manila").format("YYYY-MM-DD") +
                " " +
                shift.end_time
        );

        if (end_time.isBefore(start_time)) {
            end_time.add(1, "day");
        }

        form.start_time = start_time.format("YYYY-MM-DDTHH:mm");
        form.end_time = end_time.format("YYYY-MM-DDTHH:mm");
    }

    if (shift?.shift_breaks && shift?.shift_breaks?.length > 0) {
        shiftBreaks.value = shift.shift_breaks;

        form.breaks = [];
        shift.shift_breaks.forEach((breaks) => {
            const start_time = moment.utc(
                moment().tz("Asia/Manila").format("YYYY-MM-DD") +
                    " " +
                    shift.start_time
            );
            const breakStartTime = moment.utc(
                moment().tz("Asia/Manila").format("YYYY-MM-DD") +
                    " " +
                    breaks.start_time
            );
            const breakEndTime = moment.utc(
                moment().tz("Asia/Manila").format("YYYY-MM-DD") +
                    " " +
                    breaks.end_time
            );

            if (breakStartTime.isBefore(start_time)) {
                breakStartTime.add(1, "day");
                breakEndTime.add(1, "day");
            }

            form.breaks.push({
                shift_break_id: String(breaks.id),
                friendly_name: breaks.friendly_name,
                start_time: breakStartTime.format("YYYY-MM-DDTHH:mm"),
                end_time: breakEndTime.format("YYYY-MM-DDTHH:mm"),
            });
        });
    } else {
        form.breaks = [];
    }
};

const onSelectSchedule = () => {
    const schedule = AttendanceStore.schedules.find(
        (schedule) => schedule?.id === parseInt(form?.employee_schedule_id)
    );

    const shift = schedule?.shift;

    console.log("shift: ", shift);

    if (shift) {
        form.shift_id = String(shift.id);

        const start_time = moment.utc(schedule?.start_time);
        const end_time = moment.utc(schedule?.end_time);

        if (end_time.isBefore(start_time)) {
            end_time.add(1, "day");
        }

        form.start_time = start_time.format("YYYY-MM-DDTHH:mm");
        form.end_time = end_time.format("YYYY-MM-DDTHH:mm");
    }
    if (shift?.shift_breaks && shift?.shift_breaks?.length > 0) {
        shiftBreaks.value = shift.shift_breaks;

        form.breaks = [];
        shift.shift_breaks.forEach((breaks) => {
            const start_time = moment.utc(
                moment(schedule?.start_time)
                    .tz("Asia/Manila")
                    .format("YYYY-MM-DD") +
                    " " +
                    shift.start_time
            );
            const breakStartTime = moment.utc(
                moment(schedule?.start_time)
                    .tz("Asia/Manila")
                    .format("YYYY-MM-DD") +
                    " " +
                    breaks.start_time
            );
            const breakEndTime = moment.utc(
                moment(schedule?.end_time)
                    .tz("Asia/Manila")
                    .format("YYYY-MM-DD") +
                    " " +
                    breaks.end_time
            );

            if (breakStartTime.isBefore(start_time)) {
                breakStartTime.add(1, "day");
                breakEndTime.add(1, "day");
            }

            console.log({
                shift_break_id: String(breaks.id),
                friendly_name: breaks.friendly_name,
                start_time: breakStartTime.format("YYYY-MM-DDTHH:mm"),
                end_time: breakEndTime.format("YYYY-MM-DDTHH:mm"),
            });

            form.breaks.push({
                shift_break_id: String(breaks.id),
                friendly_name: breaks.friendly_name,
                start_time: breakStartTime.format("YYYY-MM-DDTHH:mm"),
                end_time: breakEndTime.format("YYYY-MM-DDTHH:mm"),
            });
        });
    } else {
        form.breaks = [];
    }
};

const save = async () => {
    if (!validate.value.$invalid) {
        try {
            await AttendanceStore.addAttendance(form);

            validate.value.$reset();
            resetForm();
            closeModal();
        } catch (error: any) {
            $externalResults.value = error?.response?.data?.errors;
        }
    }
};

const update = async () => {
    if (!validate.value.$invalid) {
        try {
            await AttendanceStore.updateAttendance(form);

            validate.value.$reset();
            resetForm();
            closeModal();
        } catch (error: any) {
            $externalResults.value = error?.response?.data?.errors;
        }
    }
};

const onSubmit = async () => {
    validate.value.$reset();
    validate.value.$clearExternalResults();
    validate.value.$touch();

    if (props.attendance.id) {
        await update();
    } else {
        await save();
    }
};

const resetForm = () => {
    Object.assign(form, {
        id: null,
        employee_id: "",
        shift_id: "",
        start_time: null,
        end_time: null,
        employee_schedule_id: "",
        breaks: [],
        notes: "",
    });

    shiftBreaks.value = [];
};

const closeModal = () => {
    resetForm();
    emit("close");
};

const onSelectEmployee = async () => {
    const employee = EmployeeStore.employees.find(
        (employee) => employee.id === +form.employee_id
    );

    if (employee) {
        await AttendanceStore.getNoClockIn({
            employee_id: employee.id,
        });

        form.shift_id = String(employee.shift?.id);
        onSelectShift();
    }
};

const isNewAttendance = computed(() => {
    return !props.attendance.id;
});

onMounted(async () => {
    await EmployeeStore.getAllEmployees();
    await ShiftStore.getAllShifts({ dropdown: true });
    await ShiftStore.getShiftBreakTypes();

    const { attendance } = toRefs(props);

    if (attendance.value.id) {
        Object.assign(form, {
            id: attendance.value.id,
            employee_id: String(attendance.value.employee_id),
            employee_schedule_id: String(attendance.value.employee_schedule_id),
            shift_id: String(attendance.value.shift_id),
            start_time: moment(attendance.value.start_time).format(
                "YYYY-MM-DDTHH:mm"
            ),
            end_time:
                attendance?.value.end_time !== null
                    ? moment(attendance.value.end_time).format(
                          "YYYY-MM-DDTHH:mm"
                      )
                    : null,
            notes: attendance.value.notes,
        });

        form.breaks = [];
        attendance.value.breaks.forEach((breaks) => {
            form.breaks.push({
                friendly_name: breaks?.type?.friendly_name,
                shift_break_id: String(breaks.shift_break_id),
                start_time: moment(breaks.start_time).format(
                    "YYYY-MM-DDTHH:mm"
                ),
                end_time: moment(breaks.end_time).format("YYYY-MM-DDTHH:mm"),
            });
        });

        const shift = EmployeeStore.employees.find(
            (employee) => employee.id === +form.employee_id
        )?.shift?.shift;

        // if (shift) {
        //     shiftBreaks.value = shift.shift_breaks;
        // }

        await AttendanceStore.showSchedule(
            +attendance?.value.employee_schedule_id
        );
        AttendanceStore.schedules.push(AttendanceStore.schedule);

        shiftBreaks.value = AttendanceStore.schedule.shift?.shift_breaks ?? [];
    }
});

const isEmptyObject = (obj: {}) => {
    return Object.keys(obj).length === 0;
};
</script>

<template>
    <form @submit.prevent="onSubmit">
        <div class="grid grid-cols-12 gap-6 mt-5 items-left text-left">
            <div class="intro-y col-span-12">
                <div class="intro-y pb-5 pl-5 pr-2">
                    <div class="mt-4">
                        <FormLabel class="form-label" for="employee"
                            >Employee
                            <span class="text-red-500">*</span>
                        </FormLabel>
                        <TomSelect
                            id="employee"
                            v-model="form.employee_id"
                            :disabled="!isEmptyObject(props.attendance)"
                            :value="form.employee_id"
                            class="w-full p-0"
                            @update:model-value="onSelectEmployee"
                        >
                            <option>Select Employee</option>
                            <option
                                v-for="employee in EmployeeStore.employees"
                                :value="employee.id"
                            >
                                {{ employee.name }}
                            </option>
                        </TomSelect>
                        <div class="mt-2">
                            <template v-if="validate.employee_id.$error">
                                <div
                                    v-for="(error, index) in validate
                                        .employee_id.$errors"
                                    :key="index"
                                    class="text-danger mt-2"
                                >
                                    {{ error.$message }}
                                </div>
                            </template>
                        </div>
                    </div>

                    <div class="mt-4">
                        <FormLabel class="form-label" for="shift"
                            >Shift
                            <span class="text-red-500">*</span>
                        </FormLabel>
                        <TomSelect
                            id="shift"
                            v-model="form.shift_id"
                            :value="form.shift_id"
                            class="w-full p-0"
                            disabled
                            @update:model-value="onSelectShift"
                        >
                            <option>Select Shift</option>
                            <option
                                v-for="shift in ShiftStore.allShifts"
                                :value="shift.id"
                            >
                                {{ shift.name }}
                            </option>
                        </TomSelect>
                        <div class="mt-2">
                            <template v-if="validate.shift_id.$error">
                                <div
                                    v-for="(error, index) in validate.shift_id
                                        .$errors"
                                    :key="index"
                                    class="text-danger mt-2"
                                >
                                    {{ error.$message }}
                                </div>
                            </template>
                        </div>
                    </div>

                    <div class="mt-4">
                        <div>
                            <FormLabel class="form-label" for="shift"
                                >Schedule
                                <span class="text-red-500">*</span>
                            </FormLabel>
                            <TomSelect
                                id="schedule"
                                v-model="form.employee_schedule_id"
                                :value="form.employee_schedule_id"
                                class="w-full p-0"
                                @update:model-value="onSelectSchedule"
                                :disabled="!isEmptyObject(props.attendance)"
                            >
                                <option>Select Schedule</option>
                                <option
                                    v-for="schedule in AttendanceStore.schedules"
                                    :value="schedule.id"
                                >
                                    {{ schedule.start_time }} -
                                    {{ schedule.end_time }}
                                </option>
                            </TomSelect>
                        </div>
                        <div class="my-2">
                            <template
                                v-if="validate.employee_schedule_id.$error"
                            >
                                <div
                                    v-for="(error, index) in validate
                                        .employee_schedule_id.$errors"
                                    :key="index"
                                    class="text-danger mt-2"
                                >
                                    {{ error.$message }}
                                </div>
                            </template>
                        </div>
                    </div>

                    <div class="grid grid-cols-12 gap-6">
                        <div class="mt-4 col-span-12 lg:col-span-6">
                            <FormLabel class="form-label" for="name"
                                >Start Time
                                <span class="text-red-500">*</span>
                            </FormLabel>
                            <FormInput
                                id="start_time"
                                v-model="form.start_time"
                                :value="form.start_time"
                                class="form-control w-full"
                                maxlength="50"
                                name="start_time"
                                placeholder=""
                                type="datetime-local"
                            />
                            <template v-if="validate.start_time.$error">
                                <div
                                    v-for="(error, index) in validate.start_time
                                        .$errors"
                                    :key="index"
                                    class="text-danger mt-2"
                                >
                                    {{ error.$message }}
                                </div>
                            </template>
                        </div>

                        <div class="mt-4 col-span-12 lg:col-span-6">
                            <FormLabel class="form-label" for="name"
                                >End Time
                                <span class="text-red-500">*</span>
                            </FormLabel>
                            <FormInput
                                id="end_time"
                                v-model="form.end_time"
                                :value="form.end_time"
                                class="form-control w-full"
                                maxlength="50"
                                name="end_time"
                                placeholder=""
                                type="datetime-local"
                            />
                            <template v-if="validate.end_time.$error">
                                <div
                                    v-for="(error, index) in validate.end_time
                                        .$errors"
                                    :key="index"
                                    class="text-danger mt-2"
                                >
                                    {{ error.$message }}
                                </div>
                            </template>
                        </div>
                    </div>

                    <hr class="my-6" />

                    <FormAttendanceBreak
                        v-if="AuthStore.hasPermission('can update attendance')"
                        :shift-breaks="shiftBreaks"
                        :breaks="form.breaks"
                        :errors="$externalResults"
                        :validate="validate"
                    />

                    <hr class="my-6" />

                    <div class="mt-4">
                        <FormLabel class="form-label" for="notes"
                            >Notes
                        </FormLabel>
                        <FormTextarea
                            id="notes"
                            v-model="form.notes"
                            :value="form.notes"
                            class="form-controll w-full"
                            name="notes"
                            placeholder="Add your notes here..."
                        />
                        <div class="mt-2">
                            <template v-if="validate.notes.$error">
                                <div
                                    v-for="(error, index) in validate.notes
                                        .$errors"
                                    :key="index"
                                    class="text-danger mt-2"
                                >
                                    {{ error.$message }}
                                </div>
                            </template>
                        </div>
                    </div>
                </div>
            </div>
        </div>

        <div class="">
            <div class="text-right p-2">
                <Button
                    id="btn_cancel"
                    class="w-32 mb-2 mr-1"
                    type="button"
                    variant="warning"
                    @click="closeModal"
                >
                    Cancel
                </Button>
                <Button
                    v-if="AuthStore.hasPermission('can update attendance')"
                    id="btn_process"
                    class="w-40 mb-2 mr-1"
                    type="submit"
                    variant="customPrimary"
                >
                    {{
                        isNewAttendance ? "Add Attendance" : "Update Attendance"
                    }}
                </Button>
            </div>
        </div>
    </form>
</template>
