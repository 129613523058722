<script setup lang="ts">
import {
    ref,
    defineProps,
    PropType,
    computed,
    onMounted,
    watch,
    onUpdated,
    Ref,
    nextTick,
} from "vue";
import Modal from "../Modal.vue";
import EmployeeInterface from "../../interfaces/employeesInterface";
import Button from "../../../base-components/Button";
import Lucide from "../../../base-components/Lucide";
const { employee } = defineProps({
    employee: {
        type: Object as PropType<EmployeeInterface>,
        required: true,
    },
});

const incompleteConfigurations = computed(() => {
    let configurations = [
        {
            name: "Employee's Cost History should be configured",
            status: employee.employee_cost_history === null,
        },
        {
            name: "Employee's Non Refundable History should be configured",
            status: employee.employee_nonrefundable_history === null,
        },
        {
            name: "Employee's RE Fees History should be configured",
            status: employee.employee_re_fee === null,
        },
        {
            name: "Employee's Schedule should be configured",
            status: employee.schedules_count === 0,
        },
        {
            name: "Employee's Shift should be configured",
            status: employee.shift === null,
        },
    ];

    if (employee?.client_id === 1) {
        configurations = [
            {
                name: "Employee's Schedule should be configured",
                status: employee.schedules_count === 0,
            },
            {
                name: "Employee's Shift should be configured",
                status: employee.shift === null,
            },
        ];
    }

    const data: string[] = [];
    configurations.forEach((config) => {
        console.log(config.name, config.status);
        if (config.status && !data.includes(config.name)) {
            data.push(config.name);
        }
    });

    return data;
});

const incomplete = computed(() => {
    if (employee.client_id === 1) {
        return false;
    }

    return incompleteConfigurations.value.length > 0;
});

const dialog = ref(false);
</script>

<template>
    <Modal :open="dialog" size="lg">
        <template #trigger>
            <div class="flex justify-center">
                <Lucide
                    icon="AlertTriangle"
                    class="h-4 w-4 text-yellow-500 cursor-pointer"
                    v-if="
                        employee.client_id === 1 &&
                        employee.employee_client_assignment === null
                            ? employee.schedules_count === 0 ||
                              employee.shift === null
                            : employee.employee_cost_history === null ||
                              employee.employee_nonrefundable_history ===
                                  null ||
                              employee.employee_re_fee === null ||
                              employee.schedules_count === 0 ||
                              employee.shift === null
                    "
                    @click="dialog = true"
                />
            </div>
        </template>

        <template #content>
            <div class="flex flex-row items-start">
                <div class="bg-orange-50 p-2 rounded-full">
                    <Lucide
                        icon="AlertTriangle"
                        class="h-6 w-6 text-orange-500"
                    />
                </div>

                <div class="ml-6">
                    <span class="font-medium">
                        Please be informed that the the following needs to be
                        configured:
                    </span>

                    <ul class="mt-4 list-disc list-inside">
                        <li
                            v-for="(
                                configuration, index
                            ) in incompleteConfigurations"
                            :key="index"
                        >
                            {{ configuration }}
                        </li>
                    </ul>
                </div>
            </div>
        </template>

        <template #action>
            <div class="flex justify-end">
                <div>
                    <Button
                        class="w-24 mr-1"
                        type="button"
                        variant="outline-secondary"
                        @click="dialog = false"
                    >
                        Close
                    </Button>
                </div>
            </div>
        </template>
    </Modal>
</template>
