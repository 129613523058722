<script lang="ts" setup>
import illustrationUrl from "../assets/images/illustration.svg";
import { FormInput } from "../base-components/Form";
import Button from "../base-components/Button";
import { nextTick, onMounted, reactive } from "vue";
import { useAuthStore } from "../stores/authStore";
import LoadingIcon from "../base-components/LoadingIcon";
import AlertForgeBuild from "./components/AlertForgeBuild.vue";

const AuthStore = useAuthStore();

interface Data {
    emailChecker: string;
    invalidEmailChecker: boolean;
    invalidOfficeAuth: boolean;
    invalidOfficeUser: boolean;
    invalidPassword: boolean;
    emailType: string;
    isLogginIn: boolean;
    twoFactorAuthentication: boolean;
    flash: string;
    flash_message: string;
    email: string;
    password: string;
    error: any;
    code: string;
    invalidLogin: boolean;
    twoFactorValidation: {
        error: boolean;
        message: string;
    };
}

const data: Data = reactive({
    emailChecker: "",
    invalidEmailChecker: false,
    invalidOfficeAuth: false,
    invalidOfficeUser: false,
    invalidPassword: false,
    emailType: "",
    isLogginIn: false,
    twoFactorAuthentication: false,
    flash: "",
    flash_message: "",
    email: "",
    password: "",
    error: null,
    code: "",
    invalidLogin: false,
    twoFactorValidation: {
        error: false,
        message: "",
    },
});

onMounted(() => {
    nextTick(() => {
        const emailInput = document.getElementById("emailChecker");
        if (emailInput) {
            emailInput.focus();
        }
    });
    if ((window as any).Laravel?.flash_message !== null) {
        data.flash = (window as any).Laravel?.flash_error;
        data.flash_message = (window as any).Laravel?.flash_message;
    }
});

const handleEmailInput = (event: { target: { value: any } }) => {
    //console.log(event.target.value);
    validateEmail(event.target.value);
};

const validateEmail = (email: string) => {
    if (/^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/.test(email)) {
        console.log("valid email");
    } else {
        console.log("invalid email");
    }
};

const checkEmail = () => {
    data.invalidLogin = false;
    if (data.emailChecker === "") {
        data.invalidEmailChecker = true;
        nextTick(() => {
            const emailInput = document.getElementById("emailChecker");
            if (emailInput) {
                emailInput.focus();
            }
        });
    } else {
        const email = data.emailChecker.split("@").pop();
        data.invalidEmailChecker = false;
        if (email === "remoteemployee.com") {
            window.location.href =
                import.meta.env.VITE_APP_URL + "/signin/" + data.emailChecker;
        } else {
            data.emailType = "client";
            data.email = data.emailChecker;
            nextTick(() => {
                const passwordInput = document.getElementById("password");
                if (passwordInput) {
                    passwordInput.focus();
                }
            });
        }
    }
};

const changeEmailAddress = () => {
    data.emailType = "";
    data.email = "";
    data.password = "";
    nextTick(() => {
        const emailInput = document.getElementById("emailChecker");
        if (emailInput) {
            emailInput.focus();
        }
    });
};

const handleSubmit = async (e: { preventDefault: () => void }) => {
    console.log("login");
    e.preventDefault();
    const AuthStore = useAuthStore();
    if (data.password.length > 0) {
        data.invalidPassword = false;
        await AuthStore.login(data.email, data.password)
            .then((response) => {
                console.log(response);
                if (response && response.status === "success") {
                    console.log("login success");
                    console.log(response.data);
                    window.location.href = import.meta.env.VITE_APP_URL + "/";
                } else if (
                    response &&
                    response.status === "two_factor_required"
                ) {
                    console.log("two-factor authentication required");
                    // Rest of the code for 2FA login
                } else {
                    console.log("login error");
                    console.log(response?.error);
                    data.invalidLogin = true;
                    // Rest of the code for login error
                }
            })
            .catch((error) => {
                console.log("login error");
                console.log(error);
                data.invalidLogin = true;
                // Rest of the code for login error
            });
    } else {
        data.invalidPassword = true;
        nextTick(() => {
            const passwordInput = document.getElementById("password");
            if (passwordInput) {
                passwordInput.focus();
            }
        });
    }
};
</script>

<template>
    <div
        :class="[
            '-m-3 sm:-mx-8 p-3 sm:px-8 relative h-screen lg:overflow-hidden bg-custom-color-1000 xl:bg-white dark:bg-darkmode-800 xl:dark:bg-darkmode-600',
            'before:hidden before:xl:block before:content-[\'\'] before:w-[57%] before:-mt-[28%] before:-mb-[16%] before:-ml-[13%] before:absolute before:inset-y-0 before:left-0 before:transform before:rotate-[-4.5deg] before:bg-custom-color-1000/20 before:rounded-[100%] before:dark:bg-darkmode-400',
            'after:hidden after:xl:block after:content-[\'\'] after:w-[57%] after:-mt-[20%] after:-mb-[13%] after:-ml-[13%] after:absolute after:inset-y-0 after:left-0 after:transform after:rotate-[-4.5deg] after:bg-custom-color-1000 after:rounded-[100%] after:dark:bg-darkmode-700',
        ]"
    >
        <!--                <DarkModeSwitcher/>-->
        <!--        <MainColorSwitcher/>-->
        <AlertForgeBuild :location="'public'" />
        <div class="container relative z-10 sm:px-10">
            <div class="block grid-cols-2 gap-4 xl:grid">
                <!-- BEGIN: Login Info -->
                <div class="flex-col hidden min-h-screen xl:flex">
                    <a class="flex items-center pt-5 -intro-x" href="">
                        <img
                            alt="RE Portal"
                            class="w-6"
                            src="../../images/rotated_logo.svg"
                        />
                        <span class="ml-3 text-lg text-white">
                            Remote Employee Portal
                        </span>
                    </a>
                    <div class="my-auto">
                        <img
                            :src="illustrationUrl"
                            alt="RE Portal"
                            class="w-1/2 -mt-16 -intro-x"
                        />
                        <div
                            class="mt-10 text-4xl font-medium leading-tight text-white -intro-x"
                        >
                            A few more clicks to <br />
                            sign in to your account.
                        </div>
                        <div
                            class="mt-5 text-lg text-white -intro-x text-opacity-70 dark:text-slate-400"
                        >
                            &nbsp;&nbsp;
                        </div>
                    </div>
                </div>
                <!-- END: Login Info -->
                <!-- BEGIN: Login Form -->
                <div class="flex h-screen py-5 my-10 xl:h-auto xl:py-0 xl:my-0">
                    <!-- Email Form -->
                    <div
                        v-if="data.emailType === ''"
                        class="w-full px-5 py-8 mx-auto my-auto bg-white rounded-md shadow-md xl:ml-20 dark:bg-darkmode-600 xl:bg-transparent sm:px-8 xl:p-0 xl:shadow-none sm:w-3/4 lg:w-2/4 xl:w-auto"
                    >
                        <img
                            alt="Remote Employee PH"
                            class="w-20 mx-auto lg:hidden"
                            src="../../images/rotated_logo.svg"
                        />

                        <h2
                            class="mt-8 text-2xl font-bold text-center intro-x xl:text-3xl xl:text-left"
                        >
                            Sign In.
                        </h2>
                        <div
                            class="mt-2 text-center intro-x text-slate-400 xl:hidden"
                        >
                            A few more clicks to sign in to your account.
                        </div>
                        <div class="mt-8 intro-x">
                            <FormInput
                                id="emailChecker"
                                ref="emailCheckerRef"
                                v-model="data.emailChecker"
                                class="block px-4 py-3 intro-x login__input min-w-full xl:min-w-[350px]"
                                name="emailChecker"
                                placeholder="Email"
                                type="text"
                                @keyup.enter="checkEmail"
                            />
                        </div>
                        <div class="mt-4">
                            <span
                                v-if="data.invalidEmailChecker"
                                class="text-red-500 pt-6 ml-4"
                                >Please input your email address</span
                            >
                        </div>
                        <div class="mt-4">
                            <span
                                v-if="data.flash === 'invalidUser'"
                                class="text-red-500 pt-6 ml-4"
                                >{{ data.flash_message }}</span
                            >
                        </div>
                        <div
                            class="mt-5 text-center intro-x xl:mt-8 xl:text-left"
                        >
                            <Button
                                id="loginBtn"
                                class="w-full px-4 py-3 align-top xl:w-32 xl:mr-3"
                                variant="customPrimary"
                                @click="checkEmail"
                            >
                                Next
                            </Button>
                        </div>
                    </div>
                    <!-- Email Form -->

                    <!-- Password Form -->
                    <div
                        v-if="data.emailType === 'client'"
                        class="xl:flex my-auto mx-auto xl:ml-20 bg-white dark:bg-darkmode-600 xl:bg-transparent px-5 sm:px-8 py-8 xl:p-0 rounded-md shadow-md xl:shadow-none w-full sm:w-3/4 lg:w-2/4 xl:w-auto"
                    >
                        <div class="w-full">
                            <h2 class="intro-x font-bold text-lg">
                                Sign In with {{ data.email }}
                            </h2>
                            <div
                                v-if="!AuthStore.is2FAenabled"
                                class="intro-x mt-8"
                            >
                                <FormInput
                                    id="password"
                                    ref="paswd"
                                    v-model="data.password"
                                    class="intro-x login__input form-control py-3 px-4 block mt-4"
                                    name="password"
                                    type="password"
                                    v-on:keyup.enter="handleSubmit"
                                />
                                <div class="mt-4">
                                    <span
                                        v-if="data.invalidPassword"
                                        class="text-red-500 pt-6 ml-4"
                                        >Please input your password</span
                                    >
                                </div>
                                <div v-if="data.invalidLogin" class="mt-4">
                                    <span
                                        id="invalidLoginMessage"
                                        class="text-red-500 pt-6 ml-4"
                                        >Invalid username or password. Please
                                        try again.</span
                                    >
                                </div>
                            </div>

                            <div
                                v-if="AuthStore.is2FAenabled"
                                class="intro-x mt-8"
                            >
                                <FormInput
                                    id="code"
                                    class="intro-x login__input form-control py-3 px-4 block"
                                    name="code"
                                    placeholder="Two factor code"
                                    type="text"
                                />
                                <div v-if="AuthStore.invalid2FA" class="mt-4">
                                    <span
                                        id="invalidLoginMessage"
                                        class="text-red-500 pt-6 ml-4"
                                    >
                                        Invalid two factor code. Please try
                                        again.</span
                                    >
                                </div>
                                <div
                                    v-if="data.twoFactorValidation.error"
                                    class="mt-4"
                                >
                                    <span
                                        id="invalidLoginMessage"
                                        class="text-red-500 pt-6 ml-4"
                                    >
                                        Please enter your two factor code.</span
                                    >
                                </div>
                            </div>

                            <div
                                v-if="!AuthStore.is2FAenabled"
                                class="intro-x mt-5 xl:mt-8 text-center xl:text-left"
                            >
                                <Button
                                    id="loginBtn"
                                    class="btn bg-custom-color-1000 text-white py-3 px-4 w-full xl:w-32 xl:mr-3 align-top"
                                    @click="handleSubmit"
                                >
                                    <LoadingIcon
                                        v-if="AuthStore.invalidLogin"
                                        class="w-4 h-4"
                                        icon="oval"
                                    />
                                    Login
                                </Button>
                                <Button
                                    id="backButton"
                                    class="btn bg-warning text-white py-3 px-4 w-full xl:w-32 xl:mr-3 align-top"
                                    @click="changeEmailAddress"
                                >
                                    <LoadingIcon
                                        v-if="AuthStore.invalidLogin"
                                        class="w-4 h-4"
                                        icon="oval"
                                    />
                                    Back
                                </Button>
                            </div>
                        </div>
                        <div class="w-full ml-10 hidden xl:block">
                            <img
                                alt="Remote Employee PH"
                                class="opacity-25"
                                src="../../images/rotated_logo.svg"
                            />
                        </div>
                    </div>
                    <!-- Password Form -->
                </div>
                <!-- END: Login Form -->
            </div>
        </div>
    </div>
</template>
