<script lang="ts" setup>
import { useAuthStore } from "../../stores/authStore";
import { required } from "@vuelidate/validators";
import { useVuelidate } from "@vuelidate/core";
import moment from "moment";
import { useRoute } from "vue-router";
import { useEmployeesStore } from "../../stores/employeesStore";

import { useHelperStore } from "../../stores/helperStore";
import { useUiStore } from "../../stores/uiStore";
import {
    defineEmits,
    onMounted,
    onUpdated,
    provide,
    reactive,
    ref,
    toRefs,
} from "vue";
import { useFreskdeskStore } from "../../stores/freshdeskStore";
import { useTicketsStore } from "../../stores/ticketsStore";
import router from "../../router";
import Litepicker from "../../base-components/Litepicker";
import TomSelect from "../../base-components/TomSelect";
import Button from "../../base-components/Button";
import { FormInput, FormLabel } from "../../base-components/Form";
import _ from "lodash";
import Notification, {
    NotificationElement,
} from "../../base-components/Notification";
import Lucide from "../../base-components/Lucide";
import EmployeeInterface from "../interfaces/employeesInterface";
import FormTextarea from "../../base-components/Form/FormTextarea.vue";

const toastData = reactive({
    message: "",
    subject: "",
    severity: "",
});

const TicketsStore = useTicketsStore();

const AuthStore = useAuthStore();
const HelperStore = useHelperStore();
const UiStore = useUiStore();
const FreshdeskStore = useFreskdeskStore();
const route = useRoute();
const id = Array.isArray(route.query.id) ? route.query.id[0] : route.query.id;
const categories = ref(2);
const emit = defineEmits(["output", "ticketType"]);

const EmployeesStore = useEmployeesStore();

const ticketForm = reactive({
    dateFiled: "",
    employeeId: "",
    name: "",
    room: "",
    requestStatus: "Pending",
    type: "Request",
    priority: "Low",
    datesRequested: "",
    startTime: "",
    endTime: "",
    reason: "",
    remarks: "",
});

const rules = {
    //dateFiled: { required },
    //employeeId: { required },
    //name: { required },
    room: { required },
    requestStatus: { required },
    type: { required },
    priority: { required },
    datesRequested: { required },
    startTime: { required },
    endTime: { required },
    reason: { required },
    remarks: { required },
};

const attachment_files = reactive({
    data: [],
});

const editorConfig = {
    toolbar: {
        items: ["bold", "italic", "link"],
    },
};

onUpdated(() => {
    const ticketType = HelperStore.getTicketType(Number(id ?? 0));
    UiStore.setBreadcrumb("Submit a Ticket > " + ticketType.name);
});

const validate = useVuelidate(rules, toRefs(ticketForm));

const formReset = () => {
    ticketForm.employeeId = "";
    ticketForm.name = "";
    ticketForm.room = "";
    ticketForm.requestStatus = "";
    ticketForm.type = "";
    ticketForm.priority = "";
    ticketForm.datesRequested = "";
    ticketForm.startTime = "";
    ticketForm.endTime = "";
    ticketForm.room = "";
    ticketForm.reason = "";
    ticketForm.remarks = "";
};

onMounted(async () => {
    // if (window.Cypress) {
    //     window.FreshdeskStore = FreshdeskStore;
    // }
    await EmployeesStore.getAllEmployeesForTable([]);
    await AuthStore.getLoggedInUserDetails();
    populateEmployeeDetails();
});

const employee = reactive({
    data: null as EmployeeInterface | null,
});
const populateEmployeeDetails = () => {
    //@ts-ignore
    console.log("populateEmployeeDetails", window.Laravel.user.id);
    //@ts-ignore
    const userName = window.Laravel.user.name;
    employee.data = EmployeesStore.getEmployee(parseInt(userName));
    console.log("AU", employee.data);
};

const submitTicket = async () => {
    const currentDate = moment().format("MMMM Do YYYY, h:mm:ss a");

    validate.value.$touch();
    if (validate.value.$invalid) {
        toastData.severity = "danger";
        toastData.message = "All fields required!";
        toastData.subject = "Please provide all the required information";
        console.log("show error toasts");
    } else {
        const ticketType = HelperStore.getTicketType(Number(id ?? 0));

        const desc_data = new FormData();
        desc_data.append("Date Filed", currentDate);
        desc_data.append("Priority", ticketForm.priority);
        desc_data.append("Rooms", ticketForm.room);
        desc_data.append("Dates Requested", ticketForm.datesRequested);
        desc_data.append("Start Time", ticketForm.startTime);
        desc_data.append("End Time", ticketForm.endTime);
        desc_data.append("Reason", ticketForm.reason);
        desc_data.append("Remarks", ticketForm.remarks);

        var form_body = {};
        //@ts-ignore
        desc_data.forEach((value, key) => (form_body[key] = value));

        const form = new FormData();
        form.append("id", String(id));
        form.append(
            "subject",
            "Ticket For CCTV Recordings:" + AuthStore.authUser.name
        );
        form.append("priority", ticketType?.f_priority_id?.toString() ?? "");
        form.append("status", ticketType?.f_status_id?.toString() ?? "");
        form.append("type", ticketType?.f_type?.toString() ?? "");
        form.append("type", ticketType.f_type);
        form.append("form_body", JSON.stringify(form_body));
        form.append("ticket_type_id", ticketType?.id?.toString() ?? "");

        if (attachments.data !== null) {
            _.forEach(attachments.data, function (files) {
                _.forEach(files, function (file) {
                    form.append("attachments[]", file);
                });
            });
        }

        await TicketsStore.save(form).then((response) => {
            if (response.status === "success") {
                validate.value.$reset();
                emit("output", { render: true, type: "success" });
                if (id !== null) {
                    emit("ticketType", HelperStore.getTicketType(parseInt(id)));
                }
                showSuccessNotification();
                formReset();
                router.push({
                    name: "ticketDetails",
                    params: { id: response.data.id },
                });
            } else {
                emit("output", { render: true, type: "danger" });
            }
        });
    }
};

interface Attachments {
    data: File[][];
}

const attachments: Attachments = reactive({
    data: [[]],
});
const uploadFile = (e: Event) => {
    const files = (e.target as HTMLInputElement).files;
    if (files !== null) {
        attachments.data.push(Array.from(files));
    }
};

const successNotification = ref<NotificationElement>();
const successNotificationToggle = () => {
    // Show notification
    successNotification.value?.showToast();
};

provide("bind[successNotification]", (el: NotificationElement) => {
    successNotification.value = el;
});

const showSuccessNotification = () => {
    successNotificationToggle();
};
</script>
<template>
    <div class="intro-y box p-5">
        <div class="mt-3 bg-yellow-200 text-yellow-700 p-4">
            <div class="font-medium"><Lucide icon="AlertTriangle" /></div>
            <div class="mt-2 font-medium">
                CCTV recording range is only 2 - 3 months.
            </div>
        </div>

        <div class="mt-3">
            <FormLabel class="form-label" for="crud-form-1"
                >Priority
                <span style="color: rgb(255, 0, 0)">*</span></FormLabel
            >
            <TomSelect
                id="priority"
                v-model="ticketForm.priority"
                class="w-full"
                name="priority"
            >
                <option value="Low">Low</option>
                <option value="Medium">Medium</option>
                <option value="High">High</option>
            </TomSelect>
            <template v-if="validate.priority.$error">
                <div
                    v-for="(error, index) in validate.priority.$errors"
                    :key="index"
                    class="text-danger mt-2"
                >
                    {{ error.$message }}
                </div>
            </template>
        </div>
        <div class="mt-3">
            <FormLabel class="form-label" for="crud-form-1"
                >Dates Requested
                <span style="color: rgb(255, 0, 0)">*</span></FormLabel
            >
            <Litepicker
                id="datesRequested"
                v-model="ticketForm.datesRequested"
                :options="{
                    autoApply: false,
                    showWeekNumbers: true,
                    dropdowns: {
                        minYear: 1990,
                        maxYear: null,
                        months: true,
                        years: true,
                    },
                }"
                class="form-control w-full"
                name="datesRequested"
            />
            <template v-if="validate.datesRequested.$error">
                <div
                    v-for="(error, index) in validate.datesRequested.$errors"
                    :key="index"
                    class="text-danger mt-2"
                >
                    {{ error.$message }}
                </div>
            </template>
        </div>
        <div class="mt-3">
            <FormLabel class="form-label" for="crud-form-1"
                >Start Time
                <span style="color: rgb(255, 0, 0)">*</span></FormLabel
            >
            <!--<Litepicker id="startTime" name="startTime" v-model="ticketForm.startTime" :options="{
                autoApply: false,
                showWeekNumbers: true,
                dropdowns: {
                    minYear: 1990,
                    maxYear: null,
                    months: true,
                    years: true,
                },
            }" class="form-control w-full" />-->
            <FormInput
                id="startTime"
                v-model="ticketForm.startTime"
                class="form-control w-full"
                name="startTime"
                placeholder=""
                type="time"
            />
            <template v-if="validate.startTime.$error">
                <div
                    v-for="(error, index) in validate.startTime.$errors"
                    :key="index"
                    class="text-danger mt-2"
                >
                    {{ error.$message }}
                </div>
            </template>
        </div>

        <div class="mt-3">
            <FormLabel class="form-label" for="crud-form-1"
                >End Time
                <span style="color: rgb(255, 0, 0)">*</span></FormLabel
            >
            <!--<Litepicker id="endTime" name="endTime" v-model="ticketForm.endTime" :options="{
                autoApply: false,
                showWeekNumbers: true,
                dropdowns: {
                    minYear: 1990,
                    maxYear: null,
                    months: true,
                    years: true,
                },
            }" class="form-control w-full" />-->
            <FormInput
                id="endTime"
                v-model="ticketForm.endTime"
                class="form-control w-full"
                name="endTime"
                placeholder=""
                type="time"
            />
            <template v-if="validate.endTime.$error">
                <div
                    v-for="(error, index) in validate.endTime.$errors"
                    :key="index"
                    class="text-danger mt-2"
                >
                    {{ error.$message }}
                </div>
            </template>
        </div>
        <div class="mt-3">
            <FormLabel class="form-label" for="crud-form-1"
                >Rooms <span style="color: rgb(255, 0, 0)">*</span></FormLabel
            >
            <FormInput
                id="room"
                v-model="ticketForm.room"
                class="form-control w-full"
                name="room"
                placeholder=""
                type="text"
            />
            <template v-if="validate.room.$error">
                <div
                    v-for="(error, index) in validate.room.$errors"
                    id="error_room"
                    :key="index"
                    class="text-danger mt-2"
                >
                    {{ error.$message }}
                </div>
            </template>
        </div>

        <div class="mt-3">
            <FormLabel class="form-label" for="crud-form-1"
                >Remarks <span style="color: rgb(255, 0, 0)">*</span></FormLabel
            >

            <FormTextarea
                id="remarks"
                v-model="ticketForm.remarks"
                class="form-control w-full"
                name="remarks"
                placeholder="Write your remarks here..."
            />
            <template v-if="validate.remarks.$error">
                <div
                    v-for="(error, index) in validate.remarks.$errors"
                    id="error_remarks"
                    :key="index"
                    class="text-danger mt-2"
                >
                    {{ error.$message }}
                </div>
            </template>
        </div>

        <div class="mt-3">
            <FormLabel class="form-label" for="crud-form-1"
                >Reason <span style="color: rgb(255, 0, 0)">*</span></FormLabel
            >

            <FormTextarea
                id="reason"
                v-model="ticketForm.reason"
                class="form-control w-full"
                name="reason"
                placeholder="Write your reason here..."
            />
            <template v-if="validate.reason.$error">
                <div
                    v-for="(error, index) in validate.reason.$errors"
                    id="error_reason"
                    :key="index"
                    class="text-danger mt-2"
                >
                    {{ error.$message }}
                </div>
            </template>
        </div>

        <div class="text-right mt-5">
            <router-link
                class="btn text-white bg-warning py-2 rounded-md w-28 text-center"
                to="/submit-a-ticket"
                type="button"
            >
                Cancel
            </router-link>
            <Button
                class="ml-2"
                type="button"
                variant="customPrimary"
                @click="submitTicket"
                >Submit Ticket
            </Button>
        </div>
    </div>
    <!-- Success Notification -->
    <Notification
        :options="{
            duration: 3000,
        }"
        class="flex"
        refKey="successNotification"
    >
        <Lucide class="text-success" icon="CheckCircle" />
        <div class="ml-4 mr-4">
            <div class="font-medium">System Notification</div>
            <div class="mt-1 text-slate-500">
                Ticket was created successfully.
            </div>
        </div>
    </Notification>
    <!-- Success Notification -->
</template>
