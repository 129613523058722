<script lang="ts" setup>
import { required } from "@vuelidate/validators";
import { useVuelidate } from "@vuelidate/core";
import moment from "moment";
import { useRoute } from "vue-router";

import { useHelperStore } from "../../../stores/helperStore";
import { useUiStore } from "../../../stores/uiStore";
import {
    defineEmits,
    onMounted,
    onUpdated,
    provide,
    reactive,
    ref,
    toRefs,
} from "vue";
import { useTicketsStore } from "../../../stores/ticketsStore";
import Litepicker from "../../../base-components/Litepicker";
import TomSelect from "../../../base-components/TomSelect";
import Button from "../../../base-components/Button";
import { ClassicEditor } from "../../../base-components/Ckeditor";
import { FormInput, FormLabel } from "../../../base-components/Form";
import _, { orderBy, trim } from "lodash";
import Notification, {
    NotificationElement,
} from "../../../base-components/Notification";
import Lucide from "../../../base-components/Lucide";

import router from "../../../router";
import { useEmployeesStore } from "../../../stores/employeesStore";
import ReadOnlyFieldTeamLeaders from "../../components/ReadOnlyFieldTeamLeaders.vue";
import { useShiftStore } from "../../../stores/shiftStore";
import { useAuthStore } from "../../../stores/authStore";
import FieldApprover from "../../components/FieldApprover.vue";

const toastData = reactive({
    message: "",
    subject: "",
    severity: "",
});

const AuthStore = useAuthStore();
const TicketsStore = useTicketsStore();
const HelperStore = useHelperStore();
const UiStore = useUiStore();
const EmployeeStore = useEmployeesStore();
const ShiftStore = useShiftStore();

const route = useRoute();
const id = Array.isArray(route.query.id) ? route.query.id[0] : route.query.id;
const emit = defineEmits(["output", "ticketType"]);
const loading = ref(false);
const saving = ref(false);

const ticketForm = reactive({
    employee_id: "",
    full_name: "",
    shift_id: "",
    shift: "",
    notes: "",
    effective_from: "",
    effective_to: "",
    teamLeaders: "",
    approver: "",
});

const rules = {
    employee_id: {},
    full_name: {},
    shift_id: { required },
    shift: { required },
    notes: { required },
    effective_from: { required },
    effective_to: { required },
    approver: { required },
};

const editorConfig = {
    toolbar: {
        items: ["bold", "italic", "link"],
    },
};

onUpdated(() => {
    const ticketType = HelperStore.getTicketType(Number(id ?? 0));
    UiStore.setBreadcrumb("Submit a Ticket > " + ticketType.name);
});

onMounted(async () => {
    loading.value = true;
    await EmployeeStore.getAllEmployees();
    await ShiftStore.getAllShifts({ dropdown: true });
    loading.value = false;
});

const validate = useVuelidate(rules, toRefs(ticketForm));

const formReset = () => {
    ticketForm.employee_id = "";
    ticketForm.full_name = "";
    ticketForm.shift_id = "";
    ticketForm.shift = "";
    ticketForm.notes = "";
    ticketForm.effective_from = "";
    ticketForm.effective_to = "";
    ticketForm.teamLeaders = "";
    ticketForm.approver = "";
};

const setTeamLeaders = (event: string) => {
    ticketForm.teamLeaders = event;
};

const approverName = ref("");
const setApproverName = (event: string) => {
    console.log("setApproverName");
    console.log(event);
    approverName.value = event;
};

const setApprover = (event: string) => {
    ticketForm.approver = event;
};

const submitTicket = async () => {
    saving.value = true;
    const currentDate = moment().format("MMMM Do YYYY, h:mm:ss a");
    validate.value.$reset();
    validate.value.$touch();

    if (validate.value.$invalid) {
        toastData.severity = "danger";
        toastData.message = "All fields required!";
        toastData.subject = "Please provide all the required information";
        console.log("show error notification");
        saving.value = false;
        return;
    }

    const ticketType = HelperStore.getTicketType(Number(id ?? 0));
    const ticketFormData = new FormData();

    const desc_data = new FormData();
    desc_data.append("Date Filed", currentDate);
    desc_data.append("Employee", ticketForm.full_name);
    desc_data.append("Shift", ticketForm.shift);
    desc_data.append("Notes", ticketForm.notes);
    desc_data.append("Effective From", ticketForm.effective_from);
    desc_data.append("Effective To", ticketForm.effective_to);
    desc_data.append("Approver", approverName.value);
    desc_data.append("approver_id", ticketForm.approver);

    var form_body = {};
    //@ts-ignore
    desc_data.forEach((value, key) => (form_body[key] = value));

    const form = new FormData();
    form.append("id", String(id));
    //@ts-ignore
    const userName = window.Laravel.user.name;
    form.append("subject", "Shift Change Request");
    form.append("priority", ticketType?.f_priority_id?.toString() ?? "");
    form.append("status", ticketType?.f_status_id?.toString() ?? "");
    form.append("type", ticketType?.f_type?.toString() ?? "");
    form.append("form_body", JSON.stringify(form_body));
    form.append("ticket_type_id", ticketType?.id?.toString() ?? "");
    form.append("approver_id", ticketForm.approver);

    await TicketsStore.save(form)
        .then((response) => {
            if (response.status === "success") {
                validate.value.$reset();
                emit("output", { render: true, type: "success" });
                if (id !== null) {
                    emit("ticketType", HelperStore.getTicketType(parseInt(id)));
                }
                showSuccessNotification();
                formReset();
                router.push({
                    name: "ticketDetails",
                    params: { id: response.data.id },
                });
            } else {
                console.log("response not success:", response);
                emit("output", { render: true, type: "danger" });
            }
        })
        .finally(() => (saving.value = false));
};

const successNotification = ref<NotificationElement>();
const successNotificationToggle = () => {
    // Show notification
    successNotification.value?.showToast();
};

provide("bind[successNotification]", (el: NotificationElement) => {
    successNotification.value = el;
});

const showSuccessNotification = () => {
    successNotificationToggle();
};

const onSelectEmployee = (event: string | string[]) => {
    const employee = EmployeeStore.employees.find((a) => a.id === +event);

    ticketForm.full_name = String(employee?.name ?? "");
};

const onSelectShift = (event: string | string[]) => {
    const shift = ShiftStore.allShifts.find((a) => a.id === +event);

    ticketForm.shift = String(shift?.name ?? "");
};
</script>

<template>
    <div
        class="relative intro-y box p-5 overflow-hidden"
        :class="{ 'blur-sm': loading }"
    >
        <div
            v-if="loading"
            class="absolute bg-slate-50 opacity-50 w-full h-full z-50 top-0 left-0 flex justify-center items-center"
        ></div>

        <ReadOnlyFieldTeamLeaders @item-value="setTeamLeaders($event)" />
        <FieldApprover
            :validate="validate"
            @itemName="setApproverName"
            @itemValue="setApprover"
        />

        <!-- <div class="mt-3">
            <FormLabel class="form-label" for="crud-form-1"
                >Employee
                <span style="color: rgb(255, 0, 0)">*</span></FormLabel
            >
            <TomSelect
                id="employee_id"
                v-model="ticketForm.employee_id"
                @update:modelValue="onSelectEmployee($event)"
                class="w-full"
                name="employee_id"
            >
                <option value="">Select Employee</option>
                <option
                    v-for="employee in EmployeeStore.employees"
                    :value="employee.id"
                >
                    {{ employee.name }}
                </option>
            </TomSelect>
        </div>
        <template v-if="validate.employee_id.$error">
            <div
                v-for="(error, index) in validate.employee_id.$errors"
                :key="index"
                class="text-danger mt-2"
            >
                {{ error.$message }}
            </div>
        </template> -->

        <div class="mt-3">
            <FormLabel class="form-label" for="crud-form-1"
                >Shift <span style="color: rgb(255, 0, 0)">*</span></FormLabel
            >
            <TomSelect
                id="shift_id"
                v-model="ticketForm.shift_id"
                @update:modelValue="onSelectShift($event)"
                class="w-full"
                name="shift_id"
            >
                <option value="">Select Shift</option>
                <option v-for="shift in ShiftStore.allShifts" :value="shift.id">
                    {{ shift.name }}
                </option>
            </TomSelect>
        </div>
        <template v-if="validate.shift_id.$error">
            <div
                v-for="(error, index) in validate.shift_id.$errors"
                :key="index"
                class="text-danger mt-2"
            >
                {{ error.$message }}
            </div>
        </template>

        <div class="grid grid-cols-12 gap-6">
            <div class="mt-4 col-span-12 lg:col-span-6">
                <FormLabel class="form-label" for="name"
                    >Effective From
                </FormLabel>
                <FormInput
                    id="start_time"
                    v-model="ticketForm.effective_from"
                    :value="ticketForm.effective_from"
                    class="form-control w-full"
                    maxlength="50"
                    name="start_time"
                    placeholder=""
                    type="date"
                />
                <template v-if="validate.effective_from.$error">
                    <div
                        v-for="(error, index) in validate.effective_from
                            .$errors"
                        :key="index"
                        class="text-danger mt-2"
                    >
                        {{ error.$message }}
                    </div>
                </template>
            </div>

            <div class="mt-4 col-span-12 lg:col-span-6">
                <FormLabel class="form-label" for="name"
                    >Effective To
                </FormLabel>
                <FormInput
                    id="end_time"
                    v-model="ticketForm.effective_to"
                    :value="ticketForm.effective_to"
                    class="form-control w-full"
                    maxlength="50"
                    name="end_time"
                    placeholder=""
                    type="date"
                />
                <template v-if="validate.effective_to.$error">
                    <div
                        v-for="(error, index) in validate.effective_to.$errors"
                        :key="index"
                        class="text-danger mt-2"
                    >
                        {{ error.$message }}
                    </div>
                </template>
            </div>
        </div>

        <div class="mt-3">
            <FormLabel
                >Notes <span style="color: rgb(255, 0, 0)">*</span></FormLabel
            >
            <div class="mt-2">
                <ClassicEditor
                    id="notes"
                    v-model="ticketForm.notes"
                    :config="editorConfig"
                    name="notes"
                />
                <template v-if="validate.notes.$error">
                    <div
                        v-for="(error, index) in validate.notes.$errors"
                        id="error_notes"
                        :key="index"
                        class="text-danger mt-2"
                    >
                        {{ error.$message }}
                    </div>
                </template>
            </div>
        </div>

        <div class="text-right mt-5">
            <router-link
                :disabled="saving"
                class="btn text-white bg-warning py-2 rounded-md w-28 text-center"
                to="/submit-a-ticket"
                type="button"
            >
                Cancel
            </router-link>
            <Button
                class="ml-2"
                type="button"
                variant="customPrimary"
                @click="submitTicket"
                :disabled="saving"
                >Submit Ticket
            </Button>
        </div>
    </div>

    <Notification
        :options="{
            duration: 3000,
        }"
        class="flex"
        refKey="successNotification"
    >
        <Lucide class="text-success" icon="CheckCircle" />
        <div class="ml-4 mr-4">
            <div class="font-medium">System Notification</div>
            <div class="mt-1 text-slate-500">
                Ticket was created successfully.
            </div>
        </div>
    </Notification>
</template>
