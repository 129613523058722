import { createRouter, createWebHistory } from "vue-router";
import Login from "../pages/Login.vue";
import SideMenu from "../pages/layout/SideMenu.vue";
import ClientsView from "../pages/ClientsView.vue";
import ClientDetailsView from "../pages/ClientDetailsView.vue";
import EmployeesView from "../pages/EmployeesView.vue";
import EmployeeDetailsView from "../pages/EmployeeDetailsView.vue";
import EmployeesAttendanceView from "../pages/EmployeesAttendanceView.vue";
import MyTeam from "../pages/MyTeam.vue";
import TeamView from "../pages/TeamView.vue";
import Tickets from "../pages/Tickets.vue";
import TicketDetails from "../pages/TicketDetails.vue";
import SubmitATicket from "../pages/SubmitATicket.vue";
import TicketList from "../pages/TicketList.vue";
import MyTickets from "../pages/MyTickets.vue";
import TicketsForApproval from "../pages/TicketsForApproval.vue";
import Dashboard from "../pages/Dashboard.vue";
import DashboardHr from "../pages/DashboardHr.vue";
import ShiftsView from "../pages/ShiftsView.vue";
import { DefineComponent } from "vue";
import ActivityLogs from "../pages/ActivityLogs.vue";
import ShiftActivityLogs from "../pages/ShiftActivityLogs.vue";
import { useUiStore } from "../stores/uiStore";
import ClientNotifications from "../pages/ClientNotifications.vue";
import FormClientNotifications from "../pages/forms/clientNotifications/FormClientNotifications.vue";
import FormClientNotificationsPreview from "../pages/forms/clientNotifications/FormClientNotificationsPreview.vue";
import EmployeeShiftsView from "../pages/EmployeeShiftsView.vue";
import ShiftDetailsEmployee from "../pages/ShiftDetailsEmployee.vue";
import SchedulesToday from "../pages/SchedulesToday.vue";
import PayrollConfigurationView from "../pages/payroll/PayrollConfigurationView.vue";
import MyTimesheetView from "../pages/my-timesheet/MyTimesheetView.vue";
import AccountManagementView from "../pages/AccountManagementView.vue";
import AccountManagement from "../pages/AccountManagement.vue";
import RolesAndPermissions from "../pages/RolesAndPermissions.vue";
import DashboardInvoices from "../pages/DashboardInvoices.vue";
import InvoiceView from "../pages/InvoiceView.vue";
import InvoiceDetailsView from "../pages/InvoiceDetailsView.vue";

let availableRoutes = [
    {
        path: "clients",
        name: "clients",
        component: ClientsView,
        breadcrumb: "All Clients",
    },
    {
        path: "clients/:id",
        name: "clientsDetails",
        component: ClientDetailsView,
    },
    {
        path: "client-notifications",
        name: "clientNotifications",
        component: ClientNotifications,
    },
    {
        path: "client-notifications-add",
        name: "clientNotificationsAdd",
        component: FormClientNotifications,
    },
    {
        path: "client-notifications-preview",
        name: "clientNotificationsPreview",
        component: FormClientNotificationsPreview,
    },
    {
        path: "employees",
        name: "employees",
        component: EmployeesView,
    },
    {
        path: "employees/attendance",
        name: "employeesAttendance",
        component: EmployeesAttendanceView,
    },
    {
        path: "employees/schedule-today",
        name: "employeesScheduleToday",
        component: SchedulesToday,
    },
    {
        path: "employees/shifts",
        name: "employeesShifts",
        component: EmployeeShiftsView,
    },
    {
        path: "employees/:id",
        name: "employeesDetails",
        component: EmployeeDetailsView,
    },
    {
        path: "my-team",
        name: "myTeam",
        component: MyTeam,
    },
    {
        path: "/teams",
        name: "teams",
        component: TeamView,
    },
    {
        path: "/all-tickets",
        name: "tickets",
        component: Tickets,
    },
    {
        path: "/ticket-details/:id",
        name: "ticketDetails",
        component: TicketDetails,
    },
    {
        path: "my-tickets/",
        name: "my-tickets",
        component: MyTickets,
    },
    {
        path: "/submit-a-ticket",
        name: "submitATicket",
        component: TicketList,
    },
    {
        path: "/ticket",
        name: "ticket",
        component: SubmitATicket,
    },
    {
        path: "/my-tickets",
        name: "myTickets",
        component: MyTickets,
    },
    {
        path: "/tickets-for-approval",
        name: "ticketsForApproval",
        component: TicketsForApproval,
    },
    {
        path: "/shifts",
        name: "shifts",
        component: ShiftsView,
    },
    {
        path: "/shifts/:id",
        name: "shiftsDetails",
        component: ShiftDetailsEmployee,
    },
    {
        path: "/activity-logs",
        name: "activityLogs",
        component: ActivityLogs,
    },
    {
        path: "/activity-logs/shifts",
        name: "shiftActivityLogs",
        component: ShiftActivityLogs,
    },
    {
        path: "/payroll-configurations",
        name: "payrollConfigurations",
        component: PayrollConfigurationView,
    },
    {
        path: "/my-timesheets",
        name: "myTimesheets",
        component: MyTimesheetView,
    },
    {
        path: "/account-management-view",
        name: "accountManagementView",
        component: AccountManagementView,
    },
    {
        path: "/account-management/:id",
        name: "account-management",
        component: AccountManagement,
    },
    {
        path: "/roles-and-permissions",
        name: "rolesAndPermissions",
        component: RolesAndPermissions,
    },
    {
        path: "invoices-dashboard",
        name: "invoicesDashboard",
        component: DashboardInvoices,
    },
    {
        path: "/invoices",
        name: "invoices",
        component: InvoiceView,
    },
    {
        path: "/invoices/:id",
        name: "invoice-details",
        component: InvoiceDetailsView,
    },
];

// @ts-ignore
const roles = window.Laravel.role;
// @ts-ignore
if (window.Laravel.isLoggedin) {
    const hrDasbhoard = [
        "admin",
        "payroll",
        "accounts receivable",
        "hr",
        "recruitment",
        "it",
    ];

    let isHrDashboard = hrDasbhoard.includes(roles[0]);
    let isEmployee = roles[0] === "employee";

    if (isEmployee) {
        // Remove multiple route names from availableRoutes
        availableRoutes = availableRoutes.filter(
            (route) =>
                route.name !== "clients" &&
                route.name !== "clientsDetails" &&
                route.name !== "clientNotifications" &&
                route.name !== "clientNotificationsAdd" &&
                route.name !== "clientNotificationsPreview" &&
                route.name !== "employees" &&
                route.name !== "employeesAttendance" &&
                route.name !== "employeesScheduleToday" &&
                route.name !== "employeesShifts" &&
                route.name !== "employeesDetails" &&
                route.name !== "teams" &&
                route.name !== "shifts" &&
                route.name !== "invoice-details" &&
                route.name !== "invoices" &&
                route.name !== "invoicesDashboard" &&
                route.name !== "rolesAndPermissions" &&
                route.name !== "account-management" &&
                route.name !== "accountManagementView" &&
                route.name !== "payrollConfigurations" &&
                route.name !== "activityLogs" &&
                route.name !== "tickets"
        );
    }

    if (isHrDashboard) {
        availableRoutes.push({
            path: "/",
            name: "dashboard",
            component: DashboardHr as DefineComponent<
                {},
                {},
                {},
                {},
                {},
                {},
                {},
                {},
                ""
            >,
        });
    } else {
        availableRoutes.push({
            path: "/",
            name: "dashboard",
            component: Dashboard,
        });
    }
}

export const routes = [
    {
        path: "/user-login",
        name: "login",
        component: Login,
    },
    {
        path: "/",
        component: SideMenu,
        children: availableRoutes,
    },
];

const router = createRouter({
    history: createWebHistory(),
    routes,
    scrollBehavior(to, from, savedPosition) {
        return savedPosition || { left: 0, top: 0 };
    },
});

router.beforeEach((to, next) => {
    const UiStore = useUiStore();
    //@ts-ignore
    UiStore.setBreadcrumb(to.name);

    if ((window as any).Laravel?.isLoggedin) {
        if (to.name === "login") {
            return "/";
        }
    } else if (!(window as any).Laravel?.isLoggedin) {
        if (to.name !== "login") {
            return "/user-login";
        }
    }
});

export default router;
