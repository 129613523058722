<script lang="ts" setup>
import {
    defineProps,
    onMounted,
    provide,
    reactive,
    ref,
    toRefs,
    watch,
} from "vue";
import "primevue/resources/themes/saga-blue/theme.css";
import "primevue/resources/primevue.min.css";
import "primeicons/primeicons.css";
import { useClientsStore } from "../../stores/clientsStore";
import { useEmployeesStore } from "../../stores/employeesStore";
import { useFreskdeskStore } from "../../stores/freshdeskStore";
import { useHelperStore } from "../../stores/helperStore";
import { useAuthStore } from "../../stores/authStore";
import { useOfficeStore } from "../../stores/officeStore";
import Litepicker from "../../base-components/Litepicker";
import TomSelect from "../../base-components/TomSelect";
import Button from "../../base-components/Button";
import { FormInput, FormLabel, FormSwitch } from "../../base-components/Form";
import Notification, {
    NotificationElement,
} from "../../base-components/Notification";
import Lucide from "../../base-components/Lucide";
import {
    decimal,
    email,
    maxLength,
    required,
    requiredIf,
} from "@vuelidate/validators";
import { useVuelidate } from "@vuelidate/core";
import moment from "moment";
import { useRoute } from "vue-router";
import EmployeeInterface from "../interfaces/employeesInterface";
import ClientInterface from "../interfaces/clientsInterface";
import { useShiftStore } from "../../stores/shiftStore";
import { useCurrencyStore } from "../../stores/currencyStore";

const AuthStore = useAuthStore();
const route = useRoute();
const FreshdeskStore = useFreskdeskStore();
const EmployeesStore = useEmployeesStore();
const ClientsStore = useClientsStore();
const HelperStore = useHelperStore();
const ShiftStore = useShiftStore();
const OfficeStore = useOfficeStore();
const CurrencyStore = useCurrencyStore();

const currentDate = new Date();
const currentYear = currentDate.getFullYear();
const maxYear = currentYear + 1;

const props = defineProps({
    data: {
        type: Object,
        default: {},
    },
    id: Number,
});

//const emit = defineEmits(['openModal']);

const errorNotification = ref<NotificationElement>();
const errorNotificationToggle = () => {
    // Show notification
    errorNotification.value?.showToast();
};

provide("bind[errorNotification]", (el: NotificationElement) => {
    errorNotification.value = el;
});

const showErrorsNotification = () => {
    errorNotificationToggle();
};

const successNotification = ref<NotificationElement>();
const successNotificationToggle = () => {
    successNotification.value?.showToast();
};

provide("bind[successNotification]", (el: NotificationElement) => {
    successNotification.value = el;
});

const showSuccessNotification = () => {
    successNotificationToggle();
};

const emit = defineEmits(["status", "close"]);

const lazyParams = ref({});
const dt = ref();

const searchableCols = ref([
    "legal_business_name",
    "website",
    "trade_name",
    "address_line_1",
    "city",
    "local_business_identifier",
    "primary_company_number",
]);

watch(
    () => props.id,
    async (newValue, oldValue) => {
        if (newValue !== 0) {
            getEmployeeDetails(newValue);
            EmployeesStore.updateState();
        } else {
            EmployeesStore.addState();
            resetForm();
        }
    }
);

const formData = reactive({
    id: "",
    user_id: "",
    client_id: "",
    third_party_employee_id: "",
    name: "",
    timezone_id: "",
    position: "",
    personal_phone: "",
    personal_email: "",
    address_line_1: "",
    address_line_2: "",
    city: "",
    zip: "",
    work_phone: "",
    work_email: "",
    biometric_id: "",
    client_email: "",
    office_id: "",
    secondary_work_location: "wfo",
    primary_work_location: "wfo",
    shift_id: "",
    notes: "",
    emergency_contact_name: "",
    emergency_contact_phone: "",
    emergency_contact_relationship: "",
    internal_staff_flag: false,
    sss: "",
    pagibig: "",
    tin: "",
    phic: "",
    basic_salary: "",
    start_date: moment(Date.now()).format("YYYY-MM-DD"),
    regularization_date: "",
    orientation_date: moment(Date.now()).format("YYYY-MM-DD"),
    payment_method: "",
    bank_name: "",
    bank_type: "",
    bank_account_no: "",
    "201_complete": false,
    is_salaried: false,
    cost_effective_from: "",
    cost_effective_to: "",
    cost_amount: 0,
    cost_currency_id: "",
});

const backendValidationErrors = ref({});
const $externalResults = ref({});

const rules = {
    client_id: { required },
    third_party_employee_id: { required, maxLength: maxLength(50) },
    name: { required, maxLength: maxLength(100) },
    timezone_id: { required },
    position: { required, maxLength: maxLength(100) },
    work_phone: { required, maxLength: maxLength(50) },
    work_email: { required, maxLength: maxLength(100), email },
    biometric_id: { required },
    client_email: { maxLength: maxLength(100), email },
    office_id: { required },
    secondary_work_location: { required },
    primary_work_location: { required },
    shift_id: { required },
    notes: { required, maxLength: maxLength(100) },
    internal_staff_flag: "",
    regularization_date: "",
    start_date: { required },
    orientation_date: { required },
    payment_method: { required, maxLength: maxLength(50) },
    bank_name: {},
    bank_type: {},
    bank_account_no: { maxLength: maxLength(12) },
};

const senistiveDataRules = {
    personal_phone: {},
    personal_email: { required, maxLength: maxLength(100), email },
    emergency_contact_name: { required, maxLength: maxLength(100) },
    emergency_contact_phone: { required, maxLength: maxLength(100) },
    emergency_contact_relationship: { required, maxLength: maxLength(50) },
    sss: { required, minValue: 1, maxValue: 800000 },
    pagibig: { required, minValue: 1, maxValue: 800000 },
    tin: { required, minValue: 1, maxValue: 800000 },
    phic: { required, minValue: 1, maxValue: 800000 },
    payment_method: { required, maxLength: maxLength(50) },
    bank_name: {},
    bank_type: {},
    bank_account_no: { maxLength: maxLength(12) },
    basic_salary: { required, decimal, minValue: 1, maxValue: 800000 },
    address_line_1: { required, maxLength: maxLength(100) },
    address_line_2: "",
    city: { required, maxLength: maxLength(50) },
    zip: { required, maxLength: maxLength(50) },
    cost_effective_from: {
        required: requiredIf(() => Object.keys(props.data).length === 0),
    },
    cost_effective_to: {},
    cost_amount: {
        required: requiredIf(() => Object.keys(props.data).length === 0),
    },
    cost_currency_id: {
        required: requiredIf(() => Object.keys(props.data).length === 0),
    },
};

onMounted(async () => {
    if (props.id !== 0) {
        await getEmployeeDetails(props.id);
    } else {
        await CurrencyStore.getCurrencies();
        EmployeesStore.addState();
        resetForm();
    }
    await getClientData();
    await getShiftSchedules();
    await getOffices();
});

let clientList = ref<ClientInterface[]>([]);

const getEmployeeDetails = async (id: number | undefined) => {
    const employee = props.data;
    if (typeof employee !== "undefined") {
        formData.id = employee.id;
        formData.third_party_employee_id = employee.third_party_employee_id;
        formData.client_id = String(employee.client_id);
        formData.name = employee.name;
        formData.personal_phone = employee.personal_phone;
        formData.personal_email = employee.personal_email;
        formData.client_email = employee.client_email;
        formData.work_phone = employee.work_phone;
        formData.work_email = employee.work_email;
        formData.biometric_id = employee.sprout_id;
        formData.office_id = String(employee.office_id);
        formData.secondary_work_location = String(
            employee.secondary_work_location
        );
        formData.primary_work_location = String(employee.primary_work_location);
        formData.timezone_id = String(employee.timezone_id);
        formData.position = employee.position;
        formData.shift_id = String(employee.shift_id);
        formData.emergency_contact_name = employee.emergency_contact_name;
        formData.emergency_contact_phone = employee.emergency_contact_phone;
        formData.emergency_contact_relationship =
            employee.emergency_contact_relationship;
        formData.notes = employee.notes;
        formData.sss = employee.sss;
        formData.pagibig = employee.pagibig;
        formData.tin = employee.tin;
        formData.phic = employee.phic;
        formData.basic_salary = employee.basic_salary;
        formData.internal_staff_flag =
            employee.internal_staff_flag === "0" ? false : true;
        formData.regularization_date =
            employee.regularization_date !== null
                ? formatPHPDate(employee.regularization_date)
                : "";
        formData.address_line_1 = employee.address_line_1;
        formData.address_line_2 = employee.address_line_2;
        formData.city = employee.city;
        formData.zip = employee.zip;
        formData.payment_method = employee.payment_method;
        formData.bank_name = employee.bank_name;
        formData.bank_type = employee.bank_type;
        formData.bank_account_no = employee.bank_account_no;
        formData["201_complete"] = employee["201_complete"];
        formData.is_salaried = employee.is_salaried;

        if (employee.employee_active_date != null) {
            formData.start_date = formatPHPDate(
                employee.employee_active_date.start_date
            );
            formData.orientation_date = formatPHPDate(
                employee.employee_active_date.orientation_date
            );
        } else {
            formData.start_date = "";
            formData.orientation_date = "";
        }
        EmployeesStore.mode = "Update";
        //clientList.push(employee.client);
    } else {
        EmployeesStore.mode = "Add";
        resetForm();
    }
};

const allowSensitiveData = () => {
    // @ts-ignore
    return (
        // @ts-ignore
        window.Laravel.permissions.filter(
            (item: { name: string }) =>
                item.name === "can view employees sensitive data"
        ).length >= 1
    );
};
const validate = allowSensitiveData()
    ? useVuelidate({ ...rules, ...senistiveDataRules }, toRefs(formData), {
          $externalResults,
      })
    : useVuelidate(rules, toRefs(formData), { $externalResults });
const submit = async () => {
    validate.value.$reset();
    validate.value.$clearExternalResults();
    validate.value.$touch();
    if (validate.value.$invalid) {
        backendValidationErrors.value = {
            message: ["Incomplete or Missing required data"],
        };
        showErrorsNotification();
        return;
    }

    try {
        const response =
            formData.id === ""
                ? await EmployeesStore.save(
                      formData as unknown as EmployeeInterface
                  )
                : await EmployeesStore.update(
                      formData as unknown as EmployeeInterface
                  );

        if (response.status === "success") {
            if (formData.id !== "") {
                if (route?.name === "employeesDetails") {
                    await EmployeesStore.getEmployeeDetails(+route?.params?.id);
                } else {
                    await EmployeesStore.getAllEmployees();
                }

                showSuccessNotification();
                resetForm();
                closeModal();
                validate.value.$reset();
            } else {
                showSuccessNotification();
                resetForm();
                closeModal();
                validate.value.$reset();
                emit("status", true);
            }
        } else {
            backendValidationErrors.value = response.data;
            $externalResults.value = response.data;

            showErrorsNotification();
            if (response.data === "validation.unique") {
                formData.personal_email = "";
            }
        }
    } catch (error: any) {
        console.error("error catch", error);
        backendValidationErrors.value = error.response.data;
        $externalResults.value = error.response.data;

        showErrorsNotification();
    }
};

const resetForm = () => {
    formData.id = "";
    formData.user_id = "";
    formData.client_id = "";
    formData.third_party_employee_id = "";
    formData.name = "";
    formData.timezone_id = "";
    formData.position = "";
    formData.personal_phone = "";
    formData.personal_email = "";
    formData.work_phone = "";
    formData.work_email = "";
    formData.client_email = "";
    formData.office_id = "";
    formData.secondary_work_location = "wfo";
    formData.primary_work_location = "wfo";
    formData.shift_id = "";
    formData.notes = "";
    formData.emergency_contact_name = "";
    formData.emergency_contact_phone = "";
    formData.emergency_contact_relationship = "";
    formData.internal_staff_flag = false;
    formData.sss = "";
    formData.pagibig = "";
    formData.tin = "";
    formData.phic = "";
    formData.basic_salary = "";
    formData.start_date = moment(Date.now()).format("YYYY-MM-DD");
    formData.regularization_date = "";
    formData.orientation_date = moment(Date.now()).format("YYYY-MM-DD");
    formData.address_line_1 = "";
    formData.address_line_2 = "";
    formData.city = "";
    formData.zip = "";
    formData.payment_method = "Bank Account";
    formData.bank_name = "UNION BANK OF THE PHILIPPINES(UnionHub)";
    formData.bank_type = "Savings";
    formData.bank_account_no = "";
    formData["201_complete"] = false;
    formData.is_salaried = false;
    formData.cost_effective_from = "";
    formData.cost_effective_to = "";
    formData.cost_amount = 0;
    formData.cost_currency_id = "";
};

const isEmptyObject = (obj: any) => {
    return Object.keys(obj).length === 0;
};

const closeModal = () => {
    emit("close", true);
};

const getClientData = async () => {
    await ClientsStore.getAllClients();
    clientList.value = ClientsStore.clients;
    return clientList;
};

const getShiftSchedules = async () => {
    await ShiftStore.getAllShifts({ dropdown: true });
};

const formatPHPDate = (date: moment.MomentInput) => {
    return moment(date).format("YYYY-MM-DD");
};
const changes = (event: Event) => {
    formData.regularization_date = (event.target as HTMLInputElement).value;
};

const enableSensitiveData = () => {
    return (
        EmployeesStore.employees.some((employee) => employee.sensitiveData) ||
        Object.values(AuthStore.activatedPermissionsName).includes(
            "can view employees sensitive data"
        )
    );
};

const getOffices = async () => {
    await OfficeStore.getOffices();
};
</script>
<template>
    <form @submit.prevent="submit">
        <div class="grid grid-cols-12 gap-6 mt-5 items-left text-left">
            <div class="intro-y col-span-12 lg:col-span-6">
                <!-- BEGIN: Form Layout -->
                <div class="intro-y pt-5 pb-5 pl-5 pr-2">
                    <div
                        class="border border-gray-200 px-4 py-6 rounded shadow-lg"
                    >
                        <div
                            class="flex items-center border-b border-slate-200/60 dark:border-darkmode-400"
                        >
                            <h2 class="font-medium text-base mr-auto mb-4">
                                Employee Information
                            </h2>
                        </div>
                        <div class="mt-4">
                            <FormLabel class="form-label" for="name"
                                >Name
                                <span style="color: rgb(255, 0, 0)"
                                    >*</span
                                ></FormLabel
                            >
                            <FormInput
                                id="name"
                                v-model="formData.name"
                                :value="formData.name"
                                class="form-control w-full"
                                maxLength="100"
                                name="name"
                                placeholder=""
                                type="text"
                            />
                            <template v-if="validate.name.$error">
                                <div
                                    v-for="(error, index) in validate.name
                                        .$errors"
                                    id="error_name"
                                    :key="index"
                                    class="text-danger mt-2"
                                >
                                    {{ error.$message }}
                                </div>
                            </template>
                        </div>

                        <div class="mt-3">
                            <FormLabel class="form-label" for="position"
                                >Position
                                <span style="color: rgb(255, 0, 0)"
                                    >*</span
                                ></FormLabel
                            >
                            <FormInput
                                id="position"
                                v-model="formData.position"
                                :value="formData.position"
                                class="form-control w-full"
                                maxLength="100"
                                name="position"
                                placeholder=""
                                type="text"
                            />
                            <template v-if="validate.position.$error">
                                <div
                                    v-for="(error, index) in validate.position
                                        .$errors"
                                    id="error_position"
                                    :key="index"
                                    class="text-danger mt-2"
                                >
                                    {{ error.$message }}
                                </div>
                            </template>
                        </div>

                        <div class="mt-3">
                            <FormLabel
                                class="form-label"
                                for="third_party_employee_id"
                                >Third Party Employee ID
                                <span style="color: rgb(255, 0, 0)"
                                    >*</span
                                ></FormLabel
                            >
                            <FormInput
                                id="third_party_employee_id"
                                v-model="formData.third_party_employee_id"
                                :value="formData.third_party_employee_id"
                                class="form-control w-full"
                                maxlength="50"
                                name="third_party_employee_id"
                                placeholder=""
                                type="text"
                            />
                            <template
                                v-if="validate.third_party_employee_id.$error"
                            >
                                <div
                                    v-for="(error, index) in validate
                                        .third_party_employee_id.$errors"
                                    id="error_employeeId"
                                    :key="index"
                                    class="text-danger mt-2"
                                >
                                    {{ error.$message }}
                                </div>
                            </template>
                        </div>

                        <div v-if="enableSensitiveData()" class="mt-3">
                            <FormLabel class="form-label" for="personal_phone"
                                >Personal Phone
                            </FormLabel>
                            <FormInput
                                id="personal_phone"
                                v-model="formData.personal_phone"
                                :value="formData.personal_phone"
                                class="form-control w-full"
                                maxLength="50"
                                name="personal_phone"
                                placeholder=""
                                type="text"
                            />
                            <template v-if="validate.personal_phone.$error">
                                <div
                                    v-for="(error, index) in validate
                                        .personal_phone.$errors"
                                    id="error_phone"
                                    :key="index"
                                    class="text-danger mt-2"
                                >
                                    {{ error.$message }}
                                </div>
                            </template>
                        </div>

                        <div v-if="enableSensitiveData()" class="mt-3">
                            <FormLabel
                                ref="personal_email"
                                class="form-label"
                                for="personal_email"
                                >Personal Email
                                <span style="color: rgb(255, 0, 0)"
                                    >*</span
                                ></FormLabel
                            >
                            <FormInput
                                id="personal_email"
                                v-model="formData.personal_email"
                                :value="formData.personal_email"
                                class="form-control w-full"
                                name="personal_email"
                                placeholder=""
                                type="text"
                            />
                            <template v-if="validate.personal_email.$error">
                                <div
                                    v-for="(error, index) in validate
                                        .personal_email.$errors"
                                    id="error_email"
                                    :key="index"
                                    class="text-danger mt-2"
                                >
                                    {{ error.$message }}
                                </div>
                            </template>
                        </div>

                        <div v-if="isEmptyObject(props.data)" class="mt-3">
                            <FormLabel class="form-label" for="location"
                                >Shift Schedule
                                <span style="color: rgb(255, 0, 0)"
                                    >*</span
                                ></FormLabel
                            >
                            <TomSelect
                                id="shift_id"
                                v-model="formData.shift_id"
                                :value="formData.shift_id"
                                class="w-full p-0"
                                name="shift_id"
                            >
                                <option selected value="">Select Shift</option>
                                <option
                                    v-for="schedule in ShiftStore.allShifts"
                                    :value="schedule.id"
                                >
                                    {{ schedule.name }}
                                </option>
                            </TomSelect>
                        </div>
                        <div class="mt-1 mb-1">
                            <template v-if="validate.shift_id.$error">
                                <div
                                    v-for="(error, index) in validate.shift_id
                                        .$errors"
                                    id="shift_id_error"
                                    :key="index"
                                    class="text-danger mt-2"
                                >
                                    {{ error.$message }}
                                </div>
                            </template>
                        </div>

                        <div class="mt-3">
                            <FormLabel class="form-label"
                                >Regularization Date
                            </FormLabel>
                            <Litepicker
                                id="regularization_date"
                                v-model="formData.regularization_date"
                                :options="{
                                    autoApply: true,
                                    format: 'YYYY-MM-DD',
                                    showWeekNumbers: true,
                                    autoRefresh: true,
                                    resetButton: true,
                                    dropdowns: {
                                        minYear: 2000,
                                        maxYear: maxYear,
                                        months: true,
                                        years: true,
                                    },
                                }"
                                class="form-control w-full block mx-auto"
                                name="regularization_date"
                                @change="changes($event)"
                            />
                            <template
                                v-if="validate.regularization_date.$error"
                            >
                                <div
                                    v-for="(error, index) in validate
                                        .regularization_date.$errors"
                                    :key="index"
                                    class="text-danger mt-2"
                                >
                                    {{ error.$message }}
                                </div>
                            </template>
                        </div>

                        <div class="mt-3">
                            <FormLabel class="form-label" for="work_phone"
                                >Work Phone
                                <span style="color: rgb(255, 0, 0)"
                                    >*</span
                                ></FormLabel
                            >
                            <FormInput
                                id="work_phone"
                                v-model="formData.work_phone"
                                :value="formData.work_phone"
                                class="form-control w-full"
                                maxLength="50"
                                name="work_phone"
                                placeholder=""
                                type="text"
                            />
                            <template v-if="validate.work_phone.$error">
                                <div
                                    v-for="(error, index) in validate.work_phone
                                        .$errors"
                                    id="error_wphone"
                                    :key="index"
                                    class="text-danger mt-2"
                                >
                                    {{ error.$message }}
                                </div>
                            </template>
                        </div>

                        <div class="mt-3">
                            <FormLabel class="form-label" for="work_email"
                                >Work Email
                                <span style="color: rgb(255, 0, 0)"
                                    >*</span
                                ></FormLabel
                            >
                            <FormInput
                                id="work_email"
                                v-model="formData.work_email"
                                :value="formData.work_email"
                                class="form-control w-full"
                                name="work_email"
                                placeholder=""
                                type="text"
                            />
                            <template v-if="validate.work_email.$error">
                                <div
                                    v-for="(error, index) in validate.work_email
                                        .$errors"
                                    id="error_wemail"
                                    :key="index"
                                    class="text-danger mt-2"
                                >
                                    {{ error.$message }}
                                </div>
                            </template>
                        </div>

                        <div class="mt-3">
                            <FormLabel class="form-label" for="biometric_id"
                                >Biometric ID
                                <span style="color: rgb(255, 0, 0)"
                                    >*</span
                                ></FormLabel
                            >
                            <FormInput
                                id="biometric_id"
                                v-model="formData.biometric_id"
                                :value="formData.biometric_id"
                                class="form-control w-full"
                                name="biometric_id"
                                placeholder=""
                                type="text"
                            />
                            <template v-if="validate.biometric_id.$error">
                                <div
                                    v-for="(error, index) in validate
                                        .biometric_id.$errors"
                                    id="error_biometric_id"
                                    :key="index"
                                    class="text-danger mt-2"
                                >
                                    {{ error.$message }}
                                </div>
                            </template>
                        </div>

                        <div class="mt-3">
                            <FormLabel class="form-label" for="location"
                                >Office
                                <span style="color: rgb(255, 0, 0)"
                                    >*</span
                                ></FormLabel
                            >
                            <TomSelect
                                id="office_id"
                                v-model="formData.office_id"
                                class="w-full p-0"
                                name="office_id"
                            >
                                <option value="">Select Office</option>
                                <option
                                    v-for="office in OfficeStore.offices.data"
                                    :value="String(office.id)"
                                >
                                    {{ office.address }}
                                </option>
                            </TomSelect>
                        </div>
                        <div>
                            <template v-if="validate.office_id.$error">
                                <div
                                    v-for="(error, index) in validate.office_id
                                        .$errors"
                                    id="error_wlocation"
                                    :key="index"
                                    class="text-danger mt-2"
                                >
                                    {{ error.$message }}
                                </div>
                            </template>
                        </div>

                        <div class="mt-3">
                            <FormLabel class="form-label" for="location"
                                >Primary Work Location
                                <span style="color: rgb(255, 0, 0)"
                                    >*</span
                                ></FormLabel
                            >
                            <TomSelect
                                id="primary_work_location"
                                v-model="formData.primary_work_location"
                                class="w-full p-0"
                                name="primary_work_location"
                            >
                                <option value="wfh">Work from Home</option>
                                <option value="wfo">Work from Office</option>
                                <option value="hybrid">Hybrid</option>
                            </TomSelect>
                            <template
                                v-if="validate.primary_work_location.$error"
                            >
                                <div
                                    v-for="(error, index) in validate
                                        .primary_work_location.$errors"
                                    id="error_wlocation"
                                    :key="index"
                                    class="text-danger mt-2"
                                >
                                    {{ error.$message }}
                                </div>
                            </template>
                        </div>

                        <div class="mt-3">
                            <FormLabel
                                class="form-label"
                                for="secondary_work_location"
                                >Secondary Work Location
                                <span style="color: rgb(255, 0, 0)"
                                    >*</span
                                ></FormLabel
                            >
                            <TomSelect
                                id="secondary_work_location"
                                v-model="formData.secondary_work_location"
                                class="w-full p-0"
                                name="secondary_work_location"
                            >
                                <option value="wfh">Work from Home</option>
                                <option value="wfo">Work from Office</option>
                                <option value="hybrid">Hybrid</option>
                            </TomSelect>
                            <template
                                v-if="validate.secondary_work_location.$error"
                            >
                                <div
                                    v-for="(error, index) in validate
                                        .secondary_work_location.$errors"
                                    id="error_secondary_work_location"
                                    :key="index"
                                    class="text-danger mt-2"
                                >
                                    {{ error.$message }}
                                </div>
                            </template>
                        </div>

                        <div class="mt-3">
                            <FormLabel class="form-label"
                                >Timezone
                                <span style="color: rgb(255, 0, 0)">*</span>
                            </FormLabel>
                            <TomSelect
                                id="timezone_id"
                                v-model="formData.timezone_id"
                                class="w-full p-0"
                                name="timezone_id"
                            >
                                <option>Select Timezone</option>
                                <option
                                    v-for="timezone of HelperStore.timezones"
                                    :value="timezone.id"
                                >
                                    {{ timezone.name }}
                                </option>
                            </TomSelect>
                        </div>
                        <div class="mt-1 mb-1">
                            <template v-if="validate.timezone_id.$error">
                                <div
                                    v-for="(error, index) in validate
                                        .timezone_id.$errors"
                                    :key="index"
                                    class="text-danger mt-2"
                                >
                                    {{ error.$message }}
                                </div>
                            </template>
                        </div>

                        <div class="mt-3">
                            <FormLabel class="form-label"
                                >Start Date
                                <span style="color: rgb(255, 0, 0)">*</span>
                            </FormLabel>
                            <Litepicker
                                id="start_date"
                                v-model="formData.start_date"
                                :options="{
                                    autoApply: true,
                                    format: 'YYYY-MM-DD',
                                    showWeekNumbers: true,
                                    autoRefresh: true,
                                    resetButton: true,
                                    dropdowns: {
                                        minYear: 1990,
                                        maxYear: maxYear,
                                        months: true,
                                        years: true,
                                    },
                                }"
                                class="form-control w-full block mx-auto"
                                name="start_date"
                            />
                            <template v-if="validate.start_date.$error">
                                <div
                                    v-for="(error, index) in validate.start_date
                                        .$errors"
                                    :key="index"
                                    class="text-danger mt-2"
                                >
                                    {{ error.$message }}
                                </div>
                            </template>
                        </div>

                        <div class="mt-3">
                            <FormLabel class="form-label"
                                >Orientation Date
                                <span style="color: rgb(255, 0, 0)"
                                    >*</span
                                ></FormLabel
                            >
                            <Litepicker
                                id="orientation_date"
                                v-model="formData.orientation_date"
                                :options="{
                                    autoApply: true,
                                    format: 'YYYY-MM-DD',
                                    showWeekNumbers: true,
                                    autoRefresh: true,
                                    resetButton: true,
                                    dropdowns: {
                                        minYear: 1990,
                                        maxYear: maxYear,
                                        months: true,
                                        years: true,
                                    },
                                }"
                                class="form-control w-full block mx-auto"
                                name="orientation_date"
                            />
                            <template v-if="validate.orientation_date.$error">
                                <div
                                    v-for="(error, index) in validate
                                        .orientation_date.$errors"
                                    :key="index"
                                    class="text-danger mt-2"
                                >
                                    {{ error.$message }}
                                </div>
                            </template>
                        </div>

                        <div v-if="enableSensitiveData()" class="mt-3">
                            <FormLabel class="form-label" for="address_line_1"
                                >Address Line 1
                                <span style="color: rgb(255, 0, 0)"
                                    >*</span
                                ></FormLabel
                            >
                            <FormInput
                                id="address_line_1"
                                v-model="formData.address_line_1"
                                :value="formData.address_line_1"
                                class="form-control w-full"
                                maxLength="100"
                                name="address_line_1"
                                placeholder=""
                                type="text"
                            />
                            <template v-if="validate.address_line_1.$error">
                                <div
                                    v-for="(error, index) in validate
                                        .address_line_1.$errors"
                                    id="error_name"
                                    :key="index"
                                    class="text-danger mt-2"
                                >
                                    {{ error.$message }}
                                </div>
                            </template>
                        </div>

                        <div v-if="enableSensitiveData()" class="mt-3">
                            <FormLabel class="form-label" for="address_line_2"
                                >Address Line 2
                                <span style="color: rgb(255, 0, 0)"></span
                            ></FormLabel>
                            <FormInput
                                id="address_line_2"
                                v-model="formData.address_line_2"
                                :value="formData.address_line_2"
                                class="form-control w-full"
                                maxLength="100"
                                name="address_line_2"
                                placeholder=""
                                type="text"
                            />
                            <template v-if="validate.address_line_2.$error">
                                <div
                                    v-for="(error, index) in validate
                                        .address_line_2.$errors"
                                    id="error_name"
                                    :key="index"
                                    class="text-danger mt-2"
                                >
                                    {{ error.$message }}
                                </div>
                            </template>
                        </div>

                        <div v-if="enableSensitiveData()" class="mt-3">
                            <FormLabel class="form-label" for="city"
                                >City<span style="color: rgb(255, 0, 0)"
                                    >&nbsp;*</span
                                ></FormLabel
                            >
                            <FormInput
                                id="city"
                                v-model="formData.city"
                                :value="formData.city"
                                class="form-control w-full"
                                maxLength="100"
                                name="city"
                                placeholder=""
                                type="text"
                            />
                            <template v-if="validate.city.$error">
                                <div
                                    v-for="(error, index) in validate.city
                                        .$errors"
                                    id="error_name"
                                    :key="index"
                                    class="text-danger mt-2"
                                >
                                    {{ error.$message }}
                                </div>
                            </template>
                        </div>

                        <div v-if="enableSensitiveData()" class="mt-3">
                            <FormLabel class="form-label" for="zip"
                                >Zip<span style="color: rgb(255, 0, 0)"
                                    >&nbsp;*</span
                                ></FormLabel
                            >
                            <FormInput
                                id="zip"
                                v-model="formData.zip"
                                :value="formData.zip"
                                class="form-control w-full"
                                maxLength="100"
                                name="zip"
                                placeholder=""
                                type="text"
                            />
                            <template v-if="validate.zip.$error">
                                <div
                                    v-for="(error, index) in validate.zip
                                        .$errors"
                                    id="error_name"
                                    :key="index"
                                    class="text-danger mt-2"
                                >
                                    {{ error.$message }}
                                </div>
                            </template>
                        </div>
                    </div>
                </div>
                <!-- END: Form Layout -->
            </div>
            <div class="intro-y col-span-12 lg:col-span-6">
                <!-- BEGIN: Form Layout -->
                <div class="intro-y pt-5 pb-5 pl-5 pr-2">
                    <div
                        class="border border-gray-200 px-4 py-6 rounded shadow-lg"
                    >
                        <div
                            class="flex items-center border-b border-slate-200/60 dark:border-darkmode-400"
                        >
                            <h2 class="font-medium text-base mr-auto mb-4">
                                Client Information
                            </h2>
                        </div>
                        <div class="mt-4 clientdd">
                            <FormLabel class="form-label" for="client_id"
                                >Client
                                <span style="color: rgb(255, 0, 0)"
                                    >*</span
                                ></FormLabel
                            >
                            <TomSelect
                                id="client_id"
                                v-model="formData.client_id"
                                class="w-full"
                                name="client_id"
                            >
                                <option>Select Client</option>
                                <option
                                    v-for="client of clientList"
                                    :value="client.id"
                                >
                                    {{ client.legal_business_name }}
                                </option>
                            </TomSelect>
                        </div>
                        <div class="mt-1 mb-1">
                            <template v-if="validate.client_id.$error">
                                <div
                                    v-for="(error, index) in validate.client_id
                                        .$errors"
                                    :key="index"
                                    class="text-danger mt-2"
                                >
                                    {{ error.$message }}
                                </div>
                            </template>
                        </div>

                        <div class="mt-3">
                            <FormLabel class="form-label" for="client_email"
                                >Client Email
                            </FormLabel>
                            <FormInput
                                id="client_email"
                                v-model="formData.client_email"
                                :value="formData.client_email"
                                class="form-control w-full"
                                maxLength="100"
                                name="client_email"
                                placeholder=""
                                type="text"
                            />
                            <template v-if="validate.client_email.$error">
                                <div
                                    v-for="(error, index) in validate
                                        .client_email.$errors"
                                    id="error_cemail"
                                    :key="index"
                                    class="text-danger mt-2"
                                >
                                    {{ error.$message }}
                                </div>
                            </template>
                        </div>
                    </div>

                    <div
                        v-if="enableSensitiveData()"
                        class="mt-6 border border-gray-200 px-4 py-6 rounded shadow-lg"
                    >
                        <div
                            class="flex items-center border-b border-slate-200/60 dark:border-darkmode-400"
                        >
                            <h2 class="font-medium text-base mr-auto mb-4">
                                Bank Information
                            </h2>
                        </div>

                        <div v-if="enableSensitiveData()" class="mt-3">
                            <FormLabel class="form-label" for="payment_method"
                                >Payment Method
                                <span style="color: rgb(255, 0, 0)"></span
                            ></FormLabel>
                            <TomSelect
                                id="payment_method"
                                v-model="formData.payment_method"
                                :value="formData.payment_method"
                                class="w-full p-0"
                                name="shift_id"
                            >
                                <option selected>Select Payment Method</option>
                                <option value="Bank Account">
                                    Bank Account
                                </option>
                                <option value="Cash">Cash</option>
                            </TomSelect>
                            <template v-if="validate.payment_method.$error">
                                <div
                                    v-for="(error, index) in validate
                                        .payment_method.$errors"
                                    id="error_payment_method"
                                    :key="index"
                                    class="text-danger mt-2"
                                >
                                    {{ error.$message }}
                                </div>
                            </template>
                        </div>

                        <div v-if="formData.payment_method !== 'Cash'">
                            <div v-if="enableSensitiveData()" class="mt-3">
                                <FormLabel class="form-label" for="bank_name"
                                    >Bank Name
                                    <span style="color: rgb(255, 0, 0)"></span
                                ></FormLabel>
                                <FormInput
                                    id="bank_name"
                                    v-model="formData.bank_name"
                                    :value="formData.bank_name"
                                    class="form-control w-full"
                                    name="bank_name"
                                    placeholder=""
                                    type="text"
                                />
                                <template v-if="validate.bank_name.$error">
                                    <div
                                        v-for="(error, index) in validate
                                            .bank_name.$errors"
                                        id="error_bank_name"
                                        :key="index"
                                        class="text-danger mt-2"
                                    >
                                        {{ error.$message }}
                                    </div>
                                </template>
                            </div>

                            <div v-if="enableSensitiveData()" class="mt-3">
                                <FormLabel class="form-label" for="bank_type"
                                    >Bank Type<span
                                        style="color: rgb(255, 0, 0)"
                                    ></span
                                ></FormLabel>
                                <FormInput
                                    id="bank_type"
                                    v-model="formData.bank_type"
                                    :value="formData.bank_type"
                                    class="form-control w-full"
                                    maxlength="50"
                                    name="bank_type"
                                    placeholder=""
                                    type="text"
                                />
                                <template v-if="validate.bank_type.$error">
                                    <div
                                        v-for="(error, index) in validate
                                            .bank_type.$errors"
                                        id="error_bank_type"
                                        :key="index"
                                        class="text-danger mt-2"
                                    >
                                        {{ error.$message }}
                                    </div>
                                </template>
                            </div>

                            <div v-if="enableSensitiveData()" class="mt-3">
                                <FormLabel
                                    class="form-label"
                                    for="bank_account_no"
                                    >Bank Account No.
                                    <span style="color: rgb(255, 0, 0)"></span
                                ></FormLabel>
                                <FormInput
                                    id="bank_account_no"
                                    v-model="formData.bank_account_no"
                                    :value="formData.bank_account_no"
                                    class="form-control w-full"
                                    maxlength="50"
                                    name="bank_account_no"
                                    placeholder=""
                                    type="text"
                                />
                                <template
                                    v-if="validate.bank_account_no.$error"
                                >
                                    <div
                                        v-for="(error, index) in validate
                                            .bank_account_no.$errors"
                                        id="error_bank_account_no"
                                        :key="index"
                                        class="text-danger mt-2"
                                    >
                                        {{ error.$message }}
                                    </div>
                                </template>
                            </div>
                        </div>
                    </div>

                    <div
                        class="mt-6 border border-gray-200 px-4 py-6 rounded shadow-lg"
                    >
                        <div
                            class="flex items-center border-b border-slate-200/60 dark:border-darkmode-400"
                        >
                            <h2 class="font-medium text-base mr-auto mb-4">
                                Other Information
                            </h2>
                        </div>

                        <div v-if="enableSensitiveData()" class="mt-3">
                            <FormLabel class="form-label" for="sss"
                                >SSS Number
                                <span style="color: rgb(255, 0, 0)"
                                    >*</span
                                ></FormLabel
                            >
                            <FormInput
                                id="sss"
                                v-model="formData.sss"
                                :value="formData.sss"
                                class="form-control w-full"
                                maxlength="50"
                                name="sss"
                                placeholder=""
                                type="text"
                            />
                            <template v-if="validate.sss.$error">
                                <div
                                    v-for="(error, index) in validate.sss
                                        .$errors"
                                    id="error_sss"
                                    :key="index"
                                    class="text-danger mt-2"
                                >
                                    {{ error.$message }}
                                </div>
                            </template>
                        </div>

                        <div v-if="enableSensitiveData()" class="mt-3">
                            <FormLabel class="form-label" for="pagibig"
                                >Pagibig Number
                                <span style="color: rgb(255, 0, 0)"
                                    >*</span
                                ></FormLabel
                            >
                            <FormInput
                                id="pagibig"
                                v-model="formData.pagibig"
                                :value="formData.pagibig"
                                class="form-control w-full"
                                maxlength="50"
                                name="pagibig"
                                placeholder=""
                                type="text"
                            />
                            <template v-if="validate.pagibig.$error">
                                <div
                                    v-for="(error, index) in validate.pagibig
                                        .$errors"
                                    id="error_pagibig"
                                    :key="index"
                                    class="text-danger mt-2"
                                >
                                    {{ error.$message }}
                                </div>
                            </template>
                        </div>

                        <div v-if="enableSensitiveData()" class="mt-3">
                            <FormLabel class="form-label" for="tin"
                                >Tax Indentification Number
                                <span style="color: rgb(255, 0, 0)"
                                    >*</span
                                ></FormLabel
                            >
                            <FormInput
                                id="tin"
                                v-model="formData.tin"
                                :value="formData.tin"
                                class="form-control w-full"
                                maxlength="50"
                                name="tin"
                                placeholder=""
                                type="text"
                            />
                            <template v-if="validate.tin.$error">
                                <div
                                    v-for="(error, index) in validate.tin
                                        .$errors"
                                    id="error_tin"
                                    :key="index"
                                    class="text-danger mt-2"
                                >
                                    {{ error.$message }}
                                </div>
                            </template>
                        </div>

                        <div v-if="enableSensitiveData()" class="mt-3">
                            <FormLabel class="form-label" for="phic"
                                >PhilHealth Number
                                <span style="color: rgb(255, 0, 0)"
                                    >*</span
                                ></FormLabel
                            >
                            <FormInput
                                id="phic"
                                v-model="formData.phic"
                                :value="formData.phic"
                                class="form-control w-full"
                                maxlength="50"
                                name="phic"
                                placeholder=""
                                type="text"
                            />
                            <template v-if="validate.phic.$error">
                                <div
                                    v-for="(error, index) in validate.phic
                                        .$errors"
                                    id="error_phic"
                                    :key="index"
                                    class="text-danger mt-2"
                                >
                                    {{ error.$message }}
                                </div>
                            </template>
                        </div>

                        <div
                            v-if="
                                isEmptyObject(props.data) &&
                                enableSensitiveData()
                            "
                            class="mt-3"
                        >
                            <FormLabel class="form-label" for="basic_salary"
                                >Basic Salary
                                <span style="color: rgb(255, 0, 0)"
                                    >*</span
                                ></FormLabel
                            >
                            <FormInput
                                id="basic_salary"
                                v-model="formData.basic_salary"
                                :value="formData.basic_salary"
                                class="form-control w-full"
                                maxlength="8"
                                name="basic_salary"
                                placeholder=""
                                type="text"
                            />
                            <template v-if="validate.basic_salary.$error">
                                <div
                                    v-for="(error, index) in validate
                                        .basic_salary.$errors"
                                    id="error_bsalary"
                                    :key="index"
                                    class="text-danger mt-2"
                                >
                                    {{ error.$message }}
                                </div>
                            </template>
                        </div>

                        <div v-if="enableSensitiveData()" class="mt-3">
                            <FormLabel class="form-label" for="notes"
                                >Notes
                                <span style="color: rgb(255, 0, 0)"
                                    >*</span
                                ></FormLabel
                            >
                            <FormInput
                                id="notes"
                                v-model="formData.notes"
                                :value="formData.notes"
                                class="form-control w-full"
                                maxlength="100"
                                name="notes"
                                placeholder=""
                                type="text"
                            />
                            <template v-if="validate.notes.$error">
                                <div
                                    v-for="(error, index) in validate.notes
                                        .$errors"
                                    id="error_notes"
                                    :key="index"
                                    class="text-danger mt-2"
                                >
                                    {{ error.$message }}
                                </div>
                            </template>
                        </div>

                        <div
                            class="mt-5 flex flex-row align-center items-center"
                        >
                            <FormSwitch id="internal_staff_flag">
                                <FormSwitch.Input
                                    v-model="formData.internal_staff_flag"
                                    type="checkbox"
                                    v-bind:checked="
                                        Boolean(formData.internal_staff_flag)
                                    "
                                />
                            </FormSwitch>

                            <label class="ml-2" for="internal_staff_flag"
                                >Internal Staff Flag
                            </label>
                        </div>

                        <div
                            class="mt-5 flex flex-row align-center items-center"
                        >
                            <FormSwitch id="201_complete">
                                <FormSwitch.Input
                                    v-model="formData['201_complete']"
                                    type="checkbox"
                                    v-bind:checked="
                                        Boolean(formData['201_complete'])
                                    "
                                />
                            </FormSwitch>

                            <label class="ml-2" for="internal_staff_flag"
                                >201 Complete
                            </label>
                        </div>

                        <div
                            class="mt-5 flex flex-row align-center items-center"
                        >
                            <FormSwitch id="is_salaried">
                                <FormSwitch.Input
                                    v-model="formData.is_salaried"
                                    type="checkbox"
                                    v-bind:checked="
                                        Boolean(formData.is_salaried)
                                    "
                                />
                            </FormSwitch>

                            <label class="ml-2" for="internal_staff_flag"
                                >Is Salaried?
                            </label>
                        </div>
                    </div>

                    <div
                        class="mt-6 border border-gray-200 px-4 py-6 rounded shadow-lg"
                    >
                        <div
                            class="flex items-center border-b border-slate-200/60 dark:border-darkmode-400"
                        >
                            <h2 class="font-medium text-base mr-auto mb-4">
                                Emergency Information
                            </h2>
                        </div>

                        <div v-if="enableSensitiveData()" class="mt-3">
                            <FormLabel
                                class="form-label"
                                for="emergency_contact_name"
                                >Emergency Contact Name
                                <span style="color: rgb(255, 0, 0)"
                                    >*</span
                                ></FormLabel
                            >
                            <FormInput
                                id="emergency_contact_name"
                                v-model="formData.emergency_contact_name"
                                :value="formData.emergency_contact_name"
                                class="form-control w-full"
                                name="emergency_contact_name"
                                placeholder=""
                                type="text"
                            />
                            <template
                                v-if="validate.emergency_contact_name.$error"
                            >
                                <div
                                    v-for="(error, index) in validate
                                        .emergency_contact_name.$errors"
                                    id="error_ecname"
                                    :key="index"
                                    class="text-danger mt-2"
                                >
                                    {{ error.$message }}
                                </div>
                            </template>
                        </div>

                        <div v-if="enableSensitiveData()" class="mt-3">
                            <FormLabel
                                class="form-label"
                                for="emergency_contact_phone"
                                >Emergency Contact Number
                                <span style="color: rgb(255, 0, 0)"
                                    >*</span
                                ></FormLabel
                            >
                            <FormInput
                                id="emergency_contact_phone"
                                v-model="formData.emergency_contact_phone"
                                :value="formData.emergency_contact_phone"
                                class="form-control w-full"
                                name="emergency_contact_phone"
                                placeholder=""
                                type="text"
                            />
                            <template
                                v-if="validate.emergency_contact_phone.$error"
                            >
                                <div
                                    v-for="(error, index) in validate
                                        .emergency_contact_phone.$errors"
                                    id="error_ecnumber"
                                    :key="index"
                                    class="text-danger mt-2"
                                >
                                    {{ error.$message }}
                                </div>
                            </template>
                        </div>

                        <div v-if="enableSensitiveData()" class="mt-3">
                            <FormLabel
                                class="form-label"
                                for="emergency_contact_relationship"
                                >Emergency Contact Relationship
                                <span style="color: rgb(255, 0, 0)"
                                    >*</span
                                ></FormLabel
                            >
                            <FormInput
                                id="emergency_contact_relationship"
                                v-model="
                                    formData.emergency_contact_relationship
                                "
                                :value="formData.emergency_contact_relationship"
                                class="form-control w-full"
                                maxlength="50"
                                name="emergency_contact_relationship"
                                placeholder=""
                                type="text"
                            />
                            <template
                                v-if="
                                    validate.emergency_contact_relationship
                                        .$error
                                "
                            >
                                <div
                                    v-for="(error, index) in validate
                                        .emergency_contact_relationship.$errors"
                                    id="error_ecrel"
                                    :key="index"
                                    class="text-danger mt-2"
                                >
                                    {{ error.$message }}
                                </div>
                            </template>
                        </div>
                    </div>

                    <div
                        v-if="
                            isEmptyObject(props.data) && enableSensitiveData()
                        "
                        class="mt-6 border border-gray-200 px-4 py-6 rounded shadow-lg"
                    >
                        <div
                            class="flex items-center border-b border-slate-200/60 dark:border-darkmode-400"
                        >
                            <h2 class="font-medium text-base mr-auto mb-4">
                                Cost
                            </h2>
                        </div>

                        <div class="mt-3">
                            <FormLabel
                                class="form-label"
                                for="cost_effective_from"
                                >Effective From
                                <span class="text-red-600">*</span></FormLabel
                            >
                            <FormInput
                                id="cost_effective_from"
                                v-model="formData.cost_effective_from"
                                :value="formData.cost_effective_from"
                                class="form-control w-full"
                                name="cost_effective_from"
                                type="date"
                            />
                            <template
                                v-if="validate.cost_effective_from.$error"
                            >
                                <div
                                    v-for="(error, index) in validate
                                        .cost_effective_from.$errors"
                                    id="error_ecrel"
                                    :key="index"
                                    class="text-danger mt-2"
                                >
                                    {{ error.$message }}
                                </div>
                            </template>
                        </div>

                        <div class="mt-3">
                            <FormLabel
                                class="form-label"
                                for="cost_effective_to"
                                >Effective To
                                <span class="text-red-600">*</span></FormLabel
                            >
                            <FormInput
                                id="cost_effective_to"
                                v-model="formData.cost_effective_to"
                                :value="formData.cost_effective_to"
                                class="form-control w-full"
                                name="cost_effective_to"
                                type="date"
                            />
                            <template v-if="validate.cost_effective_to.$error">
                                <div
                                    v-for="(error, index) in validate
                                        .cost_effective_to.$errors"
                                    id="error_ecrel"
                                    :key="index"
                                    class="text-danger mt-2"
                                >
                                    {{ error.$message }}
                                </div>
                            </template>
                        </div>

                        <div class="mt-3">
                            <FormLabel class="form-label" for="cost_currency_id"
                                >Currency
                                <span class="text-red-600">*</span></FormLabel
                            >
                            <TomSelect
                                id="currency_id"
                                v-model="formData.cost_currency_id"
                                :value="formData.cost_currency_id"
                                class="w-full p-0"
                                name="cost_currency_id"
                            >
                                <option selected>Select Currency</option>
                                <option
                                    v-for="currency in CurrencyStore.currencies
                                        ?.data"
                                    :value="currency.id"
                                >
                                    {{ currency.code }}
                                </option>
                            </TomSelect>
                        </div>
                        <div class="mt-1 mb-1">
                            <template v-if="validate.cost_currency_id.$error">
                                <div
                                    v-for="(error, index) in validate
                                        .cost_currency_id.$errors"
                                    id="shift_id_error"
                                    :key="index"
                                    class="text-danger mt-2"
                                >
                                    {{ error.$message }}
                                </div>
                            </template>
                        </div>

                        <div class="mt-3">
                            <FormLabel class="form-label" for="cost_amount"
                                >Amount
                                <span class="text-red-600">*</span></FormLabel
                            >
                            <FormInput
                                id="cost_amount"
                                v-model.number="formData.cost_amount"
                                :value="formData.cost_amount"
                                class="form-control w-full"
                                name="cost_amount"
                                type="number"
                            />
                            <template v-if="validate.cost_amount.$error">
                                <div
                                    v-for="(error, index) in validate
                                        .cost_amount.$errors"
                                    id="error_ecrel"
                                    :key="index"
                                    class="text-danger mt-2"
                                >
                                    {{ error.$message }}
                                </div>
                            </template>
                        </div>
                    </div>
                </div>
                <!-- END: Form Layout -->
            </div>
        </div>
    </form>
    <div class="">
        <div class="text-right p-2">
            <Button
                id="btn_cancel"
                class="w-32 mb-2 mr-1"
                variant="warning"
                @click="closeModal"
            >
                Cancel
            </Button>
            <Button
                id="btn_process"
                class="w-36 mb-2 mr-1"
                variant="customPrimary"
                @click="submit"
            >
                {{ EmployeesStore.getMode }} Employee
            </Button>
        </div>
    </div>
    <!-- Success Notification -->
    <Notification
        :options="{
            duration: 3000,
        }"
        class="flex"
        refKey="successNotification"
    >
        <Lucide class="text-success" icon="CheckCircle" />
        <div class="ml-4 mr-4">
            <div class="font-medium">System Notification</div>
            <div class="mt-1 text-slate-500">Employee Saved Successfully</div>
        </div>
    </Notification>
    <!-- Success Notification -->
    <!-- BEGIN: Failed Notification Content -->
    <Notification
        :options="{
            duration: 3000,
        }"
        class="flex"
        refKey="errorNotification"
    >
        <Lucide class="text-danger" icon="X" />
        <div class="ml-4 mr-4">
            <div class="font-medium">System Notification</div>
            <div class="mt-1 text-slate-500">
                <ul class="list-disc">
                    <template v-for="errorBag in backendValidationErrors">
                        <li class="text-red-500">
                            {{ errorBag[0] }}
                        </li>
                    </template>
                </ul>
            </div>
        </div>
    </Notification>
    <!-- BEGIN: Failed Notification Content -->
</template>
