<script lang="ts" setup>
import {
    defineEmits,
    defineProps,
    onMounted,
    provide,
    reactive,
    ref,
    toRefs,
} from "vue";
import { required } from "@vuelidate/validators";
import { useVuelidate } from "@vuelidate/core";
import { FormLabel } from "@/base-components/Form";
import TomSelect from "@/base-components/TomSelect";
import { useShiftStore } from "@/stores/shiftStore";
import Litepicker from "@/base-components/Litepicker";
import Button from "@/base-components/Button";
import { useRoute } from "vue-router";
import { useEmployeeShiftStore } from "@/stores/employeeShiftStore";
import moment from "moment";
import Notification, {
    NotificationElement,
} from "@/base-components/Notification";
import Lucide from "@/base-components/Lucide";
import { FormInput } from "@/base-components/Form";

const ShiftStore = useShiftStore();
const EmployeeShiftStore = useEmployeeShiftStore();

const props = defineProps({
    id: {
        type: Number,
        default: 0,
    },
    data: {
        type: Object,
        default: () => ({}),
    },
});

const loading = ref(false);
const route = useRoute();

const emit = defineEmits(["status", "close"]);

const closeModal = () => {
    const event = window.event; // Get the event object from the window
    if (event) {
        event.preventDefault();
    }

    console.log("close modal");
    emit("status", true);
    emit("close", true);
};

onMounted(async () => {
    await getShiftSchedules();

    if (!isEmptyObject(props.data)) {
        formData.id = props.data.id;
        formData.employee_id = String(props.data.employee_id);
        formData.shift_id = String(props.data.shift_id);
        formData.effdt = moment(props.data.effdt).format("YYYY-MM-DD");
        formData.effdt_to = props.data.effdt_to
            ? moment(props.data.effdt_to).format("YYYY-MM-DD")
            : "";
    }
});

const formData = reactive({
    id: props.id,
    employee_id: route.params.id ?? 0,
    shift_id: "",
    effdt: "",
    effdt_to: "",
});

const rules = {
    shift_id: { required },
    effdt: { required },
    effdt_to: {},
};
const $externalResults = ref({});
const backendValidationErrors = ref({});
const errorNotification = ref<NotificationElement>();
const successNotification = ref<NotificationElement>();

const getShiftSchedules = async () => {
    await ShiftStore.getAllShifts({ dropdown: true });
};

const changeEffdt = (event: Event) => {
    console.log("change effdt");
    formData.effdt = (event.target as HTMLInputElement).value;
};

const changeEffdtTo = (event: Event) => {
    console.log("change effdt_to");
    formData.effdt_to = (event.target as HTMLInputElement).value;
};

const validate = useVuelidate(rules, toRefs(formData), { $externalResults });

const submit = async () => {
    loading.value = true;
    validate.value.$reset();
    validate.value.$clearExternalResults();
    validate.value.$touch();

    if (validate.value.$invalid) {
        backendValidationErrors.value = {
            message: ["Incomplete or Missing required data"],
        };

        showErrorsNotification();
        loading.value = false;
        return;
    }

    try {
        isEmptyObject(props.data)
            ? await save()
            : await EmployeeShiftStore.update(formData);

        closeModal();
        showSuccessNotification();
        resetForm();
    } catch (error: any) {
        backendValidationErrors.value = {
            message: ["Something went wrong."],
        };
        showErrorsNotification();
        $externalResults.value = error?.response?.data?.errors;
    } finally {
        loading.value = false;
    }
};

const save = async () => {
    const payload = {
        employee_id: [formData.employee_id],
        shift_id: formData.shift_id,
        effdt: formData.effdt,
        effdt_to: formData.effdt_to,
    };

    await EmployeeShiftStore.save(payload);
};

const resetForm = () => {
    validate.value.$reset();
    validate.value.$clearExternalResults();
    formData.id = 0;
    formData.employee_id = route.params.id ?? 0;
    formData.shift_id = "";
    formData.effdt = "";
    formData.effdt_to = "";
};

const errorNotificationToggle = () => {
    // Show notification
    errorNotification.value?.showToast();
};
provide("bind[errorNotification]", (el: NotificationElement) => {
    errorNotification.value = el;
});
const showErrorsNotification = () => {
    errorNotificationToggle();
};

const successNotificationToggle = () => {
    successNotification.value?.showToast();
};
provide("bind[successNotification]", (el: NotificationElement) => {
    successNotification.value = el;
});
const showSuccessNotification = () => {
    successNotificationToggle();
};

const isEmptyObject = (obj: any) => {
    return Object.keys(obj).length === 0;
};

const onSelectShift = (event: any) => {
    if (event === "Select Shift") {
        formData.shift_id = "";

        return;
    }

    formData.shift_id = event;
};
</script>
<template>
    <div>
        <form @submit.prevent="submit">
            <div class="grid grid-cols-12 gap-2 mt-5 items-left text-left">
                <div class="intro-y col-span-12 lg:col-span-12">
                    <div class="intro-y pt-5 pb-5 pl-5 pr-2">
                        <div
                            class="border border-gray-200 px-4 py-6 rounded shadow-lg"
                        >
                            <div class="mt-3">
                                <FormLabel class="form-label" for="location">
                                    Shift Schedule <span style="color: rgb(255, 0, 0)">*</span>
                                </FormLabel>
                                <TomSelect
                                    id="shift_id"
                                    v-model="formData.shift_id"
                                    :value="formData.shift_id"
                                    class="w-full p-0"
                                    name="shift_id"
                                    @update:model-value="onSelectShift($event)"
                                >
                                    <option selected>Select Shift</option>
                                    <option
                                        v-for="schedule in ShiftStore.allShifts"
                                        :value="schedule.id"
                                    >
                                        {{ schedule.name }}
                                    </option>
                                </TomSelect>
                            </div>
                            <div class="mt-1 mb-1">
                                <template v-if="validate.shift_id.$error">
                                    <div
                                        v-for="(error, index) in validate
                                            .shift_id.$errors"
                                        id="error_wlocation"
                                        :key="index"
                                        class="text-danger mt-2"
                                    >
                                        {{ error.$message }}
                                    </div>
                                </template>
                            </div>

                            <div class="mt-3">
                                <FormLabel class="form-label">
                                    Start Date <span style="color: rgb(255, 0, 0)">*</span>
                                </FormLabel>
                                <!-- <Litepicker
                                    id="effdt"
                                    v-model="formData.effdt"
                                    :options="{
                                        autoApply: true,
                                        format: 'YYYY-MM-DD',
                                        showWeekNumbers: true,
                                        autoRefresh: true,
                                        resetButton: true,
                                        dropdowns: {
                                            minYear: 1990,
                                            maxYear: 2100,
                                            months: true,
                                            years: true,
                                        },
                                    }"
                                    class="form-control w-full block mx-auto"
                                    name="effdt"
                                    @change="changeEffdt($event)"
                                /> -->
                                <FormInput
                                    id="effdt"
                                    v-model="formData.effdt"
                                    :value="formData.effdt"
                                    class="form-control w-full"
                                    maxLength="100"
                                    name="effdt"
                                    type="date"
                                    :min="moment().format('YYYY-MM-DD')"
                                    :disabled="
                                        formData.effdt !== null &&
                                        moment(formData.effdt).isBefore(
                                            moment().format('YYYY-MM-DD')
                                        )
                                    "
                                    @change="changeEffdt($event)"
                                />
                                <template v-if="validate.effdt.$error">
                                    <div class="text-danger mt-2">
                                        {{ validate.effdt.$errors[0].$message }}
                                    </div>
                                </template>
                            </div>

                            <div class="mt-3">
                                <FormLabel class="form-label">
                                    End Date
                                </FormLabel>
                                <!-- <Litepicker
                                    id="effdt_to"
                                    v-model="formData.effdt_to"
                                    :options="{
                                        autoApply: true,
                                        format: 'YYYY-MM-DD',
                                        showWeekNumbers: true,
                                        autoRefresh: true,
                                        resetButton: true,
                                        dropdowns: {
                                            minYear: 2000,
                                            maxYear: 2100,
                                            months: true,
                                            years: true,
                                        },
                                    }"
                                    class="form-control w-full block mx-auto"
                                    name="effdt_to"
                                    @change="changeEffdtTo($event)"
                                /> -->
                                <FormInput
                                    id="effdt_to"
                                    v-model="formData.effdt_to"
                                    :value="formData.effdt_to"
                                    class="form-control w-full"
                                    maxLength="100"
                                    name="effdt_to"
                                    type="date"
                                    :min="
                                        formData.effdt ??
                                        moment().format('YYYY-MM-DD')
                                    "
                                    @change="changeEffdtTo($event)"
                                />
                                <template v-if="validate.effdt_to.$error">
                                    <div class="text-danger mt-2">
                                        {{
                                            validate.effdt_to.$errors[0]
                                                .$message
                                        }}
                                    </div>
                                </template>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <div class="">
                <div class="flex items-center justify-end p-2">
                    <Button
                        id="btn_cancel"
                        class="w-32 mb-2 mr-1"
                        variant="warning"
                        @click="closeModal"
                    >
                        Cancel
                    </Button>

                    <Button
                        id="btn_process"
                        class="w-32 mb-2 mr-1"
                        type="submit"
                        variant="customPrimary"
                        :disabled="loading"
                    >
                        <div v-if="loading" class="flex items-center">
                            <svg
                                aria-hidden="true"
                                class="w-4 h-4 mr-2 text-gray-200 animate-spin dark:text-gray-600 fill-blue-600"
                                fill="none"
                                viewBox="0 0 100 101"
                                xmlns="http://www.w3.org/2000/svg"
                            >
                                <path
                                    d="M100 50.5908C100 78.2051 77.6142 100.591 50 100.591C22.3858 100.591 0 78.2051 0 50.5908C0 22.9766 22.3858 0.59082 50 0.59082C77.6142 0.59082 100 22.9766 100 50.5908ZM9.08144 50.5908C9.08144 73.1895 27.4013 91.5094 50 91.5094C72.5987 91.5094 90.9186 73.1895 90.9186 50.5908C90.9186 27.9921 72.5987 9.67226 50 9.67226C27.4013 9.67226 9.08144 27.9921 9.08144 50.5908Z"
                                    fill="currentColor"
                                />
                                <path
                                    d="M93.9676 39.0409C96.393 38.4038 97.8624 35.9116 97.0079 33.5539C95.2932 28.8227 92.871 24.3692 89.8167 20.348C85.8452 15.1192 80.8826 10.7238 75.2124 7.41289C69.5422 4.10194 63.2754 1.94025 56.7698 1.05124C51.7666 0.367541 46.6976 0.446843 41.7345 1.27873C39.2613 1.69328 37.813 4.19778 38.4501 6.62326C39.0873 9.04874 41.5694 10.4717 44.0505 10.1071C47.8511 9.54855 51.7191 9.52689 55.5402 10.0491C60.8642 10.7766 65.9928 12.5457 70.6331 15.2552C75.2735 17.9648 79.3347 21.5619 82.5849 25.841C84.9175 28.9121 86.7997 32.2913 88.1811 35.8758C89.083 38.2158 91.5421 39.6781 93.9676 39.0409Z"
                                    fill="currentFill"
                                />
                            </svg>

                            <div>
                                {{
                                    isEmptyObject(props.data)
                                        ? "Saving..."
                                        : "Updating..."
                                }}
                            </div>
                        </div>

                        <div v-else>
                            {{ isEmptyObject(props.data) ? "Save" : "Update" }}
                        </div>
                    </Button>
                </div>
            </div>
        </form>
    </div>

    <Notification
        :options="{
            duration: 3000,
        }"
        class="flex"
        refKey="successNotification"
    >
        <Lucide class="text-success" icon="CheckCircle" />
        <div class="ml-4 mr-4">
            <div class="font-medium">System Notification</div>
            <div class="mt-1 text-slate-500">
                Shift has been successfully {{ formData.id ? 'updated' : 'added' }}.
            </div>
        </div>
    </Notification>

    <Notification
        :options="{
            duration: 3000,
        }"
        class="flex"
        refKey="errorNotification"
    >
        <Lucide class="text-danger" icon="X" />
        <div class="ml-4 mr-4">
            <div class="font-medium">System Notification</div>
            <div class="mt-1 text-slate-500">
                <ul class="list-disc">
                    <template v-for="errorBag in backendValidationErrors">
                        <li class="text-red-500">
                            {{ errorBag[0] }}
                        </li>
                    </template>
                </ul>
            </div>
        </div>
    </Notification>
</template>
