<script lang="ts" setup>
import {useAuthStore} from "../../stores/authStore";
//import { reactive, toRefs, } from "vue";
//import { required, integer, } from "@vuelidate/validators";
import {useVuelidate} from "@vuelidate/core";
import {useRoute} from "vue-router";
import {useHelperStore} from "../../stores/helperStore";
import {useUiStore} from "../../stores/uiStore";
import {defineEmits, onMounted, onUpdated, provide, reactive, ref, toRefs,} from "vue";
import {integer, required} from "@vuelidate/validators";
import {useFreskdeskStore} from "../../stores/freshdeskStore";
import moment from "moment";
import {useTicketsStore} from "../../stores/ticketsStore";
import Button from "../../base-components/Button";
import {ClassicEditor} from "../../base-components/Ckeditor";
import {FormInput, FormLabel} from "../../base-components/Form";
import router from "../../router";
import _ from "lodash";
import Notification, {NotificationElement} from "../../base-components/Notification";
import Lucide from "../../base-components/Lucide";

const toastData = reactive({
    message: "",
    subject: "",
    severity: "",
});
const TicketsStore = useTicketsStore();

const AuthStore = useAuthStore();

const HelperStore = useHelperStore();

const UiStore = useUiStore();

const FreshdeskStore = useFreskdeskStore();

const route = useRoute();
const id = Array.isArray(route.query.id) ? route.query.id[0] : route.query.id;
const categories = ref(2);
let userData = reactive({data: []});

const emit = defineEmits(["output", "ticketType"]);

const ticketForm = reactive({
    reason: "",
    fiscalYear: "",
});

const rules = {
    reason: {required},
    fiscalYear: {required, integer},
};

const editorConfig = {
    toolbar: {
        items: ["bold", "italic", "link"],
    },
};

onUpdated(() => {
});

onMounted(() => {
});

const validate = useVuelidate(rules, toRefs(ticketForm));

const formReset = () => {
    ticketForm.reason = "";
    ticketForm.fiscalYear = "";
};

const submitTicket = async () => {
    const currentDate = moment().format("MMMM Do YYYY, h:mm:ss a");

    validate.value.$touch();

    if (validate.value.$invalid) {
        toastData.severity = "danger";
        toastData.message = "All fields required!";
        toastData.subject = "Please provide all the required information";
        console.log("show error notification");
    } else {
        const ticketType = HelperStore.getTicketType(Number(id ?? 0));

        const desc_data = new FormData();
        desc_data.append("Date Filed", currentDate);
        desc_data.append("Reason for Request", ticketForm.reason);
        desc_data.append("ITR Fiscal Year", ticketForm.fiscalYear);

        var form_body = {};
        //@ts-ignore
        desc_data.forEach((value, key) => (form_body[key] = value));

        const form = new FormData();
        //@ts-ignore
        const userName = window.Laravel.user.name;
        form.append("id", String(id));
        form.append("subject", "ITR Request: " + userName);
        form.append("priority", ticketType?.f_priority_id?.toString() ?? '');
        form.append("status", ticketType?.f_status_id?.toString() ?? '');
        form.append("type", ticketType?.f_type?.toString() ?? '');
        form.append("form_body", JSON.stringify(form_body));
        form.append("ticket_type_id", ticketType?.id?.toString() ?? '');

        if (attachments.data !== null) {
            _.forEach(attachments.data, function (files) {
                _.forEach(files, function (file) {
                    form.append("attachments[]", file);
                });
            });
        }

        await TicketsStore.save(form).then((response) => {
            if (response.status === "success") {
                validate.value.$reset();
                emit("output", {render: true, type: "success"});
                if (id !== null) {
                    emit("ticketType", HelperStore.getTicketType(parseInt(id)));
                }
                showSuccessNotification();
                formReset();
                router.push({
                    name: "ticketDetails",
                    params: {id: response.data.id},
                });
            } else {
                emit("output", {render: true, type: "danger"});
            }
        });
    }
};

interface Attachments {
    data: File[][];
}

const attachments: Attachments = reactive({
    data: [[]],
});
const uploadFile = (e: Event) => {
    const files = (e.target as HTMLInputElement).files;
    if (files !== null) {
        attachments.data.push(Array.from(files));
    }
};

const successNotification = ref<NotificationElement>();
const successNotificationToggle = () => {
    // Show notification
    successNotification.value?.showToast();
};

provide("bind[successNotification]", (el: NotificationElement) => {
    successNotification.value = el;
});

const showSuccessNotification = () => {
    successNotificationToggle();
};
</script>
<template>
    <div class="intro-y box p-5">
        <div class="mt-3">
            <FormLabel
            >Reason for Request
                <span style="color: rgb(255, 0, 0)">*</span></FormLabel
            >
            <div class="mt-2">
                <ClassicEditor
                    id="reason"
                    v-model="ticketForm.reason"
                    :config="editorConfig"
                    name="reason"
                />
                <template v-if="validate.reason.$error">
                    <div
                        v-for="(error, index) in validate.reason.$errors"
                        id="error_reason"
                        :key="index"
                        class="text-danger mt-2"
                    >
                        {{ error.$message }}
                    </div>
                </template>
            </div>
        </div>

        <div class="mt-3">
            <FormLabel class="form-label" for="crud-form-1"
            >ITR Fiscal Year
                <span style="color: rgb(255, 0, 0)">*</span></FormLabel
            >
            <FormInput
                id="fiscalYear"
                v-model="ticketForm.fiscalYear"
                class="form-control w-full"
                name="fiscalYear"
                placeholder=""
                type="number"
            />
            <template v-if="validate.fiscalYear.$error">
                <div
                    v-for="(error, index) in validate.fiscalYear.$errors"
                    id="error_fiscalYear"
                    :key="index"
                    class="text-danger mt-2"
                >
                    {{ error.$message }}
                </div>
            </template>
        </div>

        <div class="text-right mt-5">
            <router-link
                class="btn text-white bg-warning py-2 rounded-md w-28 text-center"
                to="/submit-a-ticket"
                type="button"
            >
                Cancel
            </router-link>
            <Button
                class="ml-2"
                type="button"
                variant="customPrimary"
                @click="submitTicket"
            >Submit Ticket
            </Button>
        </div>
    </div>
    <!-- Success Notification -->
    <Notification
        :options="{
            duration: 3000,
        }"
        class="flex"
        refKey="successNotification"
    >
        <Lucide class="text-success" icon="CheckCircle"/>
        <div class="ml-4 mr-4">
            <div class="font-medium">System Notification</div>
            <div class="mt-1 text-slate-500">
                Ticket was created successfully.
            </div>
        </div>
    </Notification>
    <!-- Success Notification -->
</template>
