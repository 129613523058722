<script lang="ts" setup>
import {useAuthStore} from "../../stores/authStore";
import {numeric, required} from "@vuelidate/validators";
import {useVuelidate} from "@vuelidate/core";
import {useRoute} from "vue-router";
import {useHelperStore} from "../../stores/helperStore";
import {useUiStore} from "../../stores/uiStore";
import {defineEmits, onMounted, onUpdated, provide, reactive, ref, toRefs,} from "vue";
import {useFreskdeskStore} from "../../stores/freshdeskStore";
import moment from "moment";
import {useEmployeesStore} from "../../stores/employeesStore";
import {useTicketsStore} from "../../stores/ticketsStore";
import Litepicker from "../../base-components/Litepicker";
import TomSelect from "../../base-components/TomSelect";
import Button from "../../base-components/Button";
import {FormInput, FormLabel} from "../../base-components/Form";
import Notification, {NotificationElement,} from "../../base-components/Notification";
import Lucide from "../../base-components/Lucide";
import _ from "lodash";
import router from "../../router";
import EmployeeInterface from "../interfaces/employeesInterface";

const toastData = reactive({
    message: "",
    subject: "",
    severity: "",
});

const TicketsStore = useTicketsStore();

const AuthStore = useAuthStore();
const HelperStore = useHelperStore();
const UiStore = useUiStore();
const FreshdeskStore = useFreskdeskStore();
const route = useRoute();
const id = Array.isArray(route.query.id) ? route.query.id[0] : route.query.id;
const categories = ref(2);

const EmployeesStore = useEmployeesStore();
const emit = defineEmits(["output", "ticketType"]);

const employee = reactive({
    data: null as EmployeeInterface | null,
});
const ticketForm = reactive({
    //dateFiled: '',
    //employeeId: '',
    //name: '',
    agency: "SSS",
    refnumber: "",
    datePeriod: "",
    loanTypes: "",
    repayment: "",
    paymentScheme: "First Pay",
    monthlyAmortization: "",
    loanAmount: "",
    loanTerm: "",
});

const rules = {
    //dateFiled: { required },
    //employeeId: { required },
    //name: { required },
    agency: {required},
    refnumber: {required},
    datePeriod: {required},
    loanTypes: {required},
    repayment: {required},
    paymentScheme: {required},
    monthlyAmortization: {required, numeric},
    loanAmount: {required, numeric},
    loanTerm: {required},
};

// const loanTypes = ref(["BIR"]);
const loanTypes = ref<string[]>([]);
const updateAgency = (agency: any) => {
    loanTypes.value = [];
    switch (agency) {
        case "BIR":
            loanTypes.value.push("BIR");
            break;
        case "SSS":
            loanTypes.value.push(
                "Salary Loan",
                "Calamity Loan",
                // "Educational",
                // "Emergency",
                // "Stock Investment"
            );
            break;
        case "Pagibig":
            loanTypes.value.push(
                "Multi-Purpose",
                // "Housing",
                // "Short-term",
                "Calamity"
            );
            break;
        case "Philhealth":
            loanTypes.value.push("Philhealth");
            break;
        default:
            break;
    }
    console.log("loanTypes", loanTypes.value);
};

const validate = useVuelidate(rules, toRefs(ticketForm));

onMounted(async () => {
    updateAgency(ticketForm.agency)
    //await EmployeesStore.getAllEmployeesForTable();
    //populateEmployeeDetails();
    //console.log('populateEmployeeDetails', window.Laravel.user.id);
});

const populateEmployeeDetails = () => {
    // console.log('populateEmployeeDetails', window.Laravel.user.id);
    // employee.data = EmployeesStore.get(parseInt(window.Laravel.user.id));
    // console.log('AU', employee.data);
};

onUpdated(() => {
    const ticketType = HelperStore.getTicketType(Number(id ?? 0));
    UiStore.setBreadcrumb("Submit a Ticket > " + ticketType.name);
});

const formReset = () => {
    ticketForm.agency = "SSS";
    ticketForm.refnumber = "";
    ticketForm.datePeriod = "";
    ticketForm.loanTypes = "SSS";
    ticketForm.repayment = "";
    ticketForm.paymentScheme = "First Pay";
    ticketForm.monthlyAmortization = "";
    ticketForm.loanAmount = "";
    ticketForm.loanTerm = "";
};

const submitTicket = async () => {
    const currentDate = moment().format("MMMM Do YYYY, h:mm:ss a");

    validate.value.$touch();

    if (validate.value.$invalid) {
        toastData.severity = "danger";
        toastData.message = "All fields required!";
        toastData.subject = "Please provide all the required information";
        console.log("show error toast");
    } else {
        //@ts-ignore
        const userName = window.Laravel.user.name;
        const ticketType = HelperStore.getTicketType(Number(id ?? 0));

        const desc_data = new FormData();
        desc_data.append("Employee Name", userName);
        desc_data.append("Date Filed", currentDate);
        desc_data.append("Government Agency", ticketForm.agency);
        desc_data.append("Reference Number", ticketForm.refnumber);
        desc_data.append("Date Issued", ticketForm.datePeriod);
        desc_data.append("Loan Type", ticketForm.loanTypes);
        desc_data.append("Start of Loan Amortization", ticketForm.repayment);
        desc_data.append("Payment Scheme", ticketForm.paymentScheme);
        desc_data.append("Amount of Loan", ticketForm.loanAmount);
        desc_data.append(
            "Preferred amount of Monthly amortization",
            ticketForm.monthlyAmortization
        );
        desc_data.append("Loan Term", ticketForm.loanTerm);

        var form_body = {};
        //@ts-ignore
        desc_data.forEach((value, key) => (form_body[key] = value));

        const form = new FormData();
        form.append("id", String(id));
        form.append(
            "subject",
            "Employee Loan and Deductions: " + userName
        );
        form.append("priority", ticketType?.f_priority_id?.toString() ?? '');
        form.append("status", ticketType?.f_status_id?.toString() ?? '');
        form.append("type", ticketType?.f_type?.toString() ?? '');
        form.append("form_body", JSON.stringify(form_body));
        form.append("ticket_type_id", ticketType?.id?.toString() ?? '');

        if (attachments.data !== null) {
            _.forEach(attachments.data, function (files) {
                _.forEach(files, function (file) {
                    form.append("attachments[]", file);
                });
            });
        }

        await TicketsStore.save(form).then((response) => {
            console.log("tix response", response);
            if (response.status === "success") {
                validate.value.$reset();
                emit("output", {render: true, type: "success"});
                if (id !== null) {
                    emit("ticketType", HelperStore.getTicketType(parseInt(id)));
                }
                showSuccessNotification();
                formReset();
                router.push({
                    name: "ticketDetails",
                    params: {id: response.data.id},
                });
            } else {
                if(response.error?.errors) {
                    attachment_errors.value = response.error?.errors['attachments.0']
                }
                if(response.error.error) {
                    attachment_error.value = response.error?.error
                }
                // emit("output", {render: true, type: "danger"});
            }
        });
    }
};

const successNotification = ref<NotificationElement>();
const successNotificationToggle = () => {
    // Show notification
    successNotification.value?.showToast();
};

provide("bind[successNotification]", (el: NotificationElement) => {
    successNotification.value = el;
});

const showSuccessNotification = () => {
    successNotificationToggle();
};

interface Attachments {
    data: File[][];
}

const attachments: Attachments = reactive({
    data: [[]],
});

const attachment_errors = ref<any>({});
const attachment_error = ref<any>(null);
const uploadFile = (e: Event) => {
    const files = (e.target as HTMLInputElement).files;
    if (files !== null) {
        // attachments.data.push(Array.from(files));
        const fileArray = Array.from(files);
        attachments.data = [fileArray];
    }
    attachment_errors.value = ""
    attachment_error.value = ""
};
</script>
<template>
    <div class="intro-y box p-5">
        <div class="mt-3">
            <FormLabel class="form-label" for="crud-form-1"
            >Government Agency
                <span style="color: rgb(255, 0, 0)">*</span></FormLabel
            >
            <TomSelect
                id="agency"
                v-model.trim="ticketForm.agency"
                :value="ticketForm.agency"
                class="w-full"
                name="agency"
                @update:modelValue="updateAgency(ticketForm.agency)"
            >
                <!-- <option value="BIR">BIR</option> -->
                <option value="" selected disabled></option>
                <option value="SSS">SSS</option>
                <option value="Pagibig">Pagibig</option>
                <!-- <option value="Philhealth">Philhealth</option> -->
            </TomSelect>
            
            <template v-if="validate.agency.$error">
                <div
                    v-for="(error, index) in validate.agency.$errors"
                    :key="index"
                    class="text-danger mt-2"
                >
                    {{ error.$message }}
                </div>
            </template>
        </div>
        <div class="mt-3">
            <FormLabel class="form-label" for="crud-form-1"
            >Reference Number
                <span style="color: rgb(255, 0, 0)">*</span></FormLabel
            >
            <FormInput
                id="refnumber"
                v-model="ticketForm.refnumber"
                class="form-control w-full"
                name="refnumber"
                placeholder="Reference Number"
                type="text"
            />
            <template v-if="validate.refnumber.$error">
                <div
                    v-for="(error, index) in validate.refnumber.$errors"
                    id="error_refnumber"
                    :key="index"
                    class="text-danger mt-2"
                >
                    {{ error.$message }}
                </div>
            </template>
            <div class="mt-2">
                 <span class="text-gray-500 text-xs" v-if="ticketForm.agency === 'SSS'"
                >
                For SSS: Statement of Account for Loan Account No. starting SL/CL (sample: SL202211282441353/ CL202308222059262)
                </span>
                <span class="text-gray-500 text-xs" v-if="ticketForm.agency === 'Pagibig'"
                    >
                    For Pag-IBIG: 15-digit number, starting at 2 (sample: 241160000334045)
                </span>
            </div>
        </div>
        <div class="mt-3">
            <!-- <FormLabel class="form-label" for="crud-form-1"
            >Date Issued
                <span style="color: rgb(255, 0, 0)">*</span></FormLabel
            > -->
            <FormLabel class="form-label" for="crud-form-1"
            >Date of Loan
                <span style="color: rgb(255, 0, 0)">*</span></FormLabel
            >
            <Litepicker
                id="datePeriod"
                v-model="ticketForm.datePeriod"
                :options="{
                    autoApply: true,
                    showWeekNumbers: true,
                    numberOfColumns: 2,
                    numberOfMonths: 2,
                    dropdowns: {
                        minYear: 1990,
                        maxYear: null,
                        months: true,
                        years: true,
                    },
                }"
                class="form-control w-full"
                name="datePeriod"
            />
            <template v-if="validate.datePeriod.$error">
                <div
                    v-for="(error, index) in validate.datePeriod.$errors"
                    :key="index"
                    class="text-danger mt-2"
                >
                    {{ error.$message }}
                </div>
            </template>
        </div>
        <div class="mt-3">
            <FormLabel class="form-label" for="crud-form-1"
            >Loan Type 
            <span style="color: rgb(255, 0, 0)">*</span>
            </FormLabel>
            <TomSelect
                id="loanTypes"
                v-model="ticketForm.loanTypes"
                class="w-full mb-2"
                name="loanTypes"
            >
                <option v-for="loanType in loanTypes" :value="loanType">{{ loanType }}</option>
            </TomSelect>  
        </div>
        <div>
            <template v-if="validate.loanTypes.$error">
                <div
                    v-for="(error, index) in validate.loanTypes.$errors"
                    :key="index"
                    class="text-danger mt-2"
                >
                    {{ error.$message }}
                </div>
            </template>
        </div>

        <div class="mt-3">
            <FormLabel class="form-label" for="crud-form-1">
                Start of Loan Amortization <span style="color: rgb(255, 0, 0)">*</span>
            </FormLabel>

            <Litepicker
                id="repayment"
                v-model="ticketForm.repayment"
                :options="{
                    autoApply: true,
                    showWeekNumbers: true,
                    numberOfColumns: 2,
                    numberOfMonths: 2,
                    dropdowns: {
                        minYear: 1990,
                        maxYear: null,
                        months: true,
                        years: true,
                    },
                }"
                class="form-control w-full"
                name="repayment"
            />
            <template v-if="validate.repayment.$error">
                <div
                    v-for="(error, index) in validate.repayment.$errors"
                    :key="index"
                    class="text-danger mt-2"
                >
                    {{ error.$message }}
                </div>
            </template>
        </div>
        <div class="mt-3">
            <FormLabel class="form-label" for="crud-form-1"
            >Payment Scheme
                <span style="color: rgb(255, 0, 0)">*</span></FormLabel
            >

            <TomSelect
                id="paymentScheme"
                v-model="ticketForm.paymentScheme"
                class="w-full"
                name="paymentScheme"
            >
                <option value="First Pay">First Pay</option>
                <option value="Last Pay">Last Pay</option>
                <option value="Every payout of the month">
                    Every payout of the month
                </option>
            </TomSelect>
            <template v-if="validate.paymentScheme.$error">
                <div
                    v-for="(error, index) in validate.paymentScheme.$errors"
                    :key="index"
                    class="text-danger mt-2"
                >
                    {{ error.$message }}
                </div>
            </template>
        </div>

        <div class="mt-3">
            <FormLabel class="form-label" for="crud-form-1"
            >Amount of Loan or Outstanding Balance
                <span style="color: rgb(255, 0, 0)">*</span></FormLabel
            >
            <FormInput
                id="loanAmount"
                v-model="ticketForm.loanAmount"
                class="form-control w-full"
                name="loanAmount"
                placeholder="Amount of Loan or Outstanding Balance"
                type="number"
            />
            <template v-if="validate.loanAmount.$error">
                <div
                    v-for="(error, index) in validate.loanAmount.$errors"
                    id="error_loanAmount"
                    :key="index"
                    class="text-danger mt-2"
                >
                    {{ error.$message }}
                </div>
            </template>
            <div class="ml-2">
                <ul class="mt-2 list-disc list-inside">
                    <li class="text-gray-500 text-xs">Amount of loan - used for New Loans</li>
                    <li class="text-gray-500 text-xs">Outstanding balance - used for employees who have previous loan with their previous company and would like
                        to continue their payment with us</li>
                    
                </ul>
            </div>
        </div>
        <div class="mt-3">
            <FormLabel class="form-label" for="crud-form-1"
            >Amount of Monthly Amortization
                <span style="color: rgb(255, 0, 0)">*</span></FormLabel
            >
            <FormInput
                id="monthlyAmortization"
                v-model="ticketForm.monthlyAmortization"
                class="form-control w-full"
                name="monthlyAmortization"
                placeholder="Amount of Monthly Amortization"
                type="number"
            />
            <template v-if="validate.monthlyAmortization.$error">
                <div
                    v-for="(error, index) in validate.monthlyAmortization
                        .$errors"
                    id="error_monthlyAmortization"
                    :key="index"
                    class="text-danger mt-2"
                >
                    {{ error.$message }}
                </div>
            </template>
        </div>
        <div class="mt-3">
            <FormLabel class="form-label" for="crud-form-1"
            >Loan Term (months) <span style="color: rgb(255, 0, 0)">*</span>
            </FormLabel>
            <FormInput
                id="loanTerm"
                v-model="ticketForm.loanTerm"
                class="form-control w-full"
                name="loanTerm"
                placeholder="Loan Term (months)"
                type="text"
            />
            <template v-if="validate.loanTerm.$error">
                <div
                    v-for="(error, index) in validate.loanTerm.$errors"
                    id="error_loanTerm"
                    :key="index"
                    class="text-danger mt-2"
                >
                    {{ error.$message }}
                </div>
            </template>
        </div>

        <div class="mt-3 sm:col-span-2">
            <FormLabel
                class="form-label inline-block mb-2 text-gray-700"
                for="message"
            >Attachment/SOA
            </FormLabel>
            <div class="mt-1">
                <input
                    class="form-control block w-full px-3 py-1.5 text-base font-normal text-gray-700 bg-white bg-clip-padding border border-solid border-gray-300 rounded transition ease-in-out m-0 focus:text-gray-700 focus:bg-white focus:border-blue-600 focus:outline-none"
                    type="file"
                    @change="uploadFile"
                />
            </div>
            <template v-if="attachment_error">
                <div
                    id="error_reason"
                    class="text-danger mt-2"
                >
                    {{ attachment_error }}
                </div>
            </template>
            <template v-if="attachment_errors">
                <div
                    v-for="(error, index) in attachment_errors"
                    id="error_reason"
                    :key="index"
                    class="text-danger mt-2"
                >
                    {{ error }}
                </div>
            </template>
        </div>

        <div class="text-right mt-5">
            <router-link
                class="btn text-white bg-warning py-2 rounded-md w-28 text-center"
                to="/submit-a-ticket"
                type="button"
            >
                Cancel
            </router-link>
            <Button
                class="ml-2"
                type="button"
                variant="customPrimary"
                @click="submitTicket"
            >Submit Ticket
            </Button>
        </div>
    </div>
    <!-- Success Notification -->
    <Notification
        :options="{
            duration: 3000,
        }"
        class="flex"
        refKey="successNotification"
    >
        <Lucide class="text-success" icon="CheckCircle"/>
        <div class="ml-4 mr-4">
            <div class="font-medium">System Notification</div>
            <div class="mt-1 text-slate-500">
                Ticket was created successfully.
            </div>
        </div>
    </Notification>
    <!-- Success Notification -->
</template>
