<script lang="ts" setup>
import { onMounted, reactive, ref } from "vue";
import PageHeaderStandard from "./components/PageHeaderStandard.vue";
import { useAuthStore } from "../stores/authStore";
import { useClientsStore } from "../stores/clientsStore";
import Button from "../base-components/Button";
import { FilterMatchMode, FilterOperator } from "primevue/api";
import { FormInput } from "../base-components/Form";
import Lucide from "../base-components/Lucide";
import DataTable from "primevue/datatable";
import Column from "primevue/column";
import InputText from "primevue/inputtext";
import "primevue/resources/themes/saga-blue/theme.css";
import "primevue/resources/primevue.min.css";
import "primeicons/primeicons.css";
import FormClient from "./forms/FormClient.vue";
import ConfirmModal from "./components/ConfirmModal.vue";
import FormSlider from "./FormSlider.vue";
import ClientInterface from "./interfaces/clientsInterface";
import { useUiStore } from "../stores/uiStore";
import LazyParamsInterface from "./interfaces/lazyParamsInterface";
import ClientIncompleteConfigurationModal from "./components/clients/ClientIncompleteConfigurationModal.vue";

interface ClientData {
    id: number;
}

interface ClientApiData {
    data: any;
}

const AuthStore = useAuthStore();
const ClientsStore = useClientsStore();
const UiStore = useUiStore();
let showSlider = ref(false);
const formClientSettings = reactive({
    id: 0,
});
const clientId = ref(0);
const deleteId = ref(0);
const clients = ref<{ data: ClientInterface[] }>({
    data: [],
});
const loading = ref(true);

const lazyParams = ref<LazyParamsInterface>({});
const totalRecords = ref(0);
const selectAll = ref(false);
const selectedClients = ref();
const isSliderOut = ref(false);
const searchableCols = ref([
    "trade_name",
    "country.name",
    "pointOfContact.first_name",
    "timezone.friendly_name",
]);
const allIncompleteConfiguration = ref(false);
const incompleteConfiguration = ref<string[]>([]);
const incompleteConfigurationOptions = ref([
    { label: "Client Invoice Configuration", value: "clientInvoiceConfigs" },
]);
const filters = ref({
    global: { value: null, matchMode: FilterMatchMode.CONTAINS },
    trade_name: {
        operator: FilterOperator.AND,
        constraints: [{ value: null, matchMode: FilterMatchMode.STARTS_WITH }],
    },
    start_date: {
        operator: FilterOperator.AND,
        constraints: [{ value: null, matchMode: FilterMatchMode.EQUALS }],
    },
    "country.name": {
        operator: FilterOperator.AND,
        constraints: [{ value: null, matchMode: FilterMatchMode.STARTS_WITH }],
    },
    "pointOfContact.first_name": {
        operator: FilterOperator.AND,
        constraints: [{ value: null, matchMode: FilterMatchMode.STARTS_WITH }],
    },
    "timezone.friendly_name": {
        operator: FilterOperator.AND,
        constraints: [{ value: null, matchMode: FilterMatchMode.STARTS_WITH }],
    },
});
onMounted(() => {
    UiStore.setBreadcrumb("All Clients");
    AuthStore.getLoggedInUserDetails();
    loadLazyData();
});

const loadLazyData = async () => {
    loading.value = true;

    const params = {
        dt_params: JSON.stringify(lazyParams.value),
        searchable_columns: JSON.stringify(searchableCols.value),
        incomplete_configuration: incompleteConfiguration.value,
    };

    await ClientsStore.getAllClientsForTable(params);
    //@ts-ignore Todo fix this
    clients.value = ClientsStore.clientsTableData.data;
    //@ts-ignore Todo fix this
    totalRecords.value = ClientsStore.clientsTableData.data.total;
    loading.value = false;
};
const createNew = () => {
    showSlider.value = true;
    clientId.value = 0;
    isSliderOut.value = true;
};
const showForm = (value: boolean) => {
    isSliderOut.value = value;
};

const onSelectAllChange = (event: { checked: any }) => {
    console.log("onSelectAllChange");
    const selectAll = event.checked;
    // if (selectAll) {
    //     customerService.value.getCustomers().then((data: { customers: any; }) => {
    //         selectAll.value = true;
    //         selectedClients.value = data.customers;
    //     });
    // } else {
    //     selectAll.value = false;
    //     selectedClients.value = [];
    // }
};
const onRowSelect = () => {
    selectAll.value = selectedClients.value.length === totalRecords.value;
};
const onRowUnselect = () => {
    selectAll.value = false;
};
const onPage = (event: {}) => {
    lazyParams.value = event;
    loadLazyData();
};
const onSort = (event: {}) => {
    showClearFilter.value = true;
    lazyParams.value = event;
    loadLazyData();
};
const onFilter = () => {
    console.log("filtering");
    showClearFilter.value = filters.value.global.value !== "";
    lazyParams.value.filters = filters.value;
    loadLazyData();
};
const showClearFilter = ref(false);
const formClientStatus = (status: any) => {
    if (status) {
        loadLazyData();
    }
};
const closeSlider = () => {
    console.log("closeSlider");
    clientId.value = 0;
    isSliderOut.value = false;
};
const clientApiData = reactive<ClientApiData>({ data: [] });
const updateClient = (data: ClientData) => {
    isSliderOut.value = true;
    formClientSettings.id = data.id;
    clientId.value = data.id;
    clientApiData.data = data;
};
const confirmDelete = (event: { preventDefault: () => void }, data: any) => {
    console.log("confirmDelete");
    event.preventDefault();
    deleteId.value = data.id;
    setConfirmDialog(true);
};
const showConfirmDialog = ref(false);
const setConfirmDialog = (value: boolean) => {
    showConfirmDialog.value = value;
};

const processDelete = (event: any) => {
    console.log("processDelete", event);
    if (event === false) {
        deleteId.value = 0;
    } else {
        ClientsStore.delete(deleteId.value).then((response: any) => {
            if (response.status === "success") {
                loadLazyData();
                deleteId.value = 0;
            }
        });
    }
};
const dt = ref();
const resetGlobalFilter = () => {
    showClearFilter.value = false;

    filters.value = {
        global: { value: null, matchMode: FilterMatchMode.CONTAINS },
        trade_name: {
            operator: FilterOperator.AND,
            constraints: [
                { value: null, matchMode: FilterMatchMode.STARTS_WITH },
            ],
        },
        start_date: {
            operator: FilterOperator.AND,
            constraints: [{ value: null, matchMode: FilterMatchMode.EQUALS }],
        },
        "country.name": {
            operator: FilterOperator.AND,
            constraints: [
                { value: null, matchMode: FilterMatchMode.STARTS_WITH },
            ],
        },
        "pointOfContact.first_name": {
            operator: FilterOperator.AND,
            constraints: [
                { value: null, matchMode: FilterMatchMode.STARTS_WITH },
            ],
        },
        "timezone.friendly_name": {
            operator: FilterOperator.AND,
            constraints: [
                { value: null, matchMode: FilterMatchMode.STARTS_WITH },
            ],
        },
    };

    lazyParams.value = {
        first: 0,
        rows: dt.value.rows,
        sortField: null,
        sortOrder: null,
        filters: filters.value,
    };

    incompleteConfiguration.value = [];

    loadLazyData();
};
const handleSelectIncompleteConfiguration = () => {
    allIncompleteConfiguration.value =
        incompleteConfiguration.value.length ===
        incompleteConfigurationOptions.value.length;
};

const handleSelectAllIncompleteConfiguration = () => {
    if (allIncompleteConfiguration.value) {
        incompleteConfiguration.value =
            incompleteConfigurationOptions.value.map((config) => config.value);
    } else {
        incompleteConfiguration.value = [];
    }
};

const onClearIncompleteConfiguration = () => {
    incompleteConfiguration.value = [];
    allIncompleteConfiguration.value = false;

    loadLazyData();
};
</script>
<template>
    <div class="w-full">
        <PageHeaderStandard :title="'Clients'" />
        <div
            class="intro-y col-span-12 flex flex-wrap sm:flex-nowrap items-center mt-2"
        >
            <Button
                v-if="
                    Object.values(AuthStore.activatedPermissionsName).includes(
                        'can create clients'
                    )
                "
                id="add-new-client-button"
                class="mr-2 shadow-md"
                variant="customPrimary"
                @click="createNew"
            >
                Add New Client
            </Button>
            <div class="hidden md:block mx-auto text-slate-500"></div>
            <div class="flex w-full sm:w-auto mt-3 sm:mt-0 sm:ml-auto md:ml-0">
                <template v-if="showClearFilter">
                    <button
                        class="btn bg-custom-color-1000 text-white w-full mx-3 rounded-md px-4"
                        @click="resetGlobalFilter"
                    >
                        Clear
                        <span
                            class="h-3 w-4 pi pi-filter-slash p-component"
                        ></span>
                    </button>
                </template>
                <div class="relative w-56 text-slate-500">
                    <FormInput
                        id="global-search"
                        v-model="filters.global.value"
                        :value="filters.global.value"
                        class="w-56 pr-10 !box"
                        placeholder="Search..."
                        type="text"
                        @keyup.enter="onFilter"
                    />
                    <Lucide
                        class="absolute inset-y-0 right-0 w-4 h-4 my-auto mr-3"
                        icon="Search"
                    />
                </div>
            </div>
        </div>
        <DataTable
            ref="dt"
            v-model:filters="filters"
            v-model:selection="selectedClients"
            :globalFilterFields="searchableCols"
            :lazy="true"
            :loading="loading"
            :paginator="true"
            :rows="10"
            :selectAll="selectAll"
            :totalRecords="totalRecords"
            :value="clients.data"
            class="mt-4 p-datatable-sm rounded-lg shadow-lg text-xs"
            dataKey="id"
            filterDisplay="menu"
            responsiveLayout="scroll"
            showGridlines
            stripedRows
            @filter="onFilter()"
            @page="onPage($event)"
            @sort="onSort($event)"
            @select-all-change="onSelectAllChange"
            @row-select="onRowSelect"
            @row-unselect="onRowUnselect"
        >
            <template #empty> No clients found.</template>
            <template #loading> Loading client data. Please wait.</template>
            <Column
                headerStyle="min-width: 36px"
                selectionMode="multiple"
            ></Column>
            <Column
                :show-add-button="false"
                :show-filter-match-modes="false"
                :show-filter-operator="false"
                field="trade_name"
                class="text-center"
            >
                <template #body="{ data }">
                    <ClientIncompleteConfigurationModal :client="data" />
                </template>
                <template #filter>
                    <h3 class="font-semibold">Incomplete Configuration</h3>

                    <div class="mt-4 flex items-center">
                        <input
                            id="config_all"
                            type="checkbox"
                            v-model="allIncompleteConfiguration"
                            value="All"
                            @change="handleSelectAllIncompleteConfiguration"
                        />
                        <label for="config_all" class="ml-2"> All </label>
                    </div>

                    <div
                        v-for="(
                            config, index
                        ) in incompleteConfigurationOptions"
                        class="mt-2 flex items-center"
                    >
                        <input
                            :id="`config_${index}`"
                            type="checkbox"
                            v-model="incompleteConfiguration"
                            :value="config.value"
                            @change="handleSelectIncompleteConfiguration"
                        />
                        <label :for="`config_${index}`" class="ml-2">
                            {{ config.label }}
                        </label>
                    </div>
                </template>
                <template #filterclear>
                    <Button
                        class="border border-[#2196f3] text-[#2196f3] py-1.5 px-2"
                        @click="onClearIncompleteConfiguration"
                    >
                        Clear
                    </Button>
                </template>
            </Column>
            <Column :sortable="true" field="trade_name" header="Account Name">
                <template #body="{ data }">
                    {{ data.trade_name }}
                </template>
                <template #filter="{ filterModel }">
                    <InputText
                        v-model="filterModel.value"
                        class="p-column-filter"
                        placeholder="Search by account name"
                        type="text"
                    />
                </template>
            </Column>

            <Column
                :sortable="true"
                class="!text-center"
                field="start_date"
                header="Anniversary Date"
            >
                <template #body="{ data }">
                    {{ data.start_date }}
                </template>
                <template #filter="{ filterModel }">
                    <InputText
                        v-model="filterModel.value"
                        class="p-column-filter"
                        placeholder="Search by name"
                        type="date"
                    />
                </template>
            </Column>

            <Column
                :sortable="true"
                field="country.name"
                header="Country"
            >
                <template #body="{ data }">
                    {{ data.country.name }}
                </template>
                <template #filter="{ filterModel }">
                    <InputText
                        v-model="filterModel.value"
                        class="p-column-filter"
                        placeholder="Search by name"
                        type="text"
                    />
                </template>
            </Column>

            <Column
                class="!text-center"
                field="employees_count"
                header="Active Employees"
                sortable
            >
                <template #body="{ data }">
                    {{ data.employees_count }}
                </template>
            </Column>

            <Column field="pointOfContact.first_name" header="POC">
                <template #body="{ data }">
                    {{ data.point_of_contact?.full_name }}
                </template>
                <template #filter="{ filterModel }">
                    <InputText
                        v-model="filterModel.value"
                        class="p-column-filter"
                        placeholder="Search by POC"
                        type="text"
                    />
                </template>
            </Column>

            <Column field="timezone.friendly_name" header="Timezone">
                <template #body="{ data }">
                    {{ data.timezone.friendly_name }}
                </template>
                <template #filter="{ filterModel }">
                    <InputText
                        v-model="filterModel.value"
                        class="p-column-filter"
                        placeholder="Search by timezone"
                        type="text"
                    />
                </template>
            </Column>
            <Column :exportable="false" style="width: 8rem; max-width: 8rem">
                <template #body="slotProps">
                    <div class="content-center flex justify-center">
                        <!-- View -->
                        <div class="">
                            <router-link
                                :to="{
                                    name: 'clientsDetails',
                                    params: { id: slotProps.data.id },
                                }"
                            >
                                <svg
                                    :id="'view_' + slotProps.data.id"
                                    class="icon icon-tabler icon-tabler-eye cursor-pointer"
                                    fill="none"
                                    height="18"
                                    stroke="currentColor"
                                    stroke-linecap="round"
                                    stroke-linejoin="round"
                                    stroke-width="2"
                                    viewBox="0 0 24 24"
                                    width="18"
                                    xmlns="http://www.w3.org/2000/svg"
                                >
                                    <path
                                        d="M0 0h24v24H0z"
                                        fill="none"
                                        stroke="none"
                                    ></path>
                                    <path
                                        d="M12 12m-2 0a2 2 0 1 0 4 0a2 2 0 1 0 -4 0"
                                    ></path>
                                    <path
                                        d="M22 12c-2.667 4.667 -6 7 -10 7s-7.333 -2.333 -10 -7c2.667 -4.667 6 -7 10 -7s7.333 2.333 10 7"
                                    ></path>
                                </svg>
                            </router-link>
                        </div>
                        <!-- View -->
                        <!-- Update Client Button -->
                        <div
                            v-if="
                                Object.values(
                                    AuthStore.activatedPermissionsName
                                ).includes('can update clients')
                            "
                            class="ml-2"
                        >
                            <svg
                                :id="'update_' + slotProps.data.id"
                                class="icon icon-tabler icon-tabler-edit cursor-pointer"
                                fill="none"
                                height="18"
                                stroke="currentColor"
                                stroke-linecap="round"
                                stroke-linejoin="round"
                                stroke-width="2"
                                viewBox="0 0 24 24"
                                width="18"
                                xmlns="http://www.w3.org/2000/svg"
                                @click="updateClient(slotProps.data)"
                            >
                                <path
                                    d="M0 0h24v24H0z"
                                    fill="none"
                                    stroke="none"
                                ></path>
                                <path
                                    d="M7 7h-1a2 2 0 0 0 -2 2v9a2 2 0 0 0 2 2h9a2 2 0 0 0 2 -2v-1"
                                ></path>
                                <path
                                    d="M20.385 6.585a2.1 2.1 0 0 0 -2.97 -2.97l-8.415 8.385v3h3l8.385 -8.415z"
                                ></path>
                                <path d="M16 5l3 3"></path>
                            </svg>
                        </div>
                        <!-- Update -->
                        <!-- Delete -->
                        <!--@click="confirmDelete(slotProps.data)"-->
                        <div
                            v-if="
                                Object.values(
                                    AuthStore.activatedPermissionsName
                                ).includes('can delete clients')
                            "
                            class="ml-2"
                        >
                            <svg
                                :id="'delete_' + slotProps.data.id"
                                class="icon icon-tabler icon-tabler-trash cursor-pointer"
                                fill="none"
                                height="18"
                                stroke="currentColor"
                                stroke-linecap="round"
                                stroke-linejoin="round"
                                stroke-width="2"
                                viewBox="0 0 24 24"
                                width="18"
                                xmlns="http://www.w3.org/2000/svg"
                                @click="confirmDelete($event, slotProps.data)"
                            >
                                <path
                                    d="M0 0h24v24H0z"
                                    fill="none"
                                    stroke="none"
                                ></path>
                                <path d="M4 7l16 0"></path>
                                <path d="M10 11l0 6"></path>
                                <path d="M14 11l0 6"></path>
                                <path
                                    d="M5 7l1 12a2 2 0 0 0 2 2h8a2 2 0 0 0 2 -2l1 -12"
                                ></path>
                                <path
                                    d="M9 7v-3a1 1 0 0 1 1 -1h4a1 1 0 0 1 1 1v3"
                                ></path>
                            </svg>
                        </div>
                        <!-- Delete -->
                    </div>
                </template>
                <template #header="slotProps">
                    <div class="content-center flex justify-center"></div>
                </template>
            </Column>
            <template #paginatorstart>
                <!--                <Button type="button" icon="pi pi-refresh" class="p-button-text"/>-->
            </template>
            <template #paginatorend>
                <!--                <Button type="button" icon="pi pi-cloud" class="p-button-text"/>-->
            </template>
        </DataTable>
        <FormSlider :isOpen="isSliderOut" @close="closeSlider()">
            <FormClient
                :id="clientId"
                :data="clientApiData.data"
                @close="closeSlider()"
                @status="formClientStatus($event)"
            ></FormClient>
        </FormSlider>

        <ConfirmModal
            :displayConfirmModal="showConfirmDialog"
            second-description="Client has been successfully deleted."
            @closeConfirmModal="setConfirmDialog(false)"
            @proceedProcess="processDelete($event)"
        />
    </div>
</template>
