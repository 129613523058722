<script lang="ts" setup>
import Button from "../../../base-components/Button";
import dayjs from "dayjs";
import Column from "primevue/column";
import DataTable from "primevue/datatable";
import { onMounted, ref } from "vue";
import LazyParamsInterface from "../../interfaces/lazyParamsInterface";
import { FilterMatchMode, FilterOperator } from "primevue/api";
import { useClientRecurringSubscriptionStore } from "../../../stores/clientRecurringSubscriptionStore";
import { useRoute } from "vue-router";
import ClientRecurringSubscriptionInterface from "../../interfaces/clientRecurringSubscriptionInterface";
import FormSlider from "../../FormSlider.vue";
import FormClientRecurringSubscription from "../../forms/client/FormClientRecurringSubscription.vue";
import ConfirmModal from "../ConfirmModal.vue";
import ClientInterface from "../../interfaces/clientsInterface";
import { useClientsStore } from "../../../stores/clientsStore";

const route = useRoute();
const slider = ref(false);
const ClientRecurringSubscriptionStore = useClientRecurringSubscriptionStore();
const ClientsStore = useClientsStore();
const showClearFilter = ref(false);
const lazyParams = ref<LazyParamsInterface>({});
const deleted = ref();
const showConfirmDialog = ref(false);
const filters = ref({
    global: { value: null, matchMode: FilterMatchMode.CONTAINS },
    "client.legal_business_name": {
        operator: FilterOperator.AND,
        constraints: [{ value: null, matchMode: FilterMatchMode.STARTS_WITH }],
    },
    subscription: {
        operator: FilterOperator.AND,
        constraints: [{ value: null, matchMode: FilterMatchMode.STARTS_WITH }],
    },
});
const searchableCols = ref(["client.legal_business_name", "subscription"]);
const loading = ref(false);
const deleteId = ref(0);
const client = ref<ClientInterface>();

onMounted(async () => {
    console.log("mounted recurring subscription");
    await onDataFetch();
    await ClientsStore.getClientDetails(+route?.params?.id).then((response) => {
        client.value = response;
        console.log(response);
    });
});
const config = ref<ClientRecurringSubscriptionInterface>();
const onFilter = async (event: any) => {
    showClearFilter.value = filters.value.global.value !== "";

    lazyParams.value.filters = filters.value;

    await onDataFetch();
};

const onDataFetch = async () => {
    loading.value = true;

    const params = {
        client_id: route?.params?.id,
        dt_params: JSON.stringify(lazyParams.value),
        searchable_columns: JSON.stringify(searchableCols.value),
    };

    await ClientRecurringSubscriptionStore.getClientRecurringSubscriptions(
        params
    );

    loading.value = false;
};

const onPage = async (event: any) => {
    lazyParams.value = event;

    await onDataFetch();
};
const onSort = async (event: any) => {
    showClearFilter.value = true;
    lazyParams.value = event;

    await onDataFetch();
};

const updateData = (data: any) => {
    console.log("update");
    slider.value = !slider.value;

    if (data && Object.keys(data).length > 0) {
        console.log("if");
        config.value = data;
    } else {
        console.log("else");
        config.value = {} as ClientRecurringSubscriptionInterface;
    }
    console.log(config);
};

const close = async () => {
    slider.value = !slider.value;

    await onDataFetch();
};

const onClickButton = (data: any) => {
    console.log("onClickButton");
    slider.value = !slider.value;

    if (data && Object.keys(data).length > 0) {
        config.value = data;
    } else {
        config.value = {} as ClientRecurringSubscriptionInterface;
    }
    console.log(config);
};

const setConfirmDialog = (value: boolean) => {
    showConfirmDialog.value = value;
};

const confirmDelete = (event: { preventDefault: () => void }, data: any) => {
    event.preventDefault();
    deleteId.value = data.id;
    setConfirmDialog(true);
};
const processDelete = (event: any) => {
    if (event === false) {
        deleteId.value = 0;
    } else {
        ClientRecurringSubscriptionStore.deleteClientRecurringSubscription(
            deleteId.value
        )
            .then(() => {
                deleteId.value = 0;
                deleted.value = true;
            })
            .catch((error) => {
                deleted.value = false;
            });
    }
};
</script>
<template>
    <div class="intro-y box col-span-12 border-2 mt-4">
        <div
            class="flex items-center px-5 py-5 sm:py-5 border-b border-slate-200/60 dark:border-darkmode-400"
        >
            <div class="flex-1 flex justify-between">
                <h2 id="sales-agent" class="font-medium text-base mr-auto">
                    Recurring Invoice Items
                </h2>

                <Button
                    id="add-recurring-subscription"
                    class="text-xs"
                    variant="soft-secondary"
                    @click="onClickButton"
                >
                    Add Subscription
                </Button>
            </div>
        </div>
        <div class="p-5">
            <DataTable
                ref="dt"
                v-model:filters="filters"
                :globalFilterFields="searchableCols"
                :lazy="true"
                :loading="loading"
                :paginator="true"
                :rows="10"
                :totalRecords="
                    ClientRecurringSubscriptionStore
                        .clientRecurringSubscriptions?.total
                "
                :value="
                    ClientRecurringSubscriptionStore
                        .clientRecurringSubscriptions?.data
                "
                class="mt-4 p-datatable-sm text-xs"
                filterDisplay="menu"
                responsiveLayout="scroll"
                showGridlines
                stripedRows
                @filter="onFilter($event)"
                @page="onPage($event)"
                @sort="onSort($event)"
            >
                <template #empty> No available data.</template>

                <template #loading> Loading data. Please wait.</template>

                <Column
                    :sortable="true"
                    field="client.legal_business_name"
                    header="Client"
                >
                </Column>

                <Column
                    :sortable="true"
                    field="subscription"
                    header="Subscription"
                />

                <Column
                    :sortable="true"
                    field="effective_from"
                    header="Effective From"
                >
                    <template #body="{ data }">
                        {{ dayjs(data.effective_from).format("YYYY-MM-DD") }}
                    </template>
                </Column>
                <Column
                    :sortable="true"
                    field="effective_to"
                    header="Effective To"
                >
                    <template #body="{ data }">
                        <div v-if="data.effective_to !== null">
                            {{ dayjs(data.effective_to).format("YYYY-MM-DD") }}
                        </div>
                    </template>
                </Column>
                <Column :sortable="true" field="quantity" header="Quantity" />

                <Column :sortable="true" field="frequency" header="Frequency" />
                <Column :sortable="true" field="amount" header="Cost">
                    <template #body="{ data }"> {{ data.amount }}</template>
                </Column>

                <Column class="!w-[5%]">
                    <template #body="{ data }">
                        <div class="flex justify-center items-center">
                            <div>
                                <svg
                                    :id="'update_' + data.id"
                                    class="icon icon-tabler icon-tabler-edit cursor-pointer"
                                    fill="none"
                                    height="18"
                                    stroke="currentColor"
                                    stroke-linecap="round"
                                    stroke-linejoin="round"
                                    stroke-width="2"
                                    viewBox="0 0 24 24"
                                    width="18"
                                    xmlns="http://www.w3.org/2000/svg"
                                    @click="updateData(data)"
                                >
                                    <path
                                        d="M0 0h24v24H0z"
                                        fill="none"
                                        stroke="none"
                                    ></path>
                                    <path
                                        d="M7 7h-1a2 2 0 0 0 -2 2v9a2 2 0 0 0 2 2h9a2 2 0 0 0 2 -2v-1"
                                    ></path>
                                    <path
                                        d="M20.385 6.585a2.1 2.1 0 0 0 -2.97 -2.97l-8.415 8.385v3h3l8.385 -8.415z"
                                    ></path>
                                    <path d="M16 5l3 3"></path>
                                </svg>
                            </div>

                            <div class="ml-2">
                                <svg
                                    :id="'delete_' + data.id"
                                    class="icon icon-tabler icon-tabler-trash cursor-pointer"
                                    fill="none"
                                    height="18"
                                    stroke="currentColor"
                                    stroke-linecap="round"
                                    stroke-linejoin="round"
                                    stroke-width="2"
                                    viewBox="0 0 24 24"
                                    width="18"
                                    xmlns="http://www.w3.org/2000/svg"
                                    @click="confirmDelete($event, data)"
                                >
                                    <path
                                        d="M0 0h24v24H0z"
                                        fill="none"
                                        stroke="none"
                                    ></path>
                                    <path d="M4 7l16 0"></path>
                                    <path d="M10 11l0 6"></path>
                                    <path d="M14 11l0 6"></path>
                                    <path
                                        d="M5 7l1 12a2 2 0 0 0 2 2h8a2 2 0 0 0 2 -2l1 -12"
                                    ></path>
                                    <path
                                        d="M9 7v-3a1 1 0 0 1 1 -1h4a1 1 0 0 1 1 1v3"
                                    ></path>
                                </svg>
                            </div>
                        </div>
                    </template>
                </Column>
            </DataTable>
        </div>
    </div>
    <FormSlider
        :isOpen="slider"
        :size="'md'"
        header="Recurring Subscription Form"
        @close="onClickButton"
    >
        <FormClientRecurringSubscription
            :client="client as ClientInterface"
            :config="config"
            @close="close"
        />
    </FormSlider>

    <ConfirmModal
        :deleted="deleted"
        :displayConfirmModal="showConfirmDialog"
        second-description="Invoice config has been successfully deleted."
        @closeConfirmModal="setConfirmDialog(false)"
        @deleted="deleted = $event"
        @proceedProcess="processDelete($event)"
    />
</template>
