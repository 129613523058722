<script lang="ts" setup>
import { useAuthStore } from "../../stores/authStore";
import { numeric, required } from "@vuelidate/validators";
import { useVuelidate } from "@vuelidate/core";
import { useRoute } from "vue-router";
import {
    defineEmits,
    onMounted,
    onUpdated,
    provide,
    reactive,
    ref,
    toRefs,
} from "vue";

import moment from "moment";
import router from "../../router";
import { useFreskdeskStore } from "../../stores/freshdeskStore";
import { useEmployeesStore } from "../../stores/employeesStore";
import { useTicketsStore } from "../../stores/ticketsStore";
import { useHelperStore } from "../../stores/helperStore";
import { useUiStore } from "../../stores/uiStore";
import Litepicker from "../../base-components/Litepicker";
import TomSelect from "../../base-components/TomSelect";
import Button from "../../base-components/Button";
import { FormInput, FormLabel } from "../../base-components/Form";
import _ from "lodash";
import { NotificationElement } from "../../base-components/Notification";
import EmployeeInterface from "../interfaces/employeesInterface";
import ReadOnlyFieldTeamLeaders from "../components/ReadOnlyFieldTeamLeaders.vue";
import FieldApprover from "../components/FieldApprover.vue";

const toastData = reactive({
    message: "",
    subject: "",
    severity: "",
});

const AuthStore = useAuthStore();
const HelperStore = useHelperStore();
const UiStore = useUiStore();
const FreshdeskStore = useFreskdeskStore();
const route = useRoute();
const id = Array.isArray(route.query.id) ? route.query.id[0] : route.query.id;
const categories = ref(2);
const emit = defineEmits(["output", "ticketType"]);
const EmployeesStore = useEmployeesStore();
const TicketsStore = useTicketsStore();

const ticketForm = reactive({
    dispute: "Tax",
    payoutPeriod: "",
    concerns: "",
    concernDate: "",
    salaryAmount: "",
    claimNature: "",
    teamLeaders: "",
    approver: "",
});

const employee = reactive({
    data: null as EmployeeInterface | null,
});

const rules = {
    dispute: { required },
    payoutPeriod: { required },
    //name: { required },
    concerns: { required },
    concernDate: { required },
    salaryAmount: { required, numeric },
    claimNature: { required },
    approver: { required },
};

const validate = useVuelidate(rules, toRefs(ticketForm));

onUpdated(() => {
    const ticketType = HelperStore.getTicketType(Number(id ?? 0));
    UiStore.setBreadcrumb("Submit a Ticket > " + ticketType.name);
});
const formReset = () => {
    (ticketForm.dispute = "Tax"),
        (ticketForm.payoutPeriod = ""),
        (ticketForm.concerns = ""),
        (ticketForm.concernDate = ""),
        (ticketForm.salaryAmount = ""),
        (ticketForm.claimNature = ""),
        (ticketForm.approver = ""),
        (attachments.data = []);
};

const approverName = ref("");

onMounted(async () => {});

const setApprover = (event: any) => {
    ticketForm.approver = event;
};
const setApproverName = (event: any) => {
    console.log("setApproverName");
    console.log(event);
    approverName.value = event;
};
const setTeamLeaders = (event: any) => {
    ticketForm.teamLeaders = event;
};
const populateEmployeeDetails = () => {
    //@ts-ignore
    employee.data = EmployeesStore.getEmployee(
        //@ts-ignore
        parseInt(window.Laravel.user.id)
    );
    console.log("AU", employee.data);
};
const submitTicket = async () => {
    const currentDate = moment().format("MMMM Do YYYY, h:mm:ss a");

    validate.value.$touch();

    if (validate.value.$invalid) {
        console.error("missing data fields");
        toastData.severity = "danger";
        toastData.message = "All fields required!";
        toastData.subject = "Please provide all the required information";
        console.log("show error notifications");
    } else {
        const ticketType = HelperStore.getTicketType(Number(id ?? 0));

        const desc_data = new FormData();
        desc_data.append("Date Filed", currentDate);
        //@ts-ignore
        desc_data.append("Employee Name", window.Laravel.user.name);
        desc_data.append("Approver", approverName.value);
        desc_data.append("approver_id", ticketForm.approver);
        desc_data.append("Team Leaders", ticketForm.teamLeaders);
        desc_data.append("Concerned Payout Period", ticketForm.payoutPeriod);
        desc_data.append("Type of Dispute", ticketForm.dispute);
        desc_data.append("Other concerns", ticketForm.concerns);
        desc_data.append("Concern Date", ticketForm.concernDate);
        desc_data.append("Amount of Salary Dispute", ticketForm.salaryAmount);
        desc_data.append("Nature of the Claim", ticketForm.claimNature);
        desc_data.append("Requested By", AuthStore.authUser.name);

        var form_body = {};
        //@ts-ignore
        desc_data.forEach((value, key) => (form_body[key] = value));

        const form = new FormData();
        form.append("id", String(id));
        //@ts-ignore
        const userName = window.Laravel.user.name;
        form.append("subject", "Employee Payroll Disputes: " + userName);
        form.append("priority", ticketType?.f_priority_id?.toString() ?? "");
        form.append("status", ticketType?.f_status_id?.toString() ?? "");
        form.append("type", ticketType?.f_type?.toString() ?? "");
        form.append("form_body", JSON.stringify(form_body)); //important
        form.append("ticket_type_id", ticketType?.id?.toString() ?? ""); //important
        form.append("approver_id", ticketForm.approver);
        form.append("requested_by", AuthStore.authUser?.name);

        if (attachments.data !== null) {
            _.forEach(attachments.data, function (files) {
                _.forEach(files, function (file) {
                    form.append("attachments[]", file);
                });
            });
        }

        await TicketsStore.save(form).then((response) => {
            console.log("tix response", response);
            if (response.status === "success") {
                validate.value.$reset();
                emit("output", { render: true, type: "success" });
                if (id !== null) {
                    emit("ticketType", HelperStore.getTicketType(parseInt(id)));
                }
                showSuccessNotification();
                formReset();
                router.push({
                    name: "ticketDetails",
                    params: { id: response.data.id },
                });
            } else {
                emit("output", { render: true, type: "danger" });
            }
        });
    }
};

interface Attachments {
    data: File[][];
}

const attachments: Attachments = reactive({
    data: [[]],
});
const uploadFile = (e: Event) => {
    const files = (e.target as HTMLInputElement).files;
    if (files !== null) {
        attachments.data.push(Array.from(files));
    }
};

const successNotification = ref<NotificationElement>();
const successNotificationToggle = () => {
    // Show notification
    successNotification.value?.showToast();
};

provide("bind[successNotification]", (el: NotificationElement) => {
    successNotification.value = el;
});

const showSuccessNotification = () => {
    successNotificationToggle();
};
</script>
<template>
    <div class="intro-y box p-5">
        <ReadOnlyFieldTeamLeaders @item-value="setTeamLeaders($event)" />
        <FieldApprover
            @itemName="setApproverName($event)"
            @itemValue="setApprover($event)"
            :validate="validate"
        />
        <div class="mt-3">
            <FormLabel class="form-label" for="crud-form-1"
                >Concerned Payout Period
                <span style="color: rgb(255, 0, 0)">*</span></FormLabel
            >
            <Litepicker
                id="payoutPeriod"
                v-model="ticketForm.payoutPeriod"
                :options="{
                    autoApply: false,
                    singleMode: false,
                    numberOfColumns: 2,
                    numberOfMonths: 2,
                    showWeekNumbers: true,
                    dropdowns: {
                        minYear: 1990,
                        maxYear: null,
                        months: true,
                        years: true,
                    },
                }"
                class="form-control w-full"
                name="payoutPeriod"
            />
            <template v-if="validate.payoutPeriod.$error">
                <div
                    v-for="(error, index) in validate.payoutPeriod.$errors"
                    :key="index"
                    class="text-danger mt-2"
                >
                    {{ error.$message }}
                </div>
            </template>
        </div>
        <div class="mt-3">
            <FormLabel class="form-label" for="crud-form-2"
                >Type of Dispute
                <span style="color: rgb(255, 0, 0)">*</span></FormLabel
            >
            <TomSelect
                id="dispute"
                v-model="ticketForm.dispute"
                class="w-full"
                name="dispute"
            >
                <option value="Tax">Tax</option>
                <option value="Loan">Loan</option>
                <option value="Contribution">Contribution</option>
                <option value="Basic Pay">Basic Pay</option>
                <option value="Benefit/Incentive">Benefit/Incentive</option>
                <option value="Prior Period Adjustment">
                    Prior Period Adjustment
                </option>
                <option value="Overall Computation">Overall Computation</option>
                <option value="Reimburstment">Reimburstment</option>
            </TomSelect>
            <template v-if="validate.dispute.$error">
                <div
                    v-for="(error, index) in validate.dispute.$errors"
                    :key="index"
                    class="text-danger mt-2"
                >
                    {{ error.$message }}
                </div>
            </template>
        </div>
        <div class="mt-3">
            <FormLabel class="form-label" for="crud-form-1"
                >Other concerns
                <span style="color: rgb(255, 0, 0)">*</span></FormLabel
            >
            <FormInput
                id="concerns"
                v-model="ticketForm.concerns"
                class="form-control w-full"
                name="concerns"
                placeholder="Other concerns"
                type="text"
            />
            <template v-if="validate.concerns.$error">
                <div
                    v-for="(error, index) in validate.concerns.$errors"
                    id="error_concerns"
                    :key="index"
                    class="text-danger mt-2"
                >
                    {{ error.$message }}
                </div>
            </template>
        </div>
        <div class="mt-3">
            <FormLabel class="form-label" for="crud-form-1"
                >Concern Date
                <span style="color: rgb(255, 0, 0)">*</span></FormLabel
            >
            <Litepicker
                id="concernDate"
                v-model="ticketForm.concernDate"
                :options="{
                    autoApply: false,
                    showWeekNumbers: true,
                    dropdowns: {
                        minYear: 1990,
                        maxYear: null,
                        months: true,
                        years: true,
                    },
                }"
                :plugins="['multiselect']"
                class="form-control w-full"
                name="concernDate"
            />
            <template v-if="validate.concernDate.$error">
                <div
                    v-for="(error, index) in validate.concernDate.$errors"
                    :key="index"
                    class="text-danger mt-2"
                >
                    {{ error.$message }}
                </div>
            </template>
        </div>
        <div class="mt-3">
            <FormLabel class="form-label" for="crud-form-1"
                >Amount of Salary Dispute
                <span style="color: rgb(255, 0, 0)">*</span></FormLabel
            >
            <FormInput
                id="salaryAmount"
                v-model="ticketForm.salaryAmount"
                class="form-control w-full"
                name="salaryAmount"
                placeholder="Amount of Salary Dispute"
                type="number"
            />
            <template v-if="validate.salaryAmount.$error">
                <div
                    v-for="(error, index) in validate.salaryAmount.$errors"
                    id="error_salaryAmount"
                    :key="index"
                    class="text-danger mt-2"
                >
                    {{ error.$message }}
                </div>
            </template>
        </div>
        <div class="mt-3">
            <FormLabel class="form-label" for="crud-form-1"
                >Nature of the Claim
                <span style="color: rgb(255, 0, 0)">*</span></FormLabel
            >
            <FormInput
                id="claimNature"
                v-model="ticketForm.claimNature"
                class="form-control w-full"
                name="claimNature"
                placeholder="Nature of the Claim"
                type="text"
            />
            <template v-if="validate.claimNature.$error">
                <div
                    v-for="(error, index) in validate.claimNature.$errors"
                    id="error_claimNature"
                    :key="index"
                    class="text-danger mt-2"
                >
                    {{ error.$message }}
                </div>
            </template>
        </div>
        <div class="mt-3">
            <FormLabel class="form-label" for="crud-form-1"
                >Attach multiple payslips
            </FormLabel>
            <div class="mt-1">
                <input
                    ref="file"
                    :multiple="true"
                    class="font-ubuntu text-custom-color-800"
                    type="file"
                    @change="uploadFile"
                />
            </div>
        </div>
        <div class="mt-3">
            <FormLabel class="form-label" for="crud-form-1"
                >Attach multiple proof of claim
            </FormLabel>
            <div class="mt-1">
                <input
                    ref="file"
                    :multiple="true"
                    class="font-ubuntu text-custom-color-800"
                    type="file"
                    @change="uploadFile"
                />
            </div>
        </div>

        <div class="text-right mt-5">
            <router-link
                class="btn text-white bg-warning py-2 rounded-md w-28 text-center"
                to="/submit-a-ticket"
                type="button"
            >
                Cancel
            </router-link>
            <Button
                class="ml-2"
                type="button"
                variant="customPrimary"
                @click="submitTicket"
                >Submit Ticket
            </Button>
        </div>
    </div>
</template>
