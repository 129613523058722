<script lang="ts" setup>
import { computed, onMounted, ref } from "vue";
import { FilterMatchMode, FilterOperator } from "primevue/api";
import { InvoiceInterface } from "./interfaces/invoiceInterface";
import { useInvoiceStore } from "../stores/invoiceStore";
import { useAuthStore } from "../stores/authStore";
import LazyParamsInterface from "./interfaces/lazyParamsInterface";
import Button from "../base-components/Button";
import Column from "primevue/column";
import DataTable from "primevue/datatable";
import FormSlider from "./FormSlider.vue";
import ConfirmModal from "./components/ConfirmModal.vue";
import PageHeaderStandard from "./components/PageHeaderStandard.vue";
import Lucide from "../base-components/Lucide";
import { FormInput } from "../base-components/Form";
import FormInvoice from "./forms/invoice/FormInvoice.vue";
import dayjs from "dayjs";
import InputText from "primevue/inputtext";
import ImportInvoiceLineItem from "./components/invoices/ImportInvoiceLineItem.vue";
import Dropdown from "primevue/dropdown";
import { useClientsStore } from "../stores/clientsStore";
import useHelper from "@/composables/helper";
import { useEmployeesStore } from "@/stores/employeesStore";
import { useUserStore } from "@/stores/userStore";

enum BillType {
    adjustment = "Adjustment Only",
    monthly = "Monthly Cycle",
    combined = "Monthy Cycle w/ Adjustment",
    manual = "Manual",
}

const InvoiceStore = useInvoiceStore();
const AuthStore = useAuthStore();
const ClientStore = useClientsStore();
const UserStore = useUserStore();
const helper = useHelper();

const slider = ref(false);
const deleteId = ref(0);
const deleted = ref();
const showConfirmDialog = ref(false);
const lazyParams = ref<LazyParamsInterface>({});
const filters = ref({
    global: { value: null, matchMode: FilterMatchMode.CONTAINS },
    "client.legal_business_name": {
        operator: FilterOperator.AND,
        constraints: [{ value: null, matchMode: FilterMatchMode.STARTS_WITH }],
    },
    "user.name": {
        operator: FilterOperator.AND,
        constraints: [{ value: null, matchMode: FilterMatchMode.STARTS_WITH }],
    },
    "invoice_status.name": {
        operator: FilterOperator.AND,
        constraints: [{ value: null, matchMode: FilterMatchMode.EQUALS }],
    },
    "clientInvoiceConfig.bill_type": {
        operator: FilterOperator.AND,
        constraints: [{ value: null, matchMode: FilterMatchMode.EQUALS }],
    },
    id: {
        operator: FilterOperator.AND,
        constraints: [{ value: null, matchMode: FilterMatchMode.STARTS_WITH }],
    },
    "reviewer.name": {
        operator: FilterOperator.AND,
        constraints: [{ value: null, matchMode: FilterMatchMode.STARTS_WITH }],
    },
});
const searchableCols = ref([
    "client.legal_business_name",
    "user.name",
    "invoice_status.name",
    "id",
]);
const loading = ref(false);
const generating = ref(false);
const showClearFilter = ref(false);
const dt = ref();
const invoice = ref<InvoiceInterface>();
const expandedRows = ref([]);

const onClickButton = (data: any) => {
    slider.value = !slider.value;

    if (
        data &&
        Object.keys(data).length > 0 &&
        !(data instanceof PointerEvent)
    ) {
        invoice.value = data;
    } else {
        invoice.value = {} as InvoiceInterface;
    }
};

const onClickGenerate = async () => {
    generating.value = true;
    const params = {};

    try {
        await InvoiceStore.generateInvoice(params);
        await onDataFetch();
    } catch (error) {
        console.log(error);
    } finally {
        generating.value = false;
    }
};

const onCloseSlider = (fetchInvoices: boolean = false) => {
    slider.value = false;

    invoice.value = {} as InvoiceInterface;

    if (fetchInvoices) {
        onDataFetch();
    }
};

const close = async () => {
    slider.value = !slider.value;

    await onDataFetch();
};

const onFilter = async (event: any) => {
    showClearFilter.value = filters.value.global.value !== "";

    lazyParams.value.filters = filters.value;

    await onDataFetch();
};

const onSort = async (event: any) => {
    1;
    showClearFilter.value = true;
    lazyParams.value = event;

    await onDataFetch();
};

const onPage = async (event: any) => {
    lazyParams.value = event;

    await onDataFetch();
};

const onRowCollapse = () => {
    console.log("onRowSelect");
};

const onRowExpand = () => {
    console.log("onRowExpand");
};

const confirmDelete = (event: { preventDefault: () => void }, data: any) => {
    event.preventDefault();
    deleteId.value = data.id;
    setConfirmDialog(true);
};

const setConfirmDialog = (value: boolean) => {
    showConfirmDialog.value = value;
};

const processDelete = (event: any) => {
    if (event === false) {
        deleteId.value = 0;
    } else {
        InvoiceStore.deleteInvoice(deleteId.value)
            .then(() => {
                deleteId.value = 0;
                deleted.value = true;
            })
            .catch((error) => {
                deleted.value = false;
            });
    }
};

const onDataFetch = async () => {
    loading.value = true;

    const params = {
        dt_params: JSON.stringify(lazyParams.value),
        searchable_columns: JSON.stringify(searchableCols.value),
    };

    await InvoiceStore.getInvoices(params);

    loading.value = false;
};

const resetGlobalFilter = () => {
    showClearFilter.value = false;

    filters.value = {
        global: { value: null, matchMode: FilterMatchMode.CONTAINS },
        "client.legal_business_name": {
            operator: FilterOperator.AND,
            constraints: [
                { value: null, matchMode: FilterMatchMode.STARTS_WITH },
            ],
        },
        "user.name": {
            operator: FilterOperator.AND,
            constraints: [
                { value: null, matchMode: FilterMatchMode.STARTS_WITH },
            ],
        },
        "invoice_status.name": {
            operator: FilterOperator.AND,
            constraints: [
                { value: null, matchMode: FilterMatchMode.STARTS_WITH },
            ],
        },
        "clientInvoiceConfig.bill_type": {
            operator: FilterOperator.AND,
            constraints: [{ value: null, matchMode: FilterMatchMode.EQUALS }],
        },
        id: {
            operator: FilterOperator.AND,
            constraints: [
                { value: null, matchMode: FilterMatchMode.STARTS_WITH },
            ],
        },
        "reviewer.name": {
            operator: FilterOperator.AND,
            constraints: [
                { value: null, matchMode: FilterMatchMode.STARTS_WITH },
            ],
        },
    };

    lazyParams.value = {
        first: 0,
        rows: dt.value.rows,
        sortField: null,
        sortOrder: null,
        filters: filters.value,
    };

    onDataFetch();
};

const billTypeOptions = computed(() => {
    return Object.keys(BillType).map((key) => ({
        label: BillType[key as keyof typeof BillType],
        value: key,
    }));
});

const isLocal = computed(() => import.meta.env.VITE_APP_ENV === "local");
const regenerating = ref(false);
const selectedInvoice = ref<InvoiceInterface>();
const handleRegenerate = async (data: InvoiceInterface) => {
    selectedInvoice.value = data;

    if (regenerating.value) {
        return;
    }

    regenerating.value = true;

    const params = {
        client_id: data.client_id,
        type: data?.bill_period,
        regenerate: true,
    };

    await InvoiceStore.generateInvoice(params);
    await onDataFetch();

    selectedInvoice.value = {} as InvoiceInterface;
    regenerating.value = false;
};

const handleFetchUsers = async () => {
    const params = new URLSearchParams({
        "filter[permission]": "can view invoices,can create invoices",
        "fields[users]": "id,name",
    });

    await UserStore.getUsers(params);
};

onMounted(async () => {
    await onDataFetch();
    await AuthStore.getLoggedInUserDetails();
    await InvoiceStore.getInvoiceStatuses();
    await handleFetchUsers();

    if (ClientStore.clientsForDropdown.length === 0) {
        await ClientStore.getClientsForDropdown();
    }
});
</script>

<template>
    <div class="w-full">
        <PageHeaderStandard title="Shifts" />

        <div
            class="intro-y col-span-12 flex flex-wrap sm:flex-nowrap items-center mt-2"
        >
            <Button
                v-if="AuthStore.hasPermission('can create invoices')"
                id="add-invoices"
                class="mr-2 shadow-sm"
                variant="customPrimary"
                @click="onClickButton"
            >
                New Invoice
            </Button>

            <ImportInvoiceLineItem />

            <Button
                v-if="isLocal"
                id="generate-invoice"
                class="mr-2 shadow-sm"
                variant="customPrimary"
                :disabled="generating"
                @click="onClickGenerate()"
            >
                <div v-if="generating" class="flex items-center">
                    <svg
                        aria-hidden="true"
                        class="w-4 h-4 mr-2 text-gray-200 animate-spin dark:text-gray-600 fill-blue-600"
                        fill="none"
                        viewBox="0 0 100 101"
                        xmlns="http://www.w3.org/2000/svg"
                    >
                        <path
                            d="M100 50.5908C100 78.2051 77.6142 100.591 50 100.591C22.3858 100.591 0 78.2051 0 50.5908C0 22.9766 22.3858 0.59082 50 0.59082C77.6142 0.59082 100 22.9766 100 50.5908ZM9.08144 50.5908C9.08144 73.1895 27.4013 91.5094 50 91.5094C72.5987 91.5094 90.9186 73.1895 90.9186 50.5908C90.9186 27.9921 72.5987 9.67226 50 9.67226C27.4013 9.67226 9.08144 27.9921 9.08144 50.5908Z"
                            fill="currentColor"
                        />
                        <path
                            d="M93.9676 39.0409C96.393 38.4038 97.8624 35.9116 97.0079 33.5539C95.2932 28.8227 92.871 24.3692 89.8167 20.348C85.8452 15.1192 80.8826 10.7238 75.2124 7.41289C69.5422 4.10194 63.2754 1.94025 56.7698 1.05124C51.7666 0.367541 46.6976 0.446843 41.7345 1.27873C39.2613 1.69328 37.813 4.19778 38.4501 6.62326C39.0873 9.04874 41.5694 10.4717 44.0505 10.1071C47.8511 9.54855 51.7191 9.52689 55.5402 10.0491C60.8642 10.7766 65.9928 12.5457 70.6331 15.2552C75.2735 17.9648 79.3347 21.5619 82.5849 25.841C84.9175 28.9121 86.7997 32.2913 88.1811 35.8758C89.083 38.2158 91.5421 39.6781 93.9676 39.0409Z"
                            fill="currentFill"
                        />
                    </svg>

                    <div>Generating invoice...</div>
                </div>

                <div v-else>Generate Invoice</div>
            </Button>

            <div class="hidden md:block mx-auto text-slate-500"></div>

            <div class="flex w-full sm:w-auto mt-3 sm:mt-0 sm:ml-auto md:ml-0">
                <template v-if="showClearFilter">
                    <button
                        class="btn bg-custom-color-1000 text-white w-full mx-3 rounded-md px-4"
                        @click="resetGlobalFilter"
                    >
                        Clear
                        <span
                            class="h-3 w-4 pi pi-filter-slash p-component"
                        ></span>
                    </button>
                </template>

                <div class="ml-2 w-56 relative text-slate-500">
                    <FormInput
                        id="global-filter"
                        v-model="filters.global.value"
                        :value="filters.global.value"
                        class="w-56 pr-10 !box"
                        placeholder="Search..."
                        type="text"
                        @keyup.enter="onFilter($event)"
                    />

                    <Lucide
                        class="absolute inset-y-0 right-0 w-4 h-4 my-auto mr-3"
                        icon="Search"
                    />
                </div>
            </div>
        </div>

        <DataTable
            ref="dt"
            v-model:expanded-rows="expandedRows"
            v-model:filters="filters"
            :globalFilterFields="searchableCols"
            :lazy="true"
            :loading="loading"
            :paginator="true"
            :rows="10"
            :totalRecords="InvoiceStore?.invoices?.data?.total"
            :value="InvoiceStore?.invoices?.data?.data"
            class="mt-4 p-datatable-sm text-xs"
            filterDisplay="menu"
            responsiveLayout="scroll"
            showGridlines
            stripedRows
            @filter="onFilter($event)"
            @page="onPage($event)"
            @sort="onSort($event)"
            @row-collapse="onRowCollapse"
            @row-expand="onRowExpand"
        >
            <template #empty> No available data.</template>

            <template #loading> Loading data. Please wait.</template>

            <Column :expander="true" class="!min-w-0 w-0.5" />

            <Column
                :sortable="true"
                field="client.legal_business_name"
                header="Client"
            >
                <template #filter="{ filterModel }">
                    <InputText
                        v-model="filterModel.value"
                        class="p-column-filter"
                        placeholder="Search by name"
                        type="text"
                    />
                </template>
            </Column>

            <Column :sortable="true" field="id" header="Invoice #">
                <template #filter="{ filterModel }">
                    <InputText
                        v-model="filterModel.value"
                        class="p-column-filter"
                        placeholder="Search by invoice #"
                        type="text"
                    />
                </template>
            </Column>

            <Column
                :sortable="true"
                field="clientInvoiceConfig.bill_type"
                header="Bill Type"
            >
                <template #body="{ data }">
                    {{
                        BillType[
                            data.client_invoice_config
                                ?.bill_type as keyof typeof BillType
                        ]
                    }}
                </template>
                <template #filter="{ filterModel }">
                    <Dropdown
                        v-model="filterModel.value"
                        :options="billTypeOptions"
                        option-label="label"
                        option-value="value"
                        placeholder="Select Bill Type"
                    />
                </template>
            </Column>

            <Column
                :sortable="true"
                field="invoice_status.name"
                header="Status"
            >
                <template #filter="{ filterModel }">
                    <Dropdown
                        v-model="filterModel.value"
                        :options="InvoiceStore.invoiceStatuses"
                        option-label="name"
                        option-value="name"
                        placeholder="Select Status"
                    />
                </template>
            </Column>

            <Column :sortable="true" field="reviewer.name" header="Assigned">
                <template #filter="{ filterModel }">
                    <Dropdown
                        v-model="filterModel.value"
                        :options="UserStore.userList"
                        option-label="name"
                        option-value="name"
                        placeholder="Select Assignee"
                    />
                </template>
            </Column>

            <Column :sortable="true" field="start_date" header="Start Date">
                <template #body="{ data }">
                    {{ dayjs(data.start_date).format("MM/DD/YYYY") }}
                </template>
            </Column>

            <Column :sortable="true" field="end_date" header="End Date">
                <template #body="{ data }">
                    {{ dayjs(data.end_date).format("MM/DD/YYYY") }}
                </template>
            </Column>

            <Column :sortable="true" class="!text-right" header="Total Amount">
                <template #body="{ data }">
                    {{ data.currency?.symbol ?? "" }}
                    {{ helper.currencyFormat(data.total_amount) }}
                </template>
            </Column>

            <Column :sortable="true" field="sent_at" header="Sent At">
                <template #body="{ data }">
                    {{
                        data.sent_at
                            ? dayjs(data.sent_at).format("MM/DD/YYYY")
                            : ""
                    }}
                </template>
            </Column>

            <Column class="!w-[5%]">
                <template #body="{ data }">
                    <div class="flex justify-center items-center">
                        <div
                            v-if="
                                AuthStore.hasPermission(
                                    'can regenerate invoice'
                                )
                            "
                            class="cursor-pointer"
                            @click="handleRegenerate(data)"
                        >
                            <Lucide
                                icon="RotateCw"
                                class="h-4 w-4 font-bold"
                                :class="{
                                    'animate-spin cursor-not-allowed':
                                        regenerating &&
                                        selectedInvoice?.id === data.id,
                                }"
                            />
                        </div>

                        <div class="ml-2">
                            <router-link
                                :to="{
                                    name: 'invoice-details',
                                    params: { id: data.id },
                                }"
                            >
                                <svg
                                    :id="'view_' + data.id"
                                    class="icon icon-tabler icon-tabler-eye cursor-pointer"
                                    fill="none"
                                    height="18"
                                    stroke="currentColor"
                                    stroke-linecap="round"
                                    stroke-linejoin="round"
                                    stroke-width="2"
                                    viewBox="0 0 24 24"
                                    width="18"
                                    xmlns="http://www.w3.org/2000/svg"
                                >
                                    <path
                                        d="M0 0h24v24H0z"
                                        fill="none"
                                        stroke="none"
                                    ></path>
                                    <path
                                        d="M12 12m-2 0a2 2 0 1 0 4 0a2 2 0 1 0 -4 0"
                                    ></path>
                                    <path
                                        d="M22 12c-2.667 4.667 -6 7 -10 7s-7.333 -2.333 -10 -7c2.667 -4.667 6 -7 10 -7s7.333 2.333 10 7"
                                    ></path>
                                </svg>
                            </router-link>
                        </div>

                        <div
                            v-if="
                                AuthStore.hasPermission('can update invoices')
                            "
                            class="ml-2"
                        >
                            <svg
                                :id="'update_' + data.id"
                                class="icon icon-tabler icon-tabler-edit cursor-pointer"
                                fill="none"
                                height="18"
                                stroke="currentColor"
                                stroke-linecap="round"
                                stroke-linejoin="round"
                                stroke-width="2"
                                viewBox="0 0 24 24"
                                width="18"
                                xmlns="http://www.w3.org/2000/svg"
                                @click="onClickButton(data)"
                            >
                                <path
                                    d="M0 0h24v24H0z"
                                    fill="none"
                                    stroke="none"
                                ></path>
                                <path
                                    d="M7 7h-1a2 2 0 0 0 -2 2v9a2 2 0 0 0 2 2h9a2 2 0 0 0 2 -2v-1"
                                ></path>
                                <path
                                    d="M20.385 6.585a2.1 2.1 0 0 0 -2.97 -2.97l-8.415 8.385v3h3l8.385 -8.415z"
                                ></path>
                                <path d="M16 5l3 3"></path>
                            </svg>
                        </div>

                        <div
                            v-if="
                                AuthStore.hasPermission('can delete invoices')
                            "
                            class="ml-2"
                        >
                            <svg
                                :id="'delete_' + data.id"
                                class="icon icon-tabler icon-tabler-trash cursor-pointer"
                                fill="none"
                                height="18"
                                stroke="currentColor"
                                stroke-linecap="round"
                                stroke-linejoin="round"
                                stroke-width="2"
                                viewBox="0 0 24 24"
                                width="18"
                                xmlns="http://www.w3.org/2000/svg"
                                @click="confirmDelete($event, data)"
                            >
                                <path
                                    d="M0 0h24v24H0z"
                                    fill="none"
                                    stroke="none"
                                ></path>
                                <path d="M4 7l16 0"></path>
                                <path d="M10 11l0 6"></path>
                                <path d="M14 11l0 6"></path>
                                <path
                                    d="M5 7l1 12a2 2 0 0 0 2 2h8a2 2 0 0 0 2 -2l1 -12"
                                ></path>
                                <path
                                    d="M9 7v-3a1 1 0 0 1 1 -1h4a1 1 0 0 1 1 1v3"
                                ></path>
                            </svg>
                        </div>
                    </div>
                </template>
            </Column>

            <template #expansion="slotProps">
                <h5 class="text-md">
                    <b class="text-md">Items</b>
                </h5>

                <DataTable
                    :value="slotProps.data.items"
                    class="mt-2 text-xs"
                    responsiveLayout="scroll"
                >
                    <Column field="employee.name" header="Employee" sortable />
                    <Column field="description" header="Description" sortable />
                    <Column
                        class="!text-center"
                        field="quantity"
                        header="Quantity"
                        sortable
                    >
                    </Column>
                    <Column
                        class="!text-right"
                        field="unit_price"
                        header="Unit Price"
                        sortable
                    >
                    </Column>
                    <Column
                        class="!text-right"
                        field="line_item_total"
                        header="Total"
                    >
                    </Column>
                </DataTable>
            </template>
        </DataTable>
    </div>

    <FormSlider
        :isOpen="slider"
        header="Invoice Form"
        size="xl"
        @close="onCloseSlider"
    >
        <FormInvoice :invoice="invoice" @close="onCloseSlider($event)" />
    </FormSlider>

    <ConfirmModal
        :deleted="deleted"
        :displayConfirmModal="showConfirmDialog"
        second-description="Invoice has been successfully deleted."
        @closeConfirmModal="setConfirmDialog(false)"
        @deleted="deleted = $event"
        @proceedProcess="processDelete($event)"
    />
</template>

<style scoped></style>
