<script lang="ts" setup>
import { FormInput } from "../../../base-components/Form";
import { useShiftStore } from "../../../stores/shiftStore";
import Button from "../../../base-components/Button";
import FormLabel from "../../../base-components/Form/FormLabel.vue";
import Lucide from "../../../base-components/Lucide";
import { computed, PropType } from "vue";
import { EmployeeAttendanceBreakInterface } from "../../interfaces/employeeAttendanceInterface";
import moment from "moment-timezone";
import TomSelect from "../../../base-components/TomSelect";
import { ShiftBreakInterface } from "../../interfaces/shiftInterface";

const ShiftStore = useShiftStore();

const props = defineProps({
    errors: {
        type: Object,
        default: () => ({}),
    },
    shiftBreaks: {
        type: Array as PropType<Array<ShiftBreakInterface>>,
        default: () => [] as ShiftBreakInterface[],
    },
    breaks: {
        type: Array as PropType<Array<EmployeeAttendanceBreakInterface>>,
        default: () => [] as EmployeeAttendanceBreakInterface[],
    },
    validate: {
        type: Object,
        default: () => ({}),
    },
});

const availableBreaks = computed(() => {
    return props.shiftBreaks.filter(
        (shiftBreak) =>
            !props.breaks.some(
                (breakItem: any) =>
                    breakItem.friendly_name === shiftBreak.friendly_name
            )
    );
});

const addShiftBreak = () => {
    if (availableBreaks.value.length === 0) return;

    props.breaks.push({
        friendly_name: "",
        shift_break_id: "",
        start_time: "",
        end_time: "",
    });
};

const onClickTrash = (item: any) => {
    const index = props.breaks.indexOf(item);

    if (props.breaks.length > -1) {
        props.breaks.splice(index, 1);
    }
};

const onChangeShiftBreak = (item: any, event: any) => {
    item.start_time = "";
    item.end_time = "";
    item.total_hours = 0;
    item.shift_break_id = event;
};
</script>

<template>
    <div
        v-if="props.errors.breaks"
        class="p-3 mb-4 rounded-md font-bold bg-red-200 text-red-600"
    >
        {{ props.errors.breaks[0] }}
    </div>

    <div class="flex justify-between align-center">
        <h4 class="font-bold">Breaks</h4>

        <Button
            type="button"
            @click="addShiftBreak"
            :disabled="
                availableBreaks.length === 0 || props.breaks.length === 3
            "
            >Add</Button
        >
    </div>

    <div v-for="(item, index) in breaks" :key="index">
        <div class="mt-4">
            <div class="flex justify-between align-center">
                <FormLabel class="form-label">Break Type</FormLabel>

                <div>
                    <Lucide
                        class="w-4 h-4 text-red-500 hover:text-red-800 cursor-pointer"
                        icon="Trash"
                        @click="onClickTrash(item)"
                    />
                </div>
            </div>

            <div>
                <TomSelect
                    :id="`shift_break_id_${index}`"
                    v-model="item.shift_break_id"
                    :value="item.shift_break_id"
                    class="w-full p-0"
                    name="shift_break_id"
                >
                    <option value="">Select Break Type</option>
                    <option
                        v-for="breakType of props.shiftBreaks"
                        :value="String(breakType.id)"
                    >
                        {{ breakType.friendly_name }}
                    </option>
                </TomSelect>
            </div>
            <template v-if="props.errors[`breaks.${index}.shift_break_id`]">
                <div
                    v-for="(error, errorIndex) in props.errors[
                        `breaks.${index}.shift_break_id`
                    ]"
                    :key="errorIndex"
                    class="text-danger mt-2"
                >
                    {{ error }}
                </div>
            </template>
        </div>

        <div class="grid grid-cols-12 gap-6">
            <div class="mt-4 col-span-12 lg:col-span-6">
                <FormLabel class="form-label" for="start_time"
                    >Start Time
                </FormLabel>
                <FormInput
                    :id="`break_start_time_${index}`"
                    v-model="item.start_time"
                    :value="item.start_time"
                    class="form-control w-full"
                    maxlength="50"
                    name="start_time"
                    placeholder=""
                    type="datetime-local"
                />
                <template v-if="props.errors[`breaks.${index}.start_time`]">
                    <div
                        v-for="(error, errorIndex) in props.errors[
                            `breaks.${index}.start_time`
                        ]"
                        :key="errorIndex"
                        class="text-danger mt-2"
                    >
                        {{ error }}
                    </div>
                </template>
            </div>

            <div class="mt-4 col-span-12 lg:col-span-6">
                <FormLabel class="form-label" for="end_time"
                    >End Time
                </FormLabel>
                <FormInput
                    :id="`break_end_time_${index}`"
                    v-model="item.end_time"
                    :value="item.end_time"
                    class="form-control w-full"
                    maxlength="50"
                    name="end_time"
                    placeholder=""
                    type="datetime-local"
                />
                <template v-if="props.errors[`breaks.${index}.end_time`]">
                    <div
                        v-for="(error, errorIndex) in props.errors[
                            `breaks.${index}.end_time`
                        ]"
                        :key="errorIndex"
                        class="text-danger mt-2"
                    >
                        {{ error }}
                    </div>
                </template>
            </div>
        </div>
    </div>
</template>
