import Dinero from "dinero.js";

export default function useHelper() {
    const currencyFormat = (value: number, format: string = "0,0.00") => {
        if (!value) return Dinero({ amount: 0 }).toFormat(format);

        value = Math.round(value * 100);

        return Dinero({ amount: value }).toFormat(format);
    };

    return {
        currencyFormat,
    };
}
