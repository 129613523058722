import { defineStore } from "pinia";
import Http from "../services/Http";
import { EmployeeReFeeInterface } from "../pages/interfaces/employeesInterface";
import ApiResponseInterface from "../pages/interfaces/apiResponseInterface";

export const useEmployeeReFeeStore = defineStore("EmployeeReFeeStore", {
    state: (): {
        employeeReFees: EmployeeReFeeInterface[];
        employeeReFee: EmployeeReFeeInterface;
    } => {
        return {
            employeeReFees: [] as EmployeeReFeeInterface[],
            employeeReFee: {} as EmployeeReFeeInterface,
        };
    },

    actions: {
        async getEmployeeReFees(employeeId: number): Promise<void> {
            const baseUrl = import.meta.env.VITE_API_URL;

            const { data } = await new Http(baseUrl).get<
                ApiResponseInterface<EmployeeReFeeInterface[]>
            >(`/employee-re-fees?employee_id=${employeeId}`);

            this.employeeReFees = data;
        },

        async getEmployeeReFee(id: number): Promise<void> {
            const baseUrl = import.meta.env.VITE_API_URL;

            const { data } = await new Http(baseUrl).get<
                ApiResponseInterface<EmployeeReFeeInterface>
            >(`/employee-re-fees/${id}`);

            this.employeeReFee = data;
        },

        async addEmployeeReFee(payload: EmployeeReFeeInterface): Promise<void> {
            const baseUrl = import.meta.env.VITE_API_URL;

            const { data } = await new Http(baseUrl).post<
                ApiResponseInterface<EmployeeReFeeInterface>
            >("/employee-re-fees", payload);

            this.employeeReFees.unshift(data);
        },

        async updateEmployeeReFee(
            payload: EmployeeReFeeInterface
        ): Promise<void> {
            const baseUrl = import.meta.env.VITE_API_URL;

            const { data } = await new Http(baseUrl).put<
                ApiResponseInterface<EmployeeReFeeInterface>
            >(`/employee-re-fees/${payload.id}`, payload);

            const index = this.employeeReFees.findIndex(
                (item) => item.id === data.id
            );

            console.log(data);

            this.employeeReFees[index] = data;
        },

        async deleteEmployeeReFee(id: number): Promise<void> {
            const baseUrl = import.meta.env.VITE_API_URL;

            await new Http(baseUrl).delete(`/employee-re-fees/${id}`);

            const index = this.employeeReFees.findIndex(
                (item) => item.id === id
            );

            this.employeeReFees.splice(index, 1);
        },
    },
});
