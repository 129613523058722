<script lang="ts" setup>
// import Lucide from "@/base-components/Lucide/Lucide.vue";
import Button from "@/base-components/Button";
import { useAuthStore } from "@/stores/authStore";
import { onMounted, PropType, ref } from "vue";
import FormSlider from "@/pages/FormSlider.vue";
import FormEmployeeCost from "@/pages/forms/employees/FormEmployeeCost.vue";
import EmployeeInterface, {
    EmployeeReFeeInterface,
} from "@/pages/interfaces/employeesInterface";
import { useEmployeeReFeeStore } from "@/stores/employeeReFeeStore";
import { useRoute } from "vue-router";
import _ from "lodash";
import moment from "moment";
import CustomIcon from "@/pages/Icons/CustomIcon.vue";
import { Menu } from "@/base-components/Headless";
import FormEmployeeReFee from "@/pages/forms/employees/FormEmployeeReFee.vue";
import CardSkeleton from "@/pages/components/CardSkeleton.vue";
import useHelper from "@/composables/helper";

const loading = ref(false);
const AuthStore = useAuthStore();
const slider = ref(false);
const selected = ref<EmployeeReFeeInterface>();
const EmployeeReFeeStore = useEmployeeReFeeStore();
const route = useRoute();
const helper = useHelper();

const { employee } = defineProps({
    employee: {
        type: Object as PropType<EmployeeInterface>,
        required: true,
    },
});

onMounted(async () => {
    loading.value = true;
    await EmployeeReFeeStore.getEmployeeReFees(+route.params.id);
    loading.value = false;
});

const onClickUpdate = (item: EmployeeReFeeInterface) => {
    selected.value = item;
    slider.value = !slider.value;
};

const onClose = () => {
    slider.value = !slider.value;
    selected.value = {} as EmployeeReFeeInterface;
};
</script>
<template>
    <CardSkeleton v-if="loading" />

    <div v-else class="intro-y box mt-5 lg:mt-0 mb-5">
        <div class="relative flex items-center py-3 px-4">
            <h2 class="font-medium text-base mr-auto">RE Fees History</h2>

            <Button
                v-if="AuthStore.hasPermission('can create employee re fee')"
                id="addSalary"
                class="text-xs"
                variant="soft-secondary"
                @click="slider = !slider"
            >
                Add New
            </Button>
        </div>
        <div
            v-if="_.isArray(EmployeeReFeeStore.employeeReFees)"
            class="p-2 border-t border-slate-200/60 dark:border-darkmode-400"
        >
            <ul
                class="rounded-md divide-y divide-gray-200 mb-2 shadow"
                role="list"
            >
                <li
                    v-for="(item, index) in EmployeeReFeeStore.employeeReFees"
                    :key="item.id"
                    class="pl-3 pr-4 py-3 flex items-center justify-between text-sm relative"
                >
                    <div class="ml-2 flex-1 w-0 truncate">
                        <span
                            v-if="employee.employee_current_re_fee?.id === item.id"
                            class="absolute top-1 left-1 inline-flex gap-2 items-center bg-green-100 text-green-800 text-xs font-medium px-2.5 py-0.5 rounded-full"
                        >
                            <span class="w-2 h-2 bg-green-500 rounded-full"></span>
                            <span>Active</span>
                        </span>
                        <div
                            class="mt-5 flex justify-between text-gray-500 w-2/4"
                        >
                            <div>Amount</div>
                            <div class="font-bold">
                                <!-- {{ employee.client?.currency?.symbol ?? "USD" }} -->
                                {{ helper.currencyFormat(item.amount) }}
                            </div>
                        </div>

                        <div
                            class="mt-5 flex justify-between text-gray-500 w-2/4"
                        >
                            <div>Effective From:</div>
                            <div>
                                {{
                                    moment(item.effective_from).format(
                                        "YYYY-MM-DD"
                                    )
                                }}
                            </div>
                        </div>

                        <div class="flex justify-between text-gray-500 w-2/4">
                            <div>Effective To:</div>
                            <div>
                                {{
                                    item.effective_to
                                        ? moment(item.effective_to).format(
                                              "YYYY-MM-DD"
                                          )
                                        : "-"
                                }}
                            </div>
                        </div>
                    </div>
                    <!--
                        <Menu :id="`menu_${index}`">
                            <Menu.Button :as="Button" variant="soft-secondary">
                                <CustomIcon
                                    :height="18"
                                    :icon="'CircleDots'"
                                    :width="18"
                                />
                            </Menu.Button>
                            <Menu.Items class="w-40">
                                <Menu.Item
                                    :id="`update_${index}`"
                                    @click="onClickUpdate(item)"
                                    >Update
                                </Menu.Item>
                            </Menu.Items>
                        </Menu>
                    -->
                </li>
            </ul>
        </div>
        <div
            v-if="EmployeeReFeeStore.employeeReFees.length === 0"
            class="px-4 py-6 sm:px-6"
        >
            No RE fees history yet.
        </div>
    </div>
    <FormSlider
        :is-open="slider"
        header="Employee RE Fee Form"
        size="md"
        @close="onClose"
    >
        <FormEmployeeReFee
            :employee="employee"
            :data="selected"
            @close="onClose"
        />
    </FormSlider>
</template>
