<script lang="ts" setup>
import Lucide from "../../../base-components/Lucide/Lucide.vue";
import Button from "../../../base-components/Button";
import { useAuthStore } from "../../../stores/authStore";
import { onMounted, PropType, ref } from "vue";
import FormSlider from "../../FormSlider.vue";
import EmployeeInterface, {
    EmployeeClientAssignmentInterface,
} from "../../interfaces/employeesInterface";
import { useEmployeeClientAssignmentStore } from "../../../stores/employeeClientAssignmentStore";
import { useRoute } from "vue-router";
import _ from "lodash";
import moment from "moment";
import CustomIcon from "../../Icons/CustomIcon.vue";
import { Menu } from "../../../base-components/Headless";
import CardSkeleton from "../CardSkeleton.vue";
import FormEmployeeClientAssignment from "../../forms/employees/FormEmployeeClientAssignment.vue";
import ConfirmModal from "../ConfirmModal.vue";

const { employee } = defineProps({
    employee: {
        type: Object as PropType<EmployeeInterface>,
        required: true,
    },
});

const loading = ref(false);
const AuthStore = useAuthStore();
const slider = ref(false);
const selected = ref<EmployeeClientAssignmentInterface>();
const EmployeeClientAssignment = useEmployeeClientAssignmentStore();
const route = useRoute();
const dialog = ref(false);
const clientAttachmentId = ref<number | null>(null);

onMounted(async () => {
    loading.value = true;
    await EmployeeClientAssignment.getEmployeeClientAssignments(
        +route.params.id
    );
    loading.value = false;
});

const onClickUpdate = (item: EmployeeClientAssignmentInterface) => {
    selected.value = item;
    slider.value = !slider.value;
};

const onClickDelete = (item: EmployeeClientAssignmentInterface) => {
    dialog.value = !dialog.value;

    clientAttachmentId.value = Number(item.id);
};

const onClickConfirm = async (event: any) => {
    if (event === null) {
        return;
    }

    if (event === false) {
        clientAttachmentId.value = null;
        return;
    }

    if (clientAttachmentId.value === null) {
        return;
    }

    await EmployeeClientAssignment.deleteEmployeeClientAssignment(
        clientAttachmentId.value
    );
    clientAttachmentId.value = null;
    // emit("status", true);
};

const onClose = () => {
    slider.value = !slider.value;
    selected.value = {} as EmployeeClientAssignmentInterface;
};
</script>
<template>
    <CardSkeleton v-if="loading" />

    <div v-else class="intro-y box mt-5 lg:mt-0 mb-5">
        <div class="relative flex items-center py-3 px-4">
            <h2 class="font-medium text-base mr-auto">Client Assignment</h2>

            <Button
                v-if="
                    AuthStore.hasPermission(
                        'can create employee client assignment'
                    )
                "
                id="add-client"
                class="text-xs"
                variant="soft-secondary"
                @click="slider = !slider"
            >
                <Lucide class="w-5 h-5" icon="PlusCircle" />
            </Button>
        </div>
        <div
            v-if="_.isArray(EmployeeClientAssignment.employeeClientAssignments)"
            class="p-2 border-t border-slate-200/60 dark:border-darkmode-400"
        >
            <ul
                class="rounded-md divide-y divide-gray-200 mb-2 shadow"
                role="list"
            >
                <li
                    v-for="(
                        item, index
                    ) in EmployeeClientAssignment.employeeClientAssignments"
                    :key="item.id"
                    class="pl-3 pr-4 py-3 flex items-center justify-between text-sm"
                >
                    <div class="ml-2 flex-1 w-0 truncate">
                        <div
                            class="mt-5 flex justify-between text-gray-500 w-2/4"
                        >
                            <div class="font-bold">
                                {{ item.client?.legal_business_name }}
                            </div>
                        </div>

                        <div
                            class="mt-5 flex justify-between text-gray-500 w-2/4"
                        >
                            <div>Effective From:</div>
                            <div>
                                {{
                                    moment(item.effective_from).format(
                                        "YYYY-MM-DD"
                                    )
                                }}
                            </div>
                        </div>

                        <div class="flex justify-between text-gray-500 w-2/4">
                            <div>Effective To:</div>
                            <div>
                                {{
                                    item.effective_to
                                        ? moment(item.effective_to).format(
                                              "YYYY-MM-DD"
                                          )
                                        : "-"
                                }}
                            </div>
                        </div>
                    </div>
                    <Menu
                        :id="`menu_${index}`"
                        v-if="
                            AuthStore.hasPermission(
                                'can update employee client assignment'
                            ) ||
                            AuthStore.hasPermission(
                                'can delete employee client assignment'
                            )
                        "
                    >
                        <Menu.Button :as="Button" variant="soft-secondary">
                            <CustomIcon
                                :height="18"
                                :icon="'CircleDots'"
                                :width="18"
                            />
                        </Menu.Button>
                        <Menu.Items class="w-40">
                            <Menu.Item
                                v-if="
                                    AuthStore.hasPermission(
                                        'can update employee client assignment'
                                    )
                                "
                                :id="`update_${index}`"
                                @click="onClickUpdate(item)"
                            >
                                Update
                            </Menu.Item>
                            <Menu.Item
                                v-if="
                                    AuthStore.hasPermission(
                                        'can delete employee client assignment'
                                    )
                                "
                                :id="`delete_${index}`"
                                @click="onClickDelete(item)"
                            >
                                Delete
                            </Menu.Item>
                        </Menu.Items>
                    </Menu>
                </li>
            </ul>
        </div>
        <div
            v-if="
                EmployeeClientAssignment.employeeClientAssignments.length === 0
            "
            class="px-4 py-6 sm:px-6"
        >
            No client assignment yet.
        </div>
    </div>
    <FormSlider
        :is-open="slider"
        header="Employee Client Assignment"
        size="md"
        @close="onClose"
    >
        <FormEmployeeClientAssignment
            :employee="employee"
            :data="selected"
            @close="onClose"
        />
    </FormSlider>

    <ConfirmModal
        :displayConfirmModal="dialog"
        second-description="Client assignment has been deleted successfully."
        @closeConfirmModal="dialog = false"
        @proceedProcess="onClickConfirm($event)"
    />
</template>
