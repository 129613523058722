<script setup lang="ts">
import { useRoute } from "vue-router";
import { FormLabel } from "../../../base-components/Form";
import FormTextarea from "../../../base-components/Form/FormTextarea.vue";
import { useVuelidate } from "@vuelidate/core";
import { reactive, ref, toRefs, defineEmits, onMounted, provide } from "vue";
import Button from "../../../base-components/Button";
import { required } from "@vuelidate/validators";
import { useEmployeeNoteStore } from "../../../stores/employeeNoteStore";
import { EmployeeNoteInterface } from "../../interfaces/employeeNoteInterface";
import { NotificationElement } from "../../../base-components/Notification";
import Notification from "../../../base-components/Notification/Notification.vue";
import Lucide from "../../../base-components/Lucide";

const props = defineProps({
    note: {
        type: Object,
        default: () => ({}),
    },
});
const emit = defineEmits(["close"]);

const route = useRoute();
const NoteStore = useEmployeeNoteStore();
const form = reactive<EmployeeNoteInterface>({
    note: "",
    employee_id: +route?.params?.id ?? 0,
});
const $externalResults = ref({});
const rules = {
    note: {
        required,
    },
};
const backendValidationErrors = ref({});
const errorNotification = ref<NotificationElement>();
const successNotification = ref<NotificationElement>();
const successMessage = ref();

const validate = useVuelidate(rules, toRefs(form), { $externalResults });

const close = () => {
    resetForm();
    validate.value.$reset();

    emit("close");
};

const errorNotificationToggle = () => {
    // Show notification
    errorNotification.value?.showToast();
};
provide("bind[errorNotification]", (el: NotificationElement) => {
    errorNotification.value = el;
});
const showErrorsNotification = () => {
    errorNotificationToggle();
};

const successNotificationToggle = () => {
    successNotification.value?.showToast();
};
provide("bind[successNotification]", (el: NotificationElement) => {
    successNotification.value = el;
});
const showSuccessNotification = () => {
    successNotificationToggle();
};

const resetForm = () => {
    form.employee_id = +route?.params?.id ?? 0;
    form.note = "";
};

const save = async () => {
    await NoteStore.addNote(form);
    successMessage.value = "Note has been successfully added.";
};

const update = async () => {
    await NoteStore.updateNote(form);
    successMessage.value = "Note has been successfully updated.";
};

const onSubmit = async () => {
    validate.value.$reset();
    validate.value.$clearExternalResults();
    validate.value.$touch();

    if (validate.value.$invalid) {
        backendValidationErrors.value = {
            message: ["Incomplete or Missing required data"],
        };

        showErrorsNotification();
        return;
    }

    if (!validate.value.$invalid) {
        const { note } = toRefs(props);

        try {
            note.value.id ? await update() : await save();

            close();
            showSuccessNotification();
        } catch (error: any) {
            $externalResults.value = error?.response?.data?.errors;
        }
    }
};

onMounted(() => {
    const { note } = toRefs(props);

    if (note.value.id) {
        form.id = note.value.id;
        form.employee_id = note.value.employee_id;
        form.note = note.value.note;
    }
});
</script>

<template>
    <form @submit.prevent="onSubmit">
        <div class="grid grid-cols-12 gap-6 mt-5 items-left text-left">
            <div class="intro-y col-span-12">
                <!-- BEGIN: Form Layout -->
                <div class="intro-y pb-5 pl-5 pr-2">
                    <div class="mt-4">
                        <FormLabel class="form-label" for="note"
                            >Note
                            <span class="text-red-500">*</span>
                        </FormLabel>
                        <FormTextarea
                            id="note"
                            v-model="form.note"
                            :value="form.note"
                            class="form-control w-full"
                            name="note"
                            placeholder="Write something..."
                        />
                        <template v-if="validate.note.$error">
                            <div
                                v-for="(error, index) in validate.note.$errors"
                                :key="index"
                                class="text-danger mt-2"
                            >
                                {{ error.$message }}
                            </div>
                        </template>
                    </div>

                    <div class="">
                        <div class="text-right py-2">
                            <Button
                                id="btn_cancel"
                                class="w-32 mb-2 mr-1"
                                type="button"
                                variant="warning"
                                @click="close()"
                            >
                                Cancel
                            </Button>

                            <Button
                                id="btn_process"
                                class="w-36 mb-2"
                                type="submit"
                                variant="customPrimary"
                            >
                                Add Note
                            </Button>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </form>

    <Notification
        :options="{
            duration: 3000,
        }"
        class="flex"
        refKey="successNotification"
    >
        <Lucide class="text-success" icon="CheckCircle" />
        <div class="ml-4 mr-4">
            <div class="font-medium">System Notification</div>
            <div class="mt-1 text-slate-500">
                {{ successMessage }}
            </div>
        </div>
    </Notification>

    <Notification
        :options="{
            duration: 3000,
        }"
        class="flex"
        refKey="errorNotification"
    >
        <Lucide class="text-danger" icon="X" />
        <div class="ml-4 mr-4">
            <div class="font-medium">System Notification</div>
            <div class="mt-1 text-slate-500">
                <ul class="list-disc">
                    <template v-for="errorBag in backendValidationErrors">
                        <li class="text-red-500">
                            {{ errorBag[0] }}
                        </li>
                    </template>
                </ul>
            </div>
        </div>
    </Notification>
</template>
