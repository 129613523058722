<script lang="ts" setup>
import { useInvoiceStore } from "../../../stores/invoiceStore";
import {
    InvoiceInterface,
    InvoiceLineItemInterface,
} from "../../interfaces/invoiceInterface";
import {
    defineEmits,
    defineProps,
    nextTick,
    onMounted,
    PropType,
    provide,
    reactive,
    ref,
    toRefs,
    watch,
} from "vue";
import { useVuelidate } from "@vuelidate/core";
import Notification, {
    NotificationElement,
} from "../../../base-components/Notification";
import dayjs from "dayjs";
import TomSelect from "../../../base-components/TomSelect";
import {
    FormInput,
    FormLabel,
    FormSwitch,
    InputGroup,
} from "../../../base-components/Form";
import { helpers, required, requiredIf } from "@vuelidate/validators";
import FormTextarea from "../../../base-components/Form/FormTextarea.vue";
import Button from "../../../base-components/Button";
import FormInvoiceLineItem from "./FormInvoiceLineItem.vue";
import { useClientsStore } from "../../../stores/clientsStore";
import { useEmployeesStore } from "../../../stores/employeesStore";
import Lucide from "../../../base-components/Lucide";
import { useCurrencyStore } from "../../../stores/currencyStore";
import { useRoute, useRouter } from "vue-router";
import InputSwitch from "primevue/inputswitch";
import Textarea from "primevue/textarea";
import { useActivityLogsStore } from "../../../stores/activityLogsStore";
import { useUserStore } from "../../../stores/userStore";

const InvoiceStore = useInvoiceStore();
const ClientStore = useClientsStore();
const EmployeeStore = useEmployeesStore();
const ActivityLogStore = useActivityLogsStore();
const UserStore = useUserStore();

const emit = defineEmits(["close"]);
const props = defineProps({
    invoice: {
        type: Object as PropType<InvoiceInterface>,
        default: () => ({}),
    },
});
const router = useRouter();
const route = useRoute();
const editing = ref(false);
const loading = ref(false);

const form = reactive({
    id: 0,
    invoice_status_id: "",
    client_id: "",
    reviewer_id: "",
    start_date: "",
    end_date: "",
    due_date: "",
    total_amount: 0,
    currency_id: "",
    notes: "",
    invoice_reference: "",
});
const rules = {
    invoice_status_id: {
        required: requiredIf(() => Object.keys(props.invoice).length !== 0),
    },
    client_id: { required },
    reviewer_id: { required: requiredIf(() => form.invoice_status_id == "6") },
    start_date: { required },
    end_date: { required },
    due_date: { required },
    total_amount: {
        required: requiredIf(() => Object.keys(props.invoice).length !== 0),
    },
    currency_id: {},
    notes: {},
    invoice_reference: {},
};
const backendValidationErrors = ref({});
const $externalResults = ref({});
const validate = useVuelidate(rules, toRefs(form), { $externalResults });
const errorNotification = ref<NotificationElement>();
const successNotification = ref<NotificationElement>();

const errorNotificationToggle = () => {
    errorNotification.value?.showToast();
};
provide("bind[errorNotification]", (el: NotificationElement) => {
    errorNotification.value = el;
});
const showErrorsNotification = () => {
    errorNotificationToggle();
};
const successNotificationToggle = () => {
    successNotification.value?.showToast();
};
provide("bind[successNotification]", (el: NotificationElement) => {
    successNotification.value = el;
});
const showSuccessNotification = () => {
    successNotificationToggle();
};
const close = async () => {
    if (route.name !== "invoice-details" && props.invoice.id !== 0) {
        emit("close", true);
    } else {
        emit("close");
    }

    setTimeout(() => {
        form.id = 0;
        form.invoice_status_id = "";
        form.client_id = "";
        form.reviewer_id = "";
        form.start_date = "";
        form.end_date = "";
        form.due_date = "";
        form.total_amount = 0;
        form.currency_id = "";
        form.notes = "";
        form.invoice_reference = "";
    }, 250);
};

const onChangeInvoiceStatus = () => {
    if (form.invoice_status_id === "Select Status") {
        form.invoice_status_id = "";
    }
};

const update = async () => {
    editing.value = true;
    const data = await InvoiceStore.updateInvoice(form);

    if (route.name === "invoice-details") {
        await ActivityLogStore.fill("Invoice", data.id);
    }
};

const save = async () => {
    editing.value = false;
    const data = await InvoiceStore.addInvoice(form);

    await router.push({
        name: "invoice-details",
        params: { id: data.id },
    });
};

const onSubmit = async () => {
    loading.value = true;
    validate.value.$reset();
    validate.value.$clearExternalResults();
    validate.value.$touch();

    if (validate.value.$invalid) {
        backendValidationErrors.value = {
            message: ["Incomplete or Missing required data"],
        };

        showErrorsNotification();

        loading.value = false;

        return;
    }

    try {
        props?.invoice?.id ? await update() : await save();

        showSuccessNotification();

        await close();
    } catch (error: any) {
        $externalResults.value = error.response.data.errors;

        backendValidationErrors.value = {
            message: ["Something went wrong."],
        };

        showErrorsNotification();
    } finally {
        loading.value = false;
    }
};

const handleFetchUsers = async () => {
    const params = new URLSearchParams({
        "filter[permission]": "can view invoices",
        "fields[users]": "id,name",
    });

    await UserStore.getUsers(params);
};

onMounted(async () => {
    loading.value = true;
    await ClientStore.getClientsForDropdown();
    await InvoiceStore.getInvoiceStatuses();
    await handleFetchUsers();

    const { invoice } = toRefs(props);

    if (invoice?.value?.id) {
        form.id = invoice?.value?.id;
        form.invoice_status_id =
            invoice?.value?.invoice_status_id?.toString() ?? "";
        form.client_id = invoice?.value?.client_id?.toString();
        form.start_date = dayjs(invoice?.value?.start_date).format(
            "YYYY-MM-DD"
        );
        form.end_date = dayjs(invoice?.value?.end_date).format("YYYY-MM-DD");
        form.due_date = dayjs(invoice?.value?.due_date).format("YYYY-MM-DD");
        form.total_amount = invoice?.value?.total_amount;
        form.notes = invoice?.value?.notes;
        form.invoice_reference = invoice?.value?.invoice_reference;
        form.reviewer_id =
            invoice?.value?.reviewer_id === null
                ? ""
                : String(invoice?.value?.reviewer_id);

        if (invoice?.value?.currency_id) {
            form.currency_id = invoice?.value?.currency_id.toString();
        }
    }

    loading.value = false;
});
</script>

<template>
    <form @submit.prevent="onSubmit">
        <div class="grid grid-cols-12 gap-6 mt-5 items-left text-left">
            <div class="intro-y col-span-12">
                <div class="intro-y pb-5 pl-5 pr-2">
                    <div>
                        <FormLabel class="form-label" for="client_id">
                            Client
                            <span class="text-red-600">*</span>
                        </FormLabel>
                        <div>
                            <TomSelect
                                id="client_id"
                                v-model="form.client_id"
                                :value="form.client_id"
                                class="w-full p-0"
                                name="client_id"
                                :disabled="
                                    Object.keys(props.invoice).length > 0
                                "
                            >
                                <option selected>Select Client</option>
                                <option
                                    v-for="client in ClientStore.clientsForDropdown"
                                    :value="client.id"
                                >
                                    {{ client.legal_business_name }}
                                </option>
                            </TomSelect>
                        </div>
                        <template v-if="validate.client_id.$error">
                            <div
                                v-for="(error, index) in validate.client_id
                                    .$errors"
                                :key="index"
                                class="text-danger mt-2"
                            >
                                {{ error.$message }}
                            </div>
                        </template>
                    </div>

                    <div class="mt-4">
                        <FormLabel class="form-label" for="reviewer_id">
                            Reviewer
                        </FormLabel>
                        <div>
                            <TomSelect
                                id="reviewer_id"
                                v-model="form.reviewer_id"
                                :value="form.reviewer_id"
                                class="w-full p-0"
                                name="reviewer_id"
                            >
                                <option selected>Select Reviewer</option>
                                <option
                                    v-for="reviewer in UserStore.userList"
                                    :value="reviewer.id"
                                >
                                    {{ reviewer.name }}
                                </option>
                            </TomSelect>
                        </div>
                        <template v-if="validate.reviewer_id.$error">
                            <div
                                v-for="(error, index) in validate.reviewer_id
                                    .$errors"
                                :key="index"
                                class="text-danger mt-2"
                            >
                                {{ error.$message }}
                            </div>
                        </template>
                    </div>

                    <div class="grid grid-cols-12 gap-6">
                        <div class="mt-4 col-span-12 lg:col-span-4">
                            <FormLabel class="form-label" for="start_date"
                                >Start Date
                                <span class="text-red-600">*</span>
                            </FormLabel>
                            <FormInput
                                id="start_date"
                                v-model="form.start_date"
                                :value="form.start_date"
                                class="form-control w-full"
                                name="start_date"
                                type="date"
                            />
                            <template v-if="validate.start_date.$error">
                                <div
                                    v-for="(error, index) in validate.start_date
                                        .$errors"
                                    :key="index"
                                    class="text-danger mt-2"
                                >
                                    {{ error.$message }}
                                </div>
                            </template>
                        </div>

                        <div class="mt-4 col-span-12 lg:col-span-4">
                            <FormLabel class="form-label" for="end_date"
                                >End Date
                                <span class="text-red-600">*</span>
                            </FormLabel>
                            <FormInput
                                id="end_date"
                                v-model="form.end_date"
                                :value="form.end_date"
                                class="form-control w-full"
                                name="end_date"
                                type="date"
                            />
                            <template v-if="validate.end_date.$error">
                                <div
                                    v-for="(error, index) in validate.end_date
                                        .$errors"
                                    :key="index"
                                    class="text-danger mt-2"
                                >
                                    {{ error.$message }}
                                </div>
                            </template>
                        </div>

                        <div class="mt-4 col-span-12 lg:col-span-4">
                            <FormLabel class="form-label" for="due_date"
                                >Due Date
                                <span class="text-red-600">*</span>
                            </FormLabel>
                            <FormInput
                                id="due_date"
                                v-model="form.due_date"
                                :value="form.due_date"
                                class="form-control w-full"
                                name="due_date"
                                type="date"
                            />
                            <template v-if="validate.due_date.$error">
                                <div
                                    v-for="(error, index) in validate.due_date
                                        .$errors"
                                    :key="index"
                                    class="text-danger mt-2"
                                >
                                    {{ error.$message }}
                                </div>
                            </template>
                        </div>
                    </div>

                    <div
                        class="grid grid-cols-12 gap-6"
                        v-if="Object.keys(props.invoice).length > 0"
                    >
                        <div class="mt-4 col-span-12 lg:col-span-4">
                            <FormLabel class="form-label" for="total_amount"
                                >Total Amount
                            </FormLabel>
                            <InputGroup>
                                <FormInput
                                    id="total_amount"
                                    v-model.number="form.total_amount"
                                    :value="form.total_amount"
                                    class="form-control w-full"
                                    disabled
                                    name="total_amount"
                                    type="number"
                                />
                                <InputGroup.Text
                                    v-if="invoice.client?.currency"
                                >
                                    {{ invoice.client?.currency?.code }}
                                </InputGroup.Text>
                            </InputGroup>
                            <template v-if="validate.total_amount.$error">
                                <div
                                    v-for="(error, index) in validate
                                        .total_amount.$errors"
                                    :key="index"
                                    class="text-danger mt-2"
                                >
                                    {{ error.$message }}
                                </div>
                            </template>
                        </div>
                    </div>

                    <div class="mt-4">
                        <FormLabel class="form-label" for="notes"
                            >Notes
                        </FormLabel>
                        <!--                        <FormTextarea-->
                        <!--                            id="notes"-->
                        <!--                            v-model="form.notes"-->
                        <!--                            :value="form.notes"-->
                        <!--                            class="form-control w-full"-->
                        <!--                            name="notes"-->
                        <!--                            placeholder="Add your notes here..."-->
                        <!--                        />-->
                        <Textarea
                            v-model="form.notes"
                            class="w-full text-sm"
                            placeholder="Add your notes here..."
                            rows="5"
                        />
                    </div>

                    <div class="mt-4">
                        <FormLabel class="form-label" for="invoice_reference"
                            >Invoice Reference
                        </FormLabel>
                        <FormInput
                            id="invoice_reference"
                            v-model.number="form.invoice_reference"
                            :value="form.invoice_reference"
                            class="form-control w-full"
                            name="invoice_reference"
                            type="text"
                        />
                    </div>

                    <div
                        class="mt-4"
                        v-if="
                            props.invoice &&
                            Object.keys(props.invoice).length > 0
                        "
                    >
                        <FormLabel class="form-label" for="invoice_status_id">
                            Status
                            <span class="text-red-600">*</span>
                        </FormLabel>
                        <div>
                            <TomSelect
                                id="invoice_status_id"
                                v-model="form.invoice_status_id"
                                :value="form.invoice_status_id"
                                class="w-full p-0"
                                name="invoice_status_id"
                                @update:modelValue="onChangeInvoiceStatus"
                            >
                                <option selected>Select Status</option>
                                <option
                                    v-for="status in InvoiceStore.invoiceStatuses"
                                    :value="status.id"
                                >
                                    {{ status.name }}
                                </option>
                            </TomSelect>
                        </div>
                        <template v-if="validate.invoice_status_id.$error">
                            <div
                                v-for="(error, index) in validate
                                    .invoice_status_id.$errors"
                                :key="index"
                                class="text-danger mt-2"
                            >
                                {{ error.$message }}
                            </div>
                        </template>
                    </div>


                </div>
            </div>
        </div>

        <div class="">
            <div class="text-right p-2">
                <Button
                    id="btn_cancel"
                    class="w-32 mb-2 mr-1"
                    type="button"
                    variant="warning"
                    :disabled="loading"
                    @click="close()"
                >
                    Cancel
                </Button>
                <Button
                    id="btn_process"
                    class="w-36 mb-2 mr-1"
                    type="submit"
                    variant="customPrimary"
                    :disabled="loading"
                >
                    Save
                </Button>
            </div>
        </div>
    </form>

    <Notification
        :options="{
            duration: 3000,
        }"
        class="flex"
        refKey="successNotification"
    >
        <Lucide class="text-success" icon="CheckCircle" />
        <div class="ml-4 mr-4">
            <div class="font-medium">System Notification</div>
            <div v-if="editing" class="mt-1 text-slate-500">
                Invoice has been successfully updated.
            </div>
            <div v-else class="mt-1 text-slate-500">
                Invoice has been successfully added.
            </div>
        </div>
    </Notification>

    <Notification
        :options="{
            duration: 3000,
        }"
        class="flex"
        refKey="errorNotification"
    >
        <Lucide class="text-danger" icon="X" />
        <div class="ml-4 mr-4">
            <div class="font-medium">System Notification</div>
            <div class="mt-1 text-slate-500">
                <ul class="list-disc">
                    <template v-for="errorBag in backendValidationErrors">
                        <li class="text-red-500">
                            {{ errorBag[0] }}
                        </li>
                    </template>
                </ul>
            </div>
        </div>
    </Notification>
</template>
