<script lang="ts" setup>
import { onMounted, reactive, ref, toRefs } from "vue";
import { required } from "@vuelidate/validators";
import { useVuelidate } from "@vuelidate/core";
import "primevue/resources/themes/saga-blue/theme.css";
import "primevue/resources/primevue.min.css";
import "primeicons/primeicons.css";
import { FilterMatchMode, FilterOperator } from "primevue/api";
import DataTable from "primevue/datatable";
import Column from "primevue/column";
import InputText from "primevue/inputtext";
import Button from "primevue/button";
import { useClientsStore } from "@/stores/clientsStore";
import AlertModal from "@/pages/components/AlertModal.vue";
import PageHeaderStandard from "@/pages/components/PageHeaderStandard.vue";
import router from "@/router";
import { useUserStore } from "@/stores/userStore";
import { useAuthStore } from "@/stores/authStore";
import LazyParamsInterface from "@/pages/interfaces/lazyParamsInterface";
import FormInput from "@/base-components/Form/FormInput.vue";
import Lucide from "@/base-components/Lucide/Lucide.vue";
import Tippy from "@/base-components/Tippy";

const AuthStore = useAuthStore();

const ClientsStore = useClientsStore();

const UserStore = useUserStore();

//@ts-ignore
const impersonator = window.Laravel.impersonator || 0;

const alertModelSettings = reactive({
    isDisplayed: false,
    mode: "",
    subject: "",
    message: "",
});

const filters = ref({
    global: { value: null, matchMode: FilterMatchMode.CONTAINS },
    name: {
        operator: FilterOperator.AND,
        constraints: [{ value: null, matchMode: FilterMatchMode.STARTS_WITH }],
    },
    email: {
        operator: FilterOperator.AND,
        constraints: [{ value: null, matchMode: FilterMatchMode.STARTS_WITH }],
    },
    "roles.name": {
        operator: FilterOperator.AND,
        constraints: [{ value: null, matchMode: FilterMatchMode.STARTS_WITH }],
    },
    "permissions.name": {
        operator: FilterOperator.AND,
        constraints: [{ value: null, matchMode: FilterMatchMode.STARTS_WITH }],
    },
});

const expandedRows = ref([]);

const users = ref();

const selectedusers = ref();

const selectAll = ref(false);

const loading = ref(true);

const totalRecords = ref(0);

const lazyParams = ref<LazyParamsInterface>({
    rows: 100,
});

const dt = ref();

const showClearFilter = ref(false);

const columns = ref([
    { field: "name", header: "User Name" },
    { field: "email", header: "User Email" },
    { field: "roles", header: "Roles" },
    { field: "permissions", header: "Permission" },
]);
const showImpersonateIcon = ref(impersonator === 0);

const searchableCols = ref(["name", "email", "roles.name", "permissions.name"]);

let showSlider = ref(false);

const formData = reactive({
    user_id: "",
    name: "",
    position: "",
    personal_phone: "",
    personal_email: "",
    work_email: "",
    work_phone: "",
    client_email: null,
    primary_work_location: null,
    location: "",
    shift_schedule: null,
    notes: null,
    emergency_contact_name: null,
    emergency_contact_relationship: null,
    internal_staff_flag: null,
    sss: null,
    pagibig: null,
    tin: null,
    phic: null,
    basic_salary: null,
    regularization_date: null,
    timezone_id: null,
    legal_business_name: null,
});

const rules = {
    legal_business_name: { required },
    user_id: { required },
    name: { required },
    position: { required },
    personal_phone: { required },
    personal_email: { required },
    work_email: { required },
    work_phone: { required },
    client_email: { required },
    timezone_id: { required },
    primary_work_location: { required },
    location: { required },
    shift_schedule: { required },
    notes: { required },
    emergency_contact_name: { required },
    emergency_contact_relationship: { required },
    internal_staff_flag: { required },
    sss: { required },
    pagibig: { required },
    tin: { required },
    phic: { required },
    basic_salary: { required },
    regularization_date: { required },
};

const validate = useVuelidate(rules, toRefs(formData));

const formticketSettings = reactive({
    id: 0,
});

onMounted(async () => {
    await AuthStore.getLoggedInUserDetails();
    lazyParams.value = {
        first: 0,
        rows: dt.value.rows,
        sortField: null,
        sortOrder: null,
        filters: filters.value,
    };
    await ClientsStore.getAllClientsForTable({});
    await loadLazyData();

    // showImpersonateIcon.value = AuthStore.impersonated == "";
});

const createNew = () => {
    console.log("add User");
    showSlider.value = true;
    formticketSettings.id = 0;
};
const viewteam = async (data: any) => {
    console.log("edit User", data);
    showSlider.value = true;
    formticketSettings.id = data.id;
};

const deleteId = ref("");

const deleteteam = (data: any) => {
    deleteId.value = data.id;
    alertModelSettings.mode = "danger";
    alertModelSettings.subject = "Delete Client";
    alertModelSettings.message = "Are you sure you want to delete this client?";
    alertModelSettings.isDisplayed = true;
};

const processDelete = async (event: any) => {
    alertModelSettings.isDisplayed = false;
    if (event) {
        console.log(deleteId.value);
        // await UserStore.delete(deleteId.value).then(
        //     (response: { status: string }) => {
        //         console.log("response", response);
        //         if (response.status === "ok") {
        //             console.log("delete");
        //             setTimeout(() => {
        //                 deleteId.value = "";
        //                 alertModelSettings.mode = "success";
        //                 alertModelSettings.subject = "Delete Client";
        //                 alertModelSettings.message =
        //                     "Client deleted successfully";
        //                 alertModelSettings.isDisplayed = true;
        //             }, 1000);
        //         }
        //     }
        // );
        loadLazyData();
    }
};

const viewUserRole = (data: any) => {
    router.push({ name: "account-management", params: { id: data.id } });
};
const viewRoles = () => {
    console.log("viewRoles");
    router.push({ name: "roles-and-permissions" });
};

const updateticket = async (data: any) => {
    console.log("updateticket", data);
    showSlider.value = true;
    formticketSettings.id = data.id;
};
const deleteticket = async (data: any) => {
    console.log("deleteticket", data);
    // await UserStore.delete(data.id);
    loadLazyData();
};

const viewticket = (data: any) => {
    router.push({ name: "my-ticket-details", params: { id: data.id } });
};

const formticketStatus = (status: any) => {
    console.log("status", status);
    if (status) {
        loadLazyData();
    }
};

const closeSlider = () => {
    formticketSettings.id = 0;
    showSlider.value = false;
};

const loadLazyData = async () => {
    console.log("loadLazyData");
    loading.value = true;
    const params = {
        dt_params: JSON.stringify(lazyParams.value),
        searchable_columns: JSON.stringify(searchableCols.value),
    };

    await UserStore.fill(params).then((response: any) => {
        console.log("done");
        console.log(UserStore.users);
        console.log(UserStore.users.total);
        users.value = UserStore.users;
        totalRecords.value = UserStore.users?.total ?? 0;
        loading.value = false;
    });
};

const formEmployeeStatus = (status: any) => {
    if (status) {
        loadLazyData();
    }
};

const onPage = (event: any) => {
    console.log("onPage");
    lazyParams.value = event;
    console.log("New onPage");
    console.log(lazyParams);
    loadLazyData();
};

const onSort = (event: any) => {
    showClearFilter.value = true;
    console.log("onSort");
    lazyParams.value = event;
    loadLazyData();
};

const onFilter = () => {
    showClearFilter.value = true;
    console.log("onFilter");
    lazyParams.value.filters = filters.value;
    loadLazyData();
};

const resetGlobalFilter = () => {
    showClearFilter.value = false;
    console.log("reset");
    filters.value = {
        global: { value: null, matchMode: FilterMatchMode.CONTAINS },
        name: {
            operator: FilterOperator.AND,
            constraints: [
                { value: null, matchMode: FilterMatchMode.STARTS_WITH },
            ],
        },
        email: {
            operator: FilterOperator.AND,
            constraints: [
                { value: null, matchMode: FilterMatchMode.STARTS_WITH },
            ],
        },
        "roles.name": {
            operator: FilterOperator.AND,
            constraints: [
                { value: null, matchMode: FilterMatchMode.STARTS_WITH },
            ],
        },
        "permissions.name": {
            operator: FilterOperator.AND,
            constraints: [
                { value: null, matchMode: FilterMatchMode.STARTS_WITH },
            ],
        },
    };
    lazyParams.value = {
        first: 0,
        rows: dt.value.rows,
        sortField: null,
        sortOrder: null,
        filters: filters.value,
    };
    loadLazyData();
};

// const resetSearchFilter = () => {
//     showClearFilter.value = false;
//     console.log("resetSearchFilter");
//     filters.value = {
//         global: { value: null, matchMode: FilterMatchMode.CONTAINS },
//     };
//     lazyParams.value = {
//         first: 0,
//         rows: dt.value.rows,
//         sortField: null,
//         sortOrder: null,
//         filters: filters.value,
//     };
//     loadLazyData();
// };

// const onSelectAllChange = (event) => {
//     console.log("onSelectAllChange");
//     const selectAll = event.checked;
//
//     if (selectAll) {
//         customerService.value.getCustomers().then((data) => {
//             selectAll.value = true;
//             selectedteams.value = data.customers;
//         });
//     } else {
//         selectAll.value = false;
//         selectedteams.value = [];
//     }
// };

// const onRowSelect = () => {
//     console.log("onRowSelect");
//     selectAll.value = selectedteams.value.length === totalRecords.value;
// };

const onRowUnselect = () => {
    console.log("onRowUnselect");
    selectAll.value = false;
};

const onRowExpand = () => {
    console.log("onRowSelect");
};
const onRowCollapse = () => {
    console.log("onRowSelect");
};
const expandAll = () => {
    console.log("expandAll");
};
const collapseAll = () => {
    console.log("collapseAll");
};

const impersonate = (id: any) => {
    AuthStore.impersonate(id).then((data) => {
        window.location.href = '/';
    });
};
const leaveImpersonate = () => {
    AuthStore.leaveImpersonate();
};
</script>
<template>
    <div class="w-full">
        <PageHeaderStandard :title="'User Manual Override Permission'" />
        <div
            class="intro-y col-span-12 flex flex-wrap sm:flex-nowrap items-center mt-2"
        >
            <!--<button class="btn bg-custom-color-1000 text-white shadow-md mr-2" id="addNewticket" @click="createNew">Add
New
User</button>-->
<div class="hidden md:block mx-auto text-slate-500"></div>

            <div class="flex w-full sm:w-auto mt-3 sm:mt-0 sm:ml-auto md:ml-0">
                <template v-if="showClearFilter">
                    <button
                        class="btn bg-custom-color-1000 text-white w-full mx-3 rounded-md px-4"
                        @click="resetGlobalFilter"
                    >
                        Clear
                        <span
                            class="h-3 w-4 pi pi-filter-slash p-component"
                        ></span>
                    </button>
                </template>

                <div class="ml-2 w-56 relative text-slate-500">
                    <FormInput
                        id="global-filter"
                        v-model="filters.global.value"
                        :value="filters.global.value"
                        class="w-56 pr-10 !box"
                        placeholder="Search..."
                        type="text"
                        @keyup.enter="onFilter"
                    />

                    <Lucide
                        class="absolute inset-y-0 right-0 w-4 h-4 my-auto mr-3"
                        icon="Search"
                    />
                </div>
            </div>
        </div>
        <DataTable
            ref="dt"
            v-model:expandedRows="expandedRows"
            v-model:filters="filters"
            v-model:selection="selectedusers"
            :globalFilterFields="searchableCols"
            :lazy="true"
            :loading="loading"
            :paginator="true"
            :rows="15"
            :selectAll="selectAll"
            :totalRecords="totalRecords"
            :value="users?.data"
            class="mt-4 p-datatable-sm text-xs"
            dataKey="id"
            filterDisplay="menu"
            responsiveLayout="scroll"
            showGridlines
            stripedRows
            @filter="onFilter"
            @page="onPage($event)"
            @rowCollapse="onRowCollapse"
            @rowExpand="onRowExpand"
            @sort="onSort($event)"
            @row-unselect="onRowUnselect"
        >
            <template #empty> No User found.</template>
            <template #loading> Loading User Data. Please wait.</template>
            <Column headerStyle="width: 3rem" />
            <Column :sortable="true" field="id" header="User ID">
                <template #body="{ data }">
                    {{ data.id }}
                </template>
                <template #filter="{ filterModel }">
                    <InputText
                        v-model="filterModel.value"
                        class="p-column-filter"
                        placeholder="Search by User ID"
                        type="text"
                    />
                </template>
            </Column>
            <Column :sortable="true" field="name" header="User Name">
                <template #body="{ data }">
                    {{ data.name }}
                </template>
                <template #filter="{ filterModel }">
                    <InputText
                        v-model="filterModel.value"
                        class="p-column-filter"
                        placeholder="Search by User Name"
                        type="text"
                    />
                </template>
            </Column>
            <Column :sortable="true" field="email" header="User Email">
                <template #body="{ data }">
                    {{ data.email }}
                </template>
                <template #filter="{ filterModel }">
                    <InputText
                        v-model="filterModel.value"
                        class="p-column-filter"
                        placeholder="Search by User Email"
                        type="text"
                    />
                </template>
            </Column>
            <Column header="User Roles">
                <template #body="{ data }">
                    <div v-for="roles in data.roles">
                        <span
                            class="cursor-pointer font-bold"
                            @click="viewRoles()"
                            >{{ roles.name }}</span
                        >
                    </div>
                </template>
            </Column>
            <Column field="permissions.name" header="User Permissions">
                <template #body="{ data }">
                    <div v-for="permissions in data.permissions">
                        {{ permissions.name }}
                    </div>
                </template>
            </Column>
            <Column :exportable="false" style="width: 8rem; max-width: 8rem">
                <template #body="slotProps">
                    <div class="content-center flex justify-center">
                        <div class="ml-0">
                            <router-link
                                :to="{
                                    name: 'account-management',
                                    params: { id: slotProps.data.id },
                                }"
                            >
                                <Tippy content="View">
                                    <svg
                                        :id="'view_' + slotProps.data.id"
                                        class="icon icon-tabler icon-tabler-eye cursor-pointer"
                                        fill="none"
                                        height="18"
                                        stroke="currentColor"
                                        stroke-linecap="round"
                                        stroke-linejoin="round"
                                        stroke-width="2"
                                        viewBox="0 0 24 24"
                                        width="18"
                                        xmlns="http://www.w3.org/2000/svg"
                                    >
                                        <path d="M0 0h24v24H0z" fill="none" stroke="none"></path>
                                        <path d="M12 12m-2 0a2 2 0 1 0 4 0a2 2 0 1 0 -4 0"></path>
                                        <path
                                            d="M22 12c-2.667 4.667 -6 7 -10 7s-7.333 -2.333 -10 -7c2.667 -4.667 6 -7 10 -7s7.333 2.333 10 7"
                                        ></path>
                                    </svg>
                                </Tippy>
                            </router-link>
                        </div>

                        <div
                            class="ml-2"
                            v-if="
                                showImpersonateIcon &&
                                slotProps.data.id != AuthStore.authUser.id
                            "
                            @click="impersonate(slotProps.data.id)"
                        >
                            <Tippy content="Impersonate">
                                <Lucide icon="Users" class="h-4 w-4 stroke-2" />
                            </Tippy>
                        </div>
                        <div
                            class="ml-2"
                            v-if="
                                impersonator === slotProps.data.id
                            "
                        >
                            <Tippy content="Impersonator">
                                <Lucide 
                                    icon="Ghost"
                                    class="h-4 w-4 stroke-2 text-gray-400 cursor-default"
                                />
                            </Tippy>
                        </div>

                        <div
                            class="ml-2"
                            v-if="AuthStore.authUser.id === slotProps.data.id && impersonator != 0"
                            @click="leaveImpersonate()"
                        >
                            <Tippy content="Leave Impersonate">
                                <Lucide 
                                    icon="UserX"
                                    class="h-4 w-4 stroke-2 text-red-400"
                                />
                            </Tippy>
                        </div>
                    </div>
                </template>
                <template #header="slotProps">
                    <div class="content-center flex justify-center">
                        <p v-if="showClearFilter">
                            <button
                                class="btn bg-custom-color-1000 text-white w-full mx-2 p-2 rounded"
                                @click="resetGlobalFilter"
                            >
                                clear &nbsp;<span
                                    class="pi pi-filter-slash p-component"
                                ></span>
                            </button>
                        </p>
                    </div>
                </template>
            </Column>
            <template #paginatorstart>
                <!--                <Button type="button" icon="pi pi-refresh" class="p-button-text"/>-->
            </template>
            <template #paginatorend>
                <!--                <Button type="button" icon="pi pi-cloud" class="p-button-text"/>-->
            </template>
        </DataTable>

        <AlertModal
            :isDisplayed="alertModelSettings.isDisplayed"
            :message="alertModelSettings.message"
            :mode="alertModelSettings.mode"
            :subject="alertModelSettings.subject"
            @response="processDelete($event)"
        />
    </div>
</template>
