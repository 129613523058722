<script lang="ts" setup>
import "primevue/resources/themes/saga-blue/theme.css";
import "primevue/resources/primevue.min.css";
import "primeicons/primeicons.css";
import { onBeforeUnmount, onMounted, reactive, ref } from "vue";
import { FormInput } from "../base-components/Form";
import { useShiftStore } from "../stores/shiftStore";
import { useAuthStore } from "../stores/authStore";
import { useHelperStore } from "../stores/helperStore";
import { FilterMatchMode, FilterOperator } from "primevue/api";
import Button from "../base-components/Button";
import Lucide from "../base-components/Lucide";
import PageHeaderStandard from "./components/PageHeaderStandard.vue";
import Column from "primevue/column";
import FormSlider from "./FormSlider.vue";
import ShiftFrom from "./forms/shifts/FormShift.vue";
import ConfirmModal from "./components/ConfirmModal.vue";
import DataTable from "primevue/datatable";
import InputText from "primevue/inputtext";
import {
    ShiftInterface,
    ShiftPaginatedInterface,
} from "./interfaces/shiftInterface";
import LazyParamsInterface from "./interfaces/lazyParamsInterface";

const AuthStore = useAuthStore();
const ShiftStore = useShiftStore();
const HelperStore = useHelperStore();

const lazyParams = ref<LazyParamsInterface>({});
const shifts = ref<ShiftPaginatedInterface>();
const shift = ref<ShiftInterface>();
const slider = ref(false);
const deleteId = ref(0);
const deleted = ref();
const showConfirmDialog = ref(false);
const filters = ref({
    global: { value: null, matchMode: FilterMatchMode.CONTAINS },
    name: {
        operator: FilterOperator.AND,
        constraints: [{ value: null, matchMode: FilterMatchMode.STARTS_WITH }],
    },
});
const searchableCols = ref(["name"]);
const loading = ref(false);
const selectAll = ref(false);
const selectedShifts = ref<ShiftInterface[]>();
const showClearFilter = ref(false);
const dt = ref();
const range = reactive({
    from: null,
    to: null,
});

const onClickButton = (data: any) => {
    slider.value = !slider.value;

    if (data && Object.keys(data).length > 0) {
        shift.value = data;
    } else {
        shift.value = {} as ShiftInterface;
    }
};

const confirmDelete = (event: { preventDefault: () => void }, data: any) => {
    event.preventDefault();
    deleteId.value = data.id;
    setConfirmDialog(true);
};

const setConfirmDialog = (value: boolean) => {
    showConfirmDialog.value = value;
};

const processDelete = (event: any) => {
    if (event === false) {
        deleteId.value = 0;
    } else {
        ShiftStore.deleteShift(deleteId.value)
            .then(() => {
                deleteId.value = 0;
                deleted.value = true;
            })
            .catch((error) => {
                deleted.value = false;
            });
    }
};

const onFilter = async (event: any) => {
    showClearFilter.value = filters.value.global.value !== "";

    lazyParams.value.filters = filters.value;

    await onDataFetch();
};

const onSort = async (event: any) => {
    showClearFilter.value = true;
    lazyParams.value = event;

    await onDataFetch();
};

const onPage = async (event: any) => {
    lazyParams.value = event;

    await onDataFetch();
};

const onChangeDateRange = async () => {
    if (!range.from || !range.to) {
        return;
    }

    showClearFilter.value = true;

    lazyParams.value.filters = filters.value;

    loading.value = true;

    const params = {
        dt_params: JSON.stringify(lazyParams.value),
        searchable_columns: JSON.stringify(searchableCols.value),
        range,
    };

    await ShiftStore.getShifts(params);
    await ShiftStore.getShiftBreakTypes();

    shifts.value = ShiftStore.shifts;
    loading.value = false;
};

const onDataFetch = async () => {
    loading.value = true;

    const params = {
        dt_params: JSON.stringify(lazyParams.value),
        searchable_columns: JSON.stringify(searchableCols.value),
    };

    await ShiftStore.getShifts(params);
    await ShiftStore.getShiftBreakTypes();

    shifts.value = ShiftStore.shifts;
    loading.value = false;
};

onMounted(async () => {
    AuthStore.getLoggedInUserDetails();

    await onDataFetch();
});

onBeforeUnmount(() => {
    showClearFilter.value = false;
});

const onSelectAllChange = (event: { checked: any }) => {
    selectAll.value = !selectAll.value;

    selectedShifts.value = selectAll.value ? shifts?.value?.data : [];
};

const onRowSelect = () => {
    selectAll.value =
        selectedShifts?.value?.length === ShiftStore.shifts?.total;
};

const onRowUnselect = () => {
    selectAll.value = false;
};

const resetGlobalFilter = () => {
    showClearFilter.value = false;

    filters.value = {
        global: { value: null, matchMode: FilterMatchMode.CONTAINS },
        name: {
            operator: FilterOperator.AND,
            constraints: [
                { value: null, matchMode: FilterMatchMode.STARTS_WITH },
            ],
        },
    };

    lazyParams.value = {
        first: 0,
        rows: dt.value.rows,
        sortField: null,
        sortOrder: null,
        filters: filters.value,
    };

    range.from = null;
    range.to = null;

    onDataFetch();
};
</script>

<template>
    <div class="w-full">
        <PageHeaderStandard title="Shifts" />

        <div
            class="intro-y col-span-12 flex flex-wrap sm:flex-nowrap items-center mt-2"
        >
            <Button
                v-if="AuthStore.hasPermission('can create employee shifts')"
                id="add-new-shift-button"
                class="mr-2 shadow-sm"
                variant="customPrimary"
                @click="onClickButton"
            >
                New Shift
            </Button>

            <div class="hidden md:block mx-auto text-slate-500"></div>

            <div class="flex w-full sm:w-auto mt-3 sm:mt-0 sm:ml-auto md:ml-0">
                <template v-if="showClearFilter">
                    <button
                        class="btn bg-custom-color-1000 text-white w-full mx-3 rounded-md px-4"
                        @click="resetGlobalFilter"
                    >
                        Clear
                        <span
                            class="h-3 w-4 pi pi-filter-slash p-component"
                        ></span>
                    </button>
                </template>

                <div class="w-56 relative text-slate-500">
                    <FormInput
                        v-model="range.from"
                        :value="range.from"
                        class="w-56 !box"
                        type="date"
                        @change="onChangeDateRange()"
                    />
                </div>

                <div class="ml-2 w-56 relative text-slate-500">
                    <FormInput
                        v-model="range.to"
                        :value="range.to"
                        class="w-56 !box"
                        type="date"
                        @change="onChangeDateRange()"
                    />
                </div>

                <div class="ml-2 w-56 relative text-slate-500">
                    <FormInput
                        id="global-filter"
                        v-model="filters.global.value"
                        :value="filters.global.value"
                        class="w-56 pr-10 !box"
                        placeholder="Search..."
                        type="text"
                        @keyup.enter="onFilter($event)"
                    />

                    <Lucide
                        class="absolute inset-y-0 right-0 w-4 h-4 my-auto mr-3"
                        icon="Search"
                    />
                </div>
            </div>
        </div>

        <DataTable
            ref="dt"
            v-model:filters="filters"
            v-model:selection="selectedShifts"
            :globalFilterFields="searchableCols"
            :lazy="true"
            :loading="loading"
            :paginator="true"
            :rows="10"
            :selectAll="selectAll"
            :totalRecords="shifts?.total"
            :value="shifts?.data"
            class="mt-4 p-datatable-sm rounded-lg shadow-lg text-xs"
            filterDisplay="menu"
            responsiveLayout="scroll"
            showGridlines
            stripedRows
            @filter="onFilter($event)"
            @page="onPage($event)"
            @sort="onSort($event)"
            @select-all-change="onSelectAllChange($event)"
            @row-select="onRowSelect"
            @row-unselect="onRowUnselect"
        >
            <template #empty> No available data.</template>

            <template #loading> Loading data. Please wait.</template>
            <Column class="hidden" headerStyle="min-width: 36px"></Column>
            <Column :sortable="true" field="name" header="Name">
                <template #filter="{ filterModel }">
                    <InputText
                        v-model="filterModel.value"
                        class="p-column-filter"
                        placeholder="Search by name"
                        type="text"
                    />
                </template>
            </Column>
            <Column :sortable="true" field="timezone_id" header="Timezone">
                <template #body="{ data }">
                    {{
                        HelperStore.getTimezone(parseInt(data.timezone_id)).name
                    }}
                </template>
            </Column>
            <Column
                :sortable="true"
                field="shift_active_start_date"
                header="Shift Active Start Date"
            >
            </Column>

            <Column
                :sortable="true"
                field="start_time"
                header="Start Time"
            ></Column>

            <Column
                :sortable="true"
                field="end_time"
                header="End Time"
            ></Column>

            <!--            <Column-->
            <!--                :sortable="true"-->
            <!--                body-class="!text-center"-->
            <!--                class="w-4"-->
            <!--                field="assigned_employees"-->
            <!--                header="Assigned Employees"-->
            <!--            >-->
            <!--                <template #body="{ data }">-->
            <!--                    {{-->
            <!--                        data.assigned_employees === null-->
            <!--                            ? 0-->
            <!--                            : data.assigned_employees-->
            <!--                    }}-->
            <!--                </template>-->
            <!--            </Column>-->

            <Column>
                <template #body="{ data }">
                    <div class="flex justify-center items-center">
                        <div
                            v-if="
                                AuthStore.hasPermission(
                                    'can view employee shifts'
                                )
                            "
                        >
                            <router-link
                                :to="{
                                    name: 'shiftsDetails',
                                    params: { id: data.id },
                                }"
                            >
                                <svg
                                    :id="'view_' + data.id"
                                    class="icon icon-tabler icon-tabler-eye cursor-pointer"
                                    fill="none"
                                    height="18"
                                    stroke="currentColor"
                                    stroke-linecap="round"
                                    stroke-linejoin="round"
                                    stroke-width="2"
                                    viewBox="0 0 24 24"
                                    width="18"
                                    xmlns="http://www.w3.org/2000/svg"
                                >
                                    <path
                                        d="M0 0h24v24H0z"
                                        fill="none"
                                        stroke="none"
                                    ></path>
                                    <path
                                        d="M12 12m-2 0a2 2 0 1 0 4 0a2 2 0 1 0 -4 0"
                                    ></path>
                                    <path
                                        d="M22 12c-2.667 4.667 -6 7 -10 7s-7.333 -2.333 -10 -7c2.667 -4.667 6 -7 10 -7s7.333 2.333 10 7"
                                    ></path>
                                </svg>
                            </router-link>
                        </div>

                        <div
                            v-if="
                                AuthStore.hasPermission(
                                    'can update employee shifts'
                                )
                            "
                            class="ml-2"
                        >
                            <svg
                                :id="'update_' + data.id"
                                class="icon icon-tabler icon-tabler-edit cursor-pointer"
                                fill="none"
                                height="18"
                                stroke="currentColor"
                                stroke-linecap="round"
                                stroke-linejoin="round"
                                stroke-width="2"
                                viewBox="0 0 24 24"
                                width="18"
                                xmlns="http://www.w3.org/2000/svg"
                                @click="onClickButton(data)"
                            >
                                <path
                                    d="M0 0h24v24H0z"
                                    fill="none"
                                    stroke="none"
                                ></path>
                                <path
                                    d="M7 7h-1a2 2 0 0 0 -2 2v9a2 2 0 0 0 2 2h9a2 2 0 0 0 2 -2v-1"
                                ></path>
                                <path
                                    d="M20.385 6.585a2.1 2.1 0 0 0 -2.97 -2.97l-8.415 8.385v3h3l8.385 -8.415z"
                                ></path>
                                <path d="M16 5l3 3"></path>
                            </svg>
                        </div>

                        <div
                            v-if="
                                AuthStore.hasPermission(
                                    'can delete employee shifts'
                                )
                            "
                            class="ml-2"
                        >
                            <svg
                                :id="'delete_' + data.id"
                                class="icon icon-tabler icon-tabler-trash cursor-pointer"
                                fill="none"
                                height="18"
                                stroke="currentColor"
                                stroke-linecap="round"
                                stroke-linejoin="round"
                                stroke-width="2"
                                viewBox="0 0 24 24"
                                width="18"
                                xmlns="http://www.w3.org/2000/svg"
                                @click="confirmDelete($event, data)"
                            >
                                <path
                                    d="M0 0h24v24H0z"
                                    fill="none"
                                    stroke="none"
                                ></path>
                                <path d="M4 7l16 0"></path>
                                <path d="M10 11l0 6"></path>
                                <path d="M14 11l0 6"></path>
                                <path
                                    d="M5 7l1 12a2 2 0 0 0 2 2h8a2 2 0 0 0 2 -2l1 -12"
                                ></path>
                                <path
                                    d="M9 7v-3a1 1 0 0 1 1 -1h4a1 1 0 0 1 1 1v3"
                                ></path>
                            </svg>
                        </div>
                    </div>
                </template>
            </Column>
        </DataTable>

        <FormSlider
            :is-open="slider"
            header="Shift Form Details"
            size="xl"
            @close="onClickButton"
        >
            <ShiftFrom :shift="shift" @close="onClickButton" />
        </FormSlider>

        <ConfirmModal
            :deleted="deleted"
            :displayConfirmModal="showConfirmDialog"
            second-description="Shift has been successfully deleted."
            @closeConfirmModal="setConfirmDialog(false)"
            @deleted="deleted = $event"
            @proceedProcess="processDelete($event)"
        />
    </div>
</template>
