<script setup lang="ts">
import { useEmployeesStore } from "../../../stores/employeesStore";
import { onMounted, ref } from "vue";
import Button from "../../../base-components/Button";
import FormSlider from "../../FormSlider.vue";
import FormSalesAgent from "../../forms/client/FormSalesAgent.vue";
import { useClientEmployeeRoleStore } from "../../../stores/clientEmployeeRoleStore";
import { useRoute } from "vue-router";
import { FilterMatchMode, FilterOperator } from "primevue/api";
import LazyParamsInterface from "../../interfaces/lazyParamsInterface";
import DataTable from "primevue/datatable";
import Column from "primevue/column";
import ConfirmModal from "../ConfirmModal.vue";
import { useAuthStore } from "../../../stores/authStore";

const route = useRoute();
const AuthStore = useAuthStore();
const EmployeeStore = useEmployeesStore();
const ClientEmployeeStore = useClientEmployeeRoleStore();

const slider = ref(false);
const deleteId = ref(0);
const deleted = ref();
const showConfirmDialog = ref(false);
const lazyParams = ref<LazyParamsInterface>({});
const filters = ref({
    global: { value: null, matchMode: FilterMatchMode.CONTAINS },
    "employee.name": {
        operator: FilterOperator.AND,
        constraints: [{ value: null, matchMode: FilterMatchMode.STARTS_WITH }],
    },
});
const searchableCols = ref(["employee.name"]);
const loading = ref(false);
const showClearFilter = ref(false);
const dt = ref();

const close = async () => {
    slider.value = !slider.value;

    await onDataFetch();
};

const onFilter = async (event: any) => {
    showClearFilter.value = filters.value.global.value !== "";

    lazyParams.value.filters = filters.value;

    await onDataFetch();
};

const onSort = async (event: any) => {
    showClearFilter.value = true;
    lazyParams.value = event;

    await onDataFetch();
};

const onPage = async (event: any) => {
    lazyParams.value = event;

    await onDataFetch();
};

const confirmDelete = (event: { preventDefault: () => void }, data: any) => {
    event.preventDefault();
    deleteId.value = data.id;
    setConfirmDialog(true);
};

const setConfirmDialog = (value: boolean) => {
    showConfirmDialog.value = value;
};

const processDelete = (event: any) => {
    if (event === false) {
        deleteId.value = 0;
    } else {
        ClientEmployeeStore.deleteClientEmployeeRole(deleteId.value)
            .then(() => {
                deleteId.value = 0;
                deleted.value = true;
            })
            .catch((error) => {
                deleted.value = false;
            });
    }
};

const onDataFetch = async () => {
    loading.value = true;

    const params = {
        client_id: route?.params?.id,
        dt_params: JSON.stringify(lazyParams.value),
        searchable_columns: JSON.stringify(searchableCols.value),
    };

    await ClientEmployeeStore.getAllClientEmployeeRoles(params);

    loading.value = false;
};

onMounted(async () => {
    await onDataFetch();
    await EmployeeStore.getAllEmployees({ sales: true });
});
</script>

<template>
    <div class="intro-y box col-span-12 border-2">
        <div
            class="flex items-center px-5 py-5 sm:py-5 border-b border-slate-200/60 dark:border-darkmode-400"
        >
            <div class="flex-1 flex justify-between">
                <h2 id="sales-agent" class="font-medium text-base mr-auto">
                    Internal Staff
                </h2>

                <Button
                    v-if="
                        AuthStore.hasPermission(
                            'can create client employee roles'
                        )
                    "
                    id="add-sales-agent"
                    class="text-xs"
                    variant="soft-secondary"
                    @click="slider = !slider"
                >
                    Add Internal Staff
                </Button>
            </div>
        </div>

        <div class="p-5">
            <DataTable
                ref="dt"
                v-model:filters="filters"
                :globalFilterFields="searchableCols"
                :lazy="true"
                :loading="loading"
                :paginator="true"
                :rows="10"
                :totalRecords="ClientEmployeeStore.clientEmployeeRoles?.total"
                :value="ClientEmployeeStore.clientEmployeeRoles?.data"
                class="mt-4 p-datatable-sm text-xs"
                filterDisplay="menu"
                responsiveLayout="scroll"
                showGridlines
                stripedRows
                @filter="onFilter($event)"
                @page="onPage($event)"
                @sort="onSort($event)"
            >
                <template #empty> No available data.</template>

                <template #loading> Loading data. Please wait.</template>

                <Column :sortable="true" field="employee.name" header="Name">
                </Column>

                <Column class="!w-[5%]">
                    <template #body="{ data }">
                        <div class="flex justify-center items-center">
                            <div
                                v-if="
                                    AuthStore.hasPermission(
                                        'can delete client employee roles'
                                    )
                                "
                            >
                                <svg
                                    :id="'delete_' + data.id"
                                    class="icon icon-tabler icon-tabler-trash cursor-pointer"
                                    fill="none"
                                    height="18"
                                    stroke="currentColor"
                                    stroke-linecap="round"
                                    stroke-linejoin="round"
                                    stroke-width="2"
                                    viewBox="0 0 24 24"
                                    width="18"
                                    xmlns="http://www.w3.org/2000/svg"
                                    @click="confirmDelete($event, data)"
                                >
                                    <path
                                        d="M0 0h24v24H0z"
                                        fill="none"
                                        stroke="none"
                                    ></path>
                                    <path d="M4 7l16 0"></path>
                                    <path d="M10 11l0 6"></path>
                                    <path d="M14 11l0 6"></path>
                                    <path
                                        d="M5 7l1 12a2 2 0 0 0 2 2h8a2 2 0 0 0 2 -2l1 -12"
                                    ></path>
                                    <path
                                        d="M9 7v-3a1 1 0 0 1 1 -1h4a1 1 0 0 1 1 1v3"
                                    ></path>
                                </svg>
                            </div>
                        </div>
                    </template>
                </Column>
            </DataTable>
        </div>
    </div>

    <FormSlider
        header="Internal Staff Form"
        :isOpen="slider"
        :size="'md'"
        @close="close()"
    >
        <FormSalesAgent @close="close()" />
    </FormSlider>

    <ConfirmModal
        :deleted="deleted"
        :displayConfirmModal="showConfirmDialog"
        second-description="Agent has been successfully deleted."
        @closeConfirmModal="setConfirmDialog(false)"
        @deleted="deleted = $event"
        @proceedProcess="processDelete($event)"
    />
</template>

<style scoped></style>
