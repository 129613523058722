<script setup lang="ts">
import PageHeaderStandard from "../components/PageHeaderStandard.vue";
import { usePayrollConfigurationStore } from "../../stores/payrollConfigurationStore";
import { onMounted, reactive, ref } from "vue";
import LazyParamsInterface from "../interfaces/lazyParamsInterface";
import { FilterMatchMode, FilterOperator } from "primevue/api";
import DataTable from "primevue/datatable";
import Column from "primevue/column";
import Button from "../../base-components/Button";
import { FormInput } from "../../base-components/Form";
import Lucide from "../../base-components/Lucide";
import InputText from "primevue/inputtext";
import { PayrollConfigurationInterface } from "../interfaces/payrollConfigurationInterface";
import FormSlider from "../FormSlider.vue";
import FormPayrollConfiguration from "../forms/payroll/FormPayrollConfiguration.vue";
import ConfirmModal from "../components/ConfirmModal.vue";
import { useAuthStore } from "../../stores/authStore";

const AuthStore = useAuthStore();
const PayrollConfigurationStore = usePayrollConfigurationStore();

const lazyParams = ref<LazyParamsInterface>({});
const filters = ref({
    global: { value: null, matchMode: FilterMatchMode.CONTAINS },
    start_date: {
        operator: FilterOperator.AND,
        constraints: [{ value: null, matchMode: FilterMatchMode.EQUALS }],
    },
    end_date: {
        operator: FilterOperator.AND,
        constraints: [{ value: null, matchMode: FilterMatchMode.EQUALS }],
    },
    time_correction_end_date: {
        operator: FilterOperator.AND,
        constraints: [{ value: null, matchMode: FilterMatchMode.EQUALS }],
    },
    lock_in_date: {
        operator: FilterOperator.AND,
        constraints: [{ value: null, matchMode: FilterMatchMode.EQUALS }],
    },
    description: {
        operator: FilterOperator.AND,
        constraints: [{ value: null, matchMode: FilterMatchMode.CONTAINS }],
    },
    notes: {
        operator: FilterOperator.AND,
        constraints: [{ value: null, matchMode: FilterMatchMode.CONTAINS }],
    },
});
const searchableCols = ref(["description", "notes"]);
const dt = ref();
const loading = ref(false);
const showClearFilter = ref(false);
const range = reactive({
    from: null,
    to: null,
});
const payrollConfiguration = ref<PayrollConfigurationInterface>();
const slider = ref(false);
const deleteId = ref(0);
const deleted = ref();
const showConfirmDialog = ref(false);

const confirmDelete = (event: { preventDefault: () => void }, data: any) => {
    event.preventDefault();
    deleteId.value = data.id;
    setConfirmDialog(true);
};

const setConfirmDialog = (value: boolean) => {
    showConfirmDialog.value = value;
};

const processDelete = (event: any) => {
    if (event === false) {
        deleteId.value = 0;
    } else {
        PayrollConfigurationStore.deletePayrollConfiguration(deleteId.value)
            .then(() => {
                deleteId.value = 0;
                deleted.value = true;
            })
            .catch((error) => {
                deleted.value = false;
            });
    }
};

const onFilter = async (event: any) => {
    showClearFilter.value = filters.value.global.value !== "";

    lazyParams.value.filters = filters.value;

    await onDataFetch();
};

const onSort = async (event: any) => {
    showClearFilter.value = true;
    lazyParams.value = event;

    await onDataFetch();
};

const onPage = async (event: any) => {
    lazyParams.value = event;

    await onDataFetch();
};

const onDataFetch = async () => {
    loading.value = true;

    const params = {
        dt_params: JSON.stringify(lazyParams.value),
        searchable_columns: JSON.stringify(searchableCols.value),
        range,
    };
    await PayrollConfigurationStore.getPayrollConfigurations(params);

    loading.value = false;
};

const onClickButton = (data: any) => {
    slider.value = !slider.value;

    if (data && Object.keys(data).length > 0) {
        payrollConfiguration.value = data;
    } else {
        payrollConfiguration.value = {} as PayrollConfigurationInterface;
    }
};

const onChangeDateRange = async () => {
    if (!range.from || !range.to) {
        return;
    }

    showClearFilter.value = true;

    lazyParams.value.filters = filters.value;

    loading.value = true;

    const params = {
        dt_params: JSON.stringify(lazyParams.value),
        searchable_columns: JSON.stringify(searchableCols.value),
        range,
    };

    await PayrollConfigurationStore.getPayrollConfigurations(params);

    loading.value = false;
};

const resetGlobalFilter = () => {
    showClearFilter.value = false;

    filters.value = {
        global: { value: null, matchMode: FilterMatchMode.CONTAINS },
        start_date: {
            operator: FilterOperator.AND,
            constraints: [{ value: null, matchMode: FilterMatchMode.EQUALS }],
        },
        end_date: {
            operator: FilterOperator.AND,
            constraints: [{ value: null, matchMode: FilterMatchMode.EQUALS }],
        },
        time_correction_end_date: {
            operator: FilterOperator.AND,
            constraints: [{ value: null, matchMode: FilterMatchMode.EQUALS }],
        },
        lock_in_date: {
            operator: FilterOperator.AND,
            constraints: [{ value: null, matchMode: FilterMatchMode.EQUALS }],
        },
        description: {
            operator: FilterOperator.AND,
            constraints: [{ value: null, matchMode: FilterMatchMode.CONTAINS }],
        },
        notes: {
            operator: FilterOperator.AND,
            constraints: [{ value: null, matchMode: FilterMatchMode.CONTAINS }],
        },
    };

    lazyParams.value = {
        first: 0,
        rows: dt.value.rows,
        sortField: null,
        sortOrder: null,
        filters: filters.value,
    };

    range.from = null;
    range.to = null;

    onDataFetch();
};

onMounted(async () => {
    await onDataFetch();
    await AuthStore.getLoggedInUserDetails();
});
</script>

<template>
    <div class="w-full">
        <PageHeaderStandard title="Payroll Configuration" />

        <div
            class="intro-y col-span-12 flex flex-wrap sm:flex-nowrap items-center mt-2"
        >
            <Button
                v-if="
                    AuthStore.hasPermission('can create payroll configurations')
                "
                class="mr-2 shadow-sm"
                variant="customPrimary"
                @click="onClickButton"
            >
                Payroll Configuration
            </Button>

            <div class="hidden md:block mx-auto text-slate-500"></div>

            <div class="flex w-full sm:w-auto mt-3 sm:mt-0 sm:ml-auto md:ml-0">
                <template v-if="showClearFilter">
                    <button
                        class="btn bg-custom-color-1000 text-white w-full mx-3 rounded-md px-4"
                        @click="resetGlobalFilter"
                    >
                        Clear
                        <span
                            class="h-3 w-4 pi pi-filter-slash p-component"
                        ></span>
                    </button>
                </template>

                <div class="w-56 relative text-slate-500">
                    <FormInput
                        v-model="range.from"
                        :value="range.from"
                        class="w-56 !box"
                        type="date"
                        @change="onChangeDateRange()"
                    />
                </div>

                <div class="ml-2 w-56 relative text-slate-500">
                    <FormInput
                        v-model="range.to"
                        :value="range.to"
                        class="w-56 !box"
                        type="date"
                        @change="onChangeDateRange()"
                    />
                </div>

                <div class="ml-2 w-64 relative text-slate-500">
                    <FormInput
                        v-model="filters.global.value"
                        :value="filters.global.value"
                        class="w-64 pr-10 !box"
                        placeholder="Search description or note..."
                        type="text"
                        @keyup.enter="onFilter($event)"
                    />

                    <Lucide
                        class="absolute inset-y-0 right-0 w-4 h-4 my-auto mr-3"
                        icon="Search"
                    />
                </div>
            </div>
        </div>

        <DataTable
            ref="dt"
            v-model:filters="filters"
            :lazy="true"
            :loading="loading"
            :paginator="true"
            :rows="10"
            :total-records="
                PayrollConfigurationStore.payrollConfigurations?.total
            "
            :value="PayrollConfigurationStore.payrollConfigurations?.data"
            class="mt-4 p-datatable-sm rounded-lg shadow-lg text-xs"
            filter-display="menu"
            responsive-layout="scroll"
            show-gridlines
            striped-rows
            @filter="onFilter($event)"
            @page="onPage($event)"
            @sort="onSort($event)"
        >
            <template #empty> No available data.</template>

            <template #loading> Loading data. Please wait.</template>

            <Column
                header="Start Date"
                field="start_date"
                class="!w-[10%]"
                sortable
            >
                <template #filter="{ filterModel }">
                    <InputText
                        v-model="filterModel.value"
                        class="p-column-filter"
                        type="date"
                    />
                </template>
            </Column>

            <Column
                header="End Date"
                field="end_date"
                class="!w-[10%]"
                sortable
            >
                <template #filter="{ filterModel }">
                    <InputText
                        v-model="filterModel.value"
                        class="p-column-filter"
                        type="date"
                    />
                </template>
            </Column>

            <Column
                header="Time Correction End Date"
                field="time_correction_end_date"
                class="!w-[10%]"
                sortable
            >
                <template #filter="{ filterModel }">
                    <InputText
                        v-model="filterModel.value"
                        class="p-column-filter"
                        type="datetime-local"
                    />
                </template>
            </Column>

            <Column
                header="Lock In Date"
                field="lock_in_date"
                class="!w-[10%]"
                sortable
            >
                <template #filter="{ filterModel }">
                    <InputText
                        v-model="filterModel.value"
                        class="p-column-filter"
                        type="datetime-local"
                    />
                </template>
            </Column>

            <Column header="Description" field="description" sortable />

            <Column header="Notes" field="notes" sortable />

            <Column class="!w-[5%]">
                <template #body="{ data }">
                    <div class="flex justify-center items-center">
                        <div
                            v-if="
                                AuthStore.hasPermission(
                                    'can update payroll configurations'
                                )
                            "
                        >
                            <svg
                                :id="'update_' + data.id"
                                class="icon icon-tabler icon-tabler-edit cursor-pointer"
                                fill="none"
                                height="18"
                                stroke="currentColor"
                                stroke-linecap="round"
                                stroke-linejoin="round"
                                stroke-width="2"
                                viewBox="0 0 24 24"
                                width="18"
                                xmlns="http://www.w3.org/2000/svg"
                                @click="onClickButton(data)"
                            >
                                <path
                                    d="M0 0h24v24H0z"
                                    fill="none"
                                    stroke="none"
                                ></path>
                                <path
                                    d="M7 7h-1a2 2 0 0 0 -2 2v9a2 2 0 0 0 2 2h9a2 2 0 0 0 2 -2v-1"
                                ></path>
                                <path
                                    d="M20.385 6.585a2.1 2.1 0 0 0 -2.97 -2.97l-8.415 8.385v3h3l8.385 -8.415z"
                                ></path>
                                <path d="M16 5l3 3"></path>
                            </svg>
                        </div>

                        <div
                            v-if="
                                AuthStore.hasPermission(
                                    'can delete payroll configurations'
                                )
                            "
                            class="ml-2"
                        >
                            <svg
                                :id="'delete_' + data.id"
                                class="icon icon-tabler icon-tabler-trash cursor-pointer"
                                fill="none"
                                height="18"
                                stroke="currentColor"
                                stroke-linecap="round"
                                stroke-linejoin="round"
                                stroke-width="2"
                                viewBox="0 0 24 24"
                                width="18"
                                xmlns="http://www.w3.org/2000/svg"
                                @click="confirmDelete($event, data)"
                            >
                                <path
                                    d="M0 0h24v24H0z"
                                    fill="none"
                                    stroke="none"
                                ></path>
                                <path d="M4 7l16 0"></path>
                                <path d="M10 11l0 6"></path>
                                <path d="M14 11l0 6"></path>
                                <path
                                    d="M5 7l1 12a2 2 0 0 0 2 2h8a2 2 0 0 0 2 -2l1 -12"
                                ></path>
                                <path
                                    d="M9 7v-3a1 1 0 0 1 1 -1h4a1 1 0 0 1 1 1v3"
                                ></path>
                            </svg>
                        </div>
                    </div>
                </template>
            </Column>
        </DataTable>
    </div>

    <FormSlider
        :is-open="slider"
        header="Payroll Configuration Form"
        size="lg"
        @close="onClickButton"
    >
        <FormPayrollConfiguration
            :payroll-configuration="payrollConfiguration"
            @close="onClickButton"
        ></FormPayrollConfiguration>
    </FormSlider>

    <ConfirmModal
        :deleted="deleted"
        :displayConfirmModal="showConfirmDialog"
        second-description="Payroll configuration has been successfully deleted."
        @closeConfirmModal="setConfirmDialog(false)"
        @deleted="deleted = $event"
        @proceedProcess="processDelete($event)"
    />
</template>
