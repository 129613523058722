<script lang="ts" setup>
import { computed, onMounted, onUpdated, ref } from "vue";
import {
    InvoiceInterface,
    InvoiceLineItemInterface,
} from "./interfaces/invoiceInterface";
import { useInvoiceStore } from "../stores/invoiceStore";
import { useAuthStore } from "../stores/authStore";
import LazyParamsInterface from "./interfaces/lazyParamsInterface";
import Button from "../base-components/Button";
import FormSlider from "./FormSlider.vue";
import ConfirmModal from "./components/ConfirmModal.vue";
import PageHeaderStandard from "./components/PageHeaderStandard.vue";
import { useRoute } from "vue-router";
import FormInvoiceLineItem from "./forms/invoice/FormInvoiceLineItem.vue";
import { useEmployeesStore } from "../stores/employeesStore";
import { Menu } from "../base-components/Headless";
import Lucide from "../base-components/Lucide";
import FormInvoice from "./forms/invoice/FormInvoice.vue";
import { Tippy } from "vue-tippy";

import { useUiStore } from "../stores/uiStore";
import FormSendInvoiceEmail from "./forms/invoice/FormSendInvoiceEmail.vue";
import { FilterMatchMode, FilterOperator } from "primevue/api";
import DataTable from "primevue/datatable";
import Column from "primevue/column";
import ColumnGroup from "primevue/columngroup";
import Row from "primevue/row";
import { FormInput } from "../base-components/Form";
import Dropdown from "primevue/dropdown";
import InputText from "primevue/inputtext";
import Avatar from "./components/Avatar.vue";
import { useActivityLogsStore } from "../stores/activityLogsStore";
import InvoicePrivateNotes from "./components/invoices/InvoicePrivateNotes.vue";
import Dinero from "dinero.js";
import useHelper from "@/composables/helper";

const route = useRoute();
const InvoiceStore = useInvoiceStore();
const AuthStore = useAuthStore();
const EmployeeStore = useEmployeesStore();
const UiStore = useUiStore();
const ActivityLogStore = useActivityLogsStore();
const helper = useHelper();

const warning = ref(false);
const slider = ref(false);
const mainFormSlider = ref(false);
const deleteId = ref(0);
const deleted = ref();
const showConfirmDialog = ref(false);
const loading = ref(false);
const item = ref<InvoiceLineItemInterface>();
const sendEmailSlider = ref(false);
const showClearFilter = ref(false);
const dt = ref();
const filters = ref({
    global: { value: null, matchMode: FilterMatchMode.CONTAINS },
    "employee.name": {
        operator: FilterOperator.AND,
        constraints: [{ value: null, matchMode: FilterMatchMode.STARTS_WITH }],
    },
    "type.name": {
        operator: FilterOperator.AND,
        constraints: [{ value: null, matchMode: FilterMatchMode.STARTS_WITH }],
    },
    description: {
        operator: FilterOperator.AND,
        constraints: [{ value: null, matchMode: FilterMatchMode.CONTAINS }],
    },
});
const searchableCols = ref(["employee.name", "type.name", "description"]);
const lazyParams = ref<LazyParamsInterface>({
    first: 0,
    rows: 200,
    sortField: null,
    sortOrder: null,
    filters: filters.value,
});
const onFilter = async (event: any) => {
    showClearFilter.value = filters.value.global.value !== "";

    lazyParams.value.filters = filters.value;

    await onDataFetch();
};

const onSort = async (event: any) => {
    showClearFilter.value = true;
    lazyParams.value = event;
    await onDataFetch();
};

const onPage = async (event: any) => {
    lazyParams.value = event;

    await onDataFetch();
};

const totalAmount = computed(() => {
    if (!InvoiceStore.invoice) return 0;

    if (!InvoiceStore.invoiceLineItems?.data?.data) return 0;

    let total = InvoiceStore.invoiceLineItems?.data?.data.reduce(
        (total, item) => +total + +item.line_item_total,
        0
    );

    if (InvoiceStore.invoice.tax_amount) {
        total = total + +InvoiceStore.invoice.tax_amount;
    }

    if (InvoiceStore.invoice.discount_amount) {
        total = total - +InvoiceStore.invoice.discount_amount;
    }

    return total ?? 0;
});

const dueDateDisplay = computed(() => {
    let dueDate = InvoiceStore.invoice?.due_date;
    const netTerms = InvoiceStore.invoice?.net_terms;

    if (netTerms && netTerms == 0) {
        dueDate = "Due upon receipt";
    }

    if (netTerms && netTerms > 0) {
        dueDate = `Due in ${netTerms} day${
            netTerms > 1 ? "s" : ""
        } from receipt`;
    }

    return `Due Date: ${dueDate}`;
});

const onClickButton = (data: any) => {
    slider.value = !slider.value;

    if (
        data &&
        Object.keys(data).length > 0 &&
        !(data instanceof PointerEvent)
    ) {
        item.value = data;
    } else {
        item.value = {} as InvoiceLineItemInterface;
    }
};

const onCloseSlider = () => {
    sendEmailSlider.value = false;

    item.value = {} as InvoiceLineItemInterface;
};

const onClickMainFormButton = () => {
    mainFormSlider.value = !mainFormSlider.value;
};

const onClickSendEmail = () => {
    sendEmailSlider.value = !sendEmailSlider.value;
};

const close = async () => {
    slider.value = !slider.value;

    await onDataFetch();
};

const confirmDelete = (event: { preventDefault: () => void }, data: any) => {
    event.preventDefault();
    deleteId.value = data.id;
    setConfirmDialog(true);
};

const setConfirmDialog = (value: boolean) => {
    showConfirmDialog.value = value;
};

const processDelete = (event: any) => {
    if (event === false) {
        deleteId.value = 0;
    } else {
        InvoiceStore.deleteLineItem(deleteId.value)
            .then(async () => {
                deleteId.value = 0;
                deleted.value = true;

                await InvoiceStore.getInvoice(+route?.params?.id);
                await ActivityLogStore.fill("Invoice", +route?.params?.id);
            })
            .catch((error) => {
                deleted.value = false;
            });
    }
};

const onDataFetch = async () => {
    const id = route?.params?.id;

    loading.value = true;

    await InvoiceStore.getInvoice(+id);
    const params = {
        dt_params: JSON.stringify(lazyParams.value),
        searchable_columns: JSON.stringify(searchableCols.value),
    };
    await InvoiceStore.getInvoiceLineItems(+id, params);

    loading.value = false;
};

const dataTableKey = ref(0); // Initial key for the DataTable

const resetGlobalFilter = () => {
    showClearFilter.value = false;

    filters.value = {
        global: { value: null, matchMode: FilterMatchMode.CONTAINS },
        "employee.name": {
            operator: FilterOperator.AND,
            constraints: [
                { value: null, matchMode: FilterMatchMode.STARTS_WITH },
            ],
        },
        "type.name": {
            operator: FilterOperator.AND,
            constraints: [
                { value: null, matchMode: FilterMatchMode.STARTS_WITH },
            ],
        },
        description: {
            operator: FilterOperator.AND,
            constraints: [{ value: null, matchMode: FilterMatchMode.CONTAINS }],
        },
    };

    lazyParams.value = {
        first: 0,
        rows: dt.value ? dt.value.rows : 20, // Provide default row count if dt is not available
        sortField: null,
        sortOrder: null,
        filters: filters.value,
    };

    dataTableKey.value++;

    onDataFetch();
};

const onClickPrint = async (summary: boolean = false) => {
    if (summary) {
        await InvoiceStore.printInvoice(+route?.params?.id, true);

        return;
    }

    await InvoiceStore.printInvoice(+route?.params?.id);
};

const onClickPrintInternal = async () => {
    await InvoiceStore.printInvoice(+route?.params?.id, false, {
        internal: "true",
    });
};

const onClickExportExcel = async () => {
    await InvoiceStore.exportInvoice(+route?.params?.id);
};

const onClickExportExcelInternal = async () => {
    await InvoiceStore.exportInvoice(+route?.params?.id, { internal: "true" });
};

onMounted(async () => {
    await onDataFetch();
    await InvoiceStore.getInvoiceLineItemTypes();
    await AuthStore.getLoggedInUserDetails();
    await ActivityLogStore.fill("Invoice", +route?.params?.id);

    warning.value =
        InvoiceStore?.invoiceLineItems?.data?.data?.some(
            (a) => a.line_item_total == 0
        ) ?? false;
});

onUpdated(async () => {
    UiStore.setBreadcrumb("Invoice Details > INV-" + route?.params?.id);
});

const dialog = ref(false);
const open = () => {
    dialog.value = true;
};

const handleLineItemIcon = (item: InvoiceLineItemInterface) => {
    if (item.user_id !== null && !item.generated_via_cli) {
        return "User";
    } else if (item.user_id !== null && item.generated_via_cli) {
        return "UserCog";
    } else {
        return "Cpu";
    }
};

const handleLineItemText = (item: InvoiceLineItemInterface) => {
    if (item.user_id !== null && !item.generated_via_cli) {
        return "User generated";
    } else if (item.user_id !== null && item.generated_via_cli) {
        return "Modified by a user";
    } else {
        return "System generated";
    }
};

const rowClass = (data: any) => {
    return [
        {
            "!bg-blue-50": data.user_id !== null && !data.generated_via_cli,
            "!bg-yellow-50": data.user_id !== null && data.generated_via_cli,
            "!bg-green-50": data.user_id === null,
        },
    ];
};

const zeroAmount = computed(
    () =>
        InvoiceStore?.invoiceLineItems?.data?.data?.filter(
            (a) => a.line_item_total == 0
        ).length
);
</script>

<template>
    <div class="w-full">
        <PageHeaderStandard title="'Invoice Details'" />

        <div
            v-if="warning"
            class="bg-orange-50 text-orange-700 p-4 rounded shadow-sm flex justify-between items-center transition-all duration-300 ease-in-out"
        >
            <div>
                Found
                {{ zeroAmount }}
                line {{ zeroAmount > 1 ? "items" : "item" }} with zero total
                amount.
            </div>

            <div class="cursor-pointer" @click="warning = !warning">
                <Lucide :icon="'XCircle'" class="h-4 w-4" />
            </div>
        </div>

        <div class="border-1 border-blue-50 grid grid-cols-12 gap-4">
            <div class="col-span-3">
                <div class="p-5 rounded-md box mt-5">
                    <div
                        class="flex items-center pb-5 mb-5 border-b border-slate-200/60 dark:border-darkmode-400"
                    >
                        <div class="flex-1 flex justify-between items-center">
                            <div class="text-base font-medium truncate">
                                Invoice - #{{ InvoiceStore.invoice?.id }}
                            </div>

                            <div class="flex">
                                <Menu class="ml-auto sm:ml-0 z-50">
                                    <Menu.Button
                                        :as="Button"
                                        class="px-2 bg-slate-300 border-secondary bg-opacity-20 text-slate-500 dark:bg-darkmode-100/20 dark:border-darkmode-100/30 dark:text-slate-300 [&:hover:not(:disabled)]:bg-opacity-10 [&:hover:not(:disabled)]:dark:bg-darkmode-100/10 [&:hover:not(:disabled)]:dark:border-darkmode-100/20"
                                    >
                                        <span
                                            class="flex items-center justify-center w-5 h-5"
                                        >
                                            <Lucide
                                                class="w-4 h-4"
                                                icon="Download"
                                            />
                                        </span>
                                    </Menu.Button>
                                    <Menu.Items class="w-72 -mr-32">
                                        <Menu.Item @click="onClickExportExcel">
                                            <Lucide
                                                class="w-4 h-4 mr-2"
                                                icon="File"
                                            />
                                            Export Excel
                                        </Menu.Item>
                                        <Menu.Item
                                            @click="onClickExportExcelInternal"
                                        >
                                            <Lucide
                                                class="w-4 h-4 mr-2"
                                                icon="File"
                                            />
                                            Export Excel - Internal
                                        </Menu.Item>
                                        <Menu.Item @click="onClickPrint(false)">
                                            <Lucide
                                                class="w-4 h-4 mr-2"
                                                icon="File"
                                            />
                                            Export to PDF
                                        </Menu.Item>
                                        <Menu.Item @click="onClickPrint(true)">
                                            <Lucide
                                                class="w-4 h-4 mr-2"
                                                icon="File"
                                            />
                                            Export to PDF - Summary Only
                                        </Menu.Item>
                                        <Menu.Item
                                            @click="onClickPrintInternal"
                                        >
                                            <Lucide
                                                class="w-4 h-4 mr-2"
                                                icon="File"
                                            />
                                            Export to PDF - Internal
                                        </Menu.Item>
                                        <Menu.Item
                                            v-if="
                                                AuthStore.hasPermission(
                                                    'can send invoice email'
                                                )
                                            "
                                            @click="onClickSendEmail"
                                        >
                                            <Lucide
                                                class="w-4 h-4 mr-2"
                                                icon="Send"
                                            />
                                            Send Email
                                        </Menu.Item>
                                    </Menu.Items>
                                </Menu>

                                <Button
                                    class="ml-4"
                                    variant="soft-secondary"
                                    @click="onClickMainFormButton"
                                >
                                    <Lucide class="w-4 h-4" icon="Pencil" />
                                </Button>
                            </div>
                        </div>
                        <!--                        <a-->
                        <!--                            class="flex items-center ml-auto text-primary"-->
                        <!--                            href=""-->
                        <!--                        >-->
                        <!--                            <Lucide class="w-4 h-4 mr-2" icon="Edit" />-->
                        <!--                            Change Status-->
                        <!--                        </a>-->
                    </div>
                    <div class="flex items-center">
                        <Lucide
                            class="w-4 h-4 mr-2 text-slate-500"
                            icon="Clipboard"
                        />
                        Client:
                        <router-link
                            :to="{
                                path:
                                    '/clients/' +
                                    InvoiceStore.invoice?.client_id,
                            }"
                            class="ml-1"
                            target="_blank"
                        >
                            {{
                                InvoiceStore.invoice?.client
                                    ?.legal_business_name
                            }}
                        </router-link>

                        <Lucide
                            class="w-4 h-4 ml-2 text-slate-500"
                            icon="ExternalLink"
                        />
                    </div>
                    <div class="flex items-center mt-3">
                        <Lucide
                            class="w-4 h-4 mr-2 text-slate-500"
                            icon="Calendar"
                        />
                        Start Date: {{ InvoiceStore.invoice?.start_date }}
                    </div>
                    <div class="flex items-center mt-3">
                        <Lucide
                            class="w-4 h-4 mr-2 text-slate-500"
                            icon="Calendar"
                        />
                        End Date: {{ InvoiceStore.invoice?.end_date }}
                    </div>
                    <div class="flex items-center mt-3">
                        <Lucide
                            class="w-4 h-4 mr-2 text-slate-500"
                            icon="Calendar"
                        />
                        {{ dueDateDisplay }}
                    </div>
                    <div class="flex items-center mt-3">
                        <Lucide
                            class="w-4 h-4 mr-2 text-slate-500"
                            icon="StickyNote"
                        />
                        Notes: {{ InvoiceStore.invoice?.notes }}
                    </div>
                    <div class="flex items-center mt-3">
                        <Lucide
                            class="w-4 h-4 mr-2 text-slate-500"
                            icon="StickyNote"
                        />
                        Invoice Reference:
                        {{ InvoiceStore.invoice?.invoice_reference }}
                    </div>
                    <div class="flex items-center mt-3">
                        <Lucide
                            class="w-4 h-4 mr-2 text-slate-500"
                            icon="Clock"
                        />
                        Transaction Status:
                        <span
                            class="px-2 ml-1 rounded"
                            :class="{
                                'bg-warning/20 text-warning':
                                    InvoiceStore.invoice?.invoice_status_id ==
                                        1 ||
                                    InvoiceStore.invoice?.invoice_status_id ==
                                        6,
                                'bg-primary/20 text-primary':
                                    InvoiceStore.invoice?.invoice_status_id ==
                                    2,
                                'bg-success/20 text-success':
                                    InvoiceStore.invoice?.invoice_status_id ==
                                        3 ||
                                    InvoiceStore.invoice?.invoice_status_id ==
                                        7,
                                'bg-danger/20 text-danger':
                                    InvoiceStore.invoice?.invoice_status_id ==
                                    4,
                            }"
                        >
                            {{ InvoiceStore.invoice?.invoice_status?.name }}
                        </span>
                    </div>
                    <div class="flex items-center mt-3">
                        <Lucide
                            class="w-4 h-4 mr-2 text-slate-500"
                            icon="Clock"
                        />
                        Reviewer:
                        <span class="px-2 ml-1 rounded">
                            {{ InvoiceStore.invoice?.reviewer?.name }}
                        </span>
                    </div>

                    <div class="flex items-center mt-3">
                        <Lucide
                            class="w-4 h-4 mr-2 text-slate-500"
                            icon="DollarSign"
                        />
                        Total Amount:
                        {{ InvoiceStore.invoice?.currency?.symbol ?? "" }}
                        {{ helper.currencyFormat(+totalAmount ?? 0) }}
                    </div>
                </div>

                <InvoicePrivateNotes />

                <div class="p-5 rounded-md box mt-5">
                    <div class="text-base font-medium truncate">
                        Recent Activities
                    </div>

                    <div
                        class="mt-5 relative before:block before:absolute before:w-px before:h-[85%] before:bg-slate-200 before:dark:bg-darkmode-400 before:ml-5 before:mt-5"
                    >
                        <div
                            v-for="log in ActivityLogStore.logs"
                            class="intro-x relative flex items-center mb-3"
                        >
                            <div
                                class="before:block before:absolute before:w-20 before:h-px before:bg-slate-200 before:dark:bg-darkmode-400 before:mt-5 before:ml-5"
                            >
                                <Avatar
                                    :name="
                                        log.performedByName || 'Loading User'
                                    "
                                    :ring="'online'"
                                    :size="'sm'"
                                />
                            </div>
                            <div class="box px-5 py-3 ml-4 flex-1 zoom-in">
                                <div class="flex items-center">
                                    <div class="font-medium">
                                        {{ log.performedByName }}
                                    </div>
                                    <div class="text-xs text-slate-500 ml-auto">
                                        {{ log.created_at }}
                                    </div>
                                </div>
                                <div class="text-slate-500 mt-1">
                                    {{ log.event }}
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div class="col-span-9">
                <div
                    class="intro-y col-span-12 flex flex-wrap sm:flex-nowrap items-center"
                >
                    <div class="hidden md:block mx-auto text-slate-500"></div>
                </div>
                <div class="relative overflow-x-auto w-full box p-5 mt-5">
                    <div
                        class="pb-5 mb-5 border-b border-slate-200/60 dark:border-darkmode-400"
                    >
                        <div class="flex items-center">
                            <div class="text-base font-medium truncate">
                                Details
                            </div>

                            <Button
                                v-if="
                                    AuthStore.hasPermission(
                                        'can create invoices'
                                    )
                                "
                                class="flex items-center ml-auto text-primary"
                                @click="onClickButton"
                            >
                                <Lucide class="w-4 h-4 mr-2" icon="Plus" />
                                Add Item
                            </Button>
                        </div>

                        <div class="mt-6 flex justify-between items-center">
                            <div class="flex gap gap-x-4">
                                <div class="flex items-center">
                                    <div
                                        class="rounded-full w-2 h-2 bg-blue-300"
                                    ></div>

                                    <div class="ml-2 text-slate-600">
                                        User generated
                                    </div>
                                </div>

                                <div class="flex items-center">
                                    <div
                                        class="rounded-full w-2 h-2 bg-yellow-300"
                                    ></div>

                                    <div class="ml-2 text-slate-600">
                                        Modified by a user
                                    </div>
                                </div>

                                <div class="flex items-center">
                                    <div
                                        class="rounded-full w-2 h-2 bg-green-300"
                                    ></div>

                                    <div class="ml-2 text-slate-600">
                                        System generated
                                    </div>
                                </div>
                            </div>

                            <div class="flex items-center gap gap-x-1">
                                <template v-if="showClearFilter">
                                    <button
                                        class="btn bg-custom-color-1000 text-white w-full rounded-md px-4 h-[35px]"
                                        @click="resetGlobalFilter"
                                    >
                                        Clear
                                        <span
                                            class="h-3 w-4 pi pi-filter-slash p-component"
                                        ></span>
                                    </button>
                                </template>

                                <div class="ml-2 w-56 relative text-slate-500">
                                    <FormInput
                                        id="global-filter"
                                        v-model="filters.global.value"
                                        :value="filters.global.value"
                                        class="w-56 pr-10"
                                        placeholder="Search..."
                                        type="text"
                                        @keyup.enter="onFilter($event)"
                                    />

                                    <Lucide
                                        class="absolute inset-y-0 right-0 w-4 h-4 my-auto mr-3"
                                        icon="Search"
                                    />
                                </div>
                            </div>
                        </div>
                    </div>

                    <DataTable
                        :key="dataTableKey"
                        ref="dt"
                        v-model:filters="filters"
                        :globalFilterFields="searchableCols"
                        :lazy="true"
                        :loading="loading"
                        :paginator="true"
                        :rows="200"
                        :totalRecords="
                            InvoiceStore?.invoiceLineItems?.data?.total
                        "
                        :value="InvoiceStore?.invoiceLineItems?.data?.data"
                        class="mt-4 p-datatable-sm text-xs"
                        filterDisplay="menu"
                        responsiveLayout="scroll"
                        showGridlines
                        stripedRows
                        @filter="onFilter($event)"
                        @page="onPage($event)"
                        @sort="onSort($event)"
                        :row-class="rowClass"
                    >
                        <template #empty> No available data.</template>

                        <template #loading>
                            Loading data. Please wait.</template
                        >

                        <Column class="!w-[1%]">
                            <template #body="{ data }">
                                <Tippy
                                    :content="handleLineItemText(data)"
                                    placement="right"
                                >
                                    <Lucide
                                        :id="`item-${data.id}`"
                                        class="h-4 w-4"
                                        :icon="handleLineItemIcon(data)"
                                        :class="{
                                            'text-blue-800':
                                                data.user_id !== null &&
                                                !data.generated_via_cli,
                                            'text-yellow-800':
                                                data.user_id !== null &&
                                                data.generated_via_cli,
                                            'text-green-800':
                                                data.user_id === null,
                                        }"
                                    />
                                </Tippy>
                            </template>
                        </Column>

                        <Column
                            :sortable="true"
                            field="type.name"
                            header="Type"
                        >
                            <template #filter="{ filterModel }">
                                <Dropdown
                                    v-model="filterModel.value"
                                    :options="
                                        InvoiceStore.invoiceLineItemTypes.data
                                    "
                                    option-label="name"
                                    option-value="name"
                                    placeholder="Select Type"
                                />
                            </template>
                        </Column>

                        <Column
                            :sortable="true"
                            field="employee.name"
                            header="Employee"
                        >
                            <template #filter="{ filterModel }">
                                <InputText
                                    v-model="filterModel.value"
                                    class="p-column-filter"
                                    placeholder="Search employee name"
                                    type="text"
                                />
                            </template>

                            <template #body="{ data }">
                                <div v-if="data.employee_id">
                                    <router-link
                                        :to="{
                                            path:
                                                '/employees/' +
                                                data.employee.id,
                                        }"
                                        class="flex items-center"
                                        target="_blank"
                                    >
                                        <Lucide
                                            class="w-4 h-4 text-slate-500"
                                            icon="ExternalLink"
                                        />

                                        <div class="ml-2">
                                            {{ data.employee.name }}
                                        </div>
                                    </router-link>
                                </div>
                            </template>
                        </Column>

                        <Column
                            :sortable="true"
                            field="description"
                            header="Description"
                        >
                            <template #filter="{ filterModel }">
                                <InputText
                                    v-model="filterModel.value"
                                    class="p-column-filter"
                                    placeholder="Search description"
                                    type="text"
                                />
                            </template>
                        </Column>

                        <Column
                            :sortable="true"
                            field="quantity"
                            header="Quantity"
                        ></Column>

                        <Column
                            :sortable="true"
                            field="unit_price"
                            header="Unit Price"
                        >
                            <template #body="{ data }">
                                {{
                                    InvoiceStore.invoice?.currency?.symbol ?? ""
                                }}
                                {{
                                    helper.currencyFormat(
                                        +data?.unit_price ?? 0
                                    )
                                }}
                            </template>
                        </Column>

                        <Column
                            :sortable="true"
                            field="line_item_total"
                            header="Total"
                        >
                            <template #body="{ data }">
                                {{
                                    InvoiceStore.invoice?.currency?.symbol ?? ""
                                }}
                                {{
                                    helper.currencyFormat(
                                        +data?.line_item_total ?? 0
                                    )
                                }}
                            </template>
                        </Column>

                        <Column class="!w-[5%]">
                            <template #body="{ data }">
                                <div class="flex justify-center items-center">
                                    <div
                                        v-if="
                                            AuthStore.hasPermission(
                                                'can update invoices'
                                            )
                                        "
                                    >
                                        <svg
                                            :id="'update_' + data.id"
                                            class="icon icon-tabler icon-tabler-edit cursor-pointer"
                                            fill="none"
                                            height="18"
                                            stroke="currentColor"
                                            stroke-linecap="round"
                                            stroke-linejoin="round"
                                            stroke-width="2"
                                            viewBox="0 0 24 24"
                                            width="18"
                                            xmlns="http://www.w3.org/2000/svg"
                                            @click="onClickButton(data)"
                                        >
                                            <path
                                                d="M0 0h24v24H0z"
                                                fill="none"
                                                stroke="none"
                                            ></path>
                                            <path
                                                d="M7 7h-1a2 2 0 0 0 -2 2v9a2 2 0 0 0 2 2h9a2 2 0 0 0 2 -2v-1"
                                            ></path>
                                            <path
                                                d="M20.385 6.585a2.1 2.1 0 0 0 -2.97 -2.97l-8.415 8.385v3h3l8.385 -8.415z"
                                            ></path>
                                            <path d="M16 5l3 3"></path>
                                        </svg>
                                    </div>

                                    <div
                                        v-if="
                                            AuthStore.hasPermission(
                                                'can delete invoices'
                                            )
                                        "
                                        class="ml-2"
                                    >
                                        <svg
                                            :id="'delete_' + data.id"
                                            class="icon icon-tabler icon-tabler-trash cursor-pointer"
                                            fill="none"
                                            height="18"
                                            stroke="currentColor"
                                            stroke-linecap="round"
                                            stroke-linejoin="round"
                                            stroke-width="2"
                                            viewBox="0 0 24 24"
                                            width="18"
                                            xmlns="http://www.w3.org/2000/svg"
                                            @click="confirmDelete($event, data)"
                                        >
                                            <path
                                                d="M0 0h24v24H0z"
                                                fill="none"
                                                stroke="none"
                                            ></path>
                                            <path d="M4 7l16 0"></path>
                                            <path d="M10 11l0 6"></path>
                                            <path d="M14 11l0 6"></path>
                                            <path
                                                d="M5 7l1 12a2 2 0 0 0 2 2h8a2 2 0 0 0 2 -2l1 -12"
                                            ></path>
                                            <path
                                                d="M9 7v-3a1 1 0 0 1 1 -1h4a1 1 0 0 1 1 1v3"
                                            ></path>
                                        </svg>
                                    </div>
                                </div>
                            </template>
                        </Column>

                        <ColumnGroup type="footer">
                            <Row>
                                <Column
                                    footer="Page Total:"
                                    :colspan="6"
                                    footerStyle="text-align:right"
                                />
                                <Column
                                    :colspan="2"
                                    :footer="
                                        (InvoiceStore.invoice?.currency
                                            ?.symbol ?? '') +
                                        ' ' +
                                        helper.currencyFormat(+totalAmount)
                                    "
                                />
                            </Row>
                        </ColumnGroup>
                    </DataTable>
                </div>
            </div>
        </div>
    </div>

    <FormSlider
        :header="`Add Item - Invoice #${InvoiceStore.invoice?.id}`"
        :isOpen="slider"
        size="xl"
        @close="onClickButton"
    >
        <FormInvoiceLineItem
            :invoice="InvoiceStore.invoice"
            :item="item"
            :single="true"
            @close="onClickButton"
        />
    </FormSlider>

    <FormSlider
        :header="`Update Invoice #${InvoiceStore.invoice?.id}`"
        :isOpen="mainFormSlider"
        size="xl"
        @close="onClickMainFormButton"
    >
        <FormInvoice
            :invoice="InvoiceStore.invoice"
            @close="onClickMainFormButton"
        />
    </FormSlider>

    <FormSlider
        :header="`Send Invoice Email to ${InvoiceStore.invoice?.client?.legal_business_name}`"
        :is-open="sendEmailSlider"
        size="lg"
        @close="onCloseSlider"
    >
        <FormSendInvoiceEmail
            :invoice="InvoiceStore.invoice"
            @close="onCloseSlider"
        />
    </FormSlider>

    <ConfirmModal
        :deleted="deleted"
        :displayConfirmModal="showConfirmDialog"
        second-description="Item has been successfully deleted."
        @closeConfirmModal="setConfirmDialog(false)"
        @deleted="deleted = $event"
        @proceedProcess="processDelete($event)"
    />
</template>

<style scoped></style>
