<script lang="ts" setup>
import { onMounted, ref } from "vue";
import { useRoute } from "vue-router";
import { useAuthStore } from "../../../stores/authStore";
import { useEmployeeNoteStore } from "../../../stores/employeeNoteStore";
import { EmployeeNoteInterface } from "../../interfaces/employeeNoteInterface";
import Button from "../../../base-components/Button/Button.vue";
import Lucide from "../../../base-components/Lucide/Lucide.vue";
import FormSlider from "../../FormSlider.vue";
import ConfirmModal from "../ConfirmModal.vue";
import FormEmployeeNote from "../../forms/employees/FormEmployeeNote.vue";
import CardSkeleton from "../CardSkeleton.vue";
import moment from "moment";

const loading = ref(false);
const route = useRoute();
const NoteStore = useEmployeeNoteStore();
const AuthStore = useAuthStore();
const note = ref<EmployeeNoteInterface>();
const slider = ref(false);
const deleteId = ref(0);
const deleted = ref();
const showConfirmDialog = ref(false);

onMounted(async () => {
    loading.value = true;
    const clientId = route.params.id;
    await NoteStore.getEmployeeNotes(+clientId);
    loading.value = false;
});

const open = (data: EmployeeNoteInterface) => {
    slider.value = !slider.value;

    if (data && Object.keys(data).length > 0) {
        note.value = data;
    } else {
        note.value = {} as EmployeeNoteInterface;
    }
};

const close = () => {
    slider.value = !slider.value;
    note.value = {} as EmployeeNoteInterface;
};

const confirmDelete = (event: { preventDefault: () => void }, data: any) => {
    event.preventDefault();
    deleteId.value = data.id;
    setConfirmDialog(true);
};

const setConfirmDialog = (value: boolean) => {
    showConfirmDialog.value = value;
};

const processDelete = (event: any) => {
    if (event === false) {
        deleteId.value = 0;
    } else {
        NoteStore.deleteNote(deleteId.value)
            .then(() => {
                deleteId.value = 0;
                deleted.value = true;
            })
            .catch(() => {
                deleted.value = false;
            });
    }
};
</script>

<template>
    <CardSkeleton v-if="loading" />

    <div v-else class="intro-y box col-span-12 lg:col-span-4 bg-white">
        <div class="intro-y box col-span-12 lg:col-span-6">
            <div
                class="flex items-center px-5 py-5 sm:py-3 border-b border-slate-200/60 dark:border-darkmode-400"
            >
                <h2 class="font-medium text-base mr-auto">Notes</h2>

                <Button
                    v-if="AuthStore.hasPermission('can add employee note')"
                    id="add-note"
                    class="w-10 mb-2 mr-1"
                    size="sm"
                    variant="soft-secondary"
                    @click="slider = !slider"
                >
                    <Lucide class="w-5 h-5" icon="PlusCircle" />
                </Button>
            </div>

            <div
                class="p-2 border-t border-slate-200/60 dark:border-darkmode-400"
            >
                <ul
                    v-if="NoteStore.notes.length > 0"
                    class="border border-gray-200 rounded-md divide-y divide-gray-200"
                    role="list"
                >
                    <li
                        class="pl-3 pr-4 py-3 flex items-center justify-between text-sm"
                        v-for="(note, index) in NoteStore.notes"
                    >
                        <div class="w-0 flex-1 flex flex-col">
                            <div>{{ note.note }}</div>
                            <div class="text-xs text-slate-500">
                                {{
                                    moment(note.created_at).format(
                                        "MMMM DD, YYYY"
                                    )
                                }}
                            </div>
                        </div>

                        <div class="ml-4 flex gap-x-2 flex-shrink-0">
                            <a
                                :id="`update-note-${index}`"
                                v-if="
                                    AuthStore.hasPermission(
                                        'can update employee note'
                                    )
                                "
                                class="font-medium text-blue-500 hover:text-blue-800"
                                href="#"
                                @click="open(note)"
                            >
                                <Lucide class="w-4 h-4" icon="Edit" />
                            </a>

                            <a
                                :id="`delete-note-${index}`"
                                v-if="
                                    AuthStore.hasPermission(
                                        'can delete employee note'
                                    )
                                "
                                class="font-medium text-red-500 hover:text-red-800"
                                href="#"
                                @click="confirmDelete($event, note)"
                            >
                                <Lucide class="w-4 h-4" icon="Trash" />
                            </a>
                        </div>
                    </li>
                </ul>

                <div
                    v-if="NoteStore.notes.length === 0"
                    class="px-4 py-6 sm:px-6"
                >
                    No available notes.
                </div>
            </div>
        </div>
    </div>

    <FormSlider :isOpen="slider" :size="'md'" header="Add Note" @close="open">
        <FormEmployeeNote :note="note" @close="close()" />
    </FormSlider>

    <ConfirmModal
        :deleted="deleted"
        :displayConfirmModal="showConfirmDialog"
        second-description="Note has been successfully deleted."
        @closeConfirmModal="setConfirmDialog(false)"
        @deleted="deleted = $event"
        @proceedProcess="processDelete($event)"
    />
</template>
