import { defineStore } from "pinia";
import axios from "axios";
import { useAuthStore } from "./authStore";
import Http from "../services/Http";
import * as Sentry from "@sentry/browser";
import ApiResponseInterface from "../pages/interfaces/apiResponseInterface";
import TicketsInterface from "../pages/interfaces/ticketsInterface";

export const useTicketsStore = defineStore("TicketsStore", {
    state: () => {
        return {
            tickets: <any>[],
            myTickets: { data: [], total: 0 },
            myTotalTickets: 0,
            userTickets: [],
            ticketsForApproval: { data: [] as TicketsInterface[], total: 0 },
            mode: "Update",
            formData: [],
            notes: [],
            approvals: [],
            count: 0,
            attachment: "",
            $sentry: null,
            ticketGroups: [] as any[],
        };
    },
    getters: {
        getCount(): number {
            return this.count;
        },
    },
    actions: {
        async fill() {
            try {
                const response = await new Http(
                    import.meta.env.VITE_API_URL
                ).get<ApiResponseInterface<any>>("/tickets");
                this.tickets = response.data.data;
                // this.getTotalOpen();
                // this.getTotalOverdue();
            } catch (error) {
                if (this.$sentry) {
                    (this.$sentry as typeof Sentry).captureException(error);
                }
                console.log(error);
            }
        },
        async fillTicketGroups() {
            try {
                const response = await new Http(
                    import.meta.env.VITE_API_URL
                ).get<ApiResponseInterface<any[]>>(
                    "/get-ticket-type-groups"
                );
                console.log(response.data)
                this.ticketGroups = response.data;
            } catch (error) {
                if (this.$sentry) {
                    (this.$sentry as typeof Sentry).captureException(error);
                }
                console.log(error);
            }
        },
        async get(params: {
            dt_params: string;
            searchable_columns: string;
            historical: boolean;
        }) {
            try {
                const response = await new Http(
                    import.meta.env.VITE_API_URL
                ).get<ApiResponseInterface<any>>("/tickets", { params });
                console.log(response);
                this.tickets = response.data;
            } catch (error) {
                if (this.$sentry) {
                    (this.$sentry as typeof Sentry).captureException(error);
                }
                console.log(error);
            }
        },
        async getMyTickets(params: any) {
            try {
                const response = await new Http(
                    import.meta.env.VITE_API_URL
                ).get<ApiResponseInterface<any>>("/my-tickets", { params });
                this.myTickets = response.data;
            } catch (error) {
                if (this.$sentry) {
                    (this.$sentry as typeof Sentry).captureException(error);
                }
                console.log(error);
            }
        },
        async getTotalTicketsForLoggedInUser() {
            try {
                const response = await new Http(
                    import.meta.env.VITE_API_URL
                ).get<ApiResponseInterface<any>>(
                    "/get-total-tickets-for-logged-in-user"
                );
                this.myTotalTickets = response.data;
            } catch (error) {
                if (this.$sentry) {
                    (this.$sentry as typeof Sentry).captureException(error);
                }
                console.log(error);
            }
        },
        async getAllTicketsForLoggedInUser() {
            try {
                const response = await new Http(
                    import.meta.env.VITE_API_URL
                ).get<ApiResponseInterface<any>>(
                    "/get-all-tickets-for-logged-in-user"
                );
                this.userTickets = response.data;
            } catch (error) {
                if (this.$sentry) {
                    (this.$sentry as typeof Sentry).captureException(error);
                }
                console.log(error);
            }
        },
        async getTicketsForApproval(params: {
            dt_params: string;
            searchable_columns: string;
            approval: boolean;
        }) {
            console.log("getTicketsForApproval", params);
            try {
                const response = await new Http(
                    import.meta.env.VITE_API_URL
                ).get<ApiResponseInterface<any>>("/tickets-for-my-approval", {
                    params,
                });
                console.log(response);
                this.ticketsForApproval = response.data;
            } catch (error) {
                if (this.$sentry) {
                    (this.$sentry as typeof Sentry).captureException(error);
                }
                console.log(error);
            }
        },
        async getTicketDetails(params: { id: number }) {
            console.log("get ticket details");
            let status = "";
            let data = "";
            try {
                const response = (await new Http(
                    import.meta.env.VITE_API_URL
                ).get<ApiResponseInterface<any>>("/get-ticket-details", {
                    params,
                })) as ApiResponseInterface<any>;
                console.log(response);
                status = "success";
                data = response.data;
            } catch (error) {
                status = "error";
                console.error(error);
                return error;
            }

            return {
                status: status,
                data: data,
            };
        },
        async getTicketCount() {
            console.log("get ticket count");
            try {
                const response = await new Http(
                    import.meta.env.VITE_API_URL
                ).get<ApiResponseInterface<any>>("/get-ticket-count");
                this.count = response.count ?? 0;
                console.log(this.count);
            } catch (error) {
                if (this.$sentry) {
                    (this.$sentry as typeof Sentry).captureException(error);
                }
                console.log(error);
            }
        },
        async save(formData: FormData) {
            console.log("save ticket");
            try {
                const AuthStore = useAuthStore();
                const response = await new Http(
                    import.meta.env.VITE_API_URL
                ).post<ApiResponseInterface<any>>(
                    "/tickets",
                    formData,
                    AuthStore.token,
                    "multipart/form-data"
                );
                console.log(response);
                return { status: response.status, data: response.data };
            } catch (error: any) {
                if (this.$sentry) {
                    (this.$sentry as typeof Sentry).captureException(error);
                }
                console.log(error);
                return { error: error?.response?.data };
            }
        },
        async delete(id: any) {
            try {
                const response = await new Http(
                    import.meta.env.VITE_API_URL
                ).delete<ApiResponseInterface<any>>("/tickets", {
                    data: { id: id },
                });
                console.log(response);
                return true;
            } catch (error) {
                if (this.$sentry) {
                    (this.$sentry as typeof Sentry).captureException(error);
                }
                console.log(error);
            }
        },
        async notes(params: any) {
            try {
                const AuthStore = useAuthStore();
                let options = {
                    method: "GET",
                    headers: {
                        Accept: "application/json",
                        "Content-Type": "application/json",
                        Authorization: "Bearer " + AuthStore.token,
                    },
                    url: process.env.MIX_API_URL + "/ticket-notes",
                    params: params,
                };
                await axios.request(options).then((response) => {
                    this.notes = response.data;
                    console.log("note saved", response);
                });
                return this.notes;
            } catch (error) {
                console.log("note error");
                console.log(error);
            }
        },
        async saveNote(params: any) {
            let data = "";
            try {
                const response = await new Http(
                    import.meta.env.VITE_API_URL
                ).post<ApiResponseInterface<any>>("/ticket-notes", params);
                console.log(response);
                return {
                    status: "success",
                    data: response.data,
                };
            } catch (error) {
                throw error;
            }
        },
        async saveApprovals(params: {
            id: any;
            approve: boolean;
            ticket_type_id: any;
        }) {
            try {
                const response = await new Http(
                    import.meta.env.VITE_API_URL
                ).post<ApiResponseInterface<any>>("/ticket-approvals", params);
                --this.count;
                return {
                    status: "success",
                    data: response,
                };
            } catch (error) {
                console.log(error);
                return {
                    status: "error",
                };
            }
        },
        async downloadAttachment(params: { ticket_id?: any; file_name: any }) {
            try {
                const response = await new Http(
                    import.meta.env.VITE_API_URL
                ).get<ApiResponseInterface<any>>(
                    "/tickets-attachments",
                    {
                        params,
                    },
                    "",
                    true
                );
                // @ts-ignore
                var fileURL = window.URL.createObjectURL(new Blob([response]));
                var fileLink = document.createElement("a");

                fileLink.href = fileURL;
                fileLink.setAttribute("download", params.file_name);
                document.body.appendChild(fileLink);

                fileLink.click();
            } catch (error) {
                if (this.$sentry) {
                    (this.$sentry as typeof Sentry).captureException(error);
                }
                console.log(error);
                return { status: "error" };
            }
        },
        async uploadAttachment(formData: FormData) {
            console.log("save ticket attachements");
            console.log(formData);
            let stats = "";
            let files = "";
            try {
                const AuthStore = useAuthStore();
                const response = await new Http(
                    import.meta.env.VITE_API_URL
                ).post<any>(
                    "/tickets-attachments",
                    formData,
                    AuthStore.token,
                    "multipart/form-data"
                );

                return {
                    status: response.status,
                    files: response.files,
                };
            } catch (error: any) {
                if (this.$sentry) {
                    (this.$sentry as typeof Sentry).captureException(error);
                }

                return {
                    status: "error",
                    message: error,
                    error: error?.response?.data
                };
                
            }
        },
        addState() {
            this.mode = "Add";
        },
        updateState() {
            this.mode = "Update";
        },
    },
});
