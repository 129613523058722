<script lang="ts" setup>
import { FormLabel, FormSwitch } from "../../../base-components/Form";
import FormTextarea from "../../../base-components/Form/FormTextarea.vue";
import Button from "../../../base-components/Button";
import { defineEmits, defineProps, provide, reactive, ref, toRefs } from "vue";
import { useRoute } from "vue-router";
import { required, requiredIf } from "@vuelidate/validators";
import { useVuelidate } from "@vuelidate/core";
import TomSelect from "../../../base-components/TomSelect";
import { useEmployeesStore } from "../../../stores/employeesStore";
import Notification, {
    NotificationElement,
} from "../../../base-components/Notification";
import Lucide from "../../../base-components/Lucide";
import FormInput from "../../../base-components/Form/FormInput.vue";

const emit = defineEmits(["close"]);
const route = useRoute();
const EmployeeStore = useEmployeesStore();

const props = defineProps({
    is_active: {
        type: Boolean,
        default: false,
    },
});

const form = reactive({
    active: props.is_active,
    reason: "",
    note: "",
    rehire: false,
    duration: "",
    employee_id: +route?.params?.id ?? 0,
    end_date: "",
});
const backendValidationErrors = ref({});
const errorNotification = ref<NotificationElement>();
const successNotification = ref<NotificationElement>();
const errorNotificationToggle = () => {
    errorNotification.value?.showToast();
};
provide("bind[errorNotification]", (el: NotificationElement) => {
    errorNotification.value = el;
});
const showErrorsNotification = () => {
    errorNotificationToggle();
};

const successNotificationToggle = () => {
    successNotification.value?.showToast();
};
provide("bind[successNotification]", (el: NotificationElement) => {
    successNotification.value = el;
});
const showSuccessNotification = () => {
    successNotificationToggle();
};
const loading = ref(false);
const reasons = ref<string[]>([
    "Terminated / Failed Evaluation or Training",
    "Terminated / Retracted Offer",
    "Terminated / Due to Performance",
    "Terminated / AWOL or NCNS",
    "Resigned / Personal Reason",
    "Resigned / Health Issue",
    "Resigned / Family Reason",
]);
const durations = ref(["3 months", "6 months", "1 year", "Never"]);

const $externalResults = ref({});
const rules = {
    active: { required },
    reason: { required: requiredIf(() => !form.active) },
    note: {},
    rehire: {},
    duration: { required: requiredIf(() => form.rehire) },
    end_date: { required },
};
const validate = useVuelidate(rules, toRefs(form), { $externalResults });

const close = () => {
    emit("close");

    resetForm();
    validate.value.$reset();
};

const resetForm = () => {
    form.employee_id = +route?.params?.id ?? 0;
    form.active = false;
    form.reason = "";
    form.note = "";
    form.rehire = false;
    form.duration = "";
    form.end_date = "";
};

const save = async () => {
    await EmployeeStore.deactivateEmployee(form);
};

const onSubmit = async () => {
    loading.value = true;
    validate.value.$reset();
    validate.value.$clearExternalResults();
    validate.value.$touch();

    if (validate.value.$invalid) {
        backendValidationErrors.value = {
            message: ["Incomplete or Missing required data"],
        };

        showErrorsNotification();
        loading.value = false;
        return;
    }

    if (!validate.value.$invalid) {
        try {
            await save();

            close();
            showSuccessNotification();
        } catch (error: any) {
            if (error?.response?.data?.errors) {
                $externalResults.value = error?.response?.data?.errors;
            } else {
                backendValidationErrors.value = {
                    message: [
                        "Something went wrong. Please contact the development team.",
                    ],
                };

                showErrorsNotification();
            }
        } finally {
            loading.value = false;
        }
    }
};
</script>

<template>
    <form @submit.prevent="onSubmit">
        <div class="grid grid-cols-12 gap-6 mt-5 items-left text-left">
            <div class="intro-y col-span-12">
                <!-- BEGIN: Form Layout -->
                <div class="intro-y pb-5 pl-5 pr-2">
                    <div class="mt-4">
                        <label>Active<span class="text-red-500">*</span></label>

                        <FormSwitch class="mt-2">
                            <FormSwitch.Input
                                id="active"
                                v-model="form.active"
                                type="checkbox"
                                v-bind:checked="form.active"
                            />
                        </FormSwitch>
                    </div>

                    <div v-if="!form.active">
                        <div class="mt-4">
                            <FormLabel class="form-label">Reason</FormLabel>
                            <div>
                                <TomSelect
                                    id="reason"
                                    v-model="form.reason"
                                    :value="form.reason"
                                    class="w-full p-0"
                                    name="reason"
                                >
                                    <option selected>Select Reason</option>
                                    <option
                                        v-for="reason in reasons"
                                        :value="reason"
                                    >
                                        {{ reason }}
                                    </option>
                                </TomSelect>
                            </div>
                            <template v-if="validate.reason.$error">
                                <div
                                    v-for="(error, index) in validate.reason
                                        .$errors"
                                    :key="index"
                                    class="text-danger mt-2"
                                >
                                    {{ error.$message }}
                                </div>
                            </template>
                        </div>

                        <div class="mt-4">
                            <FormLabel class="form-label" for="note"
                                >Note
                            </FormLabel>

                            <FormTextarea
                                id="note"
                                v-model="form.note"
                                :value="form.note"
                                class="form-control w-full"
                                name="reason"
                                placeholder="Write something..."
                            />
                        </div>

                        <div class="mt-4">
                            <FormLabel class="form-label" for="effective_date"
                                >Effective Date
                            </FormLabel>

                            <FormInput
                                id="effective_date"
                                v-model="form.end_date"
                                :value="form.end_date"
                                class="form-control w-full"
                                name="effective_date"
                                type="date"
                            />

                            <template v-if="validate.end_date.$error">
                                <div
                                    v-for="(error, index) in validate.end_date
                                        .$errors"
                                    :key="index"
                                    class="text-danger mt-2"
                                >
                                    {{ error.$message }}
                                </div>
                            </template>
                        </div>

                        <div class="mt-4">
                            <label>Rehire</label>

                            <FormSwitch class="mt-2">
                                <FormSwitch.Input
                                    id="rehire"
                                    v-model="form.rehire"
                                    type="checkbox"
                                    v-bind:checked="form.rehire"
                                />
                            </FormSwitch>
                        </div>

                        <div v-if="form.rehire" class="mt-4">
                            <label>Duration</label>

                            <div class="mt-2">
                                <div v-for="(duration, index) in durations">
                                    <input
                                        :id="`duration-${index}`"
                                        v-model="form.duration"
                                        :value="duration"
                                        type="radio"
                                    />
                                    <label class="ml-2">{{ duration }}</label>
                                </div>
                                <template v-if="validate.duration.$error">
                                    <div
                                        v-for="(error, index) in validate
                                            .duration.$errors"
                                        :key="index"
                                        class="text-danger mt-2"
                                    >
                                        {{ error.$message }}
                                    </div>
                                </template>
                            </div>

                            <!--                        <FormCheck-->
                            <!--                            v-for="(duration, index) in durations"-->
                            <!--                            class="mt-2"-->
                            <!--                        >-->
                            <!--                            <FormCheck.Input-->
                            <!--                                :id="`radio-switch-${index}`"-->
                            <!--                                type="radio"-->
                            <!--                                name="vertical_radio_button"-->
                            <!--                                :value="duration"-->
                            <!--                                v-model="form.duration"-->
                            <!--                                :checked="form.duration === duration"-->
                            <!--                            />-->
                            <!--                            <FormCheck.Label :htmlFor="`radio-switch-${index}`">-->
                            <!--                                {{ duration }}-->
                            <!--                            </FormCheck.Label>-->
                            <!--                        </FormCheck>-->
                        </div>
                    </div>

                    <div class="">
                        <div class="flex items-center justify-end">
                            <Button
                                id="btn_cancel"
                                class="w-32 mb-2 mr-1"
                                type="button"
                                variant="warning"
                                :disabled="loading"
                                @click="close()"
                            >
                                Cancel
                            </Button>

                            <Button
                                id="btn_process"
                                class="min-w-36 mb-2"
                                type="submit"
                                variant="customPrimary"
                                :disabled="loading"
                            >
                                <div v-if="loading" class="flex items-center">
                                    <svg
                                        aria-hidden="true"
                                        class="w-4 h-4 mr-2 text-gray-200 animate-spin dark:text-gray-600 fill-blue-600"
                                        fill="none"
                                        viewBox="0 0 100 101"
                                        xmlns="http://www.w3.org/2000/svg"
                                    >
                                        <path
                                            d="M100 50.5908C100 78.2051 77.6142 100.591 50 100.591C22.3858 100.591 0 78.2051 0 50.5908C0 22.9766 22.3858 0.59082 50 0.59082C77.6142 0.59082 100 22.9766 100 50.5908ZM9.08144 50.5908C9.08144 73.1895 27.4013 91.5094 50 91.5094C72.5987 91.5094 90.9186 73.1895 90.9186 50.5908C90.9186 27.9921 72.5987 9.67226 50 9.67226C27.4013 9.67226 9.08144 27.9921 9.08144 50.5908Z"
                                            fill="currentColor"
                                        />
                                        <path
                                            d="M93.9676 39.0409C96.393 38.4038 97.8624 35.9116 97.0079 33.5539C95.2932 28.8227 92.871 24.3692 89.8167 20.348C85.8452 15.1192 80.8826 10.7238 75.2124 7.41289C69.5422 4.10194 63.2754 1.94025 56.7698 1.05124C51.7666 0.367541 46.6976 0.446843 41.7345 1.27873C39.2613 1.69328 37.813 4.19778 38.4501 6.62326C39.0873 9.04874 41.5694 10.4717 44.0505 10.1071C47.8511 9.54855 51.7191 9.52689 55.5402 10.0491C60.8642 10.7766 65.9928 12.5457 70.6331 15.2552C75.2735 17.9648 79.3347 21.5619 82.5849 25.841C84.9175 28.9121 86.7997 32.2913 88.1811 35.8758C89.083 38.2158 91.5421 39.6781 93.9676 39.0409Z"
                                            fill="currentFill"
                                        />
                                    </svg>

                                    <div>Updating status...</div>
                                </div>

                                <div v-else>Update status</div>
                            </Button>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </form>

    <Notification
        :options="{
            duration: 3000,
        }"
        class="flex"
        refKey="successNotification"
    >
        <Lucide class="text-success" icon="CheckCircle" />
        <div class="ml-4 mr-4">
            <div class="font-medium">System Notification</div>
            <div class="mt-1 text-slate-500">
                Employee status has been successfully updated.
            </div>
        </div>
    </Notification>

    <Notification
        :options="{
            duration: 3000,
        }"
        class="flex"
        refKey="errorNotification"
    >
        <Lucide class="text-danger" icon="X" />
        <div class="ml-4 mr-4">
            <div class="font-medium">System Notification</div>
            <div class="mt-1 text-slate-500">
                <ul class="list-disc">
                    <template v-for="errorBag in backendValidationErrors">
                        <li class="text-red-500">
                            {{ errorBag[0] }}
                        </li>
                    </template>
                </ul>
            </div>
        </div>
    </Notification>
</template>
