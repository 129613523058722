<script lang="ts" setup>
import { provide, reactive, ref, toRefs } from "vue";
import { useInvoiceStore } from "../../../stores/invoiceStore";
import Notification, {
    NotificationElement,
} from "../../../base-components/Notification";
import { required } from "@vuelidate/validators";
import { useVuelidate } from "@vuelidate/core";
import Modal from "../Modal.vue";
import Button from "../../../base-components/Button";
import Lucide from "../../../base-components/Lucide";

const dialog = ref(false);
const InvoiceStore = useInvoiceStore();
const backendValidationErrors = ref({});
const errorNotification = ref<NotificationElement>();
const successNotification = ref<NotificationElement>();
const $externalResults = ref<any[]>([]);
const inputFile = ref<HTMLInputElement>();

const uploadFile = (e: Event) => {
    const target = e.target as HTMLInputElement;

    if (!target.files) {
        return;
    }

    form.file = target.files[0];
};

const form = reactive({
    file: null as File | null,
});

const rules = {
    file: {
        required,
    },
};
//@ts-ignore
const validate = useVuelidate(rules, toRefs(form), { $externalResults });

const open = () => {
    dialog.value = true;
};

const close = () => {
    dialog.value = false;
    form.file = null;
    //@ts-ignore
    $externalResults.value = {};
};

const download = async () => {
    await InvoiceStore.downloadImportTemplate();
};
const errorNotificationToggle = () => {
    // Show notification
    errorNotification.value?.showToast();
};
provide("bind[errorNotification]", (el: NotificationElement) => {
    errorNotification.value = el;
});
const showErrorsNotification = () => {
    errorNotificationToggle();
};

const successNotificationToggle = () => {
    successNotification.value?.showToast();
};
provide("bind[successNotification]", (el: NotificationElement) => {
    successNotification.value = el;
});
const showSuccessNotification = () => {
    successNotificationToggle();
};

const onClickUpload = async () => {
    validate.value.$reset();
    validate.value.$clearExternalResults();
    validate.value.$touch();

    if (validate.value.$invalid) {
        backendValidationErrors.value = {
            message: ["Incomplete or Missing required data"],
        };

        showErrorsNotification();
        return;
    }

    if (!validate.value.$invalid) {
        try {
            const fd = new FormData();
            fd.append("file", form.file as File);

            await InvoiceStore.importInvoiceLineItems(fd);

            close();
            showSuccessNotification();
        } catch (error: any) {
            // @ts-ignore
            inputFile.value.value = null;
            form.file = null;
            $externalResults.value = error?.response?.data?.errors;
            validate.value.$reset();
        }
    }
};
</script>

<template>
    <Modal :open="dialog" size="lg">
        <template #trigger>
            <Button
                id="importInvoiceLineItems"
                class="hadow-md mr-2"
                variant="customPrimary"
                @click="open"
            >
                Import Invoice Line Items
            </Button>
        </template>

        <template #content>
            <div
                class="flex items-center border-b border-gray-400/50 dark:border-darkmode-400"
            >
                <h2 class="font-medium text-base mr-auto mb-4">Select File</h2>
            </div>

            <div
                v-if="$externalResults && $externalResults.length > 0"
                class="mt-4 px-4 bg-red-200 text-red-600 rounded"
            >
                <h5 class="pt-4 font-semibold">Something went wrong.</h5>
                <ul class="list-disc p-4">
                    <li v-for="error in $externalResults">
                        {{ error[0] }}
                    </li>
                </ul>
            </div>

            <div class="mt-4">
                <p>
                    <input
                        ref="inputFile"
                        id="uploadFileBtn"
                        class="btn shadow-md mr-2"
                        type="file"
                        @change="uploadFile"
                    />
                </p>
                <template v-if="validate.file.$error">
                    <div
                        v-for="(error, index) in validate.file.$errors"
                        :key="index"
                        class="text-danger mt-2"
                    >
                        {{ error.$message }}
                    </div>
                </template>
            </div>
        </template>

        <template #action>
            <div class="flex justify-between">
                <div>
                    <Button
                        class=""
                        type="button"
                        variant="outline-secondary"
                        @click="download"
                    >
                        Download Template
                    </Button>
                </div>

                <div>
                    <Button
                        class="w-24 mr-1"
                        type="button"
                        variant="outline-secondary"
                        @click="close"
                    >
                        Close
                    </Button>

                    <Button
                        id="btn_process"
                        class="w-32 mb-2 mr-1"
                        variant="customPrimary"
                        @click="onClickUpload"
                    >
                        Upload
                    </Button>
                </div>
            </div>
        </template>
    </Modal>

    <Notification
        :options="{
            duration: 3000,
        }"
        class="flex"
        refKey="successNotification"
    >
        <Lucide class="text-success" icon="CheckCircle" />
        <div class="ml-4 mr-4">
            <div class="font-medium">System Notification</div>
            <div class="mt-1 text-slate-500">
                Invoice line items has been imported.
            </div>
        </div>
    </Notification>

    <Notification
        :options="{
            duration: 3000,
        }"
        class="flex"
        refKey="errorNotification"
    >
        <Lucide class="text-danger" icon="X" />
        <div class="ml-4 mr-4">
            <div class="font-medium">System Notification</div>
            <div class="mt-1 text-slate-500">
                <ul class="list-disc">
                    <template v-for="errorBag in backendValidationErrors">
                        <li class="text-red-500">
                            {{ errorBag[0] }}
                        </li>
                    </template>
                </ul>
            </div>
        </div>
    </Notification>
</template>
