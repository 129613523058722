<script lang="ts" setup>
import { onMounted, reactive } from "vue";
import { useHelperStore } from "../stores/helperStore";
import PageHeaderStandard from "./components/PageHeaderStandard.vue";
import { useSideMenuStore } from "../stores/side-menu";
import { useAuthStore } from "../stores/authStore";
import { useTicketsStore } from "@/stores/ticketsStore";
import FormInput from "../base-components/Form/FormInput.vue";
import CustomIcon from "./Icons/CustomIcon.vue";
import { Ticket } from "lucide-vue-next";

const sideMenuStore = useSideMenuStore();

const AuthStore = useAuthStore();

const HelperStore = useHelperStore();

const TicketStore = useTicketsStore();

interface Permission {
    id: number;
    name: string;
    ticket_type_group_id: number;
    ticket_type_id: number;
}

interface RoleGroup {
    id: number;
    name: string;
}

const data = reactive({
    pageHeaderTitle: "Available Tickets",
    ticketSearchKeyword: "",
});

onMounted(async () => {
    console.log("TicketList mounted");
    await AuthStore.getLoggedInUserDetails();
    await TicketStore.fillTicketGroups();
    console.log(TicketStore.ticketGroups)
    //await sideMenuStore.processAvailableMenu();
});

const search = (event: Event) => {
    const target = event.target as HTMLInputElement;
    data.ticketSearchKeyword = target.value;
};

const startsWithVowel = (word: string) => {
    const vowels = ["a", "e", "i", "o", "u"];
    const firstLetter = word.charAt(0).toLowerCase();
    return vowels.includes(firstLetter);
};
</script>

<template>
    <PageHeaderStandard :title="data.pageHeaderTitle" />
    <div class="grid grid-cols-12 gap-6 mt-5">
        <div class="intro-y col-span-12 flex flex-wrap sm:flex-nowrap items-center mt-2">
            <div class="hidden md:block mx-auto text-slate-500">
                <!-- Showing 1 to 10 of 150 entries -->
            </div>
            <div class="w-full sm:w-auto mt-3 sm:mt-0 sm:ml-auto md:ml-0">
                <div class="lg:w-56 xl:w-56 relative text-slate-500">
                    <FormInput id="search" autocomplete="off" class="form-control lg:w-56 xl:w-56 box pr-10"
                        placeholder="Search..." type="text" v-on:input="search($event)" />
                </div>
            </div>
        </div>
        <template v-for="ticketTypeGroup in TicketStore.ticketGroups" class="intro-y flex">
            <div class="col-span-6 flex-1 box p-4">
                <div>
                    <div class="items-center p-5">
                       <span class="text-xl p-3"> {{ ticketTypeGroup.name }}</span>
                        <div v-for="ticketTypeGroupTicket in ticketTypeGroup.ticket_type_groups_tickets">
                            <!-- {{ ticketTypeGroupTicket.ticket_type_id }} -->
                        <template v-for="ticketType in HelperStore.getTicketTypeByName(
                            data.ticketSearchKeyword
                        )">
                            <div v-if="
                                Object.values(AuthStore.activatedPermissionsName).includes(
                                    'can create ' + ticketType.name.toLowerCase()
                                ) && ticketTypeGroupTicket.ticket_type_id === ticketType.id
                            " class="">
                                <!-- Object.values(AuthStore.activatedPermissionsName).includes('can create' + ticketType.name.toLowerCase()) -->
                                <div class="">
                                    <div class="flex flex-col lg:flex-row items-center p-2">
                                        <div class="flex mt-4 lg:mt-0">
                                            <router-link :id="'ticketType_' + ticketType.id" :to="{
                                                path: '/ticket',
                                                query: { id: ticketType.id },
                                            }" class="btn text-white bg-custom-color-1000 py-2 px-3 mr-2 rounded">Submit a Ticket
                                            </router-link>
                                        </div>

                                        <!-- :class="[ticketType.send_to_fdesk_on_create === 1 ? 'text-red-500' : 'text-green-500']" -->
                                        <div class="lg:ml-2 lg:mr-2 text-center lg:text-left mt-3 lg:mt-0 rounded">
                                            <router-link :id="'ticketType_' + ticketType.id" :to="{
                                                path: '/ticket',
                                                query: { id: ticketType.id },
                                            }" class="font-medium">
                                                Submit
                                                <span v-if="startsWithVowel(ticketType.name)">an</span>
                                                <span v-else>a</span>
                                                {{ ticketType.name }} - [{{ ticketType.code }}]
                                            </router-link>
                                        </div>

                                        <div
                                            class="hidden lg:block xl:block w-24 h-24 lg:w-12 lg:h-12 image-fit lg:mr-1">
                                            <div class="pt-3">
                                                <CustomIcon v-if="ticketType.send_to_fdesk_on_create" :height="24"
                                                    :icon="'Ticket'" :width="24" />
                                                <CustomIcon v-else :height="24" :icon="'Checklist'" :width="24" />
                                            </div>
                                        </div>
                                      
                                    </div>
                                </div>
                            </div>
                        </template>

                        </div>
                    </div>
                </div>
            </div>
        </template>


    </div>
</template>
