<script lang="ts" setup>
import { useAuthStore } from "../../stores/authStore";
import { required } from "@vuelidate/validators";
import { useVuelidate } from "@vuelidate/core";
import moment from "moment";
import { useRoute } from "vue-router";
import { useEmployeesStore } from "../../stores/employeesStore";

import router from "../../router";
import { useHelperStore } from "../../stores/helperStore";
import { useUiStore } from "../../stores/uiStore";
import {
    defineEmits,
    onMounted,
    onUpdated,
    provide,
    reactive,
    ref,
    toRefs,
} from "vue";
import { useFreskdeskStore } from "../../stores/freshdeskStore";
import { useTicketsStore } from "../../stores/ticketsStore";
import TomSelect from "../../base-components/TomSelect";
import Button from "../../base-components/Button";
import { ClassicEditor } from "../../base-components/Ckeditor";
import { FormLabel } from "../../base-components/Form";
import _ from "lodash";
import Notification, {
    NotificationElement,
} from "../../base-components/Notification";
import Lucide from "../../base-components/Lucide";
import EmployeeInterface from "../interfaces/employeesInterface";

const toastData = reactive({
    message: "",
    subject: "",
    severity: "",
});
const TicketsStore = useTicketsStore();

const AuthStore = useAuthStore();
const HelperStore = useHelperStore();
const UiStore = useUiStore();
const FreshdeskStore = useFreskdeskStore();
const route = useRoute();
const id = Array.isArray(route.query.id) ? route.query.id[0] : route.query.id;
const categories = ref(2);
const emit = defineEmits(["output", "ticketType"]);

const EmployeesStore = useEmployeesStore();

const ticketForm = reactive({
    dateFiled: "",
    employeeId: "",
    name: "",
    reason: "",
    requestStatus: "Pending",
    type: "Request",
    why: "Termination",
    status: "For Clearance",
});

const rules = {
    //dateFiled: { required },
    employeeId: { required },
    name: { required },
    reason: { required },
    requestStatus: { required },
    type: { required },
    why: { required },
    status: { required },
};

const attachment_files = reactive({
    data: [],
});

const editorConfig = {
    toolbar: {
        items: ["bold", "italic", "link"],
    },
};

onUpdated(() => {
    const ticketType = HelperStore.getTicketType(Number(id ?? 0));
    console.log("ticketType", ticketType);
    UiStore.setBreadcrumb("Submit a Ticket > " + ticketType.name);
});

const validate = useVuelidate(rules, toRefs(ticketForm));

const formReset = () => {
    ticketForm.employeeId = "";
    ticketForm.name = "";
    ticketForm.reason = "";
    ticketForm.requestStatus = "";
    ticketForm.type = "";
};

onMounted(async () => {
    //if (window.Cypress) {
    //  window.FreshdeskStore = FreshdeskStore;
    // }
    await EmployeesStore.getAllEmployees();
    populateEmployeeDetails();
});

const employee = reactive({
    data: null as EmployeeInterface | null,
});
const populateEmployeeDetails = () => {
    //@ts-ignore
    console.log("populateEmployeeDetails", window.Laravel.user.id);
    //@ts-ignore
    const userName = window.Laravel.user.name;
    employee.data = EmployeesStore.getEmployee(parseInt(userName));
    console.log("AU", employee.data);
};

const submitTicket = async () => {
    const currentDate = moment().format("MMMM Do YYYY, h:mm:ss a");

    validate.value.$touch();

    if (validate.value.$invalid) {
        console.error("missing data fields");
        toastData.severity = "danger";
        toastData.message = "All fields required!";
        toastData.subject = "Please provide all the required information";
        console.log("show error notification");
    } else {
        const ticketType = HelperStore.getTicketType(Number(id ?? 0));

        const desc_data = new FormData();
        desc_data.append("Date Filed", currentDate);
        desc_data.append("Employee", ticketForm.name);
        desc_data.append("Detailed Reason", ticketForm.reason);
        desc_data.append("Reason for Deactivation", ticketForm.why);
        desc_data.append("Status", ticketForm.status);

        var form_body = {};
        //@ts-ignore
        desc_data.forEach((value, key) => (form_body[key] = value));

        const form = new FormData();
        form.append("id", String(id));
        form.append(
            "subject",
            "Ticket for Log ins Deactivation: " + ticketForm.name
        );
        form.append("priority", ticketType?.f_priority_id?.toString() ?? "");
        form.append("status", ticketType?.f_status_id?.toString() ?? "");
        form.append("type", ticketType?.f_type?.toString() ?? "");
        form.append("form_body", JSON.stringify(form_body));
        form.append("ticket_type_id", ticketType?.id?.toString() ?? "");

        if (attachments.data !== null) {
            _.forEach(attachments.data, function (files) {
                _.forEach(files, function (file) {
                    form.append("attachments[]", file);
                });
            });
        }

        await TicketsStore.save(form).then((response) => {
            if (response.status === "success") {
                validate.value.$reset();
                emit("output", { render: true, type: "success" });
                if (id !== null) {
                    emit("ticketType", HelperStore.getTicketType(parseInt(id)));
                }
                showSuccessNotification();
                formReset();
                router.push({
                    name: "ticketDetails",
                    params: { id: response.data.id },
                });
            } else {
                console.log("response not success:", response);
                emit("output", { render: true, type: "danger" });
            }
        });
    }
};

interface Attachments {
    data: File[][];
}

const attachments: Attachments = reactive({
    data: [[]],
});
const uploadFile = (e: Event) => {
    const files = (e.target as HTMLInputElement).files;
    if (files !== null) {
        attachments.data.push(Array.from(files));
    }
};

const successNotification = ref<NotificationElement>();
const successNotificationToggle = () => {
    // Show notification
    successNotification.value?.showToast();
};

provide("bind[successNotification]", (el: NotificationElement) => {
    successNotification.value = el;
});

const showSuccessNotification = () => {
    successNotificationToggle();
};

const onChangeEmployee = () => {
    const employee = EmployeesStore.employees.find(
        (a) => a.id === +ticketForm.employeeId
    );

    if (employee) {
        ticketForm.name = employee.name;
    }
};
</script>
<template>
    <div class="intro-y box p-5">
        <div class="mt-3">
            <FormLabel class="form-label" for="employees"
                >Employees
                <span style="color: rgb(255, 0, 0)">*</span></FormLabel
            >
            <TomSelect
                id="employees"
                v-model="ticketForm.employeeId"
                class="w-full"
                name="employees"
                @update:modelValue="onChangeEmployee()"
            >
                <option selected>Select Employee</option>
                <option
                    v-for="employee in EmployeesStore.employees"
                    :value="employee.id"
                >
                    {{ employee.name }}
                </option>
            </TomSelect>
            <template v-if="validate.employeeId.$error">
                <div
                    v-for="(error, index) in validate.employeeId.$errors"
                    :key="index"
                    class="text-danger mt-2"
                >
                    {{ error.$message }}
                </div>
            </template>
        </div>

        <div class="mt-3">
            <FormLabel class="form-label" for="crud-form-1"
                >Reason for Deactivation
                <span style="color: rgb(255, 0, 0)">*</span></FormLabel
            >
            <TomSelect
                id="why"
                v-model="ticketForm.why"
                class="w-full"
                name="why"
            >
                <option value="Termination">Termination</option>
                <option value="Resignation">Resignation</option>
            </TomSelect>
            <template v-if="validate.why.$error">
                <div
                    v-for="(error, index) in validate.why.$errors"
                    :key="index"
                    class="text-danger mt-2"
                >
                    {{ error.$message }}
                </div>
            </template>
        </div>

        <div class="mt-3">
            <FormLabel
                >Detailed Reason
                <span style="color: rgb(255, 0, 0)">*</span></FormLabel
            >
            <div class="mt-2">
                <ClassicEditor
                    id="reason"
                    v-model="ticketForm.reason"
                    :config="editorConfig"
                    name="reason"
                />
                <template v-if="validate.reason.$error">
                    <div
                        v-for="(error, index) in validate.reason.$errors"
                        id="error_reason"
                        :key="index"
                        class="text-danger mt-2"
                    >
                        {{ error.$message }}
                    </div>
                </template>
            </div>
        </div>

        <div class="mt-3">
            <FormLabel class="form-label" for="crud-form-1"
                >Status: <span style="color: rgb(255, 0, 0)">*</span></FormLabel
            >
            <TomSelect
                id="status"
                v-model="ticketForm.status"
                class="w-full"
                name="status"
            >
                <option value="For Clearance">For Clearance</option>
                <option value="For Final Pay">For Final Pay</option>
                <option value="Cleared">Cleared</option>
            </TomSelect>
            <template v-if="validate.status.$error">
                <div
                    v-for="(error, index) in validate.status.$errors"
                    :key="index"
                    class="text-danger mt-2"
                >
                    {{ error.$message }}
                </div>
            </template>
        </div>

        <div class="text-right mt-5">
            <router-link
                class="btn text-white bg-warning py-2 rounded-md w-28 text-center"
                to="/submit-a-ticket"
                type="button"
            >
                Cancel
            </router-link>
            <Button
                class="ml-2"
                type="button"
                variant="customPrimary"
                @click="submitTicket"
                >Submit Ticket
            </Button>
        </div>
    </div>
    <!-- Success Notification -->
    <Notification
        :options="{
            duration: 3000,
        }"
        class="flex"
        refKey="successNotification"
    >
        <Lucide class="text-success" icon="CheckCircle" />
        <div class="ml-4 mr-4">
            <div class="font-medium">System Notification</div>
            <div class="mt-1 text-slate-500">
                Ticket was created successfully.
            </div>
        </div>
    </Notification>
    <!-- Success Notification -->
</template>
