<template>
    <div class="grid grid-cols-12 gap-6 mt-5 items-left text-left animate-pulse">
        <div class="intro-y col-span-12">
            <div class="intro-y p-5">
                <div class="w-full">
                    <div class="h-5 bg-gray-200 rounded w-1/2 mb-3"></div>
                    <div class="h-9 bg-gray-200 rounded w-full"></div>
                </div>

                <div class="w-full mt-4">
                    <div class="h-5 bg-gray-200 rounded w-1/2 mb-3"></div>
                    <div class="h-9 bg-gray-200 rounded w-full"></div>
                </div>

                <div class="w-full mt-4">
                    <div class="h-5 bg-gray-200 rounded w-1/2 mb-3"></div>
                    <div class="h-9 bg-gray-200 rounded w-full"></div>
                </div>
            </div>

            <div class="text-right p-2">
                <div class="inline-flex w-32 rounded h-9 bg-gray-200 mb-2 mr-1"></div>
                <div class="inline-flex w-36 rounded h-9 bg-gray-200 mb-2 mr-1"></div>
            </div>
        </div>
    </div>
</template>
