<script lang="ts" setup>
import { useShiftStore } from "../stores/shiftStore";
import { onMounted, provide, reactive, ref, toRefs } from "vue";
import { useRoute } from "vue-router";
import { ShiftInterface } from "./interfaces/shiftInterface";
import moment from "moment/moment";
import DataTable from "primevue/datatable";
import Column from "primevue/column";
import { FilterMatchMode, FilterOperator } from "primevue/api";
import LazyParamsInterface from "./interfaces/lazyParamsInterface";
import EmployeeInterface, {
    EmployeePaginatedInterface,
} from "./interfaces/employeesInterface";
import InputText from "primevue/inputtext";
import Lucide from "../base-components/Lucide";
import { FormInput, FormLabel } from "../base-components/Form";
import Button from "../base-components/Button";
import FormSlider from "./FormSlider.vue";
import TomSelect from "../base-components/TomSelect";
import { useEmployeesStore } from "../stores/employeesStore";
import { required } from "@vuelidate/validators";
import { useVuelidate } from "@vuelidate/core";
import ConfirmModal from "./components/ConfirmModal.vue";
import { useEmployeeShiftStore } from "../stores/employeeShiftStore";
import Notification, {
    NotificationElement,
} from "../base-components/Notification";
import dayjs from "dayjs";

const route = useRoute();
const ShiftStore = useShiftStore();
const EmployeeStore = useEmployeesStore();
const EmployeeShiftStore = useEmployeeShiftStore();

const shift = ref<ShiftInterface>();
const employees = ref<EmployeePaginatedInterface>();
const lazyParams = ref<LazyParamsInterface>({});
const searchableCols = ref(["name"]);
const loading = ref(false);
const selectAll = ref(false);
const selectedEmployees = ref<EmployeeInterface[]>();
const showClearFilter = ref(false);
const dt = ref();
const slider = ref(false);
const showConfirmDialog = ref(false);
const deleteId = ref(0);
const backendValidationErrors = ref({});
const errorNotification = ref<NotificationElement>();
const successNotification = ref<NotificationElement>();

const form = reactive({
    employee_id: [],
    effdt: dayjs().add(1, "days").format("YYYY-MM-DD"),
    effdt_to: dayjs().add(2, "days").format("YYYY-MM-DD"),
});

const rules = {
    employee_id: { required },
    effdt: { required },
    effdt_to: { required },
};

const $externalResults = ref({});
const validate = useVuelidate(rules, toRefs(form), { $externalResults });

const onSubmit = async () => {
    validate.value.$reset();
    validate.value.$clearExternalResults();
    validate.value.$touch();

    if (validate.value.$invalid) {
        backendValidationErrors.value = {
            message: ["Incomplete or Missing required data"],
        };

        showErrorsNotification();
        return;

        return;
    }

    if (!validate.value.$invalid) {
        try {
            const params = {
                shift_id: route?.params?.id,
                employee_id: form.employee_id,
                effdt: form.effdt,
                effdt_to: form.effdt_to,
            };

            await EmployeeShiftStore.save(params);
            form.effdt = "";
            form.effdt_to = "";
            form.employee_id = [];
            validate.value.$reset();
            await onDataFetch();
            showSuccessNotification();
            slider.value = !slider.value;
        } catch (error: any) {
            $externalResults.value = error.response.data.errors;
        }
    }
};

const errorNotificationToggle = () => {
    // Show notification
    errorNotification.value?.showToast();
};
provide("bind[errorNotification]", (el: NotificationElement) => {
    errorNotification.value = el;
});
const showErrorsNotification = () => {
    errorNotificationToggle();
};

const successNotificationToggle = () => {
    successNotification.value?.showToast();
};
provide("bind[successNotification]", (el: NotificationElement) => {
    successNotification.value = el;
});
const showSuccessNotification = () => {
    successNotificationToggle();
};

const onClickButton = (data: any) => {
    slider.value = !slider.value;
};

const filters = ref({
    global: { value: null, matchMode: FilterMatchMode.CONTAINS },
    name: {
        operator: FilterOperator.AND,
        constraints: [
            {
                value: null,
                matchMode: FilterMatchMode.STARTS_WITH,
            },
        ],
    },
});

const onFilter = async (event: any) => {
    showClearFilter.value = filters.value.global.value !== "";
    lazyParams.value.filters = filters.value;

    await onDataFetch();
};

const onSort = async (event: any) => {
    showClearFilter.value = true;
    lazyParams.value = event;

    await onDataFetch();
};

const onPage = async (event: any) => {
    lazyParams.value = event;

    await onDataFetch();
};

const onSelectAllChange = (event: { checked: any }) => {
    selectAll.value = !selectAll.value;

    selectedEmployees.value = selectAll.value ? employees?.value?.data : [];
};

const onRowSelect = () => {
    selectAll.value =
        selectedEmployees?.value?.length === ShiftStore.employees?.total;
};

const onRowUnselect = () => {
    selectAll.value = false;
};
const onDataFetch = async () => {
    loading.value = true;
    const params = {
        dt_params: JSON.stringify(lazyParams.value),
        searchable_columns: JSON.stringify(searchableCols.value),
        shift_id: route?.params?.id,
    };

    await ShiftStore.getEmployees(params);
    employees.value = ShiftStore.employees;

    loading.value = false;
};

const resetGlobalFilter = () => {
    showClearFilter.value = false;

    filters.value = {
        global: { value: null, matchMode: FilterMatchMode.CONTAINS },
        name: {
            operator: FilterOperator.AND,
            constraints: [
                { value: null, matchMode: FilterMatchMode.STARTS_WITH },
            ],
        },
    };

    lazyParams.value = {
        first: 0,
        rows: dt.value.rows,
        sortField: null,
        sortOrder: null,
        filters: filters.value,
    };

    onDataFetch();
};

const confirmDelete = (event: { preventDefault: () => void }, data: any) => {
    event.preventDefault();
    deleteId.value = data.id;
    setConfirmDialog(true);
};

const setConfirmDialog = (value: boolean) => {
    showConfirmDialog.value = value;
};

const processDelete = async (event: any) => {
    if (event === false) {
        deleteId.value = 0;
    } else {
        await ShiftStore.removeEmployee(deleteId.value).then(async () => {
            deleteId.value = 0;

            await onDataFetch();
        });
    }
};

const changeEffdt = (event: Event) => {
    console.log("change effdt");
    form.effdt = (event.target as HTMLInputElement).value;
};

const changeEffdtTo = (event: Event) => {
    console.log("change effdt to");
    form.effdt_to = (event.target as HTMLInputElement).value;
};

const getShift = (data: any, employee_id: number) => {
    return data.find(
        (a: any) =>
            +a.shift_id === +route?.params?.id && a.employee_id === employee_id
    );
};

const deleteConfirmation = (values: string[]) => {
    let message = "Are you sure you want to remove this item?";

    if (values.length > 1) {
        message = `Are you sure you want to remove these ${values.length} items?`;
    } else {
        const employee = EmployeeStore.employees.find(
            (x) => x.id === parseInt(values[0])
        );

        message = `Are you sure you want to remove ${employee?.name}?`;
    }

    return confirm(message);
};

onMounted(async () => {
    const { params } = route;

    if (params?.id) {
        await ShiftStore.getShift(+params.id);

        shift.value = ShiftStore.shift;

        await onDataFetch();
        await EmployeeStore.getAllEmployees();
    }
});
</script>

<template>
    <div class="grid grid-cols-12 gap-4">
        <div v-if="shift" class="mt-3 col-span-3">
            <!--            <div-->
            <!--                class="p-2 mt-5 box bg-custom-color-1000 text-white text-xl intro-x"-->
            <!--            >-->
            <!--                {{ shift.name }}-->
            <!--            </div>-->
            <div class="bg-white mt-3 rounded shadow-lg px-4 py-4">
                <div class="flex items-center">
                    <div class="mr-5 text-lg font-medium truncate">
                        Shift Details
                    </div>
                </div>
                <div
                    class="mt-3 col-span-12 sm:col-span-6 md:col-span-4 xl:col-span-12"
                >
                    <div class="text-slate-500">Shift name</div>
                    <div class="mt-1.5 flex items-center">
                        <div class="text-lg">
                            {{ shift.name }}
                        </div>
                    </div>
                </div>
                <div
                    class="mt-3 col-span-12 sm:col-span-6 md:col-span-4 xl:col-span-12"
                >
                    <div class="text-slate-500">Shift start and end time</div>
                    <div class="mt-1.5 flex items-center">
                        <div class="text-lg">
                            {{ shift.start_time }} - {{ shift.end_time }}
                        </div>
                    </div>
                </div>
                <div
                    class="col-span-12 sm:col-span-6 md:col-span-4 xl:col-span-12"
                >
                    <div class="text-slate-500">Shift is active starting</div>
                    <div class="mt-1.5 flex items-center">
                        <div class="text-lg">
                            {{
                                moment(shift.shift_active_start_date).format(
                                    "MMMM Do, YYYY"
                                )
                            }}
                        </div>
                    </div>
                </div>
            </div>
            <div class="bg-white mt-3 rounded shadow-lg px-4 py-4">
                <div class="flex items-center">
                    <div class="mr-5 text-lg font-medium truncate">
                        Available Shift Breaks
                    </div>
                </div>
                <template v-for="(item, index) in shift.shift_breaks">
                    <div
                        class="mt-3 col-span-12 sm:col-span-6 md:col-span-4 xl:col-span-12"
                    >
                        <div class="text-slate-500">
                            {{ item.friendly_name }} ({{
                                item.shift_break_type?.name
                            }})
                        </div>
                        <div class="mt-1.5 flex items-center">
                            <div class="text-lg">
                                {{
                                    item.start_time ? item.start_time : "--:--"
                                }}
                                -
                                {{ item.end_time ? item.end_time : "--:--" }}
                                <span class="text-xs"
                                    >({{ item.total_hours }} hrs)</span
                                >
                            </div>
                        </div>
                    </div>
                </template>
            </div>
        </div>

        <div class="col-span-9">
            <div
                class="intro-y col-span-12 flex flex-wrap sm:flex-nowrap items-center mt-5"
            >
                <Button
                    class="mr-2 shadow-sm"
                    variant="customPrimary"
                    @click="onClickButton"
                >
                    Add Employee
                </Button>

                <div class="hidden md:block mx-auto text-slate-500"></div>

                <div
                    class="flex w-full sm:w-auto mt-3 sm:mt-0 sm:ml-auto md:ml-0"
                >
                    <template v-if="showClearFilter">
                        <button
                            class="btn bg-custom-color-1000 text-white w-full mx-3 rounded-md px-4"
                            @click="resetGlobalFilter"
                        >
                            Clear
                            <span
                                class="h-3 w-4 pi pi-filter-slash p-component"
                            ></span>
                        </button>
                    </template>

                    <div class="w-56 relative text-slate-500">
                        <FormInput
                            v-model="filters.global.value"
                            :value="filters.global.value"
                            class="w-56 pr-10 !box"
                            placeholder="Search..."
                            type="text"
                            @keyup.enter="onFilter($event)"
                        />

                        <Lucide
                            class="absolute inset-y-0 right-0 w-4 h-4 my-auto mr-3"
                            icon="Search"
                        />
                    </div>
                </div>
            </div>

            <DataTable
                ref="dt"
                v-model:filters="filters"
                v-model:selection="selectedEmployees"
                :globalFilterFields="searchableCols"
                :lazy="true"
                :loading="loading"
                :paginator="true"
                :rows="10"
                :selectAll="selectAll"
                :totalRecords="employees?.total"
                :value="employees?.data"
                class="mt-4 p-datatable-sm rounded-lg shadow-lg text-xs"
                filterDisplay="menu"
                responsive-layout="scroll"
                show-gridlines
                striped-rows
                @filter="onFilter($event)"
                @page="onPage($event)"
                @sort="onSort($event)"
                @select-all-change="onSelectAllChange($event)"
                @row-select="onRowSelect"
                @row-unselect="onRowUnselect"
            >
                <template #empty> No available data.</template>

                <template #loading> Loading data. Please wait.</template>

                <Column field="name" header="Name">
                    <template #filter="{ filterModel }">
                        <InputText
                            v-model="filterModel.value"
                            class="p-column-filter"
                            placeholder="Search by name"
                            type="text"
                        />
                    </template>
                </Column>

                <Column field="client.legal_business_name" header="Client">
                    <template #filter="{ filterModel }">
                        <InputText
                            v-model="filterModel.value"
                            class="p-column-filter"
                            placeholder="Search by client legal business name"
                            type="text"
                        />
                    </template>
                </Column>

                <Column field="shift.effdt" header="Effective Date">
                    <template #body="{ data }">
                        {{
                            getShift(data.shifts, data.id)?.effdt
                                ? moment(
                                      getShift(data.shifts, data.id).effdt
                                  ).format("YYYY-MM-DD")
                                : "-"
                        }}
                    </template>
                </Column>
                <Column field="shift.effdt_to" header="Effective Until">
                    <template #body="{ data }">
                        {{
                            getShift(data.shifts, data.id)?.effdt_to
                                ? moment(
                                      getShift(data.shifts, data.id).effdt_to
                                  ).format("YYYY-MM-DD")
                                : "-"
                        }}
                    </template>
                </Column>
            </DataTable>
        </div>
    </div>
    <FormSlider
        :header="`Add Employee to ${shift?.name}`"
        :is-open="slider"
        size="lg"
        @close="onClickButton"
    >
        <form @submit.prevent="onSubmit()">
            <div class="grid grid-cols-12 gap-6 mt-5 items-left text-left">
                <div class="intro-y col-span-12">
                    <div class="intro-y pb-5 pl-5 pr-2">
                        <div class="mt-4">
                            <FormLabel class="form-label" for="employee"
                                >Employee
                            </FormLabel>

                            <div>
                                <TomSelect
                                    id="employee_id"
                                    v-model="form.employee_id"
                                    :options="{
                                        placeholder: 'Select employees to add to this shift',
                                        onDelete: (values: string[]) => deleteConfirmation(values),
                                    }"
                                    :value="form.employee_id"
                                    class="w-full p-0"
                                    multiple
                                    name="employee_id"
                                >
                                    <option
                                        v-for="employee of EmployeeStore.employees.filter(
                                            (a) =>
                                                !ShiftStore.employees.data
                                                    .map((b) => b.id)
                                                    .includes(a.id)
                                        )"
                                        :value="employee.id"
                                    >
                                        {{ employee.name }}
                                    </option>
                                </TomSelect>
                            </div>

                            <div class="mt-2">
                                <template v-if="validate.employee_id.$error">
                                    <div class="text-danger mt-2">
                                        {{
                                            validate.employee_id.$errors[0]
                                                .$message
                                        }}
                                    </div>
                                </template>
                            </div>
                        </div>

                        <div class="mt-4">
                            <FormLabel class="form-label"
                                >Effective Date
                            </FormLabel>

                            <FormInput
                                id="effdt"
                                v-model="form.effdt"
                                :min="
                                    dayjs().add(1, 'days').format('YYYY-MM-DD')
                                "
                                :value="form.effdt"
                                class="form-control w-full"
                                maxlength="50"
                                name="effdt"
                                placeholder=""
                                type="date"
                                @change="changeEffdt($event)"
                            />
                            <template v-if="validate.effdt.$error">
                                <div class="text-danger mt-2">
                                    {{ validate.effdt.$errors[0].$message }}
                                </div>
                            </template>
                        </div>

                        <div class="mt-4">
                            <FormLabel class="form-label"
                                >Effective To Date
                            </FormLabel>

                            <FormInput
                                id="effdt_to"
                                v-model="form.effdt_to"
                                :min="
                                    dayjs().add(1, 'days').format('YYYY-MM-DD')
                                "
                                :value="form.effdt_to"
                                class="form-control w-full"
                                maxlength="50"
                                name="effdt_to"
                                placeholder=""
                                type="date"
                                @change="changeEffdtTo($event)"
                            />
                            <template v-if="validate.effdt_to.$error">
                                <div class="text-danger mt-2">
                                    {{ validate.effdt_to.$errors[0].$message }}
                                </div>
                            </template>
                        </div>
                    </div>
                </div>
            </div>

            <div class="">
                <div class="text-right p-2">
                    <Button
                        id="btn_cancel"
                        class="w-32 mb-2 mr-1"
                        type="button"
                        variant="warning"
                        @click="slider = !slider"
                    >
                        Cancel
                    </Button>
                    <Button
                        id="btn_process"
                        class="w-36 mb-2 mr-1"
                        type="submit"
                        variant="customPrimary"
                    >
                        Add Employee
                    </Button>
                </div>
            </div>
        </form>
    </FormSlider>

    <ConfirmModal
        :displayConfirmModal="showConfirmDialog"
        second-description="Employee has been successfully deleted from this shift."
        @closeConfirmModal="setConfirmDialog(false)"
        @proceedProcess="processDelete($event)"
    />

    <Notification
        :options="{
            duration: 3000,
        }"
        class="flex"
        refKey="successNotification"
    >
        <Lucide class="text-success" icon="CheckCircle" />
        <div class="ml-4 mr-4">
            <div class="font-medium">System Notification</div>
            <div class="mt-1 text-slate-500">
                Employee has been successfully added.
            </div>
        </div>
    </Notification>

    <Notification
        :options="{
            duration: 3000,
        }"
        class="flex"
        refKey="errorNotification"
    >
        <Lucide class="text-danger" icon="X" />
        <div class="ml-4 mr-4">
            <div class="font-medium">System Notification</div>
            <div class="mt-1 text-slate-500">
                <ul class="list-disc">
                    <template v-for="errorBag in backendValidationErrors">
                        <li class="text-red-500">
                            {{ errorBag[0] }}
                        </li>
                    </template>
                </ul>
            </div>
        </div>
    </Notification>
</template>

<style scoped></style>
