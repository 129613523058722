<script setup lang="ts">
import { defineEmits, onMounted, PropType, provide, reactive, ref } from "vue";
import { InvoiceInterface } from "@/pages/interfaces/invoiceInterface";
import {
    FormInput,
    FormLabel,
    FormTextarea,
} from "@/base-components/Form";
import { useVuelidate } from "@vuelidate/core";
import { required } from "@vuelidate/validators";
import { useClientInvoiceConfigStore } from "@/stores/clientInvoiceConfigStore";
import Button from "@/base-components/Button";
import Lucide from "@/base-components/Lucide";
import Notification, {
    NotificationElement,
} from "@/base-components/Notification";
import { useInvoiceStore } from "@/stores/invoiceStore";
import Modal from "@/pages/components/Modal.vue";

const props = defineProps({
    invoice: {
        type: Object as PropType<InvoiceInterface>,
        required: true,
    },
});
const emit = defineEmits(["close"]);

const ClientConfigStore = useClientInvoiceConfigStore();
const InvoiceStore = useInvoiceStore();
const loading = ref(false);
const confirm = ref(false);
const sending = ref(false);
const form = reactive({
    to: "",
    cc: "",
    notes: "",
});

const rules = {
    to: {
        required,
    },
    cc: {},
    notes: {},
};
interface ExternalResults {
    [key: string]: string[];
}
const $externalResults = ref<ExternalResults>({});
const validate = useVuelidate(rules, form, { $externalResults });
const backendValidationErrors = ref({});
const errorNotification = ref<NotificationElement>();
const successNotification = ref<NotificationElement>();
const errorNotificationToggle = () => {
    // Show notification
    errorNotification.value?.showToast();
};
provide("bind[errorNotification]", (el: NotificationElement) => {
    errorNotification.value = el;
});
const showErrorsNotification = () => {
    errorNotificationToggle();
};

const successNotificationToggle = () => {
    successNotification.value?.showToast();
};
provide("bind[successNotification]", (el: NotificationElement) => {
    successNotification.value = el;
});
const showSuccessNotification = () => {
    successNotificationToggle();
};

const onConfirm = () => {
    validate.value.$reset();
    validate.value.$clearExternalResults();
    validate.value.$touch();

    if (validate.value.$invalid) {
        backendValidationErrors.value = {
            message: ["Incomplete or Missing required data"],
        };

        showErrorsNotification();
        loading.value = false;
        sending.value = false;
        return;
    }

    confirm.value = !confirm.value;
};

const onSubmit = async () => {
    onConfirm();
    loading.value = true;
    sending.value = true;

    validate.value.$reset();
    validate.value.$clearExternalResults();
    validate.value.$touch();

    if (validate.value.$invalid) {
        backendValidationErrors.value = {
            message: ["Incomplete or Missing required data"],
        };

        showErrorsNotification();
        loading.value = false;
        sending.value = false;
        return;
    }

    try {
        await InvoiceStore.sendInvoiceEmail({
            id: props.invoice.id,
            ...form,
            to: form.to.split(",").map((email) => email.trim()),
            cc: form.cc ? form.cc.split(",").map((email) => email.trim()) : [],
        });
        showSuccessNotification();
        close();
    } catch (error: any) {
        backendValidationErrors.value = {
            message: ["Something went wrong"],
        };

        showErrorsNotification();
        $externalResults.value = error.response.data.errors;
    } finally {
        loading.value = false;
        sending.value = false;
    }
};

const close = () => {
    form.to = "";
    form.cc = "";
    form.notes = "";

    emit("close");
};

const getErrors = (type: string) => {
    const regex = type === "to" ? /^to\.\d+$/ : /^cc\.\d+$/;
    const key = Object.keys($externalResults.value).find((key) =>
        regex.test(key)
    );
    return key !== undefined ? $externalResults.value[key] : [];
};

onMounted(async () => {
    loading.value = true;
    const id = props.invoice?.client_id;

    await ClientConfigStore.getClientConfigEmails(Number(id));

    // @ts-ignore
    form.to = ClientConfigStore.emails?.emails ?? "";
    loading.value = false;
});
</script>

<template>
    <form @submit.prevent="onConfirm">
        <div class="grid grid-cols-12 gap-6 mt-5 items-left text-left">
            <div class="intro-y col-span-12">
                <div class="intro-y pb-5 pl-5 pr-2">
                    <div>
                        <FormLabel class="form-label" for="to"
                            >To
                            <span class="text-red-600">*</span>
                        </FormLabel>
                        <FormInput
                            id="to"
                            v-model="form.to"
                            :value="form.to"
                            class="form-control w-full"
                            maxlength="50"
                            name="to"
                            placeholder=""
                            type="text"
                        />
                        <template v-if="validate.to.$error">
                            <div
                                v-for="(error, index) in validate.to.$errors"
                                :key="index"
                                class="text-danger mt-2"
                            >
                                {{ error.$message }}
                            </div>
                        </template>
                        <template
                            v-if="
                                Object.keys($externalResults).some((key) =>
                                    /^to\.\d+$/.test(key)
                                )
                            "
                        >
                            <div
                                v-for="(error, index) in getErrors('to')"
                                :key="index"
                                class="text-danger mt-2"
                            >
                                {{ error }}
                            </div>
                        </template>
                    </div>

                    <div class="mt-4">
                        <FormLabel class="form-label" for="cc">CC </FormLabel>
                        <FormInput
                            id="cc"
                            v-model="form.cc"
                            :value="form.cc"
                            class="form-control w-full"
                            maxlength="50"
                            name="cc"
                            placeholder=""
                            type="text"
                        />
                        <template
                            v-if="
                                Object.keys($externalResults).some((key) =>
                                    /^cc\.\d+$/.test(key)
                                )
                            "
                        >
                            <div
                                v-for="(error, index) in getErrors('cc')"
                                :key="index"
                                class="text-danger mt-2"
                            >
                                {{ error }}
                            </div>
                        </template>
                    </div>

                    <div class="mt-4">
                        <FormLabel class="form-label" for="notes"
                            >Additional Notes
                        </FormLabel>
                        <FormTextarea
                            id="notes"
                            v-model="form.notes"
                            :value="form.notes"
                            class="form-control w-full"
                            name="notes"
                            placeholder="Write something..."
                        />
                        <template v-if="validate.notes.$error">
                            <div
                                v-for="(error, index) in validate.notes.$errors"
                                :key="index"
                                class="text-danger mt-2"
                            >
                                {{ error.$message }}
                            </div>
                        </template>
                    </div>
                </div>
            </div>
        </div>

        <div class="">
            <div class="text-right p-2">
                <Button
                    id="btn_cancel"
                    class="w-32 mb-2 mr-1"
                    type="button"
                    variant="warning"
                    @click="close()"
                    :disabled="loading"
                >
                    Cancel
                </Button>
                <Button
                    id="btn_process"
                    class="w-36 mb-2 mr-1"
                    type="submit"
                    variant="customPrimary"
                    :disabled="loading"
                    v-html="sending ? 'Sending...' : 'Send'"
                />
            </div>
        </div>
    </form>

    <Modal :open="confirm" size="md">
        <template #content>
            <div class="flex items-center justify-center">
                <Lucide
                    class="w-16 h-16 mx-auto mt-3 text-warning"
                    icon="Info"
                />
            </div>

            <div class="mt-4 text-center text-slate-600 text-lg">
                Are you sure you want to send this invoice?
            </div>

            <div class="mt-8 text-center">
                <Button
                    id="btn_cancel"
                    class="w-32 mr-1"
                    type="button"
                    @click="confirm = false"
                    :disabled="loading"
                >
                    No, cancel
                </Button>
                <Button
                    id="btn_process"
                    class="w-36"
                    @click="onSubmit"
                    variant="customPrimary"
                    :disabled="loading"
                >
                    Yes, send
                </Button>
            </div>
        </template>
    </Modal>

    <Notification
        :options="{
            duration: 3000,
        }"
        class="flex"
        refKey="successNotification"
    >
        <Lucide class="text-success" icon="CheckCircle" />
        <div class="ml-4 mr-4">
            <div class="font-medium">System Notification</div>
            <div class="mt-1 text-slate-500">
                Invoice has been successfully sent.
            </div>
        </div>
    </Notification>

    <Notification
        :options="{
            duration: 3000,
        }"
        class="flex"
        refKey="errorNotification"
    >
        <Lucide class="text-danger" icon="X" />
        <div class="ml-4 mr-4">
            <div class="font-medium">System Notification</div>
            <div class="mt-1 text-slate-500">
                <ul class="list-disc">
                    <template v-for="errorBag in backendValidationErrors">
                        <li class="text-red-500">
                            {{ errorBag[0] }}
                        </li>
                    </template>
                </ul>
            </div>
        </div>
    </Notification>
</template>

<style scoped></style>
