<script lang="ts" setup>
import {
    defineEmits,
    defineProps,
    onMounted,
    provide,
    reactive,
    ref,
    toRefs,
    watch,
} from "vue";

import "primevue/resources/themes/saga-blue/theme.css";
import "primevue/resources/primevue.min.css";
import "primeicons/primeicons.css";
import { maxLength, minLength, required } from "@vuelidate/validators";
import { useVuelidate } from "@vuelidate/core";
import { useClientsStore } from "../../stores/clientsStore";
import { useHelperStore } from "../../stores/helperStore";
import { useTeamStore } from "../../stores/teamStore";
import { useEmployeesStore } from "../../stores/employeesStore";
import TomSelect from "../../base-components/TomSelect";
import Button from "../../base-components/Button";
import { FormInput, FormLabel } from "../../base-components/Form";
import ClientInterface from "../interfaces/clientsInterface";
import Notification, {
    NotificationElement,
} from "../../base-components/Notification";
import Lucide from "../../base-components/Lucide";

const TeamStore = useTeamStore();
const HelperStore = useHelperStore();
const ClientsStore = useClientsStore();
const EmployeeStore = useEmployeesStore();

const props = defineProps({
    data: Object,
    id: Number,
});

interface TeamFormData {
    id: number;
    client_id: number;
    name: string;
    team_leader: string[];
    team_members: string[];
}

const pointOfContacts = ref<any>([]);
const formData = reactive({
    id: 0,
    client_id: "",
    name: "",
    team_leader: [],
    team_members: [],
    point_of_contacts: [] as any,
});

const rules = {
    name: { required, minLength: minLength(2), maxLength: maxLength(50) },
    client_id: { required },
    team_leader: {},
    team_members: { required },
    point_of_contacts: { required },
};

const backendValidationErrors = ref<string[]>([]);
let clientList = ref<ClientInterface[]>([]);

const emit = defineEmits(["openModal", "close", "status"]);

onMounted(async () => {
    await EmployeeStore.getAllEmployees();
    await ClientsStore.getAllClients();
    clientList.value = ClientsStore.clients;
    await TeamStore.getAllTeams();
    if (props.id !== undefined && props.id !== 0) {
        getTeamDetails(props.id);
        TeamStore.updateState();
    } else {
        resetForm();
        TeamStore.addState();
    }
    // await EmployeeStore.getClientEmployees({'query': {'client_id': 1}});
});

const onChangeClient = (data: any[]) => {
    const leads: any[] = [];

    if (Array.isArray(data)) {
        data.forEach((id) => {
            EmployeeStore.teamEmployees.filter((item) => {
                if (item.id === parseInt(id)) {
                    leads.push(item);
                }
            });
        });
    }

    EmployeeStore.setMemberEmployees(
        EmployeeStore.teamEmployees.filter((x) => {
            return !leads.includes(x);
        })
    );
};

const onChangeMember = (data: any[]) => {
    const members: any[] = [];
    if (Array.isArray(data)) {
        data.forEach((id) => {
            EmployeeStore.teamEmployees.filter((item) => {
                if (item.id === parseInt(id)) {
                    members.push(item);
                }
            });
        });
    }

    EmployeeStore.setClientEmployees(
        EmployeeStore.teamEmployees.filter((x) => !members.includes(x))
    );
};

const filterEmployeeByClient = async (id: number) => {
    let response = [];
    const leaders = [];
    const emp = await EmployeeStore.getClientEmployees({ client_id: id }).then(
        (data) => {
            //response = data;
            if (formData.team_leader) {
                onChangeClient(formData.team_leader);
                onChangeMember(formData.team_members);
            }
        }
    );

    pointOfContacts.value =
        ClientsStore.clients.find((a) => a.id === id)?.point_of_contacts ?? [];
};

const updateMemberlist = async (event: any, data: any) => {
    console.log("updateMemberlist", data);
};

watch(
    () => props.id,
    (newValue, oldValue) => {
        if (newValue !== undefined && newValue !== null) {
            getTeamDetails(newValue);
            TeamStore.updateState();
        } else {
            resetForm();
            TeamStore.addState();
        }
    }
);

const getTeamDetails = async (id: number) => {
    await TeamStore.getAllTeams();
    const team = TeamStore.getTeam(id);

    await EmployeeStore.getClientEmployees({ client_id: team.client_id });

    const leaders: string[] = [];
    const members: string[] = [];

    team.employee_teams.forEach((member) => {
        if (member.team_role_id === 1) {
            leaders.push(String(member.employee_id));
        } else {
            members.push(String(member.employee_id));
        }
    });

    if (typeof team !== "undefined") {
        onChangeClient(leaders);
        onChangeMember(members);

        formData.id = team.id;
        formData.client_id = String(team.client_id);
        formData.name = team.name;
        //@ts-ignore
        formData.team_leader = leaders;

        //@ts-ignore
        formData.team_members = members;

        pointOfContacts.value =
            ClientsStore.clients.find((a) => a.id === team.client_id)
                ?.point_of_contacts ?? [];
        formData.point_of_contacts =
            team?.team_point_of_contacts?.map(
                (a: any) => a.points_of_contact_id
            ) ?? [];
    } else {
        resetForm();
    }
};

const successNotification = ref<NotificationElement>();
const successNotificationToggle = () => {
    successNotification.value?.showToast();
};

provide("bind[successNotification]", (el: NotificationElement) => {
    successNotification.value = el;
});

const showSuccessNotification = () => {
    successNotificationToggle();
};

const errorNotification = ref<NotificationElement>();
const validate = useVuelidate(rules, toRefs(formData));
provide("bind[errorNotification]", (el: NotificationElement) => {
    errorNotification.value = el;
});

const showErrorsNotification = () => {
    errorNotification.value?.showToast();
};

const submit = async () => {
    validate.value.$touch();

    if (validate.value.$invalid) {
        backendValidationErrors.value = [
            "Incomplete or missing required data.",
        ];

        showErrorsNotification();
    } else {
        const response = await TeamStore.save(formData);

        if (response.status === "success") {
            showSuccessNotification();
            resetForm();
            closeModal();
        } else {
            backendValidationErrors.value = response.data;
            showErrorsNotification();
        }
    }
};

const resetForm = () => {
    formData.id = 0;
    formData.client_id = "";
    formData.name = "";
    formData.team_leader = [];
    formData.team_members = [];
};

const closeModal = () => {
    validate.value.$reset();

    emit("status", true);

    emit("close", true);
};

const onAdd = (event: any) => {
    console.log("onAdd", event);
};

const onRemove = (event: any) => {
    console.log("onRemove", event);
};

const diff = reactive({
    data: [],
});

const showConfirmDialog = ref(false);
const deleteConfirmation = (values: string[]) => {
    let message = "Are you sure you want to remove this item?";

    if (values.length > 1) {
        message = `Are you sure you want to remove these ${values.length} items?`;
    } else {
        const leader = EmployeeStore.teamEmployees.find(
            (x) => x.id === parseInt(values[0])
        );

        message = `Are you sure you want to remove ${leader?.name}?`;
    }

    return confirm(message);
};

const pocDeleteConfirmation = (values: string[]) => {
    let message = "Are you sure you want to remove this item?";

    if (values.length > 1) {
        message = `Are you sure you want to remove these ${values.length} items?`;
    } else {
        const poc = pointOfContacts.value.find(
            (x: any) => x.id === parseInt(values[0])
        );

        message = `Are you sure you want to remove ${poc?.full_name}?`;
    }

    return confirm(message);
};

const deleteMemberConfirmation = (values: string[]) => {
    let message = "Are you sure you want to remove this item?";

    if (values.length > 1) {
        message = `Are you sure you want to remove these ${values.length} items?`;
    } else {
        const member = EmployeeStore.memberEmployees.find(
            (x) => x.id === parseInt(values[0])
        );

        message = `Are you sure you want to remove ${member?.name}?`;
    }

    return confirm(message);
};
</script>
<template>
    <form @submit.prevent="submit">
        <div class="grid grid-cols-12 gap-6 mt-5 items-left text-left">
            <div class="intro-y col-span-12">
                <div class="intro-y p-5">
                    <div>
                        <FormLabel class="form-label" for="name"
                            >Name <span style="color: rgb(255, 0, 0)">*</span>
                        </FormLabel>
                        <FormInput
                            id="name"
                            v-model="formData.name"
                            :value="formData.name"
                            class="form-control w-full"
                            maxlength="50"
                            name="name"
                            placeholder=""
                            type="text"
                        />
                        <template v-if="validate.name.$error">
                            <div
                                v-for="(error, index) in validate.name.$errors"
                                :key="index"
                                class="text-danger mt-2"
                            >
                                {{ error.$message }}
                            </div>
                        </template>
                    </div>

                    <div class="mt-3">
                        <FormLabel class="form-label" for="client_id"
                            >Client
                            <span style="color: rgb(255, 0, 0)"
                                >*</span
                            ></FormLabel
                        >
                        <div v-if="TeamStore.mode === 'Add'">
                            <TomSelect
                                id="client_id"
                                v-model="formData.client_id"
                                :options="{
                                    placeholder: 'Select your Client',
                                }"
                                class="w-full"
                                name="client_id"
                                @update:model-value="
                                    filterEmployeeByClient(
                                        +validate.client_id.$model
                                    )
                                "
                            >
                                <option>Select Client</option>
                                <option
                                    v-for="client of clientList"
                                    :value="client.id"
                                >
                                    {{ client.legal_business_name }}
                                </option>
                            </TomSelect>
                        </div>
                        <div v-if="TeamStore.mode === 'Update'">
                            <TomSelect
                                id="client_id"
                                v-model="formData.client_id"
                                :disabled="true"
                                :options="{
                                    placeholder: 'Select your Client',
                                }"
                                class="w-full"
                                name="client_id"
                            >
                                <option
                                    v-for="client of clientList"
                                    :value="client.id"
                                >
                                    {{ client.legal_business_name }}
                                </option>
                            </TomSelect>
                        </div>

                        <template v-if="validate.client_id.$error">
                            <div
                                v-for="(error, index) in validate.client_id
                                    .$errors"
                                :key="index"
                                class="text-danger mt-2"
                            >
                                {{ error.$message }}
                            </div>
                        </template>
                    </div>

                    <div class="mt-3">
                        <FormLabel class="form-label" for="point_of_contacts"
                            >Point of Contacts
                            <span style="color: rgb(255, 0, 0)"
                                >*</span
                            ></FormLabel
                        >
                        <TomSelect
                            id="point_of_contacts"
                            v-model="formData.point_of_contacts"
                            :options="{
                                placeholder: 'Select your point of contacts',
                                onDelete: (values: string[]) => pocDeleteConfirmation(values),
                            }"
                            class="w-full"
                            multiple
                            name="point_of_contacts"
                            @update:model-value="
                                onChangeClient(
                                    validate.point_of_contacts.$model as any
                                )
                            "
                        >
                            <option
                                v-for="poc of pointOfContacts"
                                :value="poc.id"
                            >
                                {{ poc.full_name }}
                            </option>
                        </TomSelect>
                        <template v-if="validate.point_of_contacts.$error">
                            <div
                                v-for="(error, index) in validate
                                    .point_of_contacts.$errors"
                                :key="index"
                                class="text-danger mt-2"
                            >
                                {{ error.$message }}
                            </div>
                        </template>
                    </div>

                    <div class="mt-3">
                        <FormLabel class="form-label" for="team_leader"
                            >Team Leader
                        </FormLabel>
                        <TomSelect
                            id="team_leader"
                            v-model="formData.team_leader"
                            :options="{
                                placeholder: 'Select your team leaders',
                                onDelete: (values: string[]) => deleteConfirmation(values),
                            }"
                            class="w-full"
                            multiple
                            name="team_leader"
                            @update:model-value="
                                onChangeClient(
                                    validate.team_leader.$model as any
                                )
                            "
                        >
                            <option
                                v-for="employee of EmployeeStore.clientEmployees"
                                :value="employee.id"
                            >
                                {{ employee.name }}
                            </option>
                        </TomSelect>
                    </div>
                    <template v-if="validate.team_leader.$error">
                        <div
                            v-for="(error, index) in validate.team_leader
                                .$errors"
                            :key="index"
                            class="text-danger mt-2"
                        >
                            {{ error.$message }}
                        </div>
                    </template>

                    <div class="mt-3">
                        <FormLabel class="form-label" for="team_members"
                            >Team Members
                            <span style="color: rgb(255, 0, 0)"
                                >*</span
                            ></FormLabel
                        >
                        <TomSelect
                            id="team_members"
                            v-model="formData.team_members"
                            :options="{
                                placeholder: 'Select your team members',
                                onDelete: (values: string[]) => deleteMemberConfirmation(values),
                            }"
                            class="w-full"
                            multiple
                            name="team_members"
                            @update:model-value="
                                onChangeMember(
                                    validate.team_members.$model as any
                                )
                            "
                        >
                            <option
                                v-for="employee of EmployeeStore.memberEmployees"
                                :value="employee.id"
                            >
                                {{ employee.name }}
                            </option>
                        </TomSelect>
                    </div>
                    <template v-if="validate.team_members.$error">
                        <div
                            v-for="(error, index) in validate.team_members
                                .$errors"
                            :key="index"
                            class="text-danger mt-2"
                        >
                            {{ error.$message }}
                        </div>
                    </template>
                </div>
            </div>
        </div>
    </form>
    <div class="">
        <div class="text-right p-2">
            <Button
                id="btn_cancel"
                class="w-32 mb-2 mr-1"
                type="button"
                variant="warning"
                @click="closeModal"
            >
                Cancel
            </Button>
            <Button
                id="btn_process"
                class="w-36 mb-2 mr-1"
                variant="customPrimary"
                @click="submit"
            >
                {{ TeamStore.getMode }} Team
            </Button>
        </div>
    </div>

    <Notification
        :options="{
            duration: 3000,
        }"
        class="flex"
        refKey="successNotification"
    >
        <Lucide class="text-success" icon="CheckCircle" />
        <div class="ml-4 mr-4">
            <div class="font-medium">System Notification</div>
            <div class="mt-1 text-slate-500">Client Saved Successfully</div>
        </div>
    </Notification>

    <Notification
        :options="{
            duration: 3000,
        }"
        class="flex"
        refKey="errorNotification"
    >
        <Lucide class="text-danger" icon="X" />
        <div class="ml-4 mr-4">
            <div class="font-medium">System Notification</div>
            <div class="mt-1 text-slate-500">
                <ul class="list-disc">
                    <template v-for="errorBag in backendValidationErrors">
                        <li class="text-red-500">
                            {{ errorBag }}
                        </li>
                    </template>
                </ul>
            </div>
        </div>
    </Notification>
</template>
