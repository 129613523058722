const plugin = require("tailwindcss/plugin");
const colors = require("tailwindcss/colors");
const { parseColor } = require("tailwindcss/lib/util/color");

/** Converts HEX color to RGB */
const toRGB = (value) => {
    return parseColor(value).color.join(" ");
};

/** @type {import('tailwindcss').Config} */
module.exports = {
    content: [
        "./src/**/*.{js,jsx,ts,vue}",
        "./resources/**/*.{php,html,js,jsx,ts,tsx,vue}",
    ],
    safelist: [
        {
            pattern: /animate-delay-.+/,
        },
    ],
    darkMode: "class",
    theme: {
        extend: {
            colors: {
                "custom-color-1000": "#003660",
                "custom-color-900": "#065D85",
                "custom-color-800": "#1CA6C0",
                "custom-color-700": "#4BB3CD",
                "custom-color-600": "#87C8DC",
                "custom-color-500": "#BCDAE7",
                "custom-color-400": "#8DB4DA",
                "custom-color-300": "#003660",
                customPrimary: "#003660",
                primary: "rgb(var(--color-primary) / <alpha-value>)",
                secondary: "rgb(var(--color-secondary) / <alpha-value>)",
                success: "rgb(var(--color-success) / <alpha-value>)",
                info: "rgb(var(--color-info) / <alpha-value>)",
                warning: "rgb(var(--color-warning) / <alpha-value>)",
                pending: "rgb(var(--color-pending) / <alpha-value>)",
                danger: "rgb(var(--color-danger) / <alpha-value>)",
                light: "rgb(var(--color-light) / <alpha-value>)",
                dark: "rgb(var(--color-dark) / <alpha-value>)",
                darkmode: {
                    50: "rgb(var(--color-darkmode-50) / <alpha-value>)",
                    100: "rgb(var(--color-darkmode-100) / <alpha-value>)",
                    200: "rgb(var(--color-darkmode-200) / <alpha-value>)",
                    300: "rgb(var(--color-darkmode-300) / <alpha-value>)",
                    400: "rgb(var(--color-darkmode-400) / <alpha-value>)",
                    500: "rgb(var(--color-darkmode-500) / <alpha-value>)",
                    600: "rgb(var(--color-darkmode-600) / <alpha-value>)",
                    700: "rgb(var(--color-darkmode-700) / <alpha-value>)",
                    800: "rgb(var(--color-darkmode-800) / <alpha-value>)",
                    900: "rgb(var(--color-darkmode-900) / <alpha-value>)",
                },
            },
            fontFamily: {
                roboto: ["Roboto"],
            },
            container: {
                center: true,
            },
            maxWidth: {
                "1/4": "25%",
                "1/2": "50%",
                "3/4": "75%",
            },
            strokeWidth: {
                0.5: 0.5,
                1.5: 1.5,
                2.5: 2.5,
            },
            backgroundImage: {
                "menu-active":
                    "url(\"data:image/svg+xml;charset=utf-8,%3Csvg xmlns='http://www.w3.org/2000/svg' width='20' height='80' viewBox='0 0 20 122.1'%3E%3Cpath data-name='Union 1' d='M16.038 122H16v-2.213a95.805 95.805 0 00-2.886-20.735 94.894 94.894 0 00-7.783-20.434A39.039 39.039 0 010 61.051a39.035 39.035 0 015.331-17.567 94.9 94.9 0 007.783-20.435A95.746 95.746 0 0016 2.314V0h4v122h-3.961v.1l-.001-.1z' fill='%23f1f5f8'/%3E%3C/svg%3E\")",
                "menu-active-dark":
                    "url(\"data:image/svg+xml;charset=utf-8,%3Csvg xmlns='http://www.w3.org/2000/svg' width='20' height='80' viewBox='0 0 20 122.1'%3E%3Cpath data-name='Union 1' d='M16.038 122H16v-2.213a95.805 95.805 0 00-2.886-20.735 94.894 94.894 0 00-7.783-20.434A39.039 39.039 0 010 61.051a39.035 39.035 0 015.331-17.567 94.9 94.9 0 007.783-20.435A95.746 95.746 0 0016 2.314V0h4v122h-3.961v.1l-.001-.1z' fill='%23232e45'/%3E%3C/svg%3E\")",
                "bredcrumb-chevron-dark":
                    "url(\"data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='24' height='24' viewBox='0 0 24 24' fill='none' stroke='currentColor' stroke-width='1' stroke-linecap='round' stroke-linejoin='round' class='feather feather-chevron-right breadcrumb__icon'%3E%3Cpolyline points='9 18 15 12 9 6'%3E%3C/polyline%3E%3C/svg%3E\")",
                "bredcrumb-chevron-light":
                    "url(\"data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='24' height='24' viewBox='0 0 24 24' fill='none' stroke='%23e8eeff' stroke-width='1' stroke-linecap='round' stroke-linejoin='round' class='feather feather-chevron-right breadcrumb__icon'%3E%3Cpolyline points='9 18 15 12 9 6'%3E%3C/polyline%3E%3C/svg%3E\")",
                "bredcrumb-chevron-darkmode":
                    "url(\"data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='24' height='24' viewBox='0 0 24 24' fill='none' stroke='%23718096' stroke-width='1' stroke-linecap='round' stroke-linejoin='round' class='feather feather-chevron-right breadcrumb__icon'%3E%3Cpolyline points='9 18 15 12 9 6'%3E%3C/polyline%3E%3C/svg%3E\")",
            },
            keyframes: {
                // Side & simple menu
                "intro-divider": {
                    "100%": {
                        opacity: 1,
                    },
                },
                "intro-menu": {
                    "100%": {
                        opacity: 1,
                        transform: "translateX(0px)",
                    },
                },
                "active-side-menu-chevron": {
                    "100%": {
                        opacity: 1,
                        "margin-right": "-27px",
                    },
                },

                // Top menu
                "intro-top-menu": {
                    "100%": {
                        opacity: 1,
                    },
                },
                "active-top-menu-chevron": {
                    "100%": {
                        opacity: 1,
                        "margin-bottom": "-56px",
                    },
                },
            },
        },
    },
    plugins: [
        require("@tailwindcss/forms"),
        require("@tailwindcss/typography"),
        plugin(function ({ addBase, matchUtilities }) {
            addBase({
                // Default colors
                ":root": {
                    "--color-primary": toRGB(colors.cyan["900"]),
                    "--color-secondary": toRGB(colors.slate["200"]),
                    "--color-success": toRGB(colors.teal["600"]),
                    "--color-info": toRGB(colors.cyan["500"]),
                    "--color-warning": toRGB(colors.amber["500"]),
                    "--color-pending": toRGB(colors.amber["600"]),
                    "--color-danger": toRGB(colors.red["700"]),
                    "--color-light": toRGB(colors.slate["100"]),
                    "--color-dark": toRGB(colors.slate["800"]),
                },
                // Default dark-mode colors
                ".dark": {
                    "--color-primary": toRGB(colors.cyan["800"]),
                    "--color-darkmode-50": "87 103 132",
                    "--color-darkmode-100": "74 90 121",
                    "--color-darkmode-200": "65 81 114",
                    "--color-darkmode-300": "53 69 103",
                    "--color-darkmode-400": "48 61 93",
                    "--color-darkmode-500": "41 53 82",
                    "--color-darkmode-600": "40 51 78",
                    "--color-darkmode-700": "35 45 69",
                    "--color-darkmode-800": "27 37 59",
                    "--color-darkmode-900": "15 23 42",
                },
                // Theme 1 colors
                ".theme-1": {
                    "--color-primary": toRGB(colors.blue["800"]),
                    "--color-secondary": toRGB(colors.slate["200"]),
                    "--color-success": toRGB(colors.lime["500"]),
                    "--color-info": toRGB(colors.cyan["500"]),
                    "--color-warning": toRGB(colors.yellow["400"]),
                    "--color-pending": toRGB(colors.orange["500"]),
                    "--color-danger": toRGB(colors.red["600"]),
                    "--color-light": toRGB(colors.slate["100"]),
                    "--color-dark": toRGB(colors.slate["800"]),
                    "&.dark": {
                        "--color-primary": toRGB(colors.emerald["800"]),
                    },
                },
                // Theme 2 colors
                ".theme-2": {
                    "--color-primary": toRGB(colors.blue["900"]),
                    "--color-secondary": toRGB(colors.slate["200"]),
                    "--color-success": toRGB(colors.teal["600"]),
                    "--color-info": toRGB(colors.cyan["500"]),
                    "--color-warning": toRGB(colors.amber["500"]),
                    "--color-pending": toRGB(colors.orange["500"]),
                    "--color-danger": toRGB(colors.red["700"]),
                    "--color-light": toRGB(colors.slate["100"]),
                    "--color-dark": toRGB(colors.slate["800"]),
                    "&.dark": {
                        "--color-primary": toRGB(colors.blue["800"]),
                    },
                },
                // Theme 3 colors
                ".theme-3": {
                    "--color-primary": toRGB(colors.emerald["900"]),
                    "--color-secondary": toRGB(colors.slate["200"]),
                    "--color-success": toRGB(colors.emerald["600"]),
                    "--color-info": toRGB(colors.cyan["500"]),
                    "--color-warning": toRGB(colors.yellow["400"]),
                    "--color-pending": toRGB(colors.amber["500"]),
                    "--color-danger": toRGB(colors.rose["600"]),
                    "--color-light": toRGB(colors.slate["100"]),
                    "--color-dark": toRGB(colors.slate["800"]),
                    "&.dark": {
                        "--color-primary": toRGB(colors.cyan["800"]),
                    },
                },
                // Theme 4 colors
                ".theme-4": {
                    "--color-primary": toRGB(colors.indigo["900"]),
                    "--color-secondary": toRGB(colors.slate["200"]),
                    "--color-success": toRGB(colors.emerald["600"]),
                    "--color-info": toRGB(colors.cyan["500"]),
                    "--color-warning": toRGB(colors.yellow["500"]),
                    "--color-pending": toRGB(colors.orange["600"]),
                    "--color-danger": toRGB(colors.red["700"]),
                    "--color-light": toRGB(colors.slate["100"]),
                    "--color-dark": toRGB(colors.slate["800"]),
                    "&.dark": {
                        "--color-primary": toRGB(colors.indigo["700"]),
                    },
                },
            });

            // Animation delay utilities
            matchUtilities(
                {
                    "animate-delay": (value) => ({
                        "animation-delay": value,
                    }),
                },
                {
                    values: (() => {
                        const values = {};
                        for (let i = 1; i <= 50; i++) {
                            values[i * 10] = `${i * 0.1}s`;
                        }
                        return values;
                    })(),
                }
            );

            // Animation fill mode utilities
            matchUtilities(
                {
                    "animate-fill-mode": (value) => ({
                        "animation-fill-mode": value,
                    }),
                },
                {
                    values: {
                        none: "none",
                        forwards: "forwards",
                        backwards: "backwards",
                        both: "both",
                    },
                }
            );
        }),
    ],
    variants: {
        extend: {
            boxShadow: ["dark"],
        },
    },
};
