<script lang="ts" setup>
import { onMounted, reactive, ref } from "vue";
import moment from "moment/moment";
import "primevue/resources/themes/saga-blue/theme.css";
import "primevue/resources/primevue.min.css";
import "primeicons/primeicons.css";
import { FilterMatchMode, FilterOperator } from "primevue/api";
import DataTable from "primevue/datatable";
import Column from "primevue/column";
import InputText from "primevue/inputtext";
import Dropdown from "primevue/dropdown";
import PageHeaderStandard from "@/pages/components/PageHeaderStandard.vue";
import router from "@/router";
import { useActivityLogsStore } from "@/stores/activityLogsStore";
import { useAuthStore } from "@/stores/authStore";
import { useUiStore } from "@/stores/uiStore";
import LazyParamsInterface from "@/pages/interfaces/lazyParamsInterface";
import Lucide from "@/base-components/Lucide";
import { FormInput } from "@/base-components/Form";

const AuthStore = useAuthStore();
const ActivityLogStore = useActivityLogsStore();
const UiStore = useUiStore();

const showClearFilter = ref(false);

const filters = ref({
    global: { value: null, matchMode: FilterMatchMode.CONTAINS },
    description: {
        operator: FilterOperator.AND,
        constraints: [{ value: null, matchMode: FilterMatchMode.EQUALS }],
    },
    event: {
        operator: FilterOperator.AND,
        constraints: [{ value: null, matchMode: FilterMatchMode.STARTS_WITH }],
    },
    'properties->performedByName': {
        operator: FilterOperator.AND,
        constraints: [{ value: null, matchMode: FilterMatchMode.CONTAINS }],
    },
});

const activity = ref();

const selectedactivity = ref();

const selectAll = ref(false);

const loading = ref(true);

const totalRecords = ref(0);

const lazyParams = ref<LazyParamsInterface>({});

const dt = ref();

let timezone = ref();

const searchableCols = ref(["description", "event", "properties->performedByName"]);

onMounted(() => {
    UiStore.setBreadcrumb("All Activity Logs");
    AuthStore.getLoggedInUserDetails();
    lazyParams.value = {
        first: 0,
        rows: dt.value.rows,
        sortField: "id",
        sortOrder: -1,
        filters: filters.value,
    };
    loadLazyData();
});

const loadLazyData = async () => {
    loading.value = true;
    const params = {
        dt_params: JSON.stringify(lazyParams.value),
        searchable_columns: JSON.stringify(searchableCols.value),
    };

    await ActivityLogStore.fillTable(params);
    descriptionOptions.value = ActivityLogStore.scopes;
    activity.value = ActivityLogStore.table.data;
    totalRecords.value = ActivityLogStore.table.total;
    loading.value = false;
};

const onPage = (event: {}) => {
    lazyParams.value = event;
    loadLazyData();
};

const onSort = (event: {}) => {
    showClearFilter.value = true;
    lazyParams.value = event;
    loadLazyData();
};

const onFilter = (event: {}) => {
    showClearFilter.value = true;
    lazyParams.value.filters = filters.value;
    loadLazyData();
};

const resetGlobalFilter = () => {
    showClearFilter.value = false;
    filters.value = {
        global: { value: null, matchMode: FilterMatchMode.CONTAINS },
        description: {
            operator: FilterOperator.AND,
            constraints: [
                { value: null, matchMode: FilterMatchMode.EQUALS },
            ],
        },
        event: {
            operator: FilterOperator.AND,
            constraints: [
                { value: null, matchMode: FilterMatchMode.STARTS_WITH },
            ],
        },
        'properties->performedByName': {
            operator: FilterOperator.AND,
            constraints: [
                { value: null, matchMode: FilterMatchMode.CONTAINS },
            ],
        },
    };
    lazyParams.value = {
        first: 0,
        rows: dt.value.rows,
        sortField: "id",
        sortOrder: -1,
        filters: filters.value,
    };
    loadLazyData();
};

const onSelectAllChange = (event: { checked: any }) => {
    const selectAll = event.checked;
};

const onRowSelect = () => {
    selectAll.value = selectedactivity.value.length === totalRecords.value;
};

const onRowUnselect = () => {
    selectAll.value = false;
};

const hasLogDetails = ['Team', 'Employee', 'Client', 'Ticket', 'Invoice'];
const gotoLogs = (description: string, id: number) => {
    switch (description) {
        case "Team":
            router.push({ name: "teams" });
            break;
        case "Employee":
            router.push({ name: "employeesDetails", params: { id: id } });
            break;
        case "Client":
            router.push({ name: "clientsDetails", params: { id: id } });
            break;
        case "Ticket":
            router.push({ name: "ticketDetails", params: { id: id } });
            break;
        case "Invoice":
            router.push({ name: "invoice-details", params: { id: id } });
            break;
        default:
            break;
    }
};
const descriptionOptions = ref<string[] | undefined>([]);
</script>
<template>
    <div class="w-full">
        <PageHeaderStandard :title="'Activity Log'" />
        <div
            class="intro-y col-span-12 flex flex-wrap sm:flex-nowrap items-center mt-2"
        >
            <div class="hidden md:block mx-auto text-slate-500"></div>

            <div class="flex w-full sm:w-auto mt-3 sm:mt-0 sm:ml-auto md:ml-0">
                <template v-if="showClearFilter">
                    <button
                        class="btn bg-custom-color-1000 text-white w-full mx-3 rounded-md px-4"
                        @click="resetGlobalFilter"
                    >
                        Clear
                        <span
                            class="h-3 w-4 pi pi-filter-slash p-component"
                        ></span>
                    </button>
                </template>

                <div class="w-56 relative text-slate-500">
                    <FormInput
                        v-model="filters.global.value"
                        :value="filters.global.value"
                        class="w-56 pr-10 !box"
                        placeholder="Search..."
                        type="text"
                        @keyup.enter="onFilter($event)"
                    />

                    <Lucide
                        class="absolute inset-y-0 right-0 w-4 h-4 my-auto mr-3"
                        icon="Search"
                    />
                </div>
            </div>
        </div>
        <DataTable
            ref="dt"
            v-model:filters="filters"
            v-model:selection="selectedactivity"
            :globalFilterFields="searchableCols"
            :lazy="true"
            :loading="loading"
            :paginator="true"
            :rows="100"
            :selectAll="selectAll"
            sortField="id"
            :sortOrder="-1"
            :totalRecords="totalRecords"
            :value="activity"
            class="mt-4 p-datatable-sm rounded-lg shadow-lg text-xs"
            dataKey="tid"
            filterDisplay="menu"
            responsiveLayout="scroll"
            showGridlines
            stripedRows
            @filter="onFilter"
            @page="onPage($event)"
            @sort="onSort($event)"
            @select-all-change="onSelectAllChange"
            @row-select="onRowSelect"
            @row-unselect="onRowUnselect"
        >
            <template #empty> No activity found.</template>
            <template #loading> Loading ticket data. Please wait.</template>
            <Column :sortable="true" field="description" header="Description" :showFilterMatchModes="false">
                <template #body="{ data }">
                    {{ data.description }}
                </template>
                <template #filter="{ filterModel }">
                    <Dropdown
                        v-model="filterModel.value"
                        :options="descriptionOptions"
                        placeholder="Select a Description"
                    />
                </template>
            </Column>
            <Column :sortable="true" field="event" header="Event">
                <template #body="{ data }">
                    {{ data.event }}
                </template>
                <template #filter="{ filterModel }">
                    <InputText
                        v-model="filterModel.value"
                        class="p-column-filter"
                        placeholder="Search by Event"
                        type="text"
                    />
                </template>
            </Column>
            <Column field="properties->performedByName" header="Performed By" :showFilterMatchModes="false">
                <template #body="{ data }">
                    {{ JSON.parse(data.properties).performedByName }}
                </template>
                <template #filter="{ filterModel }">
                    <InputText
                        v-model="filterModel.value"
                        class="p-column-filter"
                        placeholder="Search Performed By"
                        type="text"
                    />
                </template>
            </Column>
            <Column :sortable="true" field="created_at" header="Performed At">
                <template #body="{ data }">
                    {{ moment(data.created_at).format("MMMM D, YYYY hh:mmA") }}
                </template>
            </Column>
            <Column :exportable="false" style="width: 8rem; max-width: 8rem">
                <template #body="slotProps">
                    <div class="content-center flex justify-center">
                        <!-- View -->
                        <div v-if="hasLogDetails.includes(slotProps.data.description)"
                            class="text-gray-500 dark:text-gray-400 hover:text-gray-900 dark:hover:text-gray-700 bg-none border-0"
                            @click="gotoLogs(slotProps.data.description, slotProps.data.subject_id)">
                            <svg :id="'view_' + slotProps.data.id"
                                class="icon icon-tabler icon-tabler-eye cursor-pointer" fill="none" height="18"
                                stroke="currentColor" stroke-linecap="round" stroke-linejoin="round"
                                stroke-width="2" viewBox="0 0 24 24" width="18" xmlns="http://www.w3.org/2000/svg">
                                <path d="M0 0h24v24H0z" fill="none" stroke="none"></path>
                                <path d="M12 12m-2 0a2 2 0 1 0 4 0a2 2 0 1 0 -4 0"></path>
                                <path
                                    d="M22 12c-2.667 4.667 -6 7 -10 7s-7.333 -2.333 -10 -7c2.667 -4.667 6 -7 10 -7s7.333 2.333 10 7">
                                </path>
                            </svg>
                        </div>
                        <!-- View -->
                    </div>
                </template>
                <template #header="slotProps">
                    <div class="content-center flex justify-center"></div>
                </template>
            </Column>
            <template #paginatorstart>
                <!--                <Button type="button" icon="pi pi-refresh" class="p-button-text"/>-->
            </template>
            <template #paginatorend>
                <!--                <Button type="button" icon="pi pi-cloud" class="p-button-text"/>-->
            </template>
        </DataTable>
    </div>
</template>
