<script lang="ts" setup>
import { defineEmits, PropType, ref } from "vue";
import { Tab } from "../../base-components/Headless";
import { useEmployeesStore } from "../../stores/employeesStore";
import { useHelperStore } from "../../stores/helperStore";
import moment from "moment";
import EmployeeInterface from "../interfaces/employeesInterface";
import { isset } from "../../utils/helper";
import { OfficeInterface } from "../interfaces/officeInterface";
import { useAuthStore } from "../../stores/authStore";
import { useTeamStore } from "../../stores/teamStore";
import Avatar from "./Avatar.vue";
import Lucide from "../../base-components/Lucide/Lucide.vue";
import FormSlider from "../FormSlider.vue";
import EmployeeStatus from "./employees/EmployeeStatus.vue";

const AuthStore = useAuthStore();
const TeamStore = useTeamStore();
const props = defineProps({
    employee: {
        type: Object as PropType<EmployeeInterface>,
        default: () => ({}),
    },
    showIdCard: {
        type: Boolean,
        default: false,
    },
});

const EmployeesStore = useEmployeesStore();
const HelperStore = useHelperStore();
const emit = defineEmits(["refresh"]);

const slider = ref(false);

const open = () => {
    slider.value = !slider.value;
};

const close = async () => {
    slider.value = !slider.value;
    emit("refresh");
};

const WorkLocationType = {
    wfh: "Work from Home",
    wfo: "Work from Office",
    hybrid: "Hybrid",
} as any;

const getAddress = (office?: OfficeInterface): string => {
    if (!isset(office || {})) {
        return "";
    }

    return `${office?.address_line_1}, ${office?.address_line_2}, ${office?.city}, ${office?.province}, ${office?.zipcode}`;
};
</script>
<template>
    <div v-if="props.showIdCard" class="relative flex items-center p-5">
        <div class="w-12 h-12 image-fit">
            <Avatar :name="employee.name" :ring="'online'" :size="'sm'" />
        </div>
        <div class="ml-4 mr-auto w-4/5">
            <div class="font-medium text-base word-break">
                {{ employee.name }}
            </div>
            <div class="text-slate-500">
                {{ employee.position }}
            </div>
        </div>
    </div>
    <Tab.Group class="px-4">
        <Tab.List
            class="bg-color-customPrimary text-white"
            variant="boxed-tabs"
        >
            <Tab>
                <template
                    v-if="
                        Object.values(
                            AuthStore.activatedPermissionsName
                        ).includes('can view employees sensitive data')
                    "
                >
                    <Tab.Button as="button" class="w-full py-2">
                        Work
                    </Tab.Button>
                </template>
                <template
                    v-if="
                        !Object.values(
                            AuthStore.activatedPermissionsName
                        ).includes('can view employees sensitive data')
                    "
                >
                    <Tab.Button
                        as="button"
                        class="w-full py-2"
                        data-tw-target="tab1"
                    >
                        Work Information
                    </Tab.Button>
                </template>
            </Tab>
            <Tab
                tag="button"
                v-bind:class="
                    Object.values(AuthStore.activatedPermissionsName).includes(
                        'can view employees sensitive data'
                    )
                        ? ''
                        : 'hidden'
                "
                v-bind:data-tw-target="
                    Object.values(AuthStore.activatedPermissionsName).includes(
                        'can view employees sensitive data'
                    )
                        ? 'tab2'
                        : ' '
                "
            >
                <template
                    v-if="
                        Object.values(
                            AuthStore.activatedPermissionsName
                        ).includes('can view employees sensitive data')
                    "
                >
                    <Tab.Button as="button" class="w-full py-2">
                        Confidential
                    </Tab.Button>
                </template>
            </Tab>
        </Tab.List>
        <Tab.Panels class="mt-5">
            <Tab.Panel id="tab1" class="leading-relaxed">
                <div
                    class="border-t border-slate-200/60 dark:border-darkmode-400"
                >
                    <div class="px-2 py-2">
                        <div class="relative flex items-center mt-2">
                            <div class="ml-4 mr-auto">
                                <div class="flex items-center">
                                    <a class="font-medium mr-2" href="#"
                                        >Status
                                    </a>
                                    <Lucide
                                        v-if="employee?.user?.is_active"
                                        id="update-status"
                                        class="w-3 h-3 cursor-pointer"
                                        icon="Pencil"
                                        @click="open"
                                    />
                                </div>

                                <div>
                                    <div
                                        v-if="employee?.user?.is_active"
                                        class="mr-5 sm:mr-5 word-break bg-green-100 text-green-800 rounded-full px-6 py-1 font-semibold text-sm"
                                    >
                                        Active
                                    </div>

                                    <div
                                        v-else
                                        class="mr-5 sm:mr-5 word-break bg-red-100 text-red-800 rounded-full px-6 py-1 font-semibold text-sm"
                                    >
                                        Inactive
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div
                            v-if="
                                Object.values(
                                    AuthStore.activatedPermissionsName
                                ).includes('can view employees sensitive data')
                            "
                            class="relative flex items-center mt-2"
                        >
                            <div class="ml-4 mr-auto">
                                <a class="font-medium" href="#">Client</a>
                                <div
                                    v-if="
                                        employee.client &&
                                        employee.client.legal_business_name !==
                                            ''
                                    "
                                    class="text-slate-500 mr-5 sm:mr-5"
                                >
                                    <template
                                        v-if="
                                            Object.values(
                                                AuthStore.activatedPermissionsName
                                            ).includes('can view all clients')
                                        "
                                    >
                                        <router-link
                                            v-if="employee.client"
                                            :to="{
                                                path:
                                                    '/clients/' +
                                                    employee.client.id,
                                            }"
                                        >
                                            {{
                                                employee.client
                                                    .legal_business_name
                                            }}
                                        </router-link>
                                    </template>
                                    <template v-else>
                                        {{
                                            employee.client.legal_business_name
                                        }}
                                    </template>
                                </div>
                                <div v-else class="text-slate-500 mr-5 sm:mr-5">
                                    <template
                                        v-if="
                                            Object.values(
                                                AuthStore.activatedPermissionsName
                                            ).includes('can view all clients')
                                        "
                                    >
                                        <router-link
                                            :to="{
                                                path:
                                                    '/clients/' +
                                                    TeamStore.MyTeamClient.id,
                                            }"
                                        >
                                            {{
                                                TeamStore.MyTeamClient
                                                    .legal_business_name
                                            }}
                                        </router-link>
                                    </template>
                                    <template v-else>
                                        {{
                                            TeamStore.MyTeamClient
                                                .legal_business_name
                                        }}
                                    </template>
                                </div>
                            </div>
                        </div>
                        <div class="relative flex items-center mt-2">
                            <div class="ml-4 mr-auto">
                                <a class="font-medium" href="#"
                                    >Third Party Employee ID
                                </a>
                                <div
                                    class="text-slate-500 mr-5 sm:mr-5 word-break"
                                >
                                    {{ employee.third_party_employee_id }}
                                </div>
                            </div>
                        </div>
                        <div class="relative flex items-center mt-2">
                            <div class="ml-4 mr-auto">
                                <a class="font-medium" href="#">Office</a>
                                <div
                                    v-if="isset(employee.office || {})"
                                    class="text-slate-500 mr-5 sm:mr-5"
                                >
                                    {{ getAddress(employee.office) }}
                                </div>
                            </div>
                        </div>
                        <div class="relative flex items-center mt-2">
                            <div class="ml-4 mr-auto">
                                <a class="font-medium" href="#"
                                    >Primary Work Location</a
                                >
                                <div class="text-slate-500 mr-5 sm:mr-5">
                                    {{
                                        WorkLocationType[
                                            employee.primary_work_location
                                        ]
                                    }}
                                </div>
                            </div>
                        </div>
                        <div class="relative flex items-center mt-2">
                            <div class="ml-4 mr-auto">
                                <a class="font-medium" href="#"
                                    >Secondary Work Location</a
                                >
                                <div class="text-slate-500 mr-5 sm:mr-5">
                                    {{
                                        WorkLocationType[
                                            employee.secondary_work_location
                                        ]
                                    }}
                                </div>
                            </div>
                        </div>
                        <div class="relative flex items-center mt-2">
                            <div class="ml-4 mr-auto">
                                <a class="font-medium" href="#">Timezone</a>
                                <div class="text-slate-500 mr-5 sm:mr-5">
                                    {{
                                        HelperStore.getTimezone(
                                            employee.timezone_id
                                        ).name
                                    }}
                                </div>
                            </div>
                        </div>
                        <div class="relative flex items-center mt-2">
                            <div class="ml-4 mr-auto">
                                <a class="font-medium" href="#">Start Date</a>
                                <div class="text-slate-500 mr-5 sm:mr-5">
                                    {{
                                        moment(employee.start_date).format(
                                            "YYYY-MM-D h:mm a"
                                        )
                                    }}
                                </div>
                            </div>
                        </div>
                        <div class="relative flex items-center mt-2">
                            <div class="ml-4 mr-auto">
                                <a class="font-medium" href="#"
                                    >Orientation Date</a
                                >
                                <div class="text-slate-500 mr-5 sm:mr-5">
                                    {{
                                        moment(
                                            employee.orientation_date
                                        ).format("YYYY-MM-D h:mm a")
                                    }}
                                </div>
                            </div>
                        </div>
                        <div class="relative flex items-center mt-2">
                            <div class="ml-4 mr-auto">
                                <a class="font-medium" href="#"
                                    >Shift Schedule</a
                                >
                                <div
                                    class="text-slate-500 mr-5 sm:mr-5 word-break"
                                >
                                    {{ employee.shift_schedule }}
                                </div>
                            </div>
                        </div>
                        <div class="relative flex items-center mt-2">
                            <div class="ml-4 mr-auto">
                                <a class="font-medium" href="#">Work Phone</a>
                                <div
                                    class="text-slate-500 mr-5 sm:mr-5 word-break"
                                >
                                    {{ employee.work_phone }}
                                </div>
                            </div>
                        </div>
                        <div class="relative flex items-center mt-2">
                            <div class="ml-4 mr-auto">
                                <a class="font-medium" href="#">Work Email</a>
                                <div
                                    class="text-slate-500 mr-5 sm:mr-5 word-break"
                                >
                                    {{ employee.work_email }}
                                </div>
                            </div>
                        </div>
                        <div class="relative flex items-center mt-2">
                            <div class="ml-4 mr-auto">
                                <a class="font-medium" href="#">Biometric ID</a>
                                <div
                                    class="text-slate-500 mr-5 sm:mr-5 word-break"
                                >
                                    {{ employee.sprout_id ?? "-" }}
                                </div>
                            </div>
                        </div>
                        <div
                            v-if="
                                Object.values(
                                    AuthStore.activatedPermissionsName
                                ).includes('can view employees sensitive data')
                            "
                            class="relative flex items-center mt-2"
                        >
                            <div class="ml-4 mr-auto">
                                <a class="font-medium" href="#">Client Email</a>
                                <div class="text-slate-500 mr-5 sm:mr-5">
                                    {{ employee.client_email }}
                                </div>
                            </div>
                        </div>
                        <div class="relative flex items-center mt-2">
                            <div class="ml-4 mr-auto">
                                <a class="font-medium" href="#"
                                    >Internal Staff Flag</a
                                >
                                <div
                                    v-if="employee.internal_staff_flag"
                                    class="text-slate-500 mr-5 sm:mr-5"
                                >
                                    yes
                                </div>
                                <div v-else class="text-slate-500 mr-5 sm:mr-5">
                                    no
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </Tab.Panel>
            <Tab.Panel id="tab2" class="leading-relaxed">
                <div
                    class="py-2 px-2 border-t border-slate-200/60 dark:border-darkmode-400"
                >
                    <div class="relative flex items-center mt-2">
                        <div class="ml-4 mr-auto">
                            <a class="font-medium" href="#">Personal Phone</a>
                            <div class="text-slate-500 mr-5 sm:mr-5 word-break">
                                {{ employee.personal_phone }}
                            </div>
                        </div>
                    </div>
                    <div class="relative flex items-center mt-2">
                        <div class="ml-4 mr-auto">
                            <a class="font-medium" href="#">Basic Salary</a>
                            <div class="text-slate-500 mr-5 sm:mr-5">
                                {{ employee.basic_salary }}
                            </div>
                        </div>
                    </div>
                    <div class="relative flex items-center mt-2">
                        <div class="ml-4 mr-auto">
                            <a class="font-medium" href="#"
                                >Regularization Date</a
                            >
                            <div class="text-slate-500 mr-5 sm:mr-5">
                                {{ employee.regularization_date }}
                            </div>
                        </div>
                    </div>
                    <div class="relative flex items-center mt-2">
                        <div class="ml-4 mr-auto">
                            <a class="font-medium" href="#">Personal Email</a>
                            <div class="text-slate-500 mr-5 sm:mr-5">
                                {{ employee.personal_email }}
                            </div>
                        </div>
                    </div>
                    <div class="relative flex items-center mt-2">
                        <div class="ml-4 mr-auto">
                            <a class="font-medium" href="#"
                                >Emergency Contact Name</a
                            >
                            <div class="text-slate-500 mr-5 sm:mr-5 word-break">
                                {{ employee.emergency_contact_name }}
                            </div>
                        </div>
                    </div>
                    <div class="relative flex items-center mt-2">
                        <div class="ml-4 mr-auto">
                            <a class="font-medium" href="#"
                                >Emergency Contact Phone</a
                            >
                            <div class="text-slate-500 mr-5 sm:mr-5 word-break">
                                {{ employee.emergency_contact_phone }}
                            </div>
                        </div>
                    </div>
                    <div class="relative flex items-center mt-2">
                        <div class="ml-4 mr-auto">
                            <a class="font-medium" href="#"
                                >Emergency Contact Relationship</a
                            >
                            <div class="text-slate-500 mr-5 sm:mr-5 word-break">
                                {{ employee.emergency_contact_relationship }}
                            </div>
                        </div>
                    </div>
                    <div class="relative flex items-center mt-2">
                        <div class="ml-4 mr-auto">
                            <a class="font-medium" href="#">SSS Number</a>
                            <div class="text-slate-500 mr-5 sm:mr-5 word-break">
                                {{ employee.sss }}
                            </div>
                        </div>
                    </div>
                    <div class="relative flex items-center mt-2">
                        <div class="ml-4 mr-auto">
                            <a class="font-medium" href="#">Pagibig Number</a>
                            <div class="text-slate-500 mr-5 sm:mr-5 word-break">
                                {{ employee.pagibig }}
                            </div>
                        </div>
                    </div>
                    <div class="relative flex items-center mt-2">
                        <div class="ml-4 mr-auto">
                            <a class="font-medium" href="#">Tax ID Number</a>
                            <div class="text-slate-500 mr-5 sm:mr-5 word-break">
                                {{ employee.tin }}
                            </div>
                        </div>
                    </div>
                    <div class="relative flex items-center mt-2">
                        <div class="ml-4 mr-auto">
                            <a class="font-medium" href="#"
                                >PhilHealth Number</a
                            >
                            <div class="text-slate-500 mr-5 sm:mr-5 word-break">
                                {{ employee.phic }}
                            </div>
                        </div>
                    </div>
                    <div class="relative flex items-center mt-2">
                        <div class="ml-4 mr-auto">
                            <a class="font-medium" href="#">Address</a>
                            <div class="text-slate-500 mr-5 sm:mr-5 word-break">
                                {{ employee.address_line_1 }}
                                {{ employee.address_line_2 }}
                                {{ employee.city }} {{ employee.zip }}
                            </div>
                        </div>
                    </div>
                </div>
            </Tab.Panel>
        </Tab.Panels>
    </Tab.Group>

    <FormSlider
        :is-open="slider"
        header="Employee Status"
        size="md"
        @close="open"
    >
        <EmployeeStatus
            :is_active="employee?.user?.is_active"
            @close="close()"
        />
    </FormSlider>
</template>
