import { defineStore } from "pinia";
import Http from "../services/Http";
import { EmployeeNonrefundableHistoryInterface } from "../pages/interfaces/employeesInterface";
import ApiResponseInterface from "../pages/interfaces/apiResponseInterface";

export const useEmployeeNonrefundableHistoryStore = defineStore(
    "EmployeeNonrefundableHistoryStore",
    {
        state: (): {
            employeeNonrefundableHistories: EmployeeNonrefundableHistoryInterface[];
            employeeNonrefundableHistory: EmployeeNonrefundableHistoryInterface;
        } => {
            return {
                employeeNonrefundableHistories:
                    [] as EmployeeNonrefundableHistoryInterface[],
                employeeNonrefundableHistory:
                    {} as EmployeeNonrefundableHistoryInterface,
            };
        },

        actions: {
            async getEmployeeNonrefundableHistories(
                employeeId: number
            ): Promise<void> {
                const baseUrl = import.meta.env.VITE_API_URL;

                const { data } = await new Http(baseUrl).get<
                    ApiResponseInterface<
                        EmployeeNonrefundableHistoryInterface[]
                    >
                >(
                    `/employee-nonrefundable-histories?employee_id=${employeeId}`
                );

                this.employeeNonrefundableHistories = data;
            },

            async getEmployeeNonrefundableHistory(id: number): Promise<void> {
                const baseUrl = import.meta.env.VITE_API_URL;

                const { data } = await new Http(baseUrl).get<
                    ApiResponseInterface<EmployeeNonrefundableHistoryInterface>
                >(`/employee-nonrefundable-histories/${id}`);

                this.employeeNonrefundableHistory = data;
            },

            async addEmployeeNonrefundableHistory(
                payload: EmployeeNonrefundableHistoryInterface
            ): Promise<void> {
                const baseUrl = import.meta.env.VITE_API_URL;

                const { data } = await new Http(baseUrl).post<
                    ApiResponseInterface<EmployeeNonrefundableHistoryInterface>
                >("/employee-nonrefundable-histories", payload);

                this.employeeNonrefundableHistories.unshift(data);
            },

            async updateEmployeeNonrefundableHistory(
                payload: EmployeeNonrefundableHistoryInterface
            ): Promise<void> {
                const baseUrl = import.meta.env.VITE_API_URL;

                const { data } = await new Http(baseUrl).put<
                    ApiResponseInterface<EmployeeNonrefundableHistoryInterface>
                >(`/employee-nonrefundable-histories/${payload.id}`, payload);

                const index = this.employeeNonrefundableHistories.findIndex(
                    (item) => item.id === data.id
                );

                console.log(data);

                this.employeeNonrefundableHistories[index] = data;
            },

            async deleteEmployeeNonrefundableHistory(
                id: number
            ): Promise<void> {
                const baseUrl = import.meta.env.VITE_API_URL;

                await new Http(baseUrl).delete(
                    `/employee-nonrefundable-histories/${id}`
                );

                const index = this.employeeNonrefundableHistories.findIndex(
                    (item) => item.id === id
                );

                this.employeeNonrefundableHistories.splice(index, 1);
            },
        },
    }
);
