<script lang="ts" setup>
import { onMounted, reactive, ref } from "vue";
import { required } from "@vuelidate/validators";
import "primevue/resources/themes/saga-blue/theme.css";
import "primevue/resources/primevue.min.css";
import "primeicons/primeicons.css";
import { FilterMatchMode, FilterOperator } from "primevue/api";
import DataTable from "primevue/datatable";
import Column from "primevue/column";
import InputText from "primevue/inputtext";
import { useClientsStore } from "../stores/clientsStore";
import { useHelperStore } from "../stores/helperStore";
import PageHeaderStandard from "./components/PageHeaderStandard.vue";
import { useTeamStore } from "../stores/teamStore";
import FormAddTeam from "./forms/FormAddTeam.vue";
import { useEmployeesStore } from "../stores/employeesStore";
import Button from "../base-components/Button";
import Lucide from "../base-components/Lucide";
import FormInput from "../base-components/Form/FormInput.vue";
import FormSlider from "./FormSlider.vue";
import { useAuthStore } from "../stores/authStore";
import ConfirmModal from "./components/ConfirmModal.vue";
import LazyParamsInterface from "./interfaces/lazyParamsInterface";

const AuthStore = useAuthStore();

const EmployeeStore = useEmployeesStore();

const ClientStore = useClientsStore();

const HelperStore = useHelperStore();
const TeamStore = useTeamStore();

let statesOptionsDetails = {};
let timezoneOptionsDetails = {};

const alertModelSettings = reactive({
    isDisplayed: false,
    mode: "",
    subject: "",
    message: "",
});

const filters = ref({
    global: { value: null, matchMode: FilterMatchMode.CONTAINS },
    name: {
        operator: FilterOperator.AND,
        constraints: [{ value: null, matchMode: FilterMatchMode.STARTS_WITH }],
    },
    "client.legal_business_name": {
        operator: FilterOperator.AND,
        constraints: [{ value: null, matchMode: FilterMatchMode.STARTS_WITH }],
    },
});

const expandedRows = ref([]);

const teams = ref();

const selectedteams = ref();

const selectAll = ref(false);

const loading = ref(true);

const totalRecords = ref(0);

const lazyParams = ref<LazyParamsInterface>({});

const dt = ref();

const showClearFilter = ref(false);
let timezone = ref();

const columns = ref([{ field: "name", header: "Ticket Name" }]);

const searchableCols = ref(["name"]);

let showSlider = ref(false);

let buttonModalPreview = ref(false);

const formData = reactive({
    user_id: "",
    name: "",
    position: "",
    personal_phone: "",
    personal_email: "",
    work_email: "",
    work_phone: "",
    client_email: null,
    primary_work_location: null,
    location: "",
    shift_schedule: null,
    notes: null,
    emergency_contact_name: null,
    emergency_contact_relationship: null,
    internal_staff_flag: null,
    sss: null,
    pagibig: null,
    tin: null,
    phic: null,
    basic_salary: null,
    regularization_date: null,
    timezone_id: null,
});

const rules = {
    legal_business_name: { required },
    user_id: { required },
    name: { required },
    position: { required },
    personal_phone: { required },
    personal_email: { required },
    work_email: { required },
    work_phone: { required },
    client_email: { required },
    timezone_id: { required },
    primary_work_location: { required },
    location: { required },
    shift_schedule: { required },
    notes: { required },
    emergency_contact_name: { required },
    emergency_contact_relationship: { required },
    internal_staff_flag: { required },
    sss: { required },
    pagibig: { required },
    tin: { required },
    phic: { required },
    basic_salary: { required },
    regularization_date: { required },
};

const formticketSettings = reactive({
    id: 0,
});

onMounted(async () => {
    await AuthStore.getLoggedInUserDetails();
    await ClientStore.getAllClients();
    loadLazyData();
});

const deleteId = ref(0);

const updateticket = async (data: { id: number }) => {
    console.log("updateticket", data);
    showSlider.value = true;
    formticketSettings.id = data.id;
};
const deleteticket = async (data: { id: any }) => {
    console.log("deleteticket", data);
    await TeamStore.delete(data.id);
    loadLazyData();
};
const loadLazyData = async () => {
    console.log("loadLazyData");
    loading.value = true;
    const params = {
        dt_params: JSON.stringify(lazyParams.value),
        searchable_columns: JSON.stringify(searchableCols.value),
    };

    await TeamStore.fill(params).then((response: any) => {
        //await TeamStore.fill(params)
        teams.value = TeamStore.teamsTableData.data;
        totalRecords.value = TeamStore.teamsTableData.total;
        loading.value = false;
        console.log("teams = ", teams.value);
    });
};

const onPage = (event: {}) => {
    console.log("onPage");
    lazyParams.value = event;
    console.log("New onPage");
    console.log(lazyParams);
    loadLazyData();
};

const onSort = (event: {}) => {
    showClearFilter.value = true;
    console.log("onSort");
    lazyParams.value = event;
    loadLazyData();
};

const onFilter = () => {
    showClearFilter.value = filters.value.global.value !== "";
    console.log("onFilter");
    lazyParams.value.filters = filters.value;
    loadLazyData();
};

const onSelectAllChange = (event: { checked: any }) => {
    console.log("onSelectAllChange");
    const selectAll = event.checked;

    // if (selectAll) {
    //     customerService.value.getCustomers().then((data: { customers: any; }) => {
    //         selectAll.value = true;
    //         selectedteams.value = data.customers;
    //     });
    // } else {
    //     selectAll.value = false;
    //     selectedteams.value = [];
    // }
};

const onRowSelect = () => {
    console.log("onRowSelect");
    selectAll.value = selectedteams.value.length === totalRecords.value;
};

const onRowUnselect = () => {
    console.log("onRowUnselect");
    selectAll.value = false;
};

const onRowExpand = () => {
    console.log("onRowSelect");
};
const onRowCollapse = () => {
    console.log("onRowSelect");
};
const expandAll = () => {
    console.log("expandAll");
};
const collapseAll = () => {
    console.log("collapseAll");
};

const isFormTeamAddSliderOut = ref(false);

const teamId = ref(0);
const closeFormAddTeamSlider = () => {
    console.log("closeFormAddTeamSlider");
    teamId.value = 0;
    isFormTeamAddSliderOut.value = false;
};

const createNew = () => {
    console.log("add Team");
    isFormTeamAddSliderOut.value = true;
    teamId.value = 0;
};

const viewteam = async (data: { id: number }) => {
    isFormTeamAddSliderOut.value = true;
    teamId.value = data.id;
};

const formAddTeamStatus = (status: any) => {
    if (status) {
        loadLazyData();
    }
};

const showConfirmDialog = ref(false);
const setConfirmDialog = (value: boolean) => {
    showConfirmDialog.value = value;
};

const deleteteam = (data: { id: number }) => {
    deleteId.value = data.id;
    setConfirmDialog(true);
};

const processDelete = async (event: any) => {
    if (event) {
        await TeamStore.delete(deleteId.value).then(
            (response: { status: string }) => {
                if (response.status === "success") {
                    loadLazyData();
                    deleteId.value = 0;
                }
            }
        );
    }
};

const resetGlobalFilter = () => {
    showClearFilter.value = false;

    filters.value = {
        global: { value: null, matchMode: FilterMatchMode.CONTAINS },
        name: {
            operator: FilterOperator.AND,
            constraints: [
                { value: null, matchMode: FilterMatchMode.STARTS_WITH },
            ],
        },
        "client.legal_business_name": {
            operator: FilterOperator.AND,
            constraints: [
                { value: null, matchMode: FilterMatchMode.STARTS_WITH },
            ],
        },
    };

    lazyParams.value = {
        first: 0,
        rows: 10,
        sortField: "name",
        sortOrder: 1,
        filters: filters.value,
    };

    loadLazyData();
};
</script>
<template>
    <div class="w-full">
        <PageHeaderStandard :title="'Teams'" />
        <div
            class="intro-y col-span-12 flex flex-wrap sm:flex-nowrap items-center mt-2"
        >
            <Button
                id="addNewticket"
                class="mr-2 shadow-md"
                variant="customPrimary"
                @click="createNew"
                >Add New Team
            </Button>
            <div class="hidden md:block mx-auto text-slate-500"></div>
            <div class="flex w-full sm:w-auto mt-3 sm:mt-0 sm:ml-auto md:ml-0">
                <template v-if="showClearFilter">
                    <button
                        class="btn bg-custom-color-1000 text-white w-full mx-3 rounded-md px-4"
                        @click="resetGlobalFilter"
                    >
                        Clear
                        <span
                            class="h-3 w-4 pi pi-filter-slash p-component"
                        ></span>
                    </button>
                </template>

                <div class="w-56 relative text-slate-500">
                    <FormInput
                        v-model="filters['global'].value"
                        :value="filters['global'].value"
                        class="w-56 pr-10 !box"
                        placeholder="Search..."
                        type="text"
                        @keyup.enter="onFilter()"
                    />
                    <Lucide
                        class="absolute inset-y-0 right-0 w-4 h-4 my-auto mr-3"
                        icon="Search"
                    />
                </div>
            </div>
        </div>
        <DataTable
            ref="dt"
            v-model:expandedRows="expandedRows"
            v-model:filters="filters"
            v-model:selection="selectedteams"
            :globalFilterFields="searchableCols"
            :lazy="true"
            :loading="loading"
            :paginator="true"
            :rows="10"
            :selectAll="selectAll"
            :totalRecords="totalRecords"
            :value="teams"
            class="mt-4 p-datatable-sm rounded-lg shadow-lg text-xs"
            dataKey="id"
            filterDisplay="menu"
            responsiveLayout="scroll"
            showGridlines
            stripedRows
            @filter="onFilter()"
            @page="onPage($event)"
            @rowCollapse="onRowCollapse"
            @rowExpand="onRowExpand"
            @sort="onSort($event)"
            @select-all-change="onSelectAllChange"
            @row-select="onRowSelect"
            @row-unselect="onRowUnselect"
        >
            <template #empty> No teams found.</template>
            <template #loading> Loading team data. Please wait.</template>
            <Column :expander="true" headerStyle="width: 3rem" />
            <Column :sortable="true" field="id" header="Team ID">
                <template #body="{ data }">
                    {{ data.id }}
                </template>
            </Column>
            <Column :sortable="true" field="name" header="Team Name">
                <template #body="{ data }">
                    {{ data.name }}
                </template>
                <template #filter="{ filterModel }">
                    <InputText
                        v-model="filterModel.value"
                        class="p-column-filter"
                        placeholder="Search by Team Name"
                        type="text"
                    />
                </template>
            </Column>
            <Column
                :sortable="true"
                field="client.legal_business_name"
                header="Client Name"
            >
                <template #body="{ data }">
                    {{ data.client.legal_business_name }}
                </template>
                <template #filter="{ filterModel }">
                    <InputText
                        v-model="filterModel.value"
                        class="p-column-filter"
                        placeholder="Search by Client Name"
                        type="text"
                    />
                </template>
            </Column>
            <Column :exportable="false" style="width: 8rem; max-width: 8rem">
                <template #body="slotProps">
                    <div class="content-center flex justify-center">
                        <!--                        <div class="">-->
                        <!--                            <svg-->
                        <!--                                :id="'view_' + slotProps.data.id"-->
                        <!--                                class="icon icon-tabler icon-tabler-eye cursor-pointer"-->
                        <!--                                fill="none"-->
                        <!--                                height="18"-->
                        <!--                                stroke="currentColor"-->
                        <!--                                stroke-linecap="round"-->
                        <!--                                stroke-linejoin="round"-->
                        <!--                                stroke-width="2"-->
                        <!--                                viewBox="0 0 24 24"-->
                        <!--                                width="18"-->
                        <!--                                xmlns="http://www.w3.org/2000/svg"-->
                        <!--                                @click="viewteam(slotProps.data)"-->
                        <!--                            >-->
                        <!--                                <path-->
                        <!--                                    d="M0 0h24v24H0z"-->
                        <!--                                    fill="none"-->
                        <!--                                    stroke="none"-->
                        <!--                                ></path>-->
                        <!--                                <path-->
                        <!--                                    d="M12 12m-2 0a2 2 0 1 0 4 0a2 2 0 1 0 -4 0"-->
                        <!--                                ></path>-->
                        <!--                                <path-->
                        <!--                                    d="M22 12c-2.667 4.667 -6 7 -10 7s-7.333 -2.333 -10 -7c2.667 -4.667 6 -7 10 -7s7.333 2.333 10 7"-->
                        <!--                                ></path>-->
                        <!--                            </svg>-->
                        <!--                        </div>-->

                        <div class="ml-2">
                            <svg
                                :id="'update_' + slotProps.data.id"
                                class="icon icon-tabler icon-tabler-edit cursor-pointer"
                                fill="none"
                                height="18"
                                stroke="currentColor"
                                stroke-linecap="round"
                                stroke-linejoin="round"
                                stroke-width="2"
                                viewBox="0 0 24 24"
                                width="18"
                                xmlns="http://www.w3.org/2000/svg"
                                @click="viewteam(slotProps.data)"
                            >
                                <path
                                    d="M0 0h24v24H0z"
                                    fill="none"
                                    stroke="none"
                                ></path>
                                <path
                                    d="M7 7h-1a2 2 0 0 0 -2 2v9a2 2 0 0 0 2 2h9a2 2 0 0 0 2 -2v-1"
                                ></path>
                                <path
                                    d="M20.385 6.585a2.1 2.1 0 0 0 -2.97 -2.97l-8.415 8.385v3h3l8.385 -8.415z"
                                ></path>
                                <path d="M16 5l3 3"></path>
                            </svg>
                        </div>

                        <div class="ml-2">
                            <svg
                                :id="'delete_' + slotProps.data.id"
                                class="icon icon-tabler icon-tabler-trash cursor-pointer"
                                fill="none"
                                height="18"
                                stroke="currentColor"
                                stroke-linecap="round"
                                stroke-linejoin="round"
                                stroke-width="2"
                                viewBox="0 0 24 24"
                                width="18"
                                xmlns="http://www.w3.org/2000/svg"
                                @click="deleteteam(slotProps.data)"
                            >
                                <path
                                    d="M0 0h24v24H0z"
                                    fill="none"
                                    stroke="none"
                                ></path>
                                <path d="M4 7l16 0"></path>
                                <path d="M10 11l0 6"></path>
                                <path d="M14 11l0 6"></path>
                                <path
                                    d="M5 7l1 12a2 2 0 0 0 2 2h8a2 2 0 0 0 2 -2l1 -12"
                                ></path>
                                <path
                                    d="M9 7v-3a1 1 0 0 1 1 -1h4a1 1 0 0 1 1 1v3"
                                ></path>
                            </svg>
                        </div>
                        <!--                        <p>-->
                        <!--                            <Button icon="pi pi-pencil" class="p-button-text table_btn_view"-->
                        <!--                                @click="viewteam(slotProps.data)" style="color: #003660;width: 2rem;" />-->

                        <!--                            <Button icon="pi pi-trash" class="p-button-rounded p-button-text table_btn_delete"-->
                        <!--                                @click="deleteteam(slotProps.data)" style="color: #ff0000;width: 2rem;" />-->
                        <!--                        </p>-->
                    </div>
                </template>
                <template #header="slotProps">
                    <div class="content-center flex justify-center">
                        <p v-if="showClearFilter"></p>
                    </div>
                </template>
            </Column>
            <template #paginatorstart>
                <!--                <Button type="button" icon="pi pi-refresh" class="p-button-text"/>-->
            </template>
            <template #paginatorend>
                <!--                <Button type="button" icon="pi pi-cloud" class="p-button-text"/>-->
            </template>
            <template #expansion="slotProps">
                <div class="orders-subtable p-10">
                    <h5 class="text-md">
                        <b class="text-md"
                            >Members for {{ slotProps.data.name }}</b
                        >
                    </h5>
                    <DataTable
                        :value="slotProps.data.employee_teams"
                        class="mt-2 text-xs"
                        responsiveLayout="scroll"
                    >
                        <Column field="team_role_id" header="Role" sortable>
                            <template #body="{ data }">
                                {{
                                    HelperStore.getTeamRole(
                                        parseInt(data.team_role_id)
                                    ).name
                                }}
                            </template>
                        </Column>
                        <Column field="employee_id" header="Employee" sortable>
                            <template #body="{ data }">
                                {{ data.employee.name }}
                            </template>
                        </Column>
                        <!--<Column headerStyle="width:4rem">
                            <template #body>
                                <Button icon="pi pi-pencil" class="p-button-text table_btn_view"
                                    style="color: rgb(0, 54, 96);" />
                            </template>
                        </Column>-->
                    </DataTable>
                </div>
            </template>
        </DataTable>
        <FormSlider
            :header="'Team Details'"
            :isOpen="isFormTeamAddSliderOut"
            :size="'lg'"
            @close="closeFormAddTeamSlider()"
        >
            <FormAddTeam
                :id="teamId"
                @close="closeFormAddTeamSlider()"
                @status="formAddTeamStatus($event)"
            ></FormAddTeam>
        </FormSlider>

        <ConfirmModal
            :displayConfirmModal="showConfirmDialog"
            second-description="Team has been successfully deleted."
            @closeConfirmModal="setConfirmDialog(false)"
            @proceedProcess="processDelete($event)"
        />
    </div>
</template>
