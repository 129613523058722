<script lang="ts" setup>
import {
    defineEmits,
    defineProps,
    provide,
    reactive,
    ref,
    toRefs,
    watch,
} from "vue";
import { useEmployeesStore } from "../../stores/employeesStore";
import { useHelperStore } from "../../stores/helperStore";
import { required } from "@vuelidate/validators";
import { useVuelidate } from "@vuelidate/core";
import { useRoute } from "vue-router";
import _ from "lodash";
import Button from "../../base-components/Button";
import TomSelect from "../../base-components/TomSelect";
import { FormLabel } from "../../base-components/Form";
import EmployeeInterface from "../interfaces/employeesInterface";
import Notification, {
    NotificationElement,
} from "../../base-components/Notification";
import Lucide from "../../base-components/Lucide";

const EmployeesStore = useEmployeesStore();
const HelperStore = useHelperStore();

const props = defineProps({
    data: Object,
    id: Number,
});
const employee_id = ref();

const route = useRoute();
const id = Array.isArray(route.params.id)
    ? route.params.id[0]
    : route.params.id;

watch(
    () => props.id,
    async (newValue, oldValue) => {
        console.log("watching team" + newValue);
        console.log("watching team" + oldValue);

        if (newValue !== 0) {
            console.log("id", newValue);
            employee_id.value = newValue;
            //getEmployeeDetails(newValue);
            //EmployeesStore.updateState();
        } else {
            //EmployeesStore.addState();
            //resetForm();
        }
    }
);

interface Attachments {
    data: File[][];
}

const attachments: Attachments = reactive({
    data: [[]],
});

const uploadFile = (e: Event) => {
    attachments.data[0] = [];

    const files = (e.target as HTMLInputElement).files;
    if (files) {
        for (let i = 0; i < files.length; i++) {
            attachments.data[0].push(files[i]);
        }
    }
};

const form = reactive({
    type_id: "",
    employee_id: parseInt(id),
    attachments: [] as File[][],
});

const rules = {
    type_id: { required },
    attachments: { required },
};

const formReset = () => {
    form.type_id = "";
};
const loading = ref(false);
const backendValidationErrors = ref({});
const errorNotification = ref<NotificationElement>();
const successNotification = ref<NotificationElement>();
const $externalResults = ref({});
const validate = useVuelidate(rules, toRefs(form), { $externalResults });
const sendFiles = async () => {
    loading.value = true;

    const formData = new FormData();
    formData.append("type_id", form.type_id);
    formData.append("employee_id", String(form.employee_id));
    if (attachments.data !== null) {
        _.forEach(attachments.data, function (files) {
            _.forEach(files, function (file) {
                formData.append("attachments[]", file);
            });
        });
        form.attachments = attachments.data;
        //console.log('all attachments', attachments);
    }

    validate.value.$reset();
    validate.value.$clearExternalResults();
    validate.value.$touch();

    if (validate.value.$invalid) {
        backendValidationErrors.value = {
            message: ["Incomplete or Missing required data"],
        };

        showErrorsNotification();

        loading.value = false;

        return;
    }

    try {
        await EmployeesStore.uploadAttachment(
            formData as unknown as EmployeeInterface
        );

        showSuccessNotification();
        emit("status", true);
        emit("close", true);
    } catch (error: any) {
        backendValidationErrors.value = {
            message: [
                "Attachment was not saved successfully. Please try again.",
            ],
        };

        showErrorsNotification();
        $externalResults.value = error?.response?.data?.errors;
    } finally {
        loading.value = false;
    }
};

const emit = defineEmits(["status", "close"]);
const closeModal = () => {
    emit("status", true);
    emit("close", true);
};

const hasArrayErrors = () => {
    const regex = /\.\d+/;

    let error = false;

    if (typeof $externalResults.value === "undefined") {
        return false;
    }

    if (Object.keys($externalResults.value).length === 0) {
        return false;
    }

    Object.keys($externalResults.value).forEach((key) => {
        if (regex.test(key)) {
            error = true;
        }
    });

    return error;
};

const errorNotificationToggle = () => {
    // Show notification
    errorNotification.value?.showToast();
};
provide("bind[errorNotification]", (el: NotificationElement) => {
    errorNotification.value = el;
});
const showErrorsNotification = () => {
    errorNotificationToggle();
};

const successNotificationToggle = () => {
    successNotification.value?.showToast();
};
provide("bind[successNotification]", (el: NotificationElement) => {
    successNotification.value = el;
});
const showSuccessNotification = () => {
    successNotificationToggle();
};
</script>
<template>
    <div>
        <div class="intro-y pt-5 pb-5 pl-5 pr-2">
            <div
                class="border border-gray-400/50 px-4 py-6 rounded-md shadow-lg text-left"
            >
                <div
                    class="flex items-center border-b border-gray-400/50 dark:border-darkmode-400"
                >
                    <h2 class="font-medium text-base mr-auto mb-4">
                        Select File {{ hasArrayErrors() }}
                    </h2>
                </div>
                <div class="mt-3">
                    <FormLabel class="form-label"
                        >Attachment Type:
                        <span style="color: rgb(255, 0, 0)">*</span></FormLabel
                    >
                    <TomSelect
                        id="type_id"
                        v-model="form.type_id"
                        class="p-0"
                        name="type_id"
                    >
                        <option>Select Attachment Type</option>
                        <option
                            v-for="types of _.filter(
                                HelperStore.attachmentTypes,
                                (types) => types.type === 'employee'
                            )"
                            :value="types.id"
                        >
                            {{ types.name }}
                        </option>
                    </TomSelect>
                </div>
                <div>
                    <template v-if="validate.type_id.$error">
                        <div
                            v-for="(error, index) in validate.type_id.$errors"
                            :key="index"
                            class="text-danger mt-2"
                        >
                            {{ error.$message }}
                        </div>
                    </template>
                </div>

                <div class="mt-4">
                    <FormLabel class="form-label"
                        >Upload File:
                        <span style="color: rgb(255, 0, 0)">*</span></FormLabel
                    >
                    <p>
                        <input
                            id="uploadFileBtn"
                            :change="validate.attachments.$model"
                            class="btn shadow-md mr-2"
                            multiple
                            type="file"
                            @change="uploadFile"
                        />
                    </p>
                    <template v-if="validate.attachments.$error">
                        <div
                            v-for="(error, index) in validate.attachments
                                .$errors"
                            :key="index"
                            class="text-danger mt-2"
                        >
                            {{ error.$message }}
                        </div>
                    </template>

                    <template v-if="hasArrayErrors()">
                        <div
                            v-for="(error, index) in $externalResults"
                            :key="index"
                            class="text-danger mt-2"
                        >
                            {{ error[0] }}
                        </div>
                    </template>
                </div>
            </div>
        </div>

        <div class="">
            <div class="flex items-center justify-end pr-2">
                <Button
                    id="btn_cancel"
                    class="w-32 mb-2 mr-1"
                    variant="warning"
                    :disabled="loading"
                    @click="closeModal"
                >
                    Cancel
                </Button>
                <Button
                    id="btn_process"
                    class="w-32 mb-2 mr-1"
                    variant="customPrimary"
                    @click="sendFiles"
                    :disabled="loading"
                >
                    <div v-if="loading" class="flex items-center">
                        <svg
                            aria-hidden="true"
                            class="w-4 h-4 mr-2 text-gray-200 animate-spin dark:text-gray-600 fill-blue-600"
                            fill="none"
                            viewBox="0 0 100 101"
                            xmlns="http://www.w3.org/2000/svg"
                        >
                            <path
                                d="M100 50.5908C100 78.2051 77.6142 100.591 50 100.591C22.3858 100.591 0 78.2051 0 50.5908C0 22.9766 22.3858 0.59082 50 0.59082C77.6142 0.59082 100 22.9766 100 50.5908ZM9.08144 50.5908C9.08144 73.1895 27.4013 91.5094 50 91.5094C72.5987 91.5094 90.9186 73.1895 90.9186 50.5908C90.9186 27.9921 72.5987 9.67226 50 9.67226C27.4013 9.67226 9.08144 27.9921 9.08144 50.5908Z"
                                fill="currentColor"
                            />
                            <path
                                d="M93.9676 39.0409C96.393 38.4038 97.8624 35.9116 97.0079 33.5539C95.2932 28.8227 92.871 24.3692 89.8167 20.348C85.8452 15.1192 80.8826 10.7238 75.2124 7.41289C69.5422 4.10194 63.2754 1.94025 56.7698 1.05124C51.7666 0.367541 46.6976 0.446843 41.7345 1.27873C39.2613 1.69328 37.813 4.19778 38.4501 6.62326C39.0873 9.04874 41.5694 10.4717 44.0505 10.1071C47.8511 9.54855 51.7191 9.52689 55.5402 10.0491C60.8642 10.7766 65.9928 12.5457 70.6331 15.2552C75.2735 17.9648 79.3347 21.5619 82.5849 25.841C84.9175 28.9121 86.7997 32.2913 88.1811 35.8758C89.083 38.2158 91.5421 39.6781 93.9676 39.0409Z"
                                fill="currentFill"
                            />
                        </svg>

                        <div>Uploading...</div>
                    </div>

                    <div v-else>Upload</div>
                </Button>
            </div>
        </div>
    </div>

    <!-- BEGIN: Success Notification Content -->
    <div
        id="upload-success-notification-content"
        class="toastify-content hidden flex"
    >
        <!--        <CheckCircleIcon class="text-success"/>-->
        <div class="ml-4 mr-4">
            <div class="font-medium">Success</div>
            <div class="text-slate-500 mt-1">
                Attachment was saved successfully
            </div>
        </div>
    </div>
    <!-- END: Success Notification Content -->
    <!-- BEGIN: Failed Notification Content -->
    <div
        id="upload-failed-notification-content"
        class="toastify-content hidden flex"
    >
        <!--        <XCircleIcon class="text-danger"/>-->
        <div class="ml-4 mr-4">
            <div class="font-medium">Error occured!</div>
            <div class="text-slate-500 mt-1">
                Attachment was not saved successfully. Please try again.
            </div>
        </div>
    </div>

    <Notification
        :options="{
            duration: 3000,
        }"
        class="flex"
        refKey="successNotification"
    >
        <Lucide class="text-success" icon="CheckCircle" />
        <div class="ml-4 mr-4">
            <div class="font-medium">System Notification</div>
            <div class="mt-1 text-slate-500">
                File has been successfully added.
            </div>
        </div>
    </Notification>

    <Notification
        :options="{
            duration: 3000,
        }"
        class="flex"
        refKey="errorNotification"
    >
        <Lucide class="text-danger" icon="X" />
        <div class="ml-4 mr-4">
            <div class="font-medium">System Notification</div>
            <div class="mt-1 text-slate-500">
                <ul class="list-disc">
                    <template v-for="errorBag in backendValidationErrors">
                        <li class="text-red-500">
                            {{ errorBag[0] }}
                        </li>
                    </template>
                </ul>
            </div>
        </div>
    </Notification>
</template>
