<script lang="ts" setup>
import { onMounted, reactive, ref } from "vue";
import moment from "moment/moment";
import "primevue/resources/themes/saga-blue/theme.css";
import "primevue/resources/primevue.min.css";
import "primeicons/primeicons.css";
import { FilterMatchMode, FilterOperator } from "primevue/api";
import Card from "primevue/card";
import DataTable from "primevue/datatable";
import Column from "primevue/column";
import InputText from "primevue/inputtext";
import Dropdown from "primevue/dropdown";
import PageHeaderStandard from "@/pages/components/PageHeaderStandard.vue";
import { useShiftActivityLogsStore } from "@/stores/shiftActivityLogsStore";
import { useAuthStore } from "@/stores/authStore";
import LazyParamsInterface from "@/pages/interfaces/lazyParamsInterface";
import Lucide from "@/base-components/Lucide";
import { FormInput } from "@/base-components/Form";
import Avatar from "./components/Avatar.vue";
import _ from "lodash";

const AuthStore = useAuthStore();
const ShiftActivityLogStore = useShiftActivityLogsStore();

const showClearFilter = ref(false);

const filters = ref({
    global: { value: null, matchMode: FilterMatchMode.CONTAINS },
    'properties->attribute->employee->name': {
        operator: FilterOperator.AND,
        constraints: [{ value: null, matchMode: FilterMatchMode.CONTAINS }],
    },
    description: {
        operator: FilterOperator.AND,
        constraints: [{ value: null, matchMode: FilterMatchMode.EQUALS }],
    },
    event: {
        operator: FilterOperator.AND,
        constraints: [{ value: null, matchMode: FilterMatchMode.STARTS_WITH }],
    },
    'properties->performedByName': {
        operator: FilterOperator.AND,
        constraints: [{ value: null, matchMode: FilterMatchMode.CONTAINS }],
    },
});

const expandedRows = ref([]);

const activities = ref();

const selectedActivity = ref();

const selectAll = ref(false);

const loading = ref(true);

const totalRecords = ref(0);

const lazyParams = ref<LazyParamsInterface>({});

const dt = ref();

const searchableCols = ref(["properties->attribute->employee->name","description", "event", "properties->performedByName"]);

onMounted(() => {
    AuthStore.getLoggedInUserDetails();
    lazyParams.value = {
        first: 0,
        rows: dt.value.rows,
        sortField: "id",
        sortOrder: -1,
        filters: filters.value,
    };
    loadLazyData();
});

const loadLazyData = async () => {
    loading.value = true;
    const params = {
        dt_params: JSON.stringify(lazyParams.value),
        searchable_columns: JSON.stringify(searchableCols.value),
    };

    await ShiftActivityLogStore.fillTable(params);
    descriptionOptions.value = ShiftActivityLogStore.scopes;
    activities.value = ShiftActivityLogStore.table.data;
    totalRecords.value = ShiftActivityLogStore.table.total;
    loading.value = false;
};

const onPage = (event: {}) => {
    lazyParams.value = event;
    loadLazyData();
};

const onSort = (event: {}) => {
    showClearFilter.value = true;
    lazyParams.value = event;
    loadLazyData();
};

const onFilter = (event: {}) => {
    showClearFilter.value = true;
    lazyParams.value.filters = filters.value;
    loadLazyData();
};

const resetGlobalFilter = () => {
    showClearFilter.value = false;
    filters.value = {
        global: { value: null, matchMode: FilterMatchMode.CONTAINS },
        'properties->attribute->employee->name': {
            operator: FilterOperator.AND,
            constraints: [
                { value: null, matchMode: FilterMatchMode.CONTAINS },
            ],
        },
        description: {
            operator: FilterOperator.AND,
            constraints: [
                { value: null, matchMode: FilterMatchMode.EQUALS },
            ],
        },
        event: {
            operator: FilterOperator.AND,
            constraints: [
                { value: null, matchMode: FilterMatchMode.STARTS_WITH },
            ],
        },
        'properties->performedByName': {
            operator: FilterOperator.AND,
            constraints: [
                { value: null, matchMode: FilterMatchMode.CONTAINS },
            ],
        },
    };
    lazyParams.value = {
        first: 0,
        rows: dt.value.rows,
        sortField: "id",
        sortOrder: -1,
        filters: filters.value,
    };
    loadLazyData();
};

const onSelectAllChange = (event: { checked: any }) => {
    const selectAll = event.checked;
};

const onRowSelect = () => {
    selectAll.value = selectedActivity.value.length === totalRecords.value;
};

const onRowUnselect = () => {
    selectAll.value = false;
};

const descriptionOptions = ref<string[] | undefined>([]);

const getDataSequence = (type: string) => {
    let sequence:string[] = [];
    switch(type) {
        case "EmployeeShift":
            sequence = ["shiftName", "startTime", "endTime", "effectiveFrom", "effectiveTo"];
            break;
        case "EmployeeSchedule":
            sequence = ["scheduleDate", "shiftName", "startTime", "endTime", "manualOverridden"];
            break;
        default:
            sequence = [];   
    };

    return sequence;
};
</script>
<template>
    <div class="w-full">
        <PageHeaderStandard :title="'Activity Log'" />
        <div
            class="intro-y col-span-12 flex flex-wrap sm:flex-nowrap items-center mt-2"
        >
            <div class="hidden md:block mx-auto text-slate-500"></div>

            <div class="flex w-full sm:w-auto mt-3 sm:mt-0 sm:ml-auto md:ml-0">
                <template v-if="showClearFilter">
                    <button
                        class="btn bg-custom-color-1000 text-white w-full mx-3 rounded-md px-4"
                        @click="resetGlobalFilter"
                    >
                        Clear
                        <span
                            class="h-3 w-4 pi pi-filter-slash p-component"
                        ></span>
                    </button>
                </template>

                <div class="w-56 relative text-slate-500">
                    <FormInput
                        v-model="filters.global.value"
                        :value="filters.global.value"
                        class="w-56 pr-10 !box"
                        placeholder="Search..."
                        type="text"
                        @keyup.enter="onFilter($event)"
                    />

                    <Lucide
                        class="absolute inset-y-0 right-0 w-4 h-4 my-auto mr-3"
                        icon="Search"
                    />
                </div>
            </div>
        </div>
        <DataTable
            ref="dt"
            v-model:expandedRows="expandedRows"
            v-model:filters="filters"
            v-model:selection="selectedActivity"
            :globalFilterFields="searchableCols"
            :lazy="true"
            :loading="loading"
            :paginator="true"
            :rows="100"
            :selectAll="selectAll"
            sortField="id"
            :sortOrder="-1"
            :totalRecords="totalRecords"
            :value="activities"
            class="mt-4 p-datatable-sm rounded-lg shadow-lg text-xs"
            dataKey="id"
            filterDisplay="menu"
            responsiveLayout="scroll"
            showGridlines
            stripedRows
            @filter="onFilter"
            @page="onPage($event)"
            @sort="onSort($event)"
            @select-all-change="onSelectAllChange"
            @row-select="onRowSelect"
            @row-unselect="onRowUnselect"
        >
            <template #empty> No shift activity found.</template>
            <template #loading> Loading ticket data. Please wait.</template>
            <Column :expander="true" headerStyle="width: 3rem" />
            <Column field="properties->attribute->employee->name" header="Employee" :showFilterMatchModes="false">
                <template #body="{ data }">
                    <div class="flex items-center gap-2">
                        <Avatar
                            :name="JSON.parse(data.properties).attribute?.employee?.name"
                            :ring="'no-border'"
                            :size="'xs'"
                        />
                        <span class="mt-1">
                            {{ JSON.parse(data.properties).attribute?.employee?.name }}
                        </span>
                    </div>
                </template>
                <template #filter="{ filterModel }">
                    <InputText
                        v-model="filterModel.value"
                        class="p-column-filter"
                        placeholder="Search Employee"
                        type="text"
                    />
                </template>
            </Column>
            <Column :sortable="true" field="description" header="Description" :showFilterMatchModes="false">
                <template #body="{ data }">
                    {{ data.description }}
                </template>
                <template #filter="{ filterModel }">
                    <Dropdown
                        v-model="filterModel.value"
                        :options="descriptionOptions"
                        placeholder="Select a Description"
                    />
                </template>
            </Column>
            <Column :sortable="true" field="event" header="Event">
                <template #body="{ data }">
                    {{ data.event }}
                </template>
                <template #filter="{ filterModel }">
                    <InputText
                        v-model="filterModel.value"
                        class="p-column-filter"
                        placeholder="Search by Event"
                        type="text"
                    />
                </template>
            </Column>
            <Column field="properties->performedByName" header="Performed By" :showFilterMatchModes="false">
                <template #body="{ data }">
                    {{ JSON.parse(data.properties).performedByName }}
                </template>
                <template #filter="{ filterModel }">
                    <InputText
                        v-model="filterModel.value"
                        class="p-column-filter"
                        placeholder="Search Performed By"
                        type="text"
                    />
                </template>
            </Column>
            <Column :sortable="true" field="created_at" header="Performed At">
                <template #body="{ data }">
                    {{ moment(data.created_at).format("MMMM D, YYYY hh:mmA") }}
                </template>
            </Column>
            <template #paginatorstart>
                <!--                <Button type="button" icon="pi pi-refresh" class="p-button-text"/>-->
            </template>
            <template #paginatorend>
                <!--                <Button type="button" icon="pi pi-cloud" class="p-button-text"/>-->
            </template>

            <template #expansion="slotProps">
                <div class="py-4">
                    <div
                        v-if="JSON.parse(slotProps.data.properties).changes"
                        class="flex items-center justify-center gap-6"
                    >
                        <Card class="max-w-[400px] w-1/3">
                            <template #title><span class="text-sm">Old {{ _.startCase(slotProps.data.description) }}</span></template>
                            <template #content>
                                <dl class="text-gray-900 divide-y divide-gray-200">
                                    <div
                                        v-for="fieldName, key in getDataSequence(slotProps.data.description)"
                                        :key="key"
                                        class="flex flex-col py-2"
                                    >
                                        <dt class="mb-1 text-gray-500 md:text-sm">{{ _.startCase(fieldName) }}</dt>
                                        <dd class="text-sm font-semibold">
                                            {{ JSON.parse(slotProps.data.properties).changes.old[fieldName] }}
                                        </dd>
                                    </div>
                                </dl>
                            </template>
                        </Card>

                        <Lucide icon="ChevronsRight" class="w-24 h-24" />

                        <Card class="max-w-[400px] w-1/3">
                            <template #title><span class="text-sm">New {{ _.startCase(slotProps.data.description) }}</span></template>
                            <template #content>
                                <dl class="text-gray-900 divide-y divide-gray-200">
                                    <div
                                        v-for="fieldName, key in getDataSequence(slotProps.data.description)"
                                        :key="key"
                                        class="flex flex-col py-2"
                                    >
                                        <dt class="mb-1 text-gray-500 md:text-sm">{{ _.startCase(fieldName) }}</dt>
                                        <dd class="text-sm font-semibold">
                                            {{ JSON.parse(slotProps.data.properties).changes.new[fieldName] }}
                                        </dd>
                                    </div>
                                </dl>
                            </template>
                        </Card>
                    </div>

                    <div
                        v-else-if="!slotProps.data.event.includes('removed')"
                        class="flex items-center justify-center gap-6"
                    >
                        <Card class="max-w-[400px] w-1/3">
                            <template #title><span class="text-sm">{{ _.startCase(slotProps.data.description) }}</span></template>
                            <template #content>
                                <dl class="text-gray-900 divide-y divide-gray-200">
                                    <div
                                        v-for="fieldName, key in getDataSequence(slotProps.data.description)"
                                        :key="key"
                                        class="flex flex-col py-2"
                                    >
                                        <div v-if="JSON.parse(slotProps.data.properties).fields[fieldName]">
                                            <dt class="mb-1 text-gray-500 md:text-sm">{{ _.startCase(fieldName) }}</dt>
                                            <dd class="text-sm font-semibold">
                                                {{ JSON.parse(slotProps.data.properties).fields[fieldName] }}
                                            </dd>
                                        </div>
                                    </div>
                                </dl>
                            </template>
                        </Card>
                    </div>
                    <div
                        v-else
                        class="flex items-center justify-center gap-6"
                    >
                        <Card class="max-w-[400px] w-1/3">
                            <template #title><span class="text-sm">No Shift Available</span></template>
                            <template #content>
                                <span class="text-xs text-gray-500">Schedule was removed for {{ JSON.parse(slotProps.data.properties).fields.scheduleDate }}</span>
                            </template>
                        </Card>
                    </div>
                </div>
            </template>
        </DataTable>
    </div>
</template>
