<script lang="ts" setup>
import { useAuthStore } from "../stores/authStore";
import { useRolesAndPermissionStore } from "../stores/rolesAndPermissionsStore";
import PanelUserPermission from "./components/PanelUserPermission.vue";
import Avatar from "./components/Avatar.vue";
import { useUserStore } from "../stores/userStore";
import { onMounted } from "vue";
import PageHeaderStandard from "./components/PageHeaderStandard.vue";
import { useRoute } from "vue-router";
import ToastSuccess from "./components/ToastSuccess.vue";

import router from "../router";

const AuthStore = useAuthStore();

const UserStore = useUserStore();

const route = useRoute();

const RolesAndPermissionStore = useRolesAndPermissionStore();

onMounted(async () => {
    await AuthStore.getLoggedInUserDetails();
    await UserStore.getUserDetails(route?.params?.id);
    await RolesAndPermissionStore.fillRoles();
    await RolesAndPermissionStore.fillRoleGroups();
});

const notify = (event: any) => {
    console.log("notify");
};

const viewRoles = () => {
    router.push({ name: "roles-and-permissions" });
};
</script>
<template>
    <PageHeaderStandard :title="'Account Management'" />

    <div class="mt-4 grid grid-cols-3 gap-2">
        <div class="box bg-custom-color-800/10 shadow-lg">
            <div class="flex items-start px-5 pt-5">
                <div class="w-full flex flex-col lg:flex-row items-center">
                    <div class="w-16 h-16 image-fit">
                        <Avatar
                            :name="UserStore.userDetail.name"
                            :ring="'online'"
                            :size="'sm'"
                        />
                    </div>
                    <div class="lg:ml-4 text-center lg:text-left mt-3 lg:mt-0">
                        <a class="font-medium" href="">{{
                            UserStore.userDetail.name
                        }}</a>
                        <div class="text-slate-500 text-xs mt-0.5">
                            <a
                                class="cursor-pointer font-bold"
                                @click="viewRoles()"
                                >User Role: {{ UserStore.userDetail.role }}</a
                            >
                        </div>
                    </div>
                </div>
            </div>
            <div class="text-center lg:text-left p-5">
                <div
                    class="flex items-center justify-center lg:justify-start text-slate-500 mt-5"
                >
                    <!--                    <MailIcon class="w-3 h-3 mr-2" />-->
                    {{ UserStore.userDetail.email }}
                </div>
            </div>
            <div
                class="text-center lg:text-right p-5 border-t border-slate-200/60 dark:border-darkmode-400"
            >
                <button class="btn btn-outline-secondary py-1 px-2">
                    Profile
                </button>
            </div>
        </div>
    </div>

    <div class="mt-4 grid grid-cols-3 gap-2 bg-white p-2 rounded-lg">
        <template v-for="groups in RolesAndPermissionStore.roleGroups">
            <PanelUserPermission
                :data="groups.permissions"
                :panelTitle="groups.friendlyName"
                :role="'admin'"
                @response="notify"
            />
        </template>
    </div>
    <ToastSuccess
        :message="'Successfully Updated'"
        :subject="'Role Permission'"
    />
</template>
