import { defineStore } from "pinia";
import Http from "../services/Http";
import {
    InvoiceInterface,
    InvoiceLineItemInterface,
    InvoiceLineItemPaginatedInterface,
    InvoiceLineItemTypeInterface,
    InvoicePaginatedInterface,
    InvoiceStatsInterface,
    InvoiceStatusInterface,
} from "../pages/interfaces/invoiceInterface";
import ApiResponseInterface from "../pages/interfaces/apiResponseInterface";
import * as Sentry from "@sentry/browser";
import { useAuthStore } from "./authStore";

const baseUrl = import.meta.env.VITE_API_URL;

export const useInvoiceStore = defineStore("InvoiceStore", {
    state: (): {
        invoices: InvoicePaginatedInterface;
        invoice: InvoiceInterface;
        invoiceLineItemTypes: ApiResponseInterface<
            InvoiceLineItemTypeInterface[]
        >;
        invoiceStatuses: InvoiceStatusInterface[];
        $sentry: typeof Sentry | null;
        invoiceLineItems: InvoiceLineItemPaginatedInterface;
        stats: InvoiceStatsInterface;
    } => {
        return {
            invoices: {} as InvoicePaginatedInterface,
            invoice: {} as InvoiceInterface,
            invoiceLineItemTypes: {} as ApiResponseInterface<
                InvoiceLineItemTypeInterface[]
            >,
            invoiceStatuses: [] as InvoiceStatusInterface[],
            $sentry: null,
            invoiceLineItems: {} as InvoiceLineItemPaginatedInterface,
            stats: {} as InvoiceStatsInterface,
        };
    },

    actions: {
        async getInvoices(params: any): Promise<void> {
            const { data } = await new Http(
                baseUrl
            ).get<InvoicePaginatedInterface>("/invoices", { params });

            this.invoices.data = data;
        },

        async getInvoice(id: number): Promise<void> {
            const { data } = await new Http(baseUrl).get<
                ApiResponseInterface<InvoiceInterface>
            >(`/invoices/${id}`);

            this.invoice = data;
        },

        async getInvoiceLineItemTypes() {
            const { data } = await new Http(baseUrl).get<
                ApiResponseInterface<InvoiceLineItemTypeInterface[]>
            >(`/invoice-line-item-types`);

            this.invoiceLineItemTypes.data = data;
        },

        async addInvoice(payload: InvoiceInterface): Promise<InvoiceInterface> {
            const { data } = await new Http(baseUrl).post<
                ApiResponseInterface<InvoiceInterface>
            >("/invoices", payload);

            this.invoices.data.data.unshift(data);

            return data;
        },

        async updateInvoice(
            payload: InvoiceInterface
        ): Promise<InvoiceInterface> {
            const { data } = await new Http(baseUrl).put<
                ApiResponseInterface<InvoiceInterface>
            >(`/invoices/${payload.id}`, payload);

            const index = this.invoices.data?.data?.findIndex(
                (invoice) => invoice.id === payload.id
            );

            if (index > -1) {
                this.invoices.data.data[index] = data;
            }

            if (this.invoice) {
                this.invoice = data;
            }

            return data;
        },

        async getInvoiceStatuses() {
            this.invoiceStatuses = await new Http(baseUrl).get<
                InvoiceStatusInterface[]
            >(`/invoice-statuses`);
        },

        async deleteInvoice(id: number): Promise<void> {
            await new Http(baseUrl).delete<
                ApiResponseInterface<InvoiceInterface>
            >(`/invoices/${id}`);

            const index = this.invoices.data.data.findIndex(
                (invoice) => invoice.id === id
            );

            this.invoices.data.data.splice(index, 1);
        },

        async generateInvoice(payload: {}): Promise<void> {
            await new Http(baseUrl).post("/generate-invoice", payload);
        },

        async addLineItem(payload: InvoiceLineItemInterface) {
            const { data } = await new Http(baseUrl).post<
                ApiResponseInterface<InvoiceLineItemInterface>
            >(`/invoices/${payload.invoice_id}/invoice-line-items`, payload);

            this.invoiceLineItems.data.data.push(data);
            this.invoice.total_amount = data?.invoice?.total_amount ?? 0;
        },

        async updateLineItem(payload: InvoiceLineItemInterface) {
            const { data } = await new Http(baseUrl).put<
                ApiResponseInterface<InvoiceLineItemInterface>
            >(`/invoice-line-items/${payload.id}`, payload);

            if (
                this.invoiceLineItems &&
                this.invoiceLineItems.data?.data?.length > 0
            ) {
                const index = this.invoiceLineItems.data.data.findIndex(
                    (item) => item.id === payload.id
                );

                if (index !== undefined && index !== -1) {
                    this.invoiceLineItems.data.data[index] = data;
                }
            }

            this.invoice.total_amount = data?.invoice?.total_amount ?? 0;
        },

        async deleteLineItem(id: number) {
            await new Http(baseUrl).delete<
                ApiResponseInterface<InvoiceLineItemInterface>
            >(`/invoice-line-items/${id}`);

            if (
                this.invoiceLineItems &&
                this.invoiceLineItems.data?.data?.length > 0
            ) {
                const index = this.invoiceLineItems.data.data.findIndex(
                    (item) => item.id === id
                );

                if (index !== undefined && index !== -1) {
                    this.invoiceLineItems.data.data.splice(index, 1);
                }
            }
        },

        async printInvoice(id: number, summary: boolean = false, otherParams: { [key:string] : string } = {}) {
            try {
                let url = `/invoices/${id}/print`;

                if (summary) {
                    url = `/invoices/${id}/print?summary=true`;
                }

                if (Object.keys(otherParams).length) {
                    let i = 0;
                    for (let key in otherParams) {
                        url = `${url}${i == 0 ? '?' : '&'}${key}=${otherParams[key]}`;
                        i++;
                    }
                }

                const response = await new Http(baseUrl).get<any>(
                    url,
                    {},
                    "",
                    true
                );

                const fileURL = window.URL.createObjectURL(
                    new Blob([response])
                );
                const fileLink = document.createElement("a");

                fileLink.href = fileURL;

                if (this.invoice && this.invoice?.client) {
                    const fileName = `${this.invoice.id} ${
                        this.invoice.due_date
                    } - [${
                        this.invoice.client?.legal_business_name
                    } ${new Date().getTime()}].pdf`;

                    fileLink.setAttribute("download", fileName);
                } else {
                    fileLink.setAttribute(
                        "download",
                        "invoice" + new Date().getTime() + ".pdf"
                    );
                }

                document.body.appendChild(fileLink);

                fileLink.click();
            } catch (error) {
                if (this.$sentry) {
                    (this.$sentry as typeof Sentry).captureException(error);
                }
                console.log(error);
                return { status: "error" };
            }
        },

        async exportInvoice(id: number, otherParams: { [key:string] : string } = {}) {
            try {
                const response = await new Http(baseUrl).get<any>(
                    `/invoices/${id}/export`,
                    otherParams,
                    "",
                    true
                );

                const fileURL = window.URL.createObjectURL(
                    new Blob([response])
                );
                const fileLink = document.createElement("a");

                fileLink.href = fileURL;

                if (this.invoice && this.invoice?.client) {
                    const fileName = `${this.invoice.id} ${
                        this.invoice.due_date
                    } - [${
                        this.invoice.client?.legal_business_name
                    } ${new Date().getTime()}].xlsx`;

                    console.log(fileName);

                    fileLink.setAttribute("download", fileName);
                } else {
                    fileLink.setAttribute(
                        "download",
                        "invoice" + new Date().getTime() + ".xlsx"
                    );
                }

                document.body.appendChild(fileLink);

                fileLink.click();
            } catch (error) {
                if (this.$sentry) {
                    (this.$sentry as typeof Sentry).captureException(error);
                }
                console.log(error);
                return { status: "error" };
            }
        },

        async importInvoiceLineItems(payload: FormData) {
            try {
                const AuthStore = useAuthStore();
                await new Http(import.meta.env.VITE_API_URL).post<void>(
                    "/import-invoice-line-items",
                    payload,
                    AuthStore.token,
                    "multipart/form-data"
                );
            } catch (error) {
                if (this.$sentry) {
                    (this.$sentry as typeof Sentry).captureException(error);
                }

                throw error;
            }
        },

        async downloadImportTemplate() {
            try {
                const response = await new Http(
                    import.meta.env.VITE_API_URL
                ).get<ApiResponseInterface<any>>(
                    "/invoice-import-template",
                    {},
                    "",
                    true
                );
                // @ts-ignore
                var fileURL = window.URL.createObjectURL(new Blob([response]));
                var fileLink = document.createElement("a");

                fileLink.href = fileURL;
                fileLink.setAttribute(
                    "download",
                    "invoice_import_template.csv"
                );
                document.body.appendChild(fileLink);

                fileLink.click();
            } catch (error) {
                if (this.$sentry) {
                    (this.$sentry as typeof Sentry).captureException(error);
                }
                console.log(error);
                return { status: "error" };
            }
        },

        async sendInvoiceEmail(payload: any) {
            try {
                await new Http(import.meta.env.VITE_API_URL).post(
                    `/invoices/${payload.id}/send-email`,
                    payload
                );
            } catch (error) {
                if (this.$sentry) {
                    (this.$sentry as typeof Sentry).captureException(error);
                }

                throw error;
            }
        },

        async getInvoiceLineItems(id: number, params: any): Promise<void> {
            const { data } = await new Http(
                baseUrl
            ).get<InvoiceLineItemPaginatedInterface>(
                `/invoices/${id}/invoice-line-items`,
                { params }
            );

            this.invoiceLineItems.data = data;
        },

        async getInvoiceStats(): Promise<void> {
            const response = await new Http(baseUrl).get<InvoiceStatsInterface>(
                "/invoice-stats"
            );

            this.stats = response;
        },
    },
});
