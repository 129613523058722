import { defineStore } from "pinia";
import Http from "../services/Http";
import { EmployeeClientAssignmentInterface } from "../pages/interfaces/employeesInterface";
import ApiResponseInterface from "../pages/interfaces/apiResponseInterface";

export const useEmployeeClientAssignmentStore = defineStore(
    "EmployeeClientAssignmentStore",
    {
        state: (): {
            employeeClientAssignments: EmployeeClientAssignmentInterface[];
            employeeClientAssignment: EmployeeClientAssignmentInterface;
        } => {
            return {
                employeeClientAssignments:
                    [] as EmployeeClientAssignmentInterface[],
                employeeClientAssignment:
                    {} as EmployeeClientAssignmentInterface,
            };
        },

        actions: {
            async getEmployeeClientAssignments(
                employeeId: number
            ): Promise<void> {
                const baseUrl = import.meta.env.VITE_API_URL;

                this.employeeClientAssignments = await new Http(baseUrl).get<
                    EmployeeClientAssignmentInterface[]
                >(`/employee-client-assignments?employee_id=${employeeId}`);
            },

            async getEmployeeClientAssignment(id: number): Promise<void> {
                const baseUrl = import.meta.env.VITE_API_URL;

                const { data } = await new Http(baseUrl).get<
                    ApiResponseInterface<EmployeeClientAssignmentInterface>
                >(`/employee-client-assignments/${id}`);

                this.employeeClientAssignment = data;
            },

            async addEmployeeClientAssignment(
                payload: EmployeeClientAssignmentInterface
            ): Promise<void> {
                const baseUrl = import.meta.env.VITE_API_URL;

                const response = await new Http(
                    baseUrl
                ).post<EmployeeClientAssignmentInterface>(
                    "/employee-client-assignments",
                    payload
                );

                this.employeeClientAssignments.unshift(response);
            },

            async updateEmployeeClientAssignment(
                payload: EmployeeClientAssignmentInterface
            ): Promise<void> {
                const baseUrl = import.meta.env.VITE_API_URL;

                const response = await new Http(
                    baseUrl
                ).put<EmployeeClientAssignmentInterface>(
                    `/employee-client-assignments/${payload.id}`,
                    payload
                );

                const index = this.employeeClientAssignments.findIndex(
                    (item) => item.id === response.id
                );

                console.log(response);

                this.employeeClientAssignments[index] = response;
            },

            async deleteEmployeeClientAssignment(id: number): Promise<void> {
                const baseUrl = import.meta.env.VITE_API_URL;

                await new Http(baseUrl).delete(
                    `/employee-client-assignments/${id}`
                );

                const index = this.employeeClientAssignments.findIndex(
                    (item) => item.id === id
                );

                this.employeeClientAssignments.splice(index, 1);
            },
        },
    }
);
