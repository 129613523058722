<script lang="ts" setup>
import { useAuthStore } from "@/stores/authStore";
import { useHelperStore } from "@/stores/helperStore";
import { useUiStore } from "@/stores/uiStore";
import {
    defineEmits,
    defineProps,
    onMounted,
    onUpdated,
    provide,
    reactive,
    ref,
    toRefs,
} from "vue";
import { required } from "@vuelidate/validators";
import { useVuelidate } from "@vuelidate/core";
import { useRoute } from "vue-router";
import { useFreskdeskStore } from "@/stores/freshdeskStore";
import { useTicketsStore } from "@/stores/ticketsStore";
import { useClientsStore } from "@/stores/clientsStore";
import moment from "moment";
import TomSelect from "@/base-components/TomSelect";
import Button from "@/base-components/Button";
import { FormInput, FormLabel } from "@/base-components/Form";
import { ClassicEditor } from "@/base-components/Ckeditor";
import router from "@/router";
import _ from "lodash";
import Notification, {
    NotificationElement,
} from "@/base-components/Notification";
import Lucide from "@/base-components/Lucide";

const TicketsStore = useTicketsStore();
const ClientsStore = useClientsStore();

const props = defineProps({
    ticketTypeId: Number,
});

const emit = defineEmits(["output", "ticketType"]);

const AuthStore = useAuthStore();

const HelperStore = useHelperStore();

const UiStore = useUiStore();

const FreshdeskStore = useFreskdeskStore();

const route = useRoute();
const id = Array.isArray(route.query.id) ? route.query.id[0] : route.query.id;

const categories = ref(2);

const ticketForm = reactive({
    subject: "New Candidate Search:",
    role: "",
    expertise: "",
    salary: "",
    shift_schedule: "",
    primary_work_location: "",
    client: "",
    teamLeader: "",
    website: "",
    primary_company_person: "",
    position: "",
    currency: "",
    job_description: "",
    responsibilities: "",
    qualifications: "",
    comments: "",
});
const selectedClient = ref("");

const rules = {
    subject: { required },
    role: { required },
    expertise: { required },
    salary: { required },
    shift_schedule: { required },
    primary_work_location: { required },
    client: { required },
    website: { required },
    primary_company_person: { required },
    position: { required },
    currency: { required },
    job_description: { required },
    responsibilities: { required },
    qualifications: { required },
    comments: { required },
    teamLeader: {},
};

const editorData = ref();

const editorData2 = ref();

const editorConfig = {
    toolbar: {
        items: ["bold", "italic", "link"],
    },
};

onMounted(async () => {
    console.log("onMounted");
    await ClientsStore.getClientsForDropdown();
});

onUpdated(() => {
    const ticketType = HelperStore.getTicketType(Number(id ?? 0));
    UiStore.setBreadcrumb("Submit a Ticket > " + ticketType.name);
});

const teamLeaders = ref<{ id: number; name: string }[]>([]);

const getTeamLeaders = async (clientName: any) => {
    console.log("getTeamLeaders" + clientName);

    if (clientName === "new Client") {
        teamLeaders.value = [];
        selectedClient.value = "";
        ticketForm.teamLeader = "";
        return;
    }

    selectedClient.value =
        ClientsStore.clientsForDropdown.find(
            (c) => c.id === parseInt(clientName)
        )?.legal_business_name ?? "";

    let tl: { id: number; name: string }[] = [];
    teamLeaders.value = [];

    const client = await ClientsStore.getClientTeam(clientName);
    if (typeof client !== "undefined") {
        //@ts-ignore
        client.forEach((team) => {
            team.employeeTeams.forEach(
                (e: {
                    team_role_name: string;
                    employee_id: any;
                    employee: { name: any };
                }) => {
                    //@ts-ignore
                    if (e.team_role_name === "Team Leader") {
                        tl.push({
                            id: e.employee_id,
                            name: e.employee.name,
                        });
                    }
                }
            );
        });
    }
    teamLeaders.value = tl;
};

const validate = useVuelidate(rules, toRefs(ticketForm));

const formReset = () => {
    ticketForm.role = "";
    ticketForm.expertise = "";
    ticketForm.salary = "";
    ticketForm.shift_schedule = "";
    ticketForm.primary_work_location = "";
    ticketForm.client = "";
    ticketForm.teamLeader = "";
    ticketForm.website = "";
    ticketForm.primary_company_person = "";
    ticketForm.position = "";
    ticketForm.currency = "";
    ticketForm.job_description = "";
    ticketForm.responsibilities = "";
    ticketForm.qualifications = "";
    ticketForm.comments = "";
    attachments.data = [];
};

const toastData = reactive({
    message: "",
    subject: "",
    severity: "",
});

const submitTicket = async () => {
    const currentDate = moment().format("MMMM Do YYYY, h:mm:ss a");

    const desc_data = new FormData();
    desc_data.append("Date Filed", currentDate);
    desc_data.append("Role", ticketForm.role);
    desc_data.append("Expertise", ticketForm.expertise);
    desc_data.append("Salary Range in Peso", ticketForm.salary);
    desc_data.append("Shift", ticketForm.shift_schedule);
    desc_data.append("Work Setup", ticketForm.primary_work_location);
    desc_data.append("Client", selectedClient.value || 'new Client');
    desc_data.append("Team Leader", ticketForm.teamLeader);
    desc_data.append("Client Website", ticketForm.website);
    desc_data.append("Name of Client POC", ticketForm.primary_company_person);
    desc_data.append("Position of Client POC", ticketForm.position);
    desc_data.append("Currency", ticketForm.currency);
    desc_data.append("Job Description", ticketForm.job_description);
    desc_data.append(
        "Responsibilities / Job Duties",
        ticketForm.responsibilities
    );
    desc_data.append("Qualifications", ticketForm.qualifications);
    desc_data.append("Comments", ticketForm.comments);
    console.log("desc_data", desc_data);

    validate.value.$touch();
    if (validate.value.$invalid) {
        console.log(ticketForm);
        toastData.severity = "danger";
        toastData.message = "All fields required!";
        toastData.subject = "Please provide all the required information";
        console.log("show error toast!");
        console.log("missing data fields");
    } else {
        const ticketType = id ? HelperStore.getTicketType(parseInt(id)) : null;
        console.log(ticketType);
        const form = new FormData();

        var form_body = {};
        //@ts-ignore
        desc_data.forEach((value, key) => (form_body[key] = value));

        form.append("id", id?.toString() ?? "");
        form.append(
            "subject",
            (
                ticketForm.subject +
                " " +
                selectedClient.value +
                " " +
                ticketForm.role
            ).trim()
        );
        form.append("priority", ticketType?.f_priority_id?.toString() ?? "");
        form.append("status", ticketType?.f_status_id?.toString() ?? "");
        form.append("type", ticketType?.f_type?.toString() ?? "");
        form.append("custom_fields[cf_recruitment_stage]", "New Ticket");
        form.append("form_body", JSON.stringify(form_body));
        form.append("ticket_type_id", ticketType?.id?.toString() ?? "");

        if (attachments.data !== null) {
            _.forEach(attachments.data, function (files) {
                _.forEach(files, function (file) {
                    form.append("attachments[]", file);
                });
            });
        }
        const resp = await TicketsStore.save(form).then((response) => {
            if (response.status === "success") {
                validate.value.$reset();
                emit("output", { render: true, type: "success" });
                if (id !== null) {
                    emit("ticketType", HelperStore.getTicketType(parseInt(id)));
                }

                showSuccessNotification();
                formReset();
                router.push({
                    name: "ticketDetails",
                    params: { id: response.data.id },
                });
            } else {
                if(response.error?.errors) {
                attachment_errors.value = response.error?.errors['attachments.0']
                }
                if(response.error.error) {
                    attachment_error.value = response.error?.error
                }
                // emit("output", { render: true, type: "danger" });
            }
            console.log("tix response", response); // this will have access to the response object
        });

        console.log("resp", resp);
    }
};

interface Attachments {
    data: File[][];
}

const attachments: Attachments = reactive({
    data: [[]],
});

const attachment_errors = ref<any>({});
const attachment_error = ref<any>(null);
const uploadFile = (e: Event) => {
    const files = (e.target as HTMLInputElement).files;
    if (files !== null) {
        // attachments.data.push(Array.from(files));
        const fileArray = Array.from(files);
        attachments.data = [fileArray];
    }
    attachment_errors.value = ""
    attachment_error.value = ""
};

const successNotification = ref<NotificationElement>();
const successNotificationToggle = () => {
    // Show notification
    successNotification.value?.showToast();
};

provide("bind[successNotification]", (el: NotificationElement) => {
    successNotification.value = el;
});

const showSuccessNotification = () => {
    successNotificationToggle();
};
</script>
<template>
    <div class="intro-y box p-5">
        <div class="block mt-3">
            <FormLabel class="form-label"
                >Client <span style="color: rgb(255, 0, 0)">*</span></FormLabel
            >
            <TomSelect
                id="client"
                v-model.trim="ticketForm.client"
                :value="ticketForm.client"
                class="w-full"
                name="client"
                placeholder="Select Client"
                :options="{allowEmptyOption: true}"
                @update:modelValue="getTeamLeaders(ticketForm.client)"
            >
                <option value="">Select Client</option>
                <option value="new Client">New Client</option>
                <option
                    v-for="client of ClientsStore.clientsForDropdown"
                    :value="client.id"
                >
                    {{ client.legal_business_name }}
                </option>
            </TomSelect>
        </div>
        <template v-if="validate.client.$error">
            <div
                v-for="(error, index) in validate.client.$errors"
                :key="index"
                class="text-danger mt-2"
            >
                {{ error.$message }}
            </div>
        </template>

        <div class="mt-3">
            <FormLabel class="form-label"
                >Team Leader <span style="color: rgb(255, 0, 0)"></span
            ></FormLabel>
            <TomSelect
                id="teamLeader"
                v-model="ticketForm.teamLeader"
                :value="ticketForm.teamLeader"
                class="w-full"
                name="teamLeader"
                placeholder="Select Team Leader"
                :options="{allowEmptyOption: true}"
            >
                <option value="">Select Team Leader</option>
                <option v-for="tl in teamLeaders" :value="tl.name">
                    {{ tl.name }}
                </option>
            </TomSelect>
        </div>
        <template v-if="validate.teamLeader.$error">
            <div
                v-for="(error, index) in validate.teamLeader.$errors"
                :key="index"
                class="text-danger mt-2"
            >
                {{ error.$message }}
            </div>
        </template>

        <div class="mt-3">
            <FormLabel class="form-label" for="crud-form-1"
                >Role <span style="color: rgb(255, 0, 0)">*</span></FormLabel
            >
            <FormInput
                id="role"
                v-model="ticketForm.role"
                class="form-control w-full"
                name="role"
                placeholder=""
                type="text"
            />
            <template v-if="validate.role.$error">
                <div
                    v-for="(error, index) in validate.role.$errors"
                    :key="index"
                    class="text-danger mt-2"
                >
                    {{ error.$message }}
                </div>
            </template>
        </div>

        <div class="mt-3">
            <FormLabel class="form-label" for="crud-form-1"
                >Expertise
                <span style="color: rgb(255, 0, 0)">*</span></FormLabel
            >
            <FormInput
                id="expertise"
                v-model="ticketForm.expertise"
                class="form-control w-full"
                name="expertise"
                placeholder=""
                type="text"
            />
            <template v-if="validate.expertise.$error">
                <div
                    v-for="(error, index) in validate.expertise.$errors"
                    :key="index"
                    class="text-danger mt-2"
                >
                    {{ error.$message }}
                </div>
            </template>
        </div>

        <div class="mt-3">
            <FormLabel class="form-label" for="crud-form-1"
                >Salary Range in Peso
                <span style="color: rgb(255, 0, 0)">*</span></FormLabel
            >
            <FormInput
                id="salary"
                v-model="ticketForm.salary"
                class="form-control w-full"
                name="salary"
                placeholder=""
                type="text"
            />
            <template v-if="validate.salary.$error">
                <div
                    v-for="(error, index) in validate.salary.$errors"
                    :key="index"
                    class="text-danger mt-2"
                >
                    {{ error.$message }}
                </div>
            </template>
        </div>

        <div class="mt-3">
            <FormLabel class="form-label" for="crud-form-1"
                >Shift <span style="color: rgb(255, 0, 0)">*</span></FormLabel
            >
            <FormInput
                id="shift_schedule"
                v-model="ticketForm.shift_schedule"
                class="form-control w-full"
                name="shift_schedule"
                placeholder=""
                type="text"
            />
            <template v-if="validate.shift_schedule.$error">
                <div
                    v-for="(error, index) in validate.shift_schedule.$errors"
                    :key="index"
                    class="text-danger mt-2"
                >
                    {{ error.$message }}
                </div>
            </template>
        </div>

        <div class="mt-3">
            <FormLabel class="form-label" for="crud-form-2"
                >Work Setup
                <span style="color: rgb(255, 0, 0)">*</span></FormLabel
            >
            <TomSelect
                id="primary_work_location"
                v-model="ticketForm.primary_work_location"
                class="w-full"
                name="primary_work_location"
                placeholder="Select Work Setup"
                :options="{allowEmptyOption: true}"
            >
                <option value="">Select Work Setup</option>
                <option value="Work from Home">Work from Home</option>
                <option value="Work from Office">Work from Office</option>
                <option value="Hybrid">Hybrid</option>
            </TomSelect>
        </div>
        <template v-if="validate.primary_work_location.$error">
            <div
                v-for="(error, index) in validate.primary_work_location
                    .$errors"
                :key="index"
                class="text-danger mt-2"
            >
                {{ error.$message }}
            </div>
        </template>

        <div class="mt-3">
            <FormLabel class="form-label" for="crud-form-1"
                >Client Website
                <span style="color: rgb(255, 0, 0)">*</span></FormLabel
            >
            <FormInput
                id="website"
                v-model="ticketForm.website"
                class="form-control w-full"
                name="website"
                placeholder=""
                type="text"
            />
            <template v-if="validate.website.$error">
                <div
                    v-for="(error, index) in validate.website.$errors"
                    :key="index"
                    class="text-danger mt-2"
                >
                    {{ error.$message }}
                </div>
            </template>
        </div>

        <div class="mt-3">
            <FormLabel class="form-label" for="crud-form-1"
                >Name of Client POC&nbsp;<span style="color: rgb(255, 0, 0)"
                    >*</span
                ></FormLabel
            >
            <FormInput
                id="primary_company_person"
                v-model="ticketForm.primary_company_person"
                class="form-control w-full"
                name="primary_company_person"
                placeholder=""
                type="text"
            />
            <template v-if="validate.primary_company_person.$error">
                <div
                    v-for="(error, index) in validate.primary_company_person
                        .$errors"
                    :key="index"
                    class="text-danger mt-2"
                >
                    {{ error.$message }}
                </div>
            </template>
        </div>

        <div class="mt-3">
            <FormLabel class="form-label" for="crud-form-1"
                >Position of Client POC
                <span style="color: rgb(255, 0, 0)">*</span></FormLabel
            >
            <FormInput
                id="position"
                v-model="ticketForm.position"
                class="form-control w-full"
                name="position"
                placeholder=""
                type="text"
            />
            <template v-if="validate.position.$error">
                <div
                    v-for="(error, index) in validate.position.$errors"
                    :key="index"
                    class="text-danger mt-2"
                >
                    {{ error.$message }}
                </div>
            </template>
        </div>

        <div class="mt-3">
            <FormLabel class="form-label" for="crud-form-1"
                >Currency
                <span style="color: rgb(255, 0, 0)">*</span></FormLabel
            >
            <FormInput
                id="currency"
                v-model="ticketForm.currency"
                class="form-control w-full"
                name="currency"
                placeholder=""
                type="text"
            />
            <template v-if="validate.currency.$error">
                <div
                    v-for="(error, index) in validate.currency.$errors"
                    :key="index"
                    class="text-danger mt-2"
                >
                    {{ error.$message }}
                </div>
            </template>
        </div>

        <div class="mt-3">
            <FormLabel class="form-label" for="crud-form-1"
                >Job Description
                <span style="color: rgb(255, 0, 0)">*</span></FormLabel
            >
            <FormInput
                id="job_description"
                v-model="ticketForm.job_description"
                class="form-control w-full"
                name="job_description"
                placeholder=""
                type="text"
            />
            <template v-if="validate.job_description.$error">
                <div
                    v-for="(error, index) in validate.job_description.$errors"
                    :key="index"
                    class="text-danger mt-2"
                >
                    {{ error.$message }}
                </div>
            </template>
        </div>

        <div class="mt-3">
            <FormLabel
                >Responsibilities / Job Duties
                <span style="color: rgb(255, 0, 0)">*</span></FormLabel
            >
            <div class="mt-2">
                <ClassicEditor
                    id="responsibilities"
                    v-model="ticketForm.responsibilities"
                    :config="editorConfig"
                    name="responsibilities"
                />
                <template v-if="validate.responsibilities.$error">
                    <div
                        v-for="(error, index) in validate.responsibilities
                            .$errors"
                        :key="index"
                        class="text-danger mt-2"
                    >
                        {{ error.$message }}
                    </div>
                </template>
            </div>
        </div>

        <div class="mt-3">
            <FormLabel
                >Qualifications
                <span style="color: rgb(255, 0, 0)">*</span></FormLabel
            >
            <div class="mt-2">
                <ClassicEditor
                    id="qualifications"
                    v-model="ticketForm.qualifications"
                    :config="editorConfig"
                    name="qualifications"
                />
                <template v-if="validate.qualifications.$error">
                    <div
                        v-for="(error, index) in validate.qualifications
                            .$errors"
                        :key="index"
                        class="text-danger mt-2"
                    >
                        {{ error.$message }}
                    </div>
                </template>
            </div>
        </div>

        <div class="mt-3">
            <FormLabel
                >Comments
                <span style="color: rgb(255, 0, 0)">*</span></FormLabel
            >
            <div class="mt-2">
                <ClassicEditor
                    id="comments"
                    v-model="ticketForm.comments"
                    :config="editorConfig"
                    name="comments"
                />
                <template v-if="validate.comments.$error">
                    <div
                        v-for="(error, index) in validate.comments
                            .$errors"
                        :key="index"
                        class="text-danger mt-2"
                    >
                        {{ error.$message }}
                    </div>
                </template>
            </div>
        </div>

        <div class="mt-3 sm:col-span-2">
            <FormLabel
                class="block text-sm font-medium text-gray-700"
                for="message"
                >Attachment
            </FormLabel>
            <div class="mt-1">
                <input
                    class="form-control block w-full px-3 py-1.5 text-base font-normal text-gray-700 bg-white bg-clip-padding border border-solid border-gray-300 rounded transition ease-in-out m-0 focus:text-gray-700 focus:bg-white focus:border-blue-600 focus:outline-none"
                    multiple
                    type="file"
                    @change="uploadFile($event)"
                />
            </div>
            <template v-if="attachment_error">
                <div
                    id="error_reason"
                    class="text-danger mt-2"
                >
                    {{ attachment_error }}
                </div>
            </template>
            <template v-if="attachment_errors">
                <div
                    v-for="(error, index) in attachment_errors"
                    id="error_reason"
                    :key="index"
                    class="text-danger mt-2"
                >
                    {{ error }}
                </div>
            </template>
        </div>
        <div class="text-right mt-5">
            <router-link
                class="btn text-white bg-warning py-2 rounded-md w-28 text-center"
                to="/submit-a-ticket"
                type="button"
            >
                Cancel
            </router-link>
            <Button
                class="ml-2"
                type="button"
                variant="customPrimary"
                @click="submitTicket"
                >Submit Ticket
            </Button>
        </div>
    </div>
    <!-- Success Notification -->
    <Notification
        :options="{
            duration: 3000,
        }"
        class="flex"
        refKey="successNotification"
    >
        <Lucide class="text-success" icon="CheckCircle" />
        <div class="ml-4 mr-4">
            <div class="font-medium">System Notification</div>
            <div class="mt-1 text-slate-500">
                Ticket was created successfully.
            </div>
        </div>
    </Notification>
    <!-- Success Notification -->
</template>
