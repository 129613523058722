<script setup lang="ts">
import { EmployeeScheduleInterface } from "../../interfaces/employeeScheduleInterface";
import { defineEmits, onMounted, provide, reactive, ref, toRefs } from "vue";
import { required } from "@vuelidate/validators";
import useVuelidate from "@vuelidate/core";
import TomSelect from "../../../base-components/TomSelect";
import {
    FormInput,
    FormLabel,
    FormSwitch,
} from "../../../base-components/Form";
import { useShiftStore } from "../../../stores/shiftStore";
import dayjs from "dayjs";
import Button from "../../../base-components/Button";
import Notification, {
    NotificationElement,
} from "../../../base-components/Notification";
import Lucide from "../../../base-components/Lucide";
import { useEmployeeScheduleStore } from "../../../stores/employeeScheduleStore";
import FormTextarea from "../../../base-components/Form/FormTextarea.vue";
import { useRoute } from "vue-router";

const props = defineProps({
    schedule: {
        type: Object as () => EmployeeScheduleInterface,
        default: () => ({} as EmployeeScheduleInterface),
    },
});

const route = useRoute();
const ShiftStore = useShiftStore();
const EmployeeScheduleStore = useEmployeeScheduleStore();

const form = reactive<EmployeeScheduleInterface>({
    id: null,
    employee_id: "",
    employee_schedule_type_id: "",
    end_time: "",
    shift_id: "",
    start_time: "",
    notes: "",
    day_off: props.schedule.employee_schedule_type_id == "2",
});

const rules = {
    employee_id: {
        required,
    },
    employee_schedule_type_id: {
        required,
    },
    shift_id: {
        required,
    },
    start_time: {
        required,
    },
    end_time: {
        required,
    },
};
const $externalResults = ref({});
const validate = useVuelidate(rules, toRefs(form), { $externalResults });
const backendValidationErrors = ref({});
const errorNotification = ref<NotificationElement>();
const successNotification = ref<NotificationElement>();
const disabled = ref(true);

const errorNotificationToggle = () => {
    // Show notification
    errorNotification.value?.showToast();
};
provide("bind[errorNotification]", (el: NotificationElement) => {
    errorNotification.value = el;
});
const showErrorsNotification = () => {
    errorNotificationToggle();
};

const successNotificationToggle = () => {
    successNotification.value?.showToast();
};
provide("bind[successNotification]", (el: NotificationElement) => {
    successNotification.value = el;
});
const showSuccessNotification = () => {
    successNotificationToggle();
};

const onSubmit = async () => {
    validate.value.$reset();
    validate.value.$clearExternalResults();
    validate.value.$touch();

    if (validate.value.$invalid) {
        backendValidationErrors.value = {
            message: ["Incomplete or Missing required data"],
        };

        showErrorsNotification();
        return;
    }

    try {
        form.employee_schedule_type_id = form.day_off === true ? "2" : "1";

        Object.keys(props.schedule).length > 0
            ? await EmployeeScheduleStore.updateSchedule(form)
            : await EmployeeScheduleStore.addSchedule(form);

        validate.value.$reset();
        closeModal();
        resetForm();
        showSuccessNotification();
    } catch (error: any) {
        $externalResults.value = error?.response?.data?.errors;
    }
};

const emit = defineEmits(["close"]);

const closeModal = () => {
    emit("close");
};

const resetForm = () => {};

const isEmptyObject = (obj: {}) => {
    return Object.keys(obj).length === 0;
};

const onSelectShift = () => {
    const shift = ShiftStore.allShifts.find(
        (shift) => shift.id?.toString() === form.shift_id
    );

    if (shift) {
        disabled.value = shift.name !== "Custom Work Day Shift";

        const start = dayjs(
            dayjs(props.schedule.start_time).format("YYYY-MM-DD") +
                " " +
                shift.start_time
        );

        let end = dayjs(
            dayjs(props.schedule.end_time).format("YYYY-MM-DD") +
                " " +
                shift.end_time
        );

        if (end.isAfter(start)) {
            end = end.subtract(1, "day");
        }

        if (end.isBefore(start)) {
            end = end.add(1, "day");
        }

        form.start_time = start.format("YYYY-MM-DDTHH:mm");
        form.end_time = end.format("YYYY-MM-DDTHH:mm");
        form.day_off = !shift.work_days.includes(start.format("dddd"));
        form.employee_schedule_type_id = form.day_off ? "2" : "1";

        if (shift.name === "Day Off") {
            form.day_off = true;
            form.employee_schedule_type_id = "2";
        }
    }
};

const onChangeTime = (event: any, type: string) => {
    if (type === "start") {
        form.start_time = event;
    }

    if (type === "end") {
        form.end_time = event;
    }
};

onMounted(async () => {
    await ShiftStore.getAllShifts({
        dropdown: true,
        include_custom_shift: true,
    });

    if (!isEmptyObject(props.schedule)) {
        form.id = props.schedule.id;
        form.employee_schedule_type_id =
            props.schedule.employee_schedule_type_id;
        form.shift_id = String(props.schedule.shift_id);
        form.start_time = props.schedule.start_time;
        form.end_time = props.schedule.end_time;
        form.day_off = props.schedule.employee_schedule_type_id == "2";
    }

    if (route.params.id) {
        form.employee_id = String(route.params.id);
    }
});
</script>

<template>
    <form @submit.prevent="onSubmit">
        <div class="grid grid-cols-12 gap-6 mt-5 items-left text-left">
            <div class="intro-y col-span-12">
                <div class="intro-y pb-5 pl-5 pr-2">
                    <div class="mt-4">
                        <FormLabel class="form-label" for="shift"
                            >Shift
                            <span class="text-red-500">*</span>
                        </FormLabel>
                        <TomSelect
                            id="shift"
                            v-model="form.shift_id"
                            :value="form.shift_id"
                            class="w-full p-0"
                            @update:model-value="onSelectShift"
                        >
                            <option>Select Shift</option>
                            <option
                                v-for="shift in ShiftStore.allShifts"
                                :value="shift.id"
                            >
                                {{ shift.name }}
                            </option>
                        </TomSelect>
                    </div>
                    <div class="mt-2">
                        <template v-if="validate.shift_id.$error">
                            <div
                                v-for="(error, index) in validate.shift_id
                                    .$errors"
                                :key="index"
                                class="text-danger mt-2"
                            >
                                {{ error.$message }}
                            </div>
                        </template>
                    </div>

                    <div class="grid grid-cols-12 gap-6">
                        <div class="mt-4 col-span-12 lg:col-span-6">
                            <FormLabel class="form-label" for="name"
                                >Start Time
                                <span class="text-red-500">*</span>
                            </FormLabel>
                            <FormInput
                                id="start_time"
                                v-model="form.start_time"
                                :value="form.start_time"
                                class="form-control w-full"
                                maxlength="50"
                                name="start_time"
                                placeholder=""
                                type="datetime-local"
                                :disabled="disabled"
                                @update:model-value="
                                    onChangeTime($event, 'start')
                                "
                            />
                            <template v-if="validate.start_time.$error">
                                <div
                                    v-for="(error, index) in validate.start_time
                                        .$errors"
                                    :key="index"
                                    class="text-danger mt-2"
                                >
                                    {{ error.$message }}
                                </div>
                            </template>
                        </div>

                        <div class="mt-4 col-span-12 lg:col-span-6">
                            <FormLabel class="form-label" for="name"
                                >End Time
                                <span class="text-red-500">*</span>
                            </FormLabel>
                            <FormInput
                                id="end_time"
                                v-model="form.end_time"
                                :value="form.end_time"
                                class="form-control w-full"
                                maxlength="50"
                                name="end_time"
                                placeholder=""
                                type="datetime-local"
                                :disabled="disabled"
                                @update:model-value="
                                    onChangeTime($event, 'end')
                                "
                            />
                            <template v-if="validate.end_time.$error">
                                <div
                                    v-for="(error, index) in validate.end_time
                                        .$errors"
                                    :key="index"
                                    class="text-danger mt-2"
                                >
                                    {{ error.$message }}
                                </div>
                            </template>
                        </div>
                    </div>

                    <div class="mt-4">
                        <FormLabel class="form-label" for="notes"
                            >Notes
                        </FormLabel>

                        <FormTextarea
                            id="notes"
                            v-model="form.notes"
                            :value="form.notes"
                            class="form-control w-full"
                        />
                    </div>

                    <div class="mt-4">
                        <label>Day Off</label>
                        <FormSwitch class="mt-3">
                            <FormSwitch.Input
                                v-model="form.day_off"
                                type="checkbox"
                                v-bind:checked="Boolean(form.day_off)"
                            />
                        </FormSwitch>
                    </div>
                </div>
            </div>
        </div>

        <div class="">
            <div class="text-right p-2">
                <Button
                    id="btn_cancel"
                    class="w-32 mb-2 mr-1"
                    type="button"
                    variant="warning"
                    @click="closeModal"
                >
                    Cancel
                </Button>
                <Button
                    id="btn_process"
                    class="w-36 mb-2 mr-1"
                    type="submit"
                    variant="customPrimary"
                    v-html="
                        Object.keys(props.schedule).length > 0
                            ? 'Update'
                            : 'Add'
                    "
                />
            </div>
        </div>
    </form>

    <Notification
        :options="{
            duration: 3000,
        }"
        class="flex"
        refKey="successNotification"
    >
        <Lucide class="text-success" icon="CheckCircle" />
        <div class="ml-4 mr-4">
            <div class="font-medium">System Notification</div>
            <div class="mt-1 text-slate-500">
                Schedule has been successfully {{ form.id ? 'updated' : 'added' }}.
            </div>
        </div>
    </Notification>

    <Notification
        :options="{
            duration: 3000,
        }"
        class="flex"
        refKey="errorNotification"
    >
        <Lucide class="text-danger" icon="X" />
        <div class="ml-4 mr-4">
            <div class="font-medium">System Notification</div>
            <div class="mt-1 text-slate-500">
                <ul class="list-disc">
                    <template v-for="errorBag in backendValidationErrors">
                        <li class="text-red-500">
                            {{ errorBag[0] }}
                        </li>
                    </template>
                </ul>
            </div>
        </div>
    </Notification>
</template>
