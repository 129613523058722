<script lang="ts" setup>
import {useAuthStore} from "../../stores/authStore";
import {useHelperStore} from "../../stores/helperStore";
import {useUiStore} from "../../stores/uiStore";
import {defineEmits, defineProps, onMounted, onUpdated, provide, reactive, ref, toRefs,} from "vue";
import {required} from "@vuelidate/validators";
import {useVuelidate} from "@vuelidate/core";
import {useRoute} from "vue-router";
import {useFreskdeskStore} from "../../stores/freshdeskStore";
import {useTicketsStore} from "../../stores/ticketsStore";
import {useClientsStore} from "../../stores/clientsStore";
import moment from "moment";
import SelectEmployee from "../components/SelectEmployee.vue";
import router from "../../router";
import _ from "lodash";
import Notification, {NotificationElement} from "../../base-components/Notification";
import Lucide from "../../base-components/Lucide";
import TomSelect from "../../base-components/TomSelect";
import Button from "../../base-components/Button";
import {ClassicEditor} from "../../base-components/Ckeditor";
import {FormLabel} from "../../base-components/Form";

const TicketsStore = useTicketsStore();
const ClientsStore = useClientsStore();

const props = defineProps({
    ticketTypeId: Number,
});

const emit = defineEmits(["output", "ticketType"]);

const AuthStore = useAuthStore();

const HelperStore = useHelperStore();

const UiStore = useUiStore();

const FreshdeskStore = useFreskdeskStore();

const route = useRoute();
const id = Array.isArray(route.query.id) ? route.query.id[0] : route.query.id;

const categories = ref(2);

const ticketForm = reactive({
    subject: "Employee Team Change Request:",
    name: "",
    client: "",
    team: "",
    comments: "",
    employeeId: "",
});

const rules = {
    subject: {required},
    client: {required},
    employeeId: {required},
    team: "",
};

const editorData = ref();

const editorData2 = ref();

const editorConfig = {
    toolbar: {
        items: ["bold", "italic", "link"],
    },
};

onMounted(async () => {
    await ClientsStore.getAllClients();
});

onUpdated(() => {
    const ticketType = HelperStore.getTicketType(Number(id ?? 0));
    UiStore.setBreadcrumb("Submit a Ticket > " + ticketType.name);
});

const setEmployee = (event: any) => {
    ticketForm.employeeId = event;
};

const employeeName = ref("");
const setEmployeeName = (event: any) => {
    ticketForm.name = event;
    employeeName.value = event;
};

const setTeam = (name: any) => {
    if (name === "Select Team") {
        ticketForm.team = "";
    }
};

interface Team {
    name: string;

}

const teams = ref<Team[]>([]);


// const getTeams = (clientName: any) => {
//     console.log("get teams");
//     let t = [];
//     teams.value = {};
//     ticketForm.team = "";
//     const client = _.find(ClientsStore.clients, (client) => {
//         return client.legal_business_name === clientName;
//     });
//     if (typeof client !== "undefined") {
//         client.teams.forEach((team) => {
//             t.push({
//                 id: team.id,
//                 name: team.name,
//             });
//         });
//     }
//     teams.value = t;
// };

const validate = useVuelidate(rules, toRefs(ticketForm));

const formReset = () => {
    ticketForm.client = "";
    ticketForm.team = "";
    ticketForm.comments = "";
    attachments.data = [];
};

const toastData = reactive({
    message: "",
    subject: "",
    severity: "",
});

const submitTicket = async () => {
    const currentDate = moment().format("MMMM Do YYYY, h:mm:ss a");

    if (ticketForm.team === "Select Team") {
        ticketForm.team = "";
    }

    validate.value.$touch();
    if (validate.value.$invalid) {
        console.log(ticketForm);
        toastData.severity = "danger";
        toastData.message = "All fields required!";
        toastData.subject = "Please provide all the required information";
        console.log("show error notification");
        console.log("missing data fields");
    } else {
        const ticketType = HelperStore.getTicketType(Number(id ?? 0));

        const desc_data = new FormData();
        desc_data.append("Date Filed", currentDate);
        desc_data.append("Employee", ticketForm.name);
        desc_data.append("Client", ticketForm.client);
        desc_data.append("Team", ticketForm.team);
        desc_data.append("Comments", ticketForm.comments);

        var form_body = {};
        //@ts-ignore
        desc_data.forEach((value, key) => (form_body[key] = value));

        const form = new FormData();
        form.append("id", String(id));
        form.append("subject", ticketForm.subject);
        form.append("priority", ticketType?.f_priority_id?.toString() ?? '');
        form.append("status", ticketType?.f_status_id?.toString() ?? '');
        form.append("type", ticketType?.f_type?.toString() ?? '');
        form.append("custom_fields[cf_recruitment_stage]", "New Ticket");
        form.append("form_body", JSON.stringify(form_body));
        form.append("ticket_type_id", ticketType?.id?.toString() ?? '');

        if (attachments.data !== null) {
            _.forEach(attachments.data, function (files) {
                _.forEach(files, function (file) {
                    form.append("attachments[]", file);
                });
            });
        }
        await TicketsStore.save(form).then((response) => {
            if (response.status === "success") {
                validate.value.$reset();
                emit("output", {render: true, type: "success"});
                if (id !== null) {
                    emit("ticketType", HelperStore.getTicketType(parseInt(id)));
                }
                showSuccessNotification();
                formReset();
                router.push({
                    name: "ticketDetails",
                    params: {id: response.data.id},
                });
            } else {
                emit("output", {render: true, type: "danger"});
            }
        });
    }
};

interface Attachments {
    data: File[][];
}

const attachments: Attachments = reactive({
    data: [[]],
});
const uploadFile = (e: Event) => {
    const files = (e.target as HTMLInputElement).files;
    if (files !== null) {
        attachments.data.push(Array.from(files));
    }
};

const successNotification = ref<NotificationElement>();
const successNotificationToggle = () => {
    // Show notification
    successNotification.value?.showToast();
};

provide("bind[successNotification]", (el: NotificationElement) => {
    successNotification.value = el;
});

const showSuccessNotification = () => {
    successNotificationToggle();
};
</script>
<template>
    <div class="intro-y box p-5">
        <SelectEmployee
            :validate="validate"
            @itemName="setEmployeeName"
            @itemValue="setEmployee"
        />
        <div class="mt-3">
            <FormLabel class="form-label"
            >Client <span style="color: rgb(255, 0, 0)">*</span></FormLabel
            >
            <TomSelect
                id="client"
                v-model="ticketForm.client"
                class="w-full"
                name="client"

            >
                <option>Select Client</option>
                <option value="new Client">New Client</option>
                <option
                    v-for="client of ClientsStore.clients"
                    :value="client.legal_business_name"
                >
                    {{ client.legal_business_name }}
                </option>
            </TomSelect>
            <template v-if="validate.client.$error">
                <div
                    v-for="(error, index) in validate.client.$errors"
                    :key="index"
                    class="text-danger mt-2"
                >
                    {{ error.$message }}
                </div>
            </template>
        </div>
        <div class="mt-3">
            <FormLabel class="form-label"
            >Team <span style="color: rgb(255, 0, 0)"></span
            ></FormLabel>
            <TomSelect
                id="team"
                v-model="ticketForm.team"
                class="w-full"
                name="team"

            >
                <option>Select Team</option>
                <option v-for="t of teams" :value="t.name">
                    {{ t.name }}
                </option>
            </TomSelect>
            <template v-if="validate.team.$error">
                <div
                    v-for="(error, index) in validate.team.$errors"
                    :key="index"
                    class="text-danger mt-2"
                >
                    {{ error.$message }}
                </div>
            </template>
        </div>

        <div class="mt-3">
            <FormLabel
            >Comments
                <span style="color: rgb(255, 0, 0)">*</span></FormLabel
            >
            <div class="mt-2">
                <ClassicEditor
                    id="comments"
                    v-model="ticketForm.comments"
                    :config="editorConfig"
                    name="comments"
                />
            </div>
        </div>

        <div class="text-right mt-5">
            <router-link
                class="btn text-white bg-warning py-2 rounded-md w-28 text-center"
                to="/submit-a-ticket"
                type="button"
            >
                Cancel
            </router-link>
            <Button
                class="ml-2"
                type="button"
                variant="customPrimary"
                @click="submitTicket"
            >Submit Ticket
            </Button>
        </div>
    </div>
    <!-- Success Notification -->
    <Notification
        :options="{
            duration: 3000,
        }"
        class="flex"
        refKey="successNotification"
    >
        <Lucide class="text-success" icon="CheckCircle"/>
        <div class="ml-4 mr-4">
            <div class="font-medium">System Notification</div>
            <div class="mt-1 text-slate-500">
                Ticket was created successfully.
            </div>
        </div>
    </Notification>
    <!-- Success Notification -->
</template>
