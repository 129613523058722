<script lang="ts" setup>
import {
    defineEmits,
    defineProps,
    onMounted,
    PropType,
    provide,
    reactive,
    ref,
    toRefs,
} from "vue";
import { required } from "@vuelidate/validators";
import { useVuelidate } from "@vuelidate/core";
import {
    FormInput,
    FormLabel,
    InputGroup,
} from "../../../base-components/Form";
import Button from "../../../base-components/Button";
import { useRoute } from "vue-router";
import dayjs from "dayjs";
import Notification, {
    NotificationElement,
} from "../../../base-components/Notification";
import Lucide from "../../../base-components/Lucide";
import { useEmployeeReFeeStore } from "../../../stores/employeeReFeeStore";
import { useCurrencyStore } from "../../../stores/currencyStore";
import TomSelect from "../../../base-components/TomSelect";
import EmployeeInterface from "../../interfaces/employeesInterface";

const route = useRoute();
const emit = defineEmits(["close"]);
const props = defineProps({
    data: {
        type: Object,
        default: () => ({}),
    },
    employee: {
        type: Object as PropType<EmployeeInterface>,
        required: true,
    },
});

const EmployeeReFeeStore = useEmployeeReFeeStore();
const CurrencyStore = useCurrencyStore();

const notificationMessage = ref("");
const backendValidationErrors = ref<any>({});
const errorNotification = ref<NotificationElement>();
const successNotification = ref<NotificationElement>();
const errorNotificationToggle = () => {
    errorNotification.value?.showToast();
};
provide("bind[errorNotification]", (el: NotificationElement) => {
    errorNotification.value = el;
});
const showErrorsNotification = () => {
    errorNotificationToggle();
};
const successNotificationToggle = () => {
    successNotification.value?.showToast();
};
provide("bind[successNotification]", (el: NotificationElement) => {
    successNotification.value = el;
});
const showSuccessNotification = () => {
    successNotificationToggle();
};

const form = reactive({
    id: 0,
    employee_id: +route?.params?.id,
    amount: 0,
    effective_from: "",
    effective_to: "",
});

const $externalResults = ref({});
const rules = {
    effective_from: { required },
    effective_to: {},
    amount: { required },
};
const validate = useVuelidate(rules, toRefs(form), { $externalResults });

const resetForm = () => {
    form.amount = 0;
    form.effective_from = "";
    form.effective_to = "";
};

const onClose = () => {
    resetForm();
    emit("close");
};

const save = async () => {
    await EmployeeReFeeStore.addEmployeeReFee(form);
    notificationMessage.value = "Employee re fee has been successfully added.";
};

const update = async () => {
    await EmployeeReFeeStore.updateEmployeeReFee(form);
    notificationMessage.value =
        "Employee re fee has been successfully updated.";
};

const onSubmit = async () => {
    validate.value.$reset();
    validate.value.$clearExternalResults();
    backendValidationErrors.value = {};
    validate.value.$touch();

    if (validate.value.$invalid) {
        backendValidationErrors.value = {
            message: ["Incomplete or Missing required data"],
        };

        showErrorsNotification();
        return;
    }

    try {
        props.data.id ? await update() : await save();

        showSuccessNotification();
        onClose();
    } catch (error: any) {
        $externalResults.value = error?.response?.data?.errors;
        backendValidationErrors.value = error?.response?.data?.errors;
        showErrorsNotification();
    }
};

onMounted(async () => {
    await CurrencyStore.getCurrencies();

    if (props.data.id) {
        form.id = props.data.id;
        form.amount = props.data.amount;
        form.effective_from = dayjs(props.data.effective_from).format(
            "YYYY-MM-DD"
        );
        form.effective_to = props.data.effective_to;
    }
});
</script>

<template>
    <form @submit.prevent="onSubmit">
        <div class="grid grid-cols-12 gap-6 mt-5 items-left text-left">
            <div class="intro-y col-span-12">
                <div class="intro-y p-5">
                    <div>
                        <FormLabel class="form-label" for="name"
                            >Amount <span class="text-red-500">*</span>
                        </FormLabel>
                        <InputGroup>
                            <FormInput
                                id="amount"
                                v-model="form.amount"
                                :value="form.amount"
                                type="text"
                                class="form-control w-full"
                            />
                            <InputGroup.Text v-if="employee.client?.currency">
                                {{ employee.client?.currency?.code }}
                            </InputGroup.Text>
                        </InputGroup>
                        <template v-if="validate.amount.$error">
                            <div
                                v-for="(error, index) in validate.amount
                                    .$errors"
                                :key="index"
                                class="text-danger mt-2"
                            >
                                {{ error.$message }}
                            </div>
                        </template>
                    </div>

                    <div class="mt-3">
                        <FormLabel class="form-label" for="effective_from"
                            >Effective From <span class="text-red-500">*</span>
                        </FormLabel>
                        <FormInput
                            id="effective_from"
                            v-model="form.effective_from"
                            :value="form.effective_from"
                            class="form-control w-full"
                            maxlength="50"
                            name="effective_from"
                            placeholder=""
                            type="date"
                        />
                        <template v-if="validate.effective_from.$error">
                            <div
                                v-for="(error, index) in validate.effective_from
                                    .$errors"
                                :key="index"
                                class="text-danger mt-2"
                            >
                                {{ error.$message }}
                            </div>
                        </template>
                    </div>

                    <div class="mt-3">
                        <FormLabel class="form-label" for="name"
                            >Effective To
                        </FormLabel>
                        <FormInput
                            id="effective_to"
                            v-model="form.effective_to"
                            :disabled="!form.effective_from"
                            :min="
                                dayjs(form.effective_from)
                                    .add(1, 'day')
                                    .format('YYYY-MM-DD')
                            "
                            :value="form.effective_to"
                            class="form-control w-full"
                            maxlength="50"
                            name="effective_to"
                            placeholder=""
                            type="date"
                        />
                        <template v-if="backendValidationErrors?.effective_to">
                            <div class="text-red-500 mt-1">
                                {{ backendValidationErrors?.effective_to[0] }}
                            </div>
                        </template>
                    </div>
                </div>

                <div class="">
                    <div class="text-right p-2">
                        <Button
                            id="btn_cancel"
                            class="w-32 mb-2 mr-1"
                            type="button"
                            variant="warning"
                            @click="onClose"
                        >
                            Cancel
                        </Button>

                        <Button
                            id="btn_process"
                            class="w-36 mb-2 mr-1"
                            type="submit"
                            variant="customPrimary"
                        >
                            Save
                        </Button>
                    </div>
                </div>
            </div>
        </div>
    </form>

    <Notification
        :options="{
            duration: 3000,
        }"
        class="flex"
        refKey="successNotification"
    >
        <Lucide class="text-success" icon="CheckCircle" />
        <div class="ml-4 mr-4">
            <div class="font-medium">System Notification</div>
            <div class="mt-1 text-slate-500">
                {{ notificationMessage }}
            </div>
        </div>
    </Notification>

    <Notification
        :options="{
            duration: 3000,
        }"
        class="flex"
        refKey="errorNotification"
    >
        <Lucide class="text-danger" icon="X" />
        <div class="ml-4 mr-4">
            <div class="font-medium">System Notification</div>
            <div class="mt-1 text-slate-500">
                <ul class="list-disc">
                    <template v-for="errorBag in backendValidationErrors">
                        <li class="text-red-500">
                            {{ errorBag[0] }}
                        </li>
                    </template>
                </ul>
            </div>
        </div>
    </Notification>
</template>
