<script setup lang="ts">
import PageHeaderStandard from "./components/PageHeaderStandard.vue";
import PanelPermissionGroup from "./components/PanelPermissionGroup.vue";
import PanelPermissionGroup2 from "./components/PanelPermissionGroup2.vue";
import { useAuthStore } from "../stores/authStore";
import { useRolesAndPermissionStore } from "../stores/rolesAndPermissionsStore";
import { onMounted, ref, reactive, watch } from "vue";
import ToastSuccess from "./components/ToastSuccess.vue";
import Group from "../base-components/Headless/Tab/Group.vue";
import List from "../base-components/Headless/Tab/List.vue";
import Panels from "../base-components/Headless/Tab/Panels.vue";
import Panel from "../base-components/Headless/Tab/Panel.vue";
import Tab from "../base-components/Headless/Tab/Tab/Tab.vue";

const AuthStore = useAuthStore();

const RolesAndPermissionStore = useRolesAndPermissionStore();

interface Permission {
    activatedToRole: string[];
    friendlyName: string;
    id: number;
    name: string;
    permission_group_id: number;
    permission_id: number;
}

interface RoleGroup {
    id: number;
    name: string;
    friendlyName: string;
    permissions: Permission[];
}

interface RoleGroups {
    tickets: RoleGroup[];
    others: RoleGroup[];
}

const active_role_id = ref(1);
const roleGroups = reactive<RoleGroups>({
    tickets: [],
    others: [],
});

const showPreloader = ref(true);

onMounted(async () => {
    await AuthStore.getLoggedInUserDetails();
    await RolesAndPermissionStore.fillRoles();
    await RolesAndPermissionStore.fillRoleGroups();
    prepareData();
});

const prepareData = () => {
    console.log("prepareData");
    RolesAndPermissionStore.roleGroups.forEach((group) => {
        if (group.id === 9) {
            roleGroups.tickets.push(group);
        } else {
            roleGroups.others.push(group);
        }
    });
    showPreloader.value = false;
};

// watch(active_role_id, (newValue, oldValue) => {
//     console.log('watching role id');
//     console.log(newValue);
// })

const setActiveRoleId = (id: any) => {
    active_role_id.value = +id;
};

const notify = (event: any) => {
    console.log(event);
    // Toastify({
    //     node: dom("#success-notification")
    //         .clone()
    //         .removeClass("hidden")[0],
    //     duration: 3000,
    //     newWindow: true,
    //     close: true,
    //     gravity: "top",
    //     position: "right",
    //     stopOnFocus: true,
    // }).showToast();
};
</script>

<template>
    <PageHeaderStandard :title="'Roles and Permissions'" />
    <div class="" v-if="showPreloader">
        <div
            class="text-md col-span-6 sm:col-span-3 xl:col-span-2 flex flex-col justify-end items-center animate-bounce"
        >
            Loading data. Please wait...
        </div>
    </div>
    <Group class="mt-4" :fullWith="true" v-if="!showPreloader">
        <List class="py-4">
            <div class="flex-1 flex items-stretch gap gap-x-2">
                <Tab
                    class="flex-1 flex items-center justify-center w-full border bordr-gray-500/10 py-2 h-full text-center rounded-md cursor-pointer"
                    tag="button"
                    @click="setActiveRoleId(1)"
                    >Admin</Tab
                >
                <Tab
                    class="flex-1 flex items-center justify-center w-full p-2 border border-gray-500/10 h-full text-center rounded-md cursor-pointer"
                    tag="button"
                    @click="setActiveRoleId(4)"
                    >Payroll</Tab
                >
                <Tab
                    class="flex-1 flex items-center justify-center w-full p-2 border border-gray-500/10 h-full text-center rounded-md cursor-pointer"
                    tag="button"
                    @click="setActiveRoleId(7)"
                >
                    Account Receivable
                </Tab>
                <Tab
                    class="flex-1 flex items-center justify-center w-full p-2 border border-gray-500/10 h-full text-center rounded-md cursor-pointer"
                    tag="button"
                    @click="setActiveRoleId(14)"
                >
                    Account Manager
                </Tab>
                <Tab
                    class="flex-1 flex items-center justify-center w-full p-2 border border-gray-500/10 h-full text-center rounded-md cursor-pointer"
                    tag="button"
                    @click="setActiveRoleId(3)"
                    >HR</Tab
                >
                <Tab
                    class="flex-1 flex items-center justify-center w-full p-2 border border-gray-500/10 h-full text-center rounded-md cursor-pointer"
                    tag="button"
                    @click="setActiveRoleId(6)"
                    >Recruitment
                </Tab>
                <Tab
                    class="flex-1 flex items-center justify-center w-full p-2 border border-gray-500/10 h-full text-center rounded-md cursor-pointer"
                    tag="button"
                    @click="setActiveRoleId(5)"
                    >Client</Tab
                >
                <Tab
                    class="flex-1 flex items-center justify-center w-full p-2 border border-gray-500/10 h-full text-center rounded-md cursor-pointer"
                    tag="button"
                    @click="setActiveRoleId(2)"
                    >Employee</Tab
                >
                <Tab
                    class="flex-1 flex items-center justify-center w-full p-2 border border-gray-500/10 h-full text-center rounded-md cursor-pointer"
                    tag="button"
                    @click="setActiveRoleId(8)"
                    >IT</Tab
                >
                <Tab
                    class="flex-1 flex items-center justify-center w-full p-2 border border-gray-500/10 h-full text-center rounded-md cursor-pointer"
                    tag="button"
                    @click="setActiveRoleId(9)"
                    >Team Lead</Tab
                >
                <Tab
                    class="flex-1 flex items-center justify-center w-full p-2 border border-gray-500/10 h-full text-center rounded-md cursor-pointer"
                    tag="button"
                    @click="setActiveRoleId(10)"
                    >Office Admin</Tab
                >
                <Tab
                    class="flex-1 flex items-center justify-center w-full p-2 border border-gray-500/10 h-full text-center rounded-md cursor-pointer"
                    tag="button"
                    @click="setActiveRoleId(11)"
                    >Sales</Tab
                >
                <Tab
                    class="flex-1 flex items-center justify-center w-full p-2 border border-gray-500/10 h-full text-center rounded-md cursor-pointer"
                    tag="button"
                    @click="setActiveRoleId(12)"
                    >Accounting</Tab
                >
                <Tab
                    class="flex-1 flex items-center justify-center w-full p-2 border border-gray-500/10 h-full text-center rounded-md cursor-pointer"
                    tag="button"
                    @click="setActiveRoleId(13)"
                    >Finance</Tab
                >
            </div>
        </List>
        <Panels
            class="mt-5 border-2 border-gray-300/30 rounded-md p-2 shadow-lg"
        >
            <Panel class="leading-relaxed">
                <template></template>
                <div class="grid grid-cols-4 gap-2">
                    <template v-for="groups in roleGroups.others">
                        <PanelPermissionGroup
                            :panelTitle="groups.friendlyName"
                            :data="groups.permissions"
                            :role="'admin'"
                            :roleId="active_role_id"
                            @response="notify"
                        />
                    </template>
                </div>
                <div class="mt-2 shadow-lg">
                    <template v-for="groups in roleGroups.tickets">
                        <PanelPermissionGroup2
                            :panelTitle="groups.friendlyName"
                            :data="groups.permissions"
                            :role="'admin'"
                            :roleId="active_role_id"
                            @response="notify"
                        />
                    </template>
                </div>
            </Panel>
            <Panel class="leading-relaxed">
                <div class="grid grid-cols-4 gap-2">
                    <template v-for="groups in roleGroups.others">
                        <PanelPermissionGroup
                            :panelTitle="groups.friendlyName"
                            :data="groups.permissions"
                            :role="'payroll'"
                            :roleId="active_role_id"
                            @response="notify"
                        />
                    </template>
                </div>
                <div class="mt-2 shadow-lg">
                    <template v-for="groups in roleGroups.tickets">
                        <PanelPermissionGroup2
                            :panelTitle="groups.friendlyName"
                            :data="groups.permissions"
                            :role="'payroll'"
                            :roleId="active_role_id"
                            @response="notify"
                        />
                    </template>
                </div>
            </Panel>
            <Panel class="leading-relaxed">
                <div class="grid grid-cols-4 gap-2">
                    <template v-for="groups in roleGroups.others">
                        <PanelPermissionGroup
                            :panelTitle="groups.friendlyName"
                            :data="groups.permissions"
                            :role="'account receivable'"
                            :roleId="active_role_id"
                            @response="notify"
                        />
                    </template>
                </div>
                <div class="mt-2 shadow-lg">
                    <template v-for="groups in roleGroups.tickets">
                        <PanelPermissionGroup2
                            :panelTitle="groups.friendlyName"
                            :data="groups.permissions"
                            :role="'account receivable'"
                            :roleId="active_role_id"
                            @response="notify"
                        />
                    </template>
                </div>
            </Panel>
            <Panel class="leading-relaxed">
                <div class="grid grid-cols-4 gap-2">
                    <template v-for="groups in roleGroups.others">
                        <PanelPermissionGroup
                            :panelTitle="groups.friendlyName"
                            :data="groups.permissions"
                            :role="'hr'"
                            :roleId="active_role_id"
                            @response="notify"
                        />
                    </template>
                </div>
                <div class="mt-2 shadow-lg">
                    <template v-for="groups in roleGroups.tickets">
                        <PanelPermissionGroup2
                            :panelTitle="groups.friendlyName"
                            :data="groups.permissions"
                            :role="'hr'"
                            :roleId="active_role_id"
                            @response="notify"
                        />
                    </template>
                </div>
            </Panel>
            <Panel class="leading-relaxed">
                <div class="grid grid-cols-4 gap-2">
                    <template v-for="groups in roleGroups.others">
                        <PanelPermissionGroup
                            :panelTitle="groups.friendlyName"
                            :data="groups.permissions"
                            :role="'recruitment'"
                            :roleId="active_role_id"
                            @response="notify"
                        />
                    </template>
                </div>
                <div class="mt-2 shadow-lg">
                    <template v-for="groups in roleGroups.tickets">
                        <PanelPermissionGroup2
                            :panelTitle="groups.friendlyName"
                            :data="groups.permissions"
                            :role="'recruitment'"
                            :roleId="active_role_id"
                            @response="notify"
                        />
                    </template>
                </div>
            </Panel>
            <Panel class="leading-relaxed">
                <div class="grid grid-cols-4 gap-2">
                    <template v-for="groups in roleGroups.others">
                        <PanelPermissionGroup
                            :panelTitle="groups.friendlyName"
                            :data="groups.permissions"
                            :role="'client'"
                            :roleId="active_role_id"
                            @response="notify"
                        />
                    </template>
                </div>
                <div class="mt-2 shadow-lg">
                    <template v-for="groups in roleGroups.tickets">
                        <PanelPermissionGroup2
                            :panelTitle="groups.friendlyName"
                            :data="groups.permissions"
                            :role="'client'"
                            :roleId="active_role_id"
                            @response="notify"
                        />
                    </template>
                </div>
            </Panel>
            <Panel class="leading-relaxed">
                <div class="grid grid-cols-4 gap-2">
                    <template v-for="groups in roleGroups.others">
                        <PanelPermissionGroup
                            :panelTitle="groups.friendlyName"
                            :data="groups.permissions"
                            :role="'employee'"
                            :roleId="active_role_id"
                            @response="notify"
                        />
                    </template>
                </div>
                <div class="mt-2 shadow-lg">
                    <template v-for="groups in roleGroups.tickets">
                        <PanelPermissionGroup2
                            :panelTitle="groups.friendlyName"
                            :data="groups.permissions"
                            :role="'employee'"
                            :roleId="active_role_id"
                            @response="notify"
                        />
                    </template>
                </div>
            </Panel>
            <Panel class="leading-relaxed">
                <div class="grid grid-cols-4 gap-2">
                    <template v-for="groups in roleGroups.others">
                        <PanelPermissionGroup
                            :panelTitle="groups.friendlyName"
                            :data="groups.permissions"
                            :role="'it'"
                            :roleId="active_role_id"
                            @response="notify"
                        />
                    </template>
                </div>
                <div class="mt-2 shadow-lg">
                    <template v-for="groups in roleGroups.tickets">
                        <PanelPermissionGroup2
                            :panelTitle="groups.friendlyName"
                            :data="groups.permissions"
                            :role="'it'"
                            :roleId="active_role_id"
                            @response="notify"
                        />
                    </template>
                </div>
            </Panel>
            <Panel class="leading-relaxed">
                <div class="grid grid-cols-4 gap-2">
                    <template v-for="groups in roleGroups.others">
                        <PanelPermissionGroup
                            :panelTitle="groups.friendlyName"
                            :data="groups.permissions"
                            :role="'team lead'"
                            :roleId="active_role_id"
                            @response="notify"
                        />
                    </template>
                </div>
                <div class="mt-2 shadow-lg">
                    <template v-for="groups in roleGroups.tickets">
                        <PanelPermissionGroup2
                            :panelTitle="groups.friendlyName"
                            :data="groups.permissions"
                            :role="'team lead'"
                            :roleId="active_role_id"
                            @response="notify"
                        />
                    </template>
                </div>
            </Panel>
            <Panel class="leading-relaxed">
                <div class="grid grid-cols-4 gap-2">
                    <template v-for="groups in roleGroups.others">
                        <PanelPermissionGroup
                            :panelTitle="groups.friendlyName"
                            :data="groups.permissions"
                            :role="'office admin'"
                            :roleId="active_role_id"
                            @response="notify"
                        />
                    </template>
                </div>
                <div class="mt-2 shadow-lg">
                    <template v-for="groups in roleGroups.tickets">
                        <PanelPermissionGroup2
                            :panelTitle="groups.friendlyName"
                            :data="groups.permissions"
                            :role="'office admin'"
                            :roleId="active_role_id"
                            @response="notify"
                        />
                    </template>
                </div>
            </Panel>
            <Panel class="leading-relaxed">
                <div class="grid grid-cols-4 gap-2">
                    <template v-for="groups in roleGroups.others">
                        <PanelPermissionGroup
                            :panelTitle="groups.friendlyName"
                            :data="groups.permissions"
                            :role="'sales'"
                            :roleId="active_role_id"
                            @response="notify"
                        />
                    </template>
                </div>
                <div class="mt-2 shadow-lg">
                    <template v-for="groups in roleGroups.tickets">
                        <PanelPermissionGroup2
                            :panelTitle="groups.friendlyName"
                            :data="groups.permissions"
                            :role="'sales'"
                            :roleId="active_role_id"
                            @response="notify"
                        />
                    </template>
                </div>
            </Panel>
            <Panel class="leading-relaxed">
                <div class="grid grid-cols-4 gap-2">
                    <template v-for="groups in roleGroups.others">
                        <PanelPermissionGroup
                            :panelTitle="groups.friendlyName"
                            :data="groups.permissions"
                            :role="'accounting'"
                            :roleId="active_role_id"
                            @response="notify"
                        />
                    </template>
                </div>
                <div class="mt-2 shadow-lg">
                    <template v-for="groups in roleGroups.tickets">
                        <PanelPermissionGroup2
                            :panelTitle="groups.friendlyName"
                            :data="groups.permissions"
                            :role="'accounting'"
                            :roleId="active_role_id"
                            @response="notify"
                        />
                    </template>
                </div>
            </Panel>
            <Panel class="leading-relaxed">
                <div class="grid grid-cols-4 gap-2">
                    <template v-for="groups in roleGroups.others">
                        <PanelPermissionGroup
                            :panelTitle="groups.friendlyName"
                            :data="groups.permissions"
                            :role="'finance'"
                            :roleId="active_role_id"
                            @response="notify"
                        />
                    </template>
                </div>
                <div class="mt-2 shadow-lg">
                    <template v-for="groups in roleGroups.tickets">
                        <PanelPermissionGroup2
                            :panelTitle="groups.friendlyName"
                            :data="groups.permissions"
                            :role="'finance'"
                            :roleId="active_role_id"
                            @response="notify"
                        />
                    </template>
                </div>
            </Panel>
        </Panels>
    </Group>
    <ToastSuccess
        :message="'Successfully Updated'"
        :subject="'Role Permission'"
    />
</template>
