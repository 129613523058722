<script lang="ts" setup>
import { computed, onMounted, provide, reactive, ref } from "vue";
import Avatar from "./components/Avatar.vue";
import Button from "../base-components/Button";
import FormInput from "../base-components/Form/FormInput.vue";
import FormSlider from "./FormSlider.vue";
import PageHeaderStandard from "./components/PageHeaderStandard.vue";
import TabEmployeeDetails from "./components/TabEmployeeDetails.vue";
import { useAuthStore } from "../stores/authStore";
import { useTeamStore } from "../stores/teamStore";
import { useEmployeesStore } from "../stores/employeesStore";
import EmployeeInterface from "./interfaces/employeesInterface";
import EmployeeShiftsView from "./EmployeeShiftsView.vue";

const AuthStore = useAuthStore();
const TeamStore = useTeamStore();
const EmployeesStore = useEmployeesStore();

// Refs
const importantNotesRef = ref();
const teamleads = ref();
const teammembers = ref();
const clientInfo = ref();
const timezone = ref();
const ticketsInfo = ref();
const employeeData = reactive({
    employee: undefined as EmployeeInterface | undefined,
});

const loading = ref(true);
const showSlider = ref(false);
const basicModalPreview = ref(false);
const isEmployeeViewDetailSliderOut = ref(false);
// Reactive data
const viewEmployeeData = reactive({
    data: [],
    client: [],
});
const formEmployeeSettings = reactive({
    id: 0,
});
const data = reactive({
    teamSearchKeywords: "",
});

// Provide
provide("bind[importantNotesRef]", (el: any) => {
    importantNotesRef.value = el;
});

// Lifecycle hooks
onMounted(async () => {
    await loadLazyData();
    //@ts-ignore
    (window as any).Echo.channel("clockin-channel").listen(
        "ClockIn",
        async (e: any) => {
            console.log("go public");
            console.log(e);
            await AuthStore.getLoggedInUserDetails();
            loadLazyData();
        }
    );
});

// Computed properties
const filteredTeamsCount = computed(() => {
    return TeamStore.filterTeamsCountByName(data.teamSearchKeywords);
});

// Functions
const closeEmployeeViewDetailSlider = () => {
    isEmployeeViewDetailSliderOut.value = false;
};
const loadLazyData = async () => {
    //@ts-ignore
    const userId = window.Laravel.user.id;
    await TeamStore.getUserTeam(userId).then((response: any) => {
        // teamleads.value = (TeamStore.myTeam.data.leads) ? TeamStore.myTeam.data.leads : [];
        // teammembers.value = (TeamStore.myTeam.data.members) ? TeamStore.myTeam.data.members : [];
        // clientInfo.value = TeamStore.myTeams.data.client;
        loading.value = false;
    });
};

const prevImportantNotes = () => {
    const el = importantNotesRef.value;
    el.tns.goTo("prev");
};

const formEmployeeStatus = (status: any) => {
    // console.log('status', status);
    // if (status) {
    //     loadLazyData();
    // }
};

const closeSlider = () => {
    loadLazyData();
    formEmployeeSettings.id = 0;
    showSlider.value = false;
};

const viewEmployee = (data: any, client: any) => {
    console.clear();
    console.log(data);
    console.log(client);
    isEmployeeViewDetailSliderOut.value = true;
    employeeData.employee = data;

    if (employeeData.employee) {
        employeeData.employee.client = client;
    }
};

const search = (event: InputEvent) => {
    data.teamSearchKeywords = (event.target as HTMLInputElement).value;
};
</script>

<template>
    <PageHeaderStandard :title="'My Teams'" />
    <div>
        <div>
            <div
                class="intro-y col-span-12 flex flex-wrap sm:flex-nowrap items-center"
                style="margin-top: -30px"
            >
                <div class="md:block mx-auto text-slate-500"></div>
                <div class="w-full sm:w-auto mt-3 sm:mt-0 sm:ml-auto md:ml-0">
                    <div class="lg:w-56 xl:w-56 relative text-slate-500">
                        <FormInput
                            class="form-control lg:w-56 xl:w-56 box pr-10"
                            placeholder="Search..."
                            type="text"
                            v-on:input="search($event as InputEvent)"
                        />
                    </div>
                </div>
            </div>

            <div
                v-for="(teams, ctr) in TeamStore.myTeams"
                class="mt-4 p-4 border border-gray-300 bg-white rounded-lg shadow-lg"
            >
                <h2 class="intro-y text-lg font-medium mt-4 ml-1">
                    {{ teams.name }}
                </h2>

                <div class="grid grid-cols-12 gap-6 mt-5">
                    <div
                        v-for="team in TeamStore.filterTeamEmployeesByName(
                            data.teamSearchKeywords,
                            ctr
                        )"
                        class="intro-y col-span-12 md:col-span-6"
                    >
                        <div class="box">
                            <div
                                class="flex flex-col lg:flex-row items-center p-5"
                            >
                                <div
                                    class="w-24 h-24 lg:w-12 lg:h-12 image-fit lg:mr-1 px-4 lg:px-0"
                                >
                                    <Avatar
                                        :name="team.employee.name"
                                        :ring="
                                            team.employee
                                                .clockin_status_type_id === 2
                                                ? 'offline'
                                                : 'online'
                                        "
                                        :size="'sm'"
                                        @click="
                                            viewEmployee(
                                                team.employee,
                                                teams.client
                                            )
                                        "
                                    />
                                    <div
                                        v-if="team.type === 'leads'"
                                        class="absolute -mb-3 -mr-3 flex items-center justify-center bottom-0 right-0 bg-yellow-400 rounded-full p-2"
                                    >
                                        <!--                                        <StarIcon class="w-3 h-3 text-black" />-->
                                    </div>
                                </div>
                                <div
                                    class="lg:ml-2 lg:mr-auto text-center lg:text-left mt-3 lg:mt-0"
                                >
                                    <a
                                        class="font-medium cursor-pointer ml-2"
                                        @click="
                                            viewEmployee(
                                                team.employee,
                                                teams.client
                                            )
                                        "
                                        >{{ team.employee.name }}</a
                                    >
                                    <div
                                        class="text-slate-500 text-xs mt-0.5 text-red-500 ml-2"
                                    >
                                        {{ team.employee.position }}
                                    </div>
                                    <div class="text-center lg:text-left ml-2">
                                        <div
                                            class="flex items-center justify-center lg:justify-start text-slate-500"
                                        >
                                            <!--                                            <MailIcon class="w-3 h-3 mr-2" />-->
                                            {{ team.employee.personal_email }}
                                        </div>
                                    </div>
                                </div>
                                <div class="flex mt-4 lg:mt-0 text-sm">
                                    <Button
                                        class="text-xs"
                                        variant="customPrimary"
                                        @click="
                                            viewEmployee(
                                                team.employee,
                                                teams.client
                                            )
                                        "
                                        >View Profile
                                    </Button>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <h2
                v-if="TeamStore.myTeams.length == 0"
                class="intro-y text-md font-medium mt-10"
            >
                Not part of a team as of the moment
            </h2>
        </div>
        <FormSlider
            :header="'View Employee Details'"
            :isOpen="isEmployeeViewDetailSliderOut"
            :size="'md'"
            @close="closeEmployeeViewDetailSlider()"
        >
            <div class="text-left">
                <TabEmployeeDetails
                    :employee="employeeData.employee"
                    :showIdCard="true"
                />
            </div>
        </FormSlider>
    </div>
    <div
        class="mt-4 p-4 intro-y border border-gray-300 bg-white rounded-lg shadow-lg"
    >
        <EmployeeShiftsView :team-view="true" />
    </div>
</template>
